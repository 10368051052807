<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/karyawan?id_karyawan='+search.id_karyawan+'&nama='+search.nama+'&nik='+search.nik+'&jabatan='+search.jabatan+'&unit='+search.unit+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Vehicle List</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Vehicle Code</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Vehicle Name</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Vehicle Type</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Brand</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Plat No.</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">STNK No.</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">STNK Exipration Date</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">KIR No.</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">KIR Exipration Date</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Max Capacity</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Driver</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Status</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Plant</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                          <span class="badge badge-sm bg-gradient-primary">{{ row.code }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.vehicle_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.vehicle_type }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.brand }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.plate_no }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.stnk_no }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.stnk_expiration_date).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.inspection_no }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.kir_expiration_date).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.max_capacity }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.driver_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-info" v-if="row.status == 'Ready'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-danger" v-if="row.status == 'Not Ready'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-success" v-if="row.status == 'On Duty'">{{ row.status }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.plant_name }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Vehicle Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Vehicle Name" v-model="vehicleListData.vehicle_name">
        <label for="example-text-input" class="form-control-label mt-3">Vehicle Type <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="vehicleListData.vehicle_type">
          <option value="Passenger Car">Passenger Car</option>
          <option value="Pick Up Car">Pick Up Car</option>
          <option value="Truck">Truck</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Brand <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Brand" v-model="vehicleListData.brand">
        <label for="example-text-input" class="form-control-label mt-3">Plate No <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Plat No" v-model="vehicleListData.plate_no">
        <label for="example-text-input" class="form-control-label mt-3">STNK No. <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="STNK No." v-model="vehicleListData.stnk_no">
        <label for="example-text-input" class="form-control-label mt-3">STNK Exipration Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="STNK Exipration Date" v-model="vehicleListData.stnk_expiration_date">
        <label for="example-text-input" class="form-control-label mt-3">KIR No. <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="KIR No." v-model="vehicleListData.inspection_no">
        <label for="example-text-input" class="form-control-label mt-3">KIR Exipration Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="KIR Exipration Date" v-model="vehicleListData.kir_expiration_date">
        <label for="example-text-input" class="form-control-label mt-3">Max Capacity <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Max Capacity" v-model="vehicleListData.max_capacity">
        <label for="example-text-input" class="form-control-label mt-3">Driver <span style="color: red;">*</span></label>
        <multiselect v-model="vehicleListData.driver" :options="tableDriver.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Driver" label="driver_name" track-by="driver_name" :preselect-first="true" @keyup="getDriver">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Status <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="vehicleListData.status">
          <option value="Ready">Ready</option>
          <option value="Not Ready">Not Ready</option>
          <option value="On Duty">On Duty</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Plant <span style="color: red;">*</span></label>
        <multiselect class="mb-3" v-model="vehicleListData.plant" :options="tablePlant.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Plant" label="name" track-by="name" :preselect-first="true" @keyup="getPlant">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <button type="primary" class="btn btn-sm btn-info btn-fill" @click="save()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Save</span>
          </span>
        </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Code</label>
        <input type="text" class="form-control" placeholder="Code" v-model="search.code" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Driver Name</label>
        <input type="text" class="form-control" placeholder="Driver Name" v-model="search.driver_name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Remarks</label>
        <input type="text" class="form-control" placeholder="Remarks" v-model="search.remarks" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select" aria-label="Default select example" v-model="search.status" v-on:keyup.enter="get()">
          <option value="Ready">Ready</option>
          <option value="Not Ready">Not Ready</option>
          <option value="On Duty">On Duty</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Plant <span style="color: red;">*</span></label>
        <multiselect class="mb-3" v-model="search.plant" :options="tablePlant.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Plant" label="name" track-by="name" :preselect-first="true" @keyup="getPlant">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import vehicleList from '@/services/vehicleList.service';
import plant from '@/services/plant.service';
import driverList from '@/services/driverList.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      vehicleListData: {},
      search: {
        code: '',
        driver_name: '',
        remarks: '',
        status: '',
        plant: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tablePlant: {
        data: []
      },
      tableDriver: {
        data: []
      },

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, vehicleList.index({code: this.search.code, driver_name: this.search.driver_name, remarks: this.search.warehouse_location, status: this.search.status, plant: this.search.plant['code'], page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getPlant(param){
      let context = this;        
      Api(context, plant.getPlant({search: param.target.value})).onSuccess(function(response) {    
          context.tablePlant.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePlant.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getDriver(param){
      let context = this;        
      Api(context, driverList.getDriver({search: param.target.value})).onSuccess(function(response) {    
          context.tableDriver.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableDriver.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add       = true;
      this.form.show      = true;
      this.form.title     = "Add Data";
      this.vehicleListData = {};
    },
    edit(id) {
      let context = this;               
      Api(context, vehicleList.show(id)).onSuccess(function(response) {
        context.vehicleListData        = response.data.data.data;
        context.vehicleListData.driver = response.data.data.dataDriver;
        context.vehicleListData.plant  = response.data.data.dataPlant;
        context.form.show              = true;
        context.form.title             = 'Edit Data'; 
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.vehicleListData.vehicle_name != undefined && context.vehicleListData.vehicle_type != undefined && context.vehicleListData.brand != undefined && context.vehicleListData.plate_no != undefined && context.vehicleListData.stnk_no != undefined && context.vehicleListData.stnk_expiration_date != undefined && context.vehicleListData.inspection_no != undefined && context.vehicleListData.kir_expiration_date != undefined && context.vehicleListData.max_capacity != undefined && context.vehicleListData.driver != undefined && context.vehicleListData.status != undefined && context.vehicleListData.plant != undefined) {
        formData.append('vehicle_name', context.vehicleListData.vehicle_name);
        formData.append('vehicle_type', context.vehicleListData.vehicle_type);
        formData.append('brand', context.vehicleListData.brand);
        formData.append('plate_no', context.vehicleListData.plate_no);
        formData.append('stnk_no', context.vehicleListData.stnk_no);
        formData.append('stnk_expiration_date', context.vehicleListData.stnk_expiration_date);
        formData.append('inspection_no', context.vehicleListData.inspection_no);
        formData.append('kir_expiration_date', context.vehicleListData.kir_expiration_date);
        formData.append('max_capacity', context.vehicleListData.max_capacity);
        formData.append('driver', JSON.stringify(context.vehicleListData.driver));
        formData.append('status', context.vehicleListData.status);
        formData.append('plant', JSON.stringify(context.vehicleListData.plant));
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, vehicleList.create(formData));
      } else {
          api = Api(context, vehicleList.update(this.vehicleListData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, vehicleList.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
