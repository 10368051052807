<template>
  <div class="py-4 container-fluid">
    <div class="row mb-5">
      <div class="col-lg-8">
      </div>
      <div class="col-lg-4">
        <select class="form-select" aria-label="Default select example" @change="changeProject()" v-model="paramProject" v-if="paramKurcac != 'xcshadbs655o2j0'">
          <option value="C0411" >C0411</option>
          <option value="C0393" >C0393</option>
          <option value="C0392" >C0392</option>
          <option value="E0027" >E0027</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Total Data"
          :value="convertRp(totalData)"
          :valueTwo="convertRp(totalDataDB)"
          iconClass="fa fa-database"
          iconBackground="bg-gradient-primary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Submit DWG"
          :value="convertRp(totalSubmitDwg)"
          :valueTwo="convertRp(totalSubmitDwgDB)"
          iconClass="fa fa-object-group"
          iconBackground="bg-gradient-secondary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="MTO"
          :value="convertRp(totalMto)"
          :valueTwo="convertRp(totalMtoDB)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Material Preparation"
          :value="convertRp(totalMaterialPreparation)"
          :valueTwo="convertRp(totalMaterialPreparationDB)"
          iconClass="fa fa-cubes"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Fit Up"
          :value="convertRp(totalFitUp)"
          :valueTwo="convertRp(totalFitUpDB)"
          iconClass="fa fa-puzzle-piece"
          iconBackground="bg-gradient-warning"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Welding"
          :value="convertRp(totalWelding)"
          :valueTwo="convertRp(totalWeldingDB)"
          iconClass="fa fa-thermometer-full"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="RFI"
          :value="convertRp(totalRfi)"
          :valueTwo="convertRp(totalRfiDB)"
          iconClass="fa fa-tags"
          iconBackground="bg-gradient-info"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Visual"
          :value="convertRp(totalVisual)"
          :valueTwo="convertRp(totalVisualDB)"
          iconClass="fa fa-eye"
          iconBackground="bg-gradient-primary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="PMI"
          :value="convertRp(totalPmi)"
          :valueTwo="convertRp(totalPmiDB)"
          iconClass="fa fa-heartbeat"
          iconBackground="bg-gradient-secondary"
          directionReverse
          v-if="paramProject != 'C0393'"
        ></card>
        <card
          title="PMI"
          value="N/A"
          iconClass="fa fa-newspaper-o"
          iconBackground="bg-gradient-secondary"
          directionReverse
          v-else
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Ferrite"
          :value="convertRp(totalFerrite)"
          :valueTwo="convertRp(totalFerriteDB)"
          iconClass="fa fa-street-view"
          iconBackground="bg-gradient-info"
          directionReverse
          v-if="paramProject != 'C0393'"
        ></card>
        <card
          title="Ferrite"
          value="N/A"
          iconClass="fa fa-newspaper-o"
          iconBackground="bg-gradient-secondary"
          directionReverse
          v-else
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Radiograph"
          :value="convertRp(totalRadiograph)"
          :valueTwo="convertRp(totalRadiographDB)"
          iconClass="fa fa-podcast"
          iconBackground="bg-gradient-warning"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Preasure Test"
          :value="convertRp(totalPreasureTest)"
          :valueTwo="convertRp(totalPreasureTestDB)"
          iconClass="fa fa-life-ring"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Report"
          :value="convertRp(totalReport)"
          :valueTwo="convertRp(totalReportDB)"
          iconClass="ni ni-satisfied"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Pickling"
          :value="convertRp(totalPickling)"
          :valueTwo="convertRp(totalPicklingDB)"
          iconClass="fa fa-truck"
          iconBackground="bg-gradient-primary"
          directionReverse
          v-if="paramProject != 'C0393'"
        ></card>
        <card
          title="Pickling"
          value="N/A"
          iconClass="fa fa-newspaper-o"
          iconBackground="bg-gradient-secondary"
          directionReverse
          v-else
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="WCN"
          :value="convertRp(totalWcn)"
          :valueTwo="convertRp(totalWcnDB)"
          iconClass="fa fa-tasks"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
    </div>

    <!-- CHART PIE DATA -->
    <div class="card mb-3"> 
      <div class="card-header pb-0 text-center align-content-center justify-content-center">
        <h5 class="text-center">PERCENTAGE OF {{paramProject}} PIPING MONITORING GRAPH</h5>
      </div>
      <div class="card-body mt-4 scroll-pie-chart">
        <div class="row">
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">Submit DWG <span style="size:90px; color: red;">{{progresPersenDwg}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartData"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">MTO <span style="size:90px; color: red;">{{progresPersenMto}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataMto"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">Material Preparation <span style="size:90px; color: red;">{{progresPersenPreparation}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataPreparation"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">Fit Up <span style="size:90px; color: red;">{{progresPersenFitUp}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataFitUp"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">Welding <span style="size:90px; color: red;">{{progresPersenWelding}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataWelding"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">RFI <span style="size:90px; color: red;">{{progresPersenRfi}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataRfi"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">Visual <span style="size:90px; color: red;">{{progresPersenVisual}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataVisual"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">PMI <span style="size:90px; color: red;" v-if="paramProject != 'C0393'">{{progresPersenPmi}} %</span><span v-else><b>N/A</b></span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataPmi"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">Ferrite <span style="size:90px; color: red;" v-if="paramProject != 'C0393'">{{progresPersenFerrite}} %</span><span v-else><b>N/A</b></span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataFerrite"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">Radiograph <span style="size:90px; color: red;">{{progresPersenRadiograph}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataRadiograph"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">Preasure Test <span style="size:90px; color: red;">{{progresPersenPreasureTest}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataPreasureTest"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">Report <span style="size:90px; color: red;">{{progresPersenReport}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataReport"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">Pickling <span style="size:90px; color: red;" v-if="paramProject != 'C0393'">{{progresPersenPickling}} %</span><span v-else><b>N/A</b></span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataPickling"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">WCN <span style="size:90px; color: red;">{{progresPersenWcn}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataWcn"
              :height="300" 
            />
          </div>
        </div>
      </div>
    </div>

    <!-- CHART Bar DATA -->
    <div class="card mb-3"> 
      <div class="card-header pb-0 text-center align-content-center justify-content-center">
        <div class="row">
          <div class="col-10">
            <h5 class="text-center">DAILY MONITORING GRAPH {{paramLineChart}} PIPING {{paramProject}} </h5>
          </div>
          <div class="col-2">
            <select class="form-select" aria-label="Default select example" @change="changeLineChart()" v-model="paramLineChart">
              <option value="Submit DWG" >Submit DWG</option>
              <option value="MTO" >MTO</option>
              <option value="Material Preparation" >Material Preparation</option>
              <option value="Fit Up" >Fit Up</option>
              <option value="Welding" >Welding</option>
              <option value="RFI" >RFI</option>
              <option value="Visual" >Visual</option>
              <option value="PMI" >PMI</option>
              <option value="Ferrite" >Ferrite</option>
              <option value="Radiograph" >Radiograph</option>
              <option value="Preasure Test" >Preasure Test</option>
              <option value="Report" >Report</option>
              <option value="Pickling" >Pickling</option>
              <option value="WCN" >WCN</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-body mt-4">
        <div class="pt-3 chart">
          <canvas id="chart-cons-week" class="chart-canvas" height="300"></canvas>
        </div>
      </div>
    </div>

    <!-- CHART Line DATA -->
    <div class="card mb-3"> 
      <div class="card-header pb-0 text-center align-content-center justify-content-center">
        <div class="row">
          <div class="col-10">
            <h5 class="text-center">WEEKLY MONITORING GRAPH {{paramLineChart}} PIPING {{paramProject}} </h5>
          </div>
          <div class="col-2">
            <select class="form-select" aria-label="Default select example" @change="changeLineChart()" v-model="paramLineChart">
              <option value="Submit DWG" >Submit DWG</option>
              <option value="MTO" >MTO</option>
              <option value="Material Preparation" >Material Preparation</option>
              <option value="Fit Up" >Fit Up</option>
              <option value="Welding" >Welding</option>
              <option value="RFI" >RFI</option>
              <option value="Visual" >Visual</option>
              <option value="PMI" >PMI</option>
              <option value="Ferrite" >Ferrite</option>
              <option value="Radiograph" >Radiograph</option>
              <option value="Preasure Test" >Preasure Test</option>
              <option value="Report" >Report</option>
              <option value="Pickling" >Pickling</option>
              <option value="WCN" >WCN</option>
            </select>
            <p class="text-sm mt-5 mb-n12">
              <i class="fa fa-arrow-up text-success"></i>
              <span class="font-weight-bold" v-if="paramLineChart == 'Submit DWG'"> {{progresPersenDwg}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'MTO'"> {{progresPersenMto}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'Material Preparation'"> {{progresPersenPreparation}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'Fit Up'"> {{progresPersenFitUp}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'Welding'"> {{progresPersenWelding}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'RFI'"> {{progresPersenRfi}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'Visual'"> {{progresPersenVisual}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'PMI'"> {{progresPersenPmi}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'Ferrite'"> {{progresPersenFerrite}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'Radiograph'"> {{progresPersenRadiograph}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'Preasure Test'"> {{progresPersenPreasureTest}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'Report'"> {{progresPersenReport}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'Pickling'"> {{progresPersenPickling}} %</span>
              <span class="font-weight-bold" v-if="paramLineChart == 'WCN'"> {{progresPersenWcn}} %</span>
            </p>
          </div>
        </div>
      </div>
      <div class="card-body mt-4 float-right">
        <div class="pt-3 chart">
          <canvas id="chart-line" class="chart-canvas" height="300"></canvas>
        </div>
      </div>
    </div>

    <!-- DATA MASTER -->
    <a class="btn btn-sm btn-success" style="margin-right: 10px;" :href="apiUrl+'export-excel/monitoring-piping?project_number='+paramProject+'&spool_no='+search.spool_no['spool_no']+'&joint_id='+search.joint_id+'&size='+search.size+'&sf='+search.sf+'&material_type='+search.material_type+'&isometric='+search.isometric['isometric']+''" target="_BLANK"><i class="fa fas-file-excel-o fa-sm"></i> Export All Data</a>
    <a class="btn btn-sm btn-warning" style="margin-right: 10px;" :href="apiUrl+'export-excel/monitoring-piping-resume?project_number='+paramProject+'&spool_no='+search.spool_no['spool_no']+'&joint_id='+search.joint_id+'&size='+search.size+'&sf='+search.sf+'&material_type='+search.material_type+'&isometric='+search.isometric['isometric']+''" target="_BLANK"><i class="fa fa-file-excel-o fa-sm"></i> Export Report</a>
   <!--  <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/vehicle-request?request_no='+search.request_no+'&plant='+search.plant['code']+'&destination='+search.destination+'&agenda='+search.agenda+'&departure_date='+search.departure_date+'&return_date='+search.return_date+'&vehicle_type='+search.vehicle_type+'&vehicle='+search.vehicle+'&approved_by='+search.approved_by['name']+'&status='+search.status+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a> -->

    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Master Data Piping</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="primary"
                  size="sm"
                  @click="create()"
                  v-if="(getNameForPermision == 'Denny Setiawan' || getNameForPermision == 'Qodratullah' || getNameForPermision == 'Bayu Sutiara' || getNameForPermision == 'Admin' || getNameForPermision == 'Ade Sundana' || getNameForPermision == 'Arifurrohman')"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Project No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Internal ID</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Joint Parent Id</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Path Id</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Joint Id</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Size</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">SF</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Schedule</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Thickness</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Isometric</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Spool No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Material Type</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Submit DWG</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">MTO</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Material Preparation</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Fit Up</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Welding</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">RFI</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">RFI NO</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">W-STAMP</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Visual</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">PMI</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Ferrite</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Radiograph</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Preasure Test</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Report</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Pickling</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">WCN</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">PC Status</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">PC Lates</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">QC Status</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">QC Lates</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Last Updated By</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.project_number }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.internal_id }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.join_parent_id }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.path_id }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.joint_id }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.size }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.sf }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.schedule }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.thickness }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.isometric }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.spool_no }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.material_type }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.submit_dwg }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.mto }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.material_preparation }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.fit_up }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.welding }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.rfi }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.rfi_no }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.w_stamp }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.visual }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.pmi }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.ferrite }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.radiograph }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.preasure_test }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.report }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.pickling }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.wcn }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.pc_status }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.pc_lates }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qc_status }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qc_lates }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.updated_by }}</span>
                      </td>
                      <!-- <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.departure_date).locale('en').format('ll') }}</span>
                      </td> -->
                      <td class="align-middle text-center text-sm">
                        <i class="fas fa-edit fa-sm" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" v-if="(getNameForPermision == 'Denny Setiawan' || getNameForPermision == 'Qodratullah' || getNameForPermision == 'Bayu Sutiara' || getNameForPermision == 'Admin' || getNameForPermision == 'Ade Sundana' || getNameForPermision == 'Arifurrohman' || getNameForPermision == 'Rivaldi Lambadoko Harahap')"></i>

                        <i class="fas fa-trash fa-sm" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)" v-if="(getNameForPermision == 'Denny Setiawan' || getNameForPermision == 'Qodratullah' || getNameForPermision == 'Bayu Sutiara' || getNameForPermision == 'Admin' || getNameForPermision == 'Ade Sundana' || getNameForPermision == 'Arifurrohman' || getNameForPermision == 'Rivaldi Lambadoko Harahap')"></i>

                        <i class="fa fa-clone fa-sm" style="cursor: pointer;" @click="copy(row.id)" v-if="(getNameForPermision == 'Denny Setiawan' || getNameForPermision == 'Qodratullah' || getNameForPermision == 'Bayu Sutiara' || getNameForPermision == 'Admin' || getNameForPermision == 'Ade Sundana' || getNameForPermision == 'Arifurrohman' || getNameForPermision == 'Rivaldi Lambadoko Harahap')"></i>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project No. <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Project No." v-model="monitoringData.project_number">
        <label for="example-text-input" class="form-control-label mt-3">Internal Id </label>
        <input type="number" class="form-control" placeholder="Internal Id" v-model="monitoringData.internal_id">

        <label for="example-text-input" class="form-control-label mt-3">Joint Parent Id </label>
        <input type="number" class="form-control" placeholder="Joint Parent Id" v-model="monitoringData.join_parent_id">

        <label for="example-text-input" class="form-control-label mt-3">Path Id <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Path Id" v-model="monitoringData.path_id">

        <label for="example-text-input" class="form-control-label mt-3">Joint Id <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Joint Id" v-model="monitoringData.joint_id">

        <label for="example-text-input" class="form-control-label mt-3">Size <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Size" v-model="monitoringData.size">

        <label for="example-text-input" class="form-control-label mt-3">SF <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="monitoringData.sf">
          <option value="FIELD" >FIELD</option>
          <option value="FIELD FIT WELD" >FIELD FIT WELD</option>
          <option value="SHOP" >SHOP</option>
          <option value="WS" >WS</option>
          <option value="FW" >FW</option>
        </select>

        <label for="example-text-input" class="form-control-label mt-3">Schedule </label>
        <input type="text" class="form-control" placeholder="Schedule" v-model="monitoringData.schedule">

        <label for="example-text-input" class="form-control-label mt-3">Thickness </label>
        <input type="text" class="form-control" placeholder="Thickness" v-model="monitoringData.thickness">

        <label for="example-text-input" class="form-control-label mt-3">Isometric <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Isometric" v-model="monitoringData.isometric">

        <label for="example-text-input" class="form-control-label mt-3">Spool No <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Spool No" v-model="monitoringData.spool_no">

        <label for="example-text-input" class="form-control-label mt-3">Material Type <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Material Type" v-model="monitoringData.material_type">
        <!-- <select class="form-select" aria-label="Default select example" v-model="monitoringData.material_type">
          <option value="AS" >AS</option>
          <option value="DSS" >DSS</option>
          <option value="TNM" >TNM</option>
        </select> -->

        <label for="example-text-input" class="form-control-label mt-3">Submit DWG </label>
        <input type="date" class="form-control" placeholder="Submit DWG" v-model="monitoringData.submit_dwg">
        <label for="example-text-input" class="form-control-label mt-3">MTO </label>
        <input type="date" class="form-control" placeholder="MTO" v-model="monitoringData.mto">
        <label for="example-text-input" class="form-control-label mt-3">Material Preparation </label>
        <input type="date" class="form-control" placeholder="Material Preparation" v-model="monitoringData.material_preparation">
        <label for="example-text-input" class="form-control-label mt-3">Fit Up </label>
        <input type="date" class="form-control" placeholder="Fit Up" v-model="monitoringData.fit_up">
        <label for="example-text-input" class="form-control-label mt-3">Welding </label>
        <input type="date" class="form-control" placeholder="Welding" v-model="monitoringData.welding">
        <label for="example-text-input" class="form-control-label mt-3">RFI </label>
        <input type="date" class="form-control" placeholder="RFI" v-model="monitoringData.rfi">
        <label for="example-text-input" class="form-control-label mt-3">RFI NO </label>
        <input type="text" class="form-control" placeholder="RFI NO" v-model="monitoringData.rfi_no">
        <label for="example-text-input" class="form-control-label mt-3">W-STAMP </label>
        <input type="text" class="form-control" placeholder="W-STAMP" v-model="monitoringData.w_stamp">
        <label for="example-text-input" class="form-control-label mt-3">Visual </label>
        <input type="date" class="form-control" placeholder="Visual" v-model="monitoringData.visual">
        <label for="example-text-input" class="form-control-label mt-3">PMI </label>
        <input type="date" class="form-control" placeholder="PMI" v-model="monitoringData.pmi">
        <label for="example-text-input" class="form-control-label mt-3">Ferrite </label>
        <input type="date" class="form-control" placeholder="Ferrite" v-model="monitoringData.ferrite">
        <label for="example-text-input" class="form-control-label mt-3">Radiograph </label>
        <input type="date" class="form-control" placeholder="Radiograph" v-model="monitoringData.radiograph">
        <label for="example-text-input" class="form-control-label mt-3">Preasure Test </label>
        <input type="date" class="form-control" placeholder="Preasure Test" v-model="monitoringData.preasure_test">
        <label for="example-text-input" class="form-control-label mt-3">Report </label>
        <input type="date" class="form-control" placeholder="Report" v-model="monitoringData.report">
        <label for="example-text-input" class="form-control-label mt-3">Pickling </label>
        <input type="date" class="form-control" placeholder="Pickling" v-model="monitoringData.pickling">
        <label for="example-text-input" class="form-control-label mt-3">WCN </label>
        <input type="date" class="form-control" placeholder="WCN" v-model="monitoringData.wcn">
        <label for="example-text-input" class="form-control-label mt-3">PC Status </label>
        <select class="form-select" aria-label="Default select example" v-model="monitoringData.pc_status">
          <option value="Material Preparation" >Material Preparation</option>
          <option value="Fit Up" >Fit Up</option>
          <option value="Welding" >Welding</option>
          <option value="Pickling" >Pickling</option>
          <option value="WCN" >WCN</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">PC Lates </label>
        <input type="date" class="form-control" placeholder="PC Lates" v-model="monitoringData.pc_lates">
        <label for="example-text-input" class="form-control-label mt-3">QC Status </label>
        <select class="form-select" aria-label="Default select example" v-model="monitoringData.qc_status">
          <option value="RFI" >RFI</option>
          <option value="Visual" >Visual</option>
          <option value="PMI" >PMI</option>
          <option value="Ferrite" >Ferrite</option>
          <option value="Radiograph" >Radiograph</option>
          <option value="Preasure Test" >Preasure Test</option>
          <option value="Report" >Report</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">QC Lates </label>
        <input type="date" class="form-control" placeholder="QC Lates" v-model="monitoringData.qc_lates">

      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="sendRequest()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Save</span>
          </span>
        </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL COPY VALUE ======= -->
  <div class="container">
    <vue-final-modal v-model="formCopy.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formCopy.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formCopy.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project No. <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Project No." v-model="monitoringData.project_number">
        <label for="example-text-input" class="form-control-label mt-3">Internal Id </label>
        <input type="number" class="form-control" placeholder="Internal Id" v-model="monitoringData.internal_id">
        <label for="example-text-input" class="form-control-label mt-3">Joint Parent Id </label>
        <input type="number" class="form-control" placeholder="Joint Parent Id" v-model="monitoringData.join_parent_id">
        <label for="example-text-input" class="form-control-label mt-3">Path Id <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Path Id" v-model="monitoringData.path_id">
        <label for="example-text-input" class="form-control-label mt-3">Joint Id <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Joint Id" v-model="monitoringData.joint_id">
        <label for="example-text-input" class="form-control-label mt-3">Size <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Size" v-model="monitoringData.size">
        <label for="example-text-input" class="form-control-label mt-3">SF <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="monitoringData.sf">
          <option value="FIELD" >FIELD</option>
          <option value="FIELD FIT WELD" >FIELD FIT WELD</option>
          <option value="SHOP" >SHOP</option>
          <option value="WS" >WS</option>
          <option value="FW" >FW</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Schedule </label>
        <input type="text" class="form-control" placeholder="Schedule" v-model="monitoringData.schedule">
        <label for="example-text-input" class="form-control-label mt-3">Thickness </label>
        <input type="text" class="form-control" placeholder="Thickness" v-model="monitoringData.thickness">
        <label for="example-text-input" class="form-control-label mt-3">Isometric <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Isometric" v-model="monitoringData.isometric">
        <label for="example-text-input" class="form-control-label mt-3">Spool No <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Spool No" v-model="monitoringData.spool_no">
        <label for="example-text-input" class="form-control-label mt-3">Material Type <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Material Type" v-model="monitoringData.material_type">
        <label for="example-text-input" class="form-control-label mt-3">Submit DWG </label>
        <input type="date" class="form-control" placeholder="Submit DWG" v-model="monitoringData.submit_dwg">
        <label for="example-text-input" class="form-control-label mt-3">MTO </label>
        <input type="date" class="form-control" placeholder="MTO" v-model="monitoringData.mto">
        <label for="example-text-input" class="form-control-label mt-3">Material Preparation </label>
        <input type="date" class="form-control" placeholder="Material Preparation" v-model="monitoringData.material_preparation">
        <label for="example-text-input" class="form-control-label mt-3">Fit Up </label>
        <input type="date" class="form-control" placeholder="Fit Up" v-model="monitoringData.fit_up">
        <label for="example-text-input" class="form-control-label mt-3">Welding </label>
        <input type="date" class="form-control" placeholder="Welding" v-model="monitoringData.welding">
        <label for="example-text-input" class="form-control-label mt-3">RFI </label>
        <input type="date" class="form-control" placeholder="RFI" v-model="monitoringData.rfi">
        <label for="example-text-input" class="form-control-label mt-3">RFI NO </label>
        <input type="text" class="form-control" placeholder="RFI NO" v-model="monitoringData.rfi_no">
        <label for="example-text-input" class="form-control-label mt-3">W-STAMP </label>
        <input type="text" class="form-control" placeholder="W-STAMP" v-model="monitoringData.w_stamp">
        <label for="example-text-input" class="form-control-label mt-3">Visual </label>
        <input type="date" class="form-control" placeholder="Visual" v-model="monitoringData.visual">
        <label for="example-text-input" class="form-control-label mt-3">PMI </label>
        <input type="date" class="form-control" placeholder="PMI" v-model="monitoringData.pmi">
        <label for="example-text-input" class="form-control-label mt-3">Ferrite </label>
        <input type="date" class="form-control" placeholder="Ferrite" v-model="monitoringData.ferrite">
        <label for="example-text-input" class="form-control-label mt-3">Radiograph </label>
        <input type="date" class="form-control" placeholder="Radiograph" v-model="monitoringData.radiograph">
        <label for="example-text-input" class="form-control-label mt-3">Report </label>
        <input type="date" class="form-control" placeholder="Report" v-model="monitoringData.report">
        <label for="example-text-input" class="form-control-label mt-3">Pickling </label>
        <input type="date" class="form-control" placeholder="Pickling" v-model="monitoringData.pickling">
        <label for="example-text-input" class="form-control-label mt-3">WCN </label>
        <input type="date" class="form-control" placeholder="WCN" v-model="monitoringData.wcn">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formCopy.show = true">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
        <button type="primary" class="btn btn-sm btn-success btn-fill" @click="saveCopy()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Copy</span>
          </span>
        </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Spool No</label>
        <multiselect v-model="search.spool_no" :options="tableSpool.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Spool No" label="spool_no" track-by="spool_no" :preselect-first="true" @keyup="getMaterialPiping" @select="getMaterialJointId()" v-on:keyup.enter="get(), getVisual()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Joint Id</label>
        <select class="form-select" aria-label="Default select example" v-model="search.joint_id" v-on:keyup.enter="get(), getVisual()">
          <option :value="row.joint_id" v-for="(row, i) in tableJointId.data" :key="i">{{ row.joint_id }}</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Size</label>
        <input type="text" class="form-control" placeholder="Size" v-model="search.size" v-on:keyup.enter="get(), getVisual()">
        <label for="example-text-input" class="form-control-label mt-3">SF</label>
        <select class="form-select" aria-label="Default select example" v-model="search.sf" v-on:keyup.enter="get(), getVisual()">
          <option value="FIELD" >FIELD</option>
          <option value="FIELD FIT WELD" >FIELD FIT WELD</option>
          <option value="SHOP" >SHOP</option>
          <option value="Workshop" >Workshop</option>
          <option value="WS" >WS</option>
          <option value="FW" >FW</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Material Type</label>
        <input type="text" class="form-control" placeholder="Material Type" v-model="search.material_type" v-on:keyup.enter="get(), getVisual()">
        <!-- <select class="form-select" aria-label="Default select example" v-model="search.material_type" v-on:keyup.enter="get(), getVisual()">
          <option value="AS" >AS</option>
          <option value="DSS" >DSS</option>
          <option value="TNM" >TNM</option>
        </select> -->
        <label for="example-text-input" class="form-control-label mt-3">Isometric</label>
        <multiselect v-model="search.isometric" :options="tableIsometric.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Isometric" label="isometric" track-by="isometric" :preselect-first="true" @keyup="getMaterialIsometric" v-on:keyup.enter="get(), getVisual()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Flow Type</label>
        <select class="form-select" aria-label="Default select example" v-model="search.flow_type" v-on:keyup.enter="get(), getVisual()">
          <option value="SUBMIT DWG" >SUBMIT DWG</option>
          <option value="MTO" >MTO</option>
          <option value="MATERIAL PREPARATION" >MATERIAL PREPARATION</option>
          <option value="FIT UP" >FIT UP</option>
          <option value="WELDING" >WELDING</option>
          <option value="RFI" >RFI</option>
          <option value="VISUAL" >VISUAL</option>
          <option value="PMI" >PMI</option>
          <option value="FERRITE" >FERRITE</option>
          <option value="RADIOGRAPH" >RADIOGRAPH</option>
          <option value="REPORT" >REPORT</option>
          <option value="PICKLING" >PICKLING</option>
          <option value="WCN" >WCN</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Date Range</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control mb-3" v-model="search.date"/>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), getVisual()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'
import Chart from "chart.js/auto";
var moment = require('moment');
import materialPiping from '@/services/materialPiping.service';

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    Card,
    flatPickr,
    Pie,
  },
  data() {
    return {
      backgroundRed: null,
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      getNameForPermision: '',
      getEmployeeForPermision: '',
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formCopy: {
        add: true,
        title: "Copy Value",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      monitoringData: {},
      search: {
        spool_no: '',
        joint_id: '',
        size: '',
        sf: '',
        material_type: '',
        isometric: '',
        flow_type: '',
        date: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tableSpool: {
        data: []
      },
      tableJointId: {
        data: []
      },
      tableIsometric: {
        data: []
      },
      totalData: '',
      totalDataDB: '',
      totalSubmitDwg: '',
      totalSubmitDwgDB: '',
      totalMto: '',
      totalMtoDB: '',
      totalMaterialPreparation: '',
      totalMaterialPreparationDB: '',
      totalFitUp: '',
      totalFitUpDB: '',
      totalWelding: '',
      totalWeldingDB: '',
      totalRfi: '',
      totalRfiDB: '',
      totalVisual: '',
      totalVisualDB: '',
      totalPmi: '',
      totalPmiDB: '',
      totalFerrite: '',
      totalFerriteDB: '',
      totalRadiograph: '',
      totalRadiographDB: '',
      totalPreasureTest: '',
      totalPreasureTestDB: '',
      totalReport: '',
      totalReportDB: '',
      totalPickling: '',
      totalPicklingDB: '',
      totalWcn: '',
      totalWcnDB: '',

      progresPersenDwg: '',
      pendingPersenDwg: '',
      progresPersenMto: '',
      pendingPersenMto: '',
      progresPersenPreparation: '',
      pendingPersenPreparation: '',
      progresPersenFitUp: '',
      pendingPersenFitUp: '',
      progresPersenWelding: '',
      pendingPersenWelding: '',
      progresPersenRfi: '',
      pendingPersenRfi: '',
      progresPersenVisual: '',
      pendingPersenVisual: '',
      progresPersenPmi: '',
      pendingPersenPmi: '',
      progresPersenFerrite: '',
      pendingPersenFerrite: '',
      progresPersenRadiograph: '',
      pendingPersenRadiograph: '',
      progresPersenPreasureTest: '',
      pendingPersenPreasureTest: '',
      progresPersenReport: '',
      pendingPersenReport: '',
      progresPersenPickling: '',
      pendingPersenPickling: '',
      progresPersenWcn: '',
      pendingPersenWcn: '',

      dateWcn: '',
      qtyWcn: [],
      dbWcn: [],
      dateWeek: '',
      qtyWeek: [],
      dbWeek: [],

      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            labelTwo: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataMto: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataPreparation: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataFitUp: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataWelding: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataRfi: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataVisual: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataPmi: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataFerrite: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataRadiograph: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataPreasureTest: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataReport: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataPickling: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataWcn: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartOptions: {
        responsive: false
      },
      paramLineChart: '',
      paramProject: '',
      paramKurcac: '',

    };
  },
  mounted(){
    this.getRoleCustomer()
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    changeProject(param){
      localStorage.setItem('paramProject', this.paramProject)
      location.reload();
    },
    getRoleCustomer(param){
      let context = this;               
      Api(context, materialPiping.getRoleCustomer()).onSuccess(function(response) {
          const projectNumber = ["C0411", "C0393", "C0392", "E0027"];

          if (projectNumber.includes(response.data.data.getEmployeeForPermision)) {
            localStorage.setItem('paramProject', response.data.data.getEmployeeForPermision)
            // untuk disable select project monitoring piping
            localStorage.setItem('paramKurcac', 'xcshadbs655o2j0')
          }
      }).onError(function(error) {                    
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() {
        context.formFilter.show = false; 
        context.paramLineChart = localStorage.getItem('paramLineChart');
        context.paramProject   = localStorage.getItem('paramProject');
        // untuk disable select project monitoring piping
        context.paramKurcac   = localStorage.getItem('paramKurcac');
        context.get();
        context.getVisual();
        context.getLineChart();
      })
      .call()
    },
    get(param){
      let context = this;               
      Api(context, materialPiping.index({project_number: this.paramProject, spool_no: this.search.spool_no['spool_no'], joint_id: this.search.joint_id, size: this.search.size, sf: this.search.sf, material_type: this.search.material_type, isometric: this.search.isometric['isometric'], flow_type: this.search.flow_type, date: this.search.date, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data              = response.data.data.data.data;
          context.pagination.page_count   = response.data.data.data.last_page
          context.getNameForPermision     = response.data.data.getNameForPermision
          context.getEmployeeForPermision = response.data.data.getEmployeeForPermision

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() {
        context.formFilter.show = false; 
      })
      .call()
    },
    getVisual(param){
      let context = this;               
      Api(context, materialPiping.getVisual({project_number: this.paramProject, spool_no: this.search.spool_no['spool_no'], joint_id: this.search.joint_id, size: this.search.size, sf: this.search.sf, material_type: this.search.material_type, isometric: this.search.isometric['isometric'], date: this.search.date })).onSuccess(function(response) {    
          context.totalData                  = response.data.data.totalData
          context.totalDataDB                = response.data.data.totalDataDB
          context.totalSubmitDwg             = response.data.data.totalSubmitDwg
          context.totalSubmitDwgDB           = response.data.data.totalSubmitDwgDB
          context.totalMto                   = response.data.data.totalMto
          context.totalMtoDB                 = response.data.data.totalMtoDB
          context.totalMaterialPreparation   = response.data.data.totalMaterialPreparation
          context.totalMaterialPreparationDB = response.data.data.totalMaterialPreparationDB
          context.totalFitUp                 = response.data.data.totalFitUp
          context.totalFitUpDB               = response.data.data.totalFitUpDB
          context.totalWelding               = response.data.data.totalWelding
          context.totalWeldingDB             = response.data.data.totalWeldingDB
          context.totalVisual                = response.data.data.totalVisual
          context.totalVisualDB              = response.data.data.totalVisualDB
          context.totalRfi                   = response.data.data.totalRfi
          context.totalRfiDB                 = response.data.data.totalRfiDB
          context.totalPmi                   = response.data.data.totalPmi
          context.totalPmiDB                 = response.data.data.totalPmiDB
          context.totalFerrite               = response.data.data.totalFerrite
          context.totalFerriteDB             = response.data.data.totalFerriteDB
          context.totalRadiograph            = response.data.data.totalRadiograph
          context.totalRadiographDB          = response.data.data.totalRadiographDB
          context.totalPreasureTest          = response.data.data.totalPreasureTest
          context.totalPreasureTestDB        = response.data.data.totalPreasureTestDB
          context.totalReport                = response.data.data.totalReport
          context.totalReportDB              = response.data.data.totalReportDB
          context.totalPickling              = response.data.data.totalPickling
          context.totalPicklingDB            = response.data.data.totalPicklingDB
          context.totalWcn                   = response.data.data.totalWcn
          context.totalWcnDB                 = response.data.data.totalWcnDB

          context.progresPersenDwg = response.data.data.progresPersenDwg
          context.pendingPersenDwg = response.data.data.pendingPersenDwg
          context.progresPersenMto = response.data.data.progresPersenMto
          context.pendingPersenMto = response.data.data.pendingPersenMto
          context.progresPersenPreparation = response.data.data.progresPersenPreparation
          context.pendingPersenPreparation = response.data.data.pendingPersenPreparation
          context.progresPersenFitUp = response.data.data.progresPersenFitUp
          context.pendingPersenFitUp = response.data.data.pendingPersenFitUp
          context.progresPersenWelding = response.data.data.progresPersenWelding
          context.pendingPersenWelding = response.data.data.pendingPersenWelding
          context.progresPersenRfi = response.data.data.progresPersenRfi
          context.pendingPersenRfi = response.data.data.pendingPersenRfi
          context.progresPersenVisual = response.data.data.progresPersenVisual
          context.pendingPersenVisual = response.data.data.pendingPersenVisual
          context.progresPersenPmi = response.data.data.progresPersenPmi
          context.pendingPersenPmi = response.data.data.pendingPersenPmi
          context.progresPersenFerrite = response.data.data.progresPersenFerrite
          context.pendingPersenFerrite = response.data.data.pendingPersenFerrite
          context.progresPersenRadiograph = response.data.data.progresPersenRadiograph
          context.pendingPersenRadiograph = response.data.data.pendingPersenRadiograph
          context.progresPersenPreasureTest = response.data.data.progresPersenPreasureTest
          context.pendingPersenPreasureTest = response.data.data.pendingPersenPreasureTest
          context.progresPersenReport = response.data.data.progresPersenReport
          context.pendingPersenReport = response.data.data.pendingPersenReport
          context.progresPersenPickling = response.data.data.progresPersenPickling
          context.pendingPersenPickling = response.data.data.pendingPersenPickling
          context.progresPersenWcn = response.data.data.progresPersenWcn
          context.pendingPersenWcn = response.data.data.pendingPersenWcn

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.fillData();
         
      })
      .call()
    },
    changeLineChart(param){
      localStorage.setItem('paramLineChart', this.paramLineChart)
      location.reload();
    },
    getLineChart(){
      let context = this;               
      Api(context, materialPiping.getLineChart({project_number: this.paramProject, paramLineChart: this.paramLineChart})).onSuccess(function(response) {    

          context.dateWcn = response.data.data.dateWcn
          context.qtyWcn  = response.data.data.qtyWcn
          context.dbWcn   = response.data.data.dbWcn

          context.dateWeek = response.data.data.dateWeek
          context.qtyWeek  = response.data.data.qtyWeek
          context.dbWeek   = response.data.data.dbWeek

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.getChartLine();
         context.getChartBar();
      })
      .call()
    },
    getMaterialPiping(param){
      if (param.target.value.length >= 4) {
        let context = this;        
        Api(context, materialPiping.getMaterialPiping({project_number: this.paramProject, search: param.target.value})).onSuccess(function(response) {    
            context.tableSpool.data = response.data.data;
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.tableSpool.data = [];
            }
        }).onFinish(function() { 
        })
        .call()
      }
    },
    getMaterialJointId(){
      let context = this;

      Api(context, materialPiping.getMaterialJointId({project_number: this.paramProject, spool_no: context.search.spool_no['spool_no'], input_type: ''})).onSuccess(function(response) {    
          context.tableJointId.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableJointId.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getMaterialIsometric(param){
      if (param.target.value.length >= 4) {
        let context = this;        
        Api(context, materialPiping.getMaterialIsometric({project_number: this.paramProject, search: param.target.value})).onSuccess(function(response) {    
            context.tableIsometric.data = response.data.data;
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.tableIsometric.data = [];
            }
        }).onFinish(function() { 
        })
        .call()
      }
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add       = true;
      this.form.show      = true;
      this.form.title     = "Add Data";
      this.monitoringData = {};
    },
    edit(id) {
      let context = this;               
      Api(context, materialPiping.show(id)).onSuccess(function(response) {
        context.monitoringData = response.data.data;
        context.form.show      = true;
        context.form.title     = 'Edit Data'; 
      })
      .call()        
    },
    sendRequest(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.monitoringData.project_number != undefined && context.monitoringData.path_id != undefined && context.monitoringData.joint_id != undefined && context.monitoringData.size != undefined && context.monitoringData.sf != undefined && context.monitoringData.isometric != undefined && context.monitoringData.spool_no != undefined && context.monitoringData.material_type != undefined) {
        formData.append('project_number', context.monitoringData.project_number);
        formData.append('internal_id', (context.monitoringData.internal_id == undefined) ? '' : context.monitoringData.internal_id);
        formData.append('join_parent_id', (context.monitoringData.join_parent_id == undefined) ? '' : context.monitoringData.join_parent_id);
        formData.append('path_id', context.monitoringData.path_id);
        formData.append('joint_id', context.monitoringData.joint_id);
        formData.append('size', context.monitoringData.size);
        formData.append('sf', context.monitoringData.sf);
        formData.append('schedule', (context.monitoringData.schedule == undefined) ? '' : context.monitoringData.schedule);
        formData.append('thickness', (context.monitoringData.thickness == undefined) ? '' : context.monitoringData.thickness);
        formData.append('isometric', context.monitoringData.isometric);
        formData.append('spool_no', context.monitoringData.spool_no);
        formData.append('material_type', context.monitoringData.material_type);

        formData.append('submit_dwg', (context.monitoringData.submit_dwg == undefined) ? '' : context.monitoringData.submit_dwg);
        formData.append('mto', (context.monitoringData.mto == undefined) ? '' : context.monitoringData.mto);
        formData.append('material_preparation', (context.monitoringData.material_preparation == undefined) ? '' : context.monitoringData.material_preparation);
        formData.append('fit_up', (context.monitoringData.fit_up == undefined) ? '' : context.monitoringData.fit_up);
        formData.append('welding', (context.monitoringData.welding == undefined) ? '' : context.monitoringData.welding);
        formData.append('rfi', (context.monitoringData.rfi == undefined) ? '' : context.monitoringData.rfi);
        formData.append('rfi_no', (context.monitoringData.rfi_no == undefined) ? '' : context.monitoringData.rfi_no);
        formData.append('w_stamp', (context.monitoringData.w_stamp == undefined) ? '' : context.monitoringData.w_stamp);
        formData.append('visual', (context.monitoringData.visual == undefined) ? '' : context.monitoringData.visual);
        formData.append('pmi', (context.monitoringData.pmi == undefined) ? '' : context.monitoringData.pmi);
        formData.append('ferrite', (context.monitoringData.ferrite == undefined) ? '' : context.monitoringData.ferrite);
        formData.append('radiograph', (context.monitoringData.radiograph == undefined) ? '' : context.monitoringData.radiograph);
        formData.append('preasure_test', (context.monitoringData.preasure_test == undefined) ? '' : context.monitoringData.preasure_test);
        formData.append('report', (context.monitoringData.report == undefined) ? '' : context.monitoringData.report);
        formData.append('pickling', (context.monitoringData.pickling == undefined) ? '' : context.monitoringData.pickling);
        formData.append('wcn', (context.monitoringData.wcn == undefined) ? '' : context.monitoringData.wcn);
        formData.append('pc_status', (context.monitoringData.pc_status == undefined) ? '' : context.monitoringData.pc_status);
        formData.append('pc_lates', (context.monitoringData.pc_lates == undefined) ? '' : context.monitoringData.pc_lates);
        formData.append('qc_status', (context.monitoringData.qc_status == undefined) ? '' : context.monitoringData.qc_status);
        formData.append('qc_lates', (context.monitoringData.qc_lates == undefined) ? '' : context.monitoringData.wcn);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, materialPiping.create(formData));
      } else {
          api = Api(context, materialPiping.update(this.monitoringData.id, formData));
      }
      api.onSuccess(function(response) {
        context.get();
        context.getVisual();
        context.form.show      = false;
        context.monitoringData = {}
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, materialPiping.delete(id)).onSuccess(function(response) {
            context.get();
            context.getVisual();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    copy(id) {
      let context = this;               
      Api(context, materialPiping.show(id)).onSuccess(function(response) {
        context.monitoringData = response.data.data;
        context.formCopy.show  = true;
        context.formCopy.title = 'Copy Value'; 
      })
      .call()        
    },
    saveCopy(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.monitoringData.project_number != undefined && context.monitoringData.path_id != undefined && context.monitoringData.joint_id != undefined && context.monitoringData.size != undefined && context.monitoringData.sf != undefined && context.monitoringData.isometric != undefined && context.monitoringData.spool_no != undefined && context.monitoringData.material_type != undefined) {
        formData.append('project_number', context.monitoringData.project_number);
        formData.append('internal_id', (context.monitoringData.internal_id == undefined) ? '' : context.monitoringData.internal_id);
        formData.append('join_parent_id', (context.monitoringData.join_parent_id == undefined) ? '' : context.monitoringData.join_parent_id);
        formData.append('path_id', context.monitoringData.path_id);
        formData.append('joint_id', context.monitoringData.joint_id);
        formData.append('size', context.monitoringData.size);
        formData.append('sf', context.monitoringData.sf);
        formData.append('schedule', (context.monitoringData.schedule == undefined) ? '' : context.monitoringData.schedule);
        formData.append('thickness', (context.monitoringData.thickness == undefined) ? '' : context.monitoringData.thickness);
        formData.append('isometric', context.monitoringData.isometric);
        formData.append('spool_no', context.monitoringData.spool_no);
        formData.append('material_type', context.monitoringData.material_type);

        formData.append('submit_dwg', (context.monitoringData.submit_dwg == undefined) ? '' : context.monitoringData.submit_dwg);
        formData.append('mto', (context.monitoringData.mto == undefined) ? '' : context.monitoringData.mto);
        formData.append('material_preparation', (context.monitoringData.material_preparation == undefined) ? '' : context.monitoringData.material_preparation);
        formData.append('fit_up', (context.monitoringData.fit_up == undefined) ? '' : context.monitoringData.fit_up);
        formData.append('welding', (context.monitoringData.welding == undefined) ? '' : context.monitoringData.welding);
        formData.append('rfi', (context.monitoringData.rfi == undefined) ? '' : context.monitoringData.rfi);
        formData.append('rfi_no', (context.monitoringData.rfi_no == undefined) ? '' : context.monitoringData.rfi_no);
        formData.append('w_stamp', (context.monitoringData.w_stamp == undefined) ? '' : context.monitoringData.w_stamp);
        formData.append('visual', (context.monitoringData.visual == undefined) ? '' : context.monitoringData.visual);
        formData.append('pmi', (context.monitoringData.pmi == undefined) ? '' : context.monitoringData.pmi);
        formData.append('ferrite', (context.monitoringData.ferrite == undefined) ? '' : context.monitoringData.ferrite);
        formData.append('radiograph', (context.monitoringData.radiograph == undefined) ? '' : context.monitoringData.radiograph);
        formData.append('report', (context.monitoringData.report == undefined) ? '' : context.monitoringData.report);
        formData.append('pickling', (context.monitoringData.pickling == undefined) ? '' : context.monitoringData.pickling);
        formData.append('wcn', (context.monitoringData.wcn == undefined) ? '' : context.monitoringData.wcn);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, materialPiping.copyValue(formData));
      api.onSuccess(function(response) {
        context.get();
        context.getVisual();
        context.formCopy.show  = false;
        context.monitoringData = {}
        context.notify((context.formCopy === 'Copy Value') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.formCopy != 'Copy Value') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    fillData () {
      this.chartData = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#7FFF00','#FF7F50'],
            data: [this.progresPersenDwg,this.pendingPersenDwg]
          }, 
        ],
      }
      this.chartDataMto = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#00BFFF','#FFD700'],
            data: [this.progresPersenMto,this.pendingPersenMto]
          }, 
        ],
      }
      this.chartDataPreparation = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#FFC0CB','#00FF7F'],
            data: [this.progresPersenPreparation,this.pendingPersenPreparation]
          }, 
        ],
      }
      this.chartDataFitUp = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#FF6347','#40E0D0'],
            data: [this.progresPersenFitUp,this.pendingPersenFitUp]
          }, 
        ],
      }
      this.chartDataWelding = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#9ACD32','#EE82EE'],
            data: [this.progresPersenWelding,this.pendingPersenWelding]
          }, 
        ],
      }
      this.chartDataRfi = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#CD853F','#87CEFA'],
            data: [this.progresPersenRfi,this.pendingPersenRfi]
          }, 
        ],
      }
      this.chartDataVisual = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#9370DB','#CD5C5C'],
            data: [this.progresPersenVisual,this.pendingPersenVisual]
          }, 
        ],
      }
      this.chartDataPmi = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#FF7F50','#FFD700'],
            data: [this.progresPersenPmi,this.pendingPersenPmi]
          }, 
        ],
      }
      this.chartDataFerrite = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#ADFF2F','#ADD8E6'],
            data: [this.progresPersenFerrite,this.pendingPersenFerrite]
          }, 
        ],
      }
      this.chartDataRadiograph = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#20B2AA','#87CEFA'],
            data: [this.progresPersenRadiograph,this.pendingPersenRadiograph]
          }, 
        ],
      }
      this.chartDataPreasureTest = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#DC143C','#00008B'],
            data: [this.progresPersenPreasureTest,this.pendingPersenPreasureTest]
          }, 
        ],
      }
      this.chartDataReport = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#FA8072','#FFC0CB'],
            data: [this.progresPersenReport,this.pendingPersenReport]
          }, 
        ],
      }
      this.chartDataPickling = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#9400D3','#FF8C00'],
            data: [this.progresPersenPickling,this.pendingPersenPickling]
          }, 
        ],
      }
      this.chartDataWcn = {
        labels: ['FINISHED', 'NOT FINISHED YET'],
        datasets: [
          {
            backgroundColor: ['#FFDEAD','#6B8E23'],
            data: [this.progresPersenWcn,this.pendingPersenWcn]
          }, 
        ],
      }
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    setBg(id) {
      this.backgroundRed = id
    },
    getChartLine(){
      var ctx1 = document.getElementById("chart-line").getContext("2d");

      var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, "rgba(94, 114, 228, 0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(94, 114, 228, 0.0)");
      gradientStroke1.addColorStop(0, "rgba(94, 114, 228, 0)");

      new Chart(ctx1, {
        type: "line",
        data: {
          labels: Object.values(this.dateWeek),
          datasets: [
            {
              label: "Joint",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              borderColor: "#3A416F ",
              backgroundColor: gradientStroke1,
              // eslint-disable-next-line no-dupe-keys
              borderWidth: 3,
              fill: true,
              data: Object.values(this.qtyWeek),
              maxBarThickness: 6,
            },
            {
              label: "DB",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              borderColor: "#4BB543 ",
              backgroundColor: gradientStroke1,
              // eslint-disable-next-line no-dupe-keys
              borderWidth: 3,
              fill: true,
              data: Object.values(this.dbWeek),
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#fbfbfb",
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#ccc",
                padding: 20,
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    },
    getChartBar() {
    // Chart Consumption by day
    var ctx = document.getElementById("chart-cons-week").getContext("2d");

    new Chart(ctx, {
      type: "bar",
      data: {
        labels: Object.values(this.dateWcn),
        datasets: [
          {
            label: "Joint",
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "#3A416F",
            data: Object.values(this.qtyWcn),
            maxBarThickness: 6,
          },
          {
            label: "DB",
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "#4BB543",
            data: Object.values(this.dbWcn),
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              beginAtZero: true,
              font: {
                size: 12,
                family: "Open Sans",
                style: "normal",
              },
              color: "#9ca2b7",
            },
          },
          // eslint-disable-next-line no-dupe-keys
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
          // eslint-disable-next-line no-dupe-keys
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
        },
      },
    });
  },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
.scroll-pie-chart { 
  overflow: auto; 
  height: 400px; 
}
</style>
