import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('material-stock-piping/index', {params});
    },
    getHistory(params) {
        return Service().get('material-stock-piping/index-history-material', {params});
    },
    getComponent(params) {
        return Service().get('material-stock-piping/get-component', {params});
    },
    getSize(params) {
        return Service().get('material-stock-piping/get-size', {params});
    },
    show(id) {
        return Service().get('material-stock-piping/show/'+id);
    },
    create(params) {
        return Service().post('material-stock-piping/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('material-stock-piping/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('material-stock-piping/delete/'+id);
    },
    import(params) {
        return Service().post('import/import-stock-material', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}