<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <!-- <div class="container-fluid"> -->
            <div class="top-0 my-auto text-center col-12 d-lg-flex h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div class="position-relative bg-gradient-primary h-100 m-3  border-radius-lg d-flex flex-column justify-content-center overflow-hidden bg">
                <span class="mask bg-gradient-dark opacity-7"></span>
                <h3 class="mt-3 text-white font-weight-bolder position-relative" style="text-shadow: 2px 2px 4px black;">Vehicle Request</h3>
                <h5 class="mt-0 text-white font-weight-bolder position-relative"> <img src="@/assets/img/logo-tef.png" width="290"></h5>
                <h5 class="mt-0 text-white font-weight-bolder position-relative" style="text-shadow: 2px 2px 4px black;">PT. TOKKI ENGINEERING AND FABRICATION</h5>
                <div class="container-fluid scroll">
                  <div class="row justify-content-center mt-3">
                    <div class="col-lg-3 card mb-6">
                      <div class="card-body text-start">
                        <label for="example-text-input" class="form-control-label mt-3">Plant <span style="color: red;">*</span></label>
                        <select class="form-select" aria-label="Default select example" v-model="vehicleRequestData.plant" @change="getVehicle(), vehicleRequestData.vehicle = ''">
                          <option value="PLANT-001">Discovery</option>
                          <option value="PLANT-002">Endeavor</option>
                          <option value="PLANT-003">Simba</option>
                        </select>
                        <label for="example-text-input" class="form-control-label mt-3">PIC <span style="color: red;">*</span></label>
                        <multiselect v-model="vehicleRequestData.pic" :options="tablePic.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select PIC" label="name" track-by="name" :preselect-first="true" @keyup="getPic">
                          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
                        </multiselect>
                        <label for="example-text-input" class="form-control-label mt-3">Passenger <span style="color: red;">*</span></label>
                        <multiselect v-model="vehicleRequestData.passenger" :options="tablePassenger.data" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Passenger" label="name" track-by="name" :preselect-first="true" @keyup="getPassenger">
                          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
                        </multiselect>
                        <label for="example-text-input" class="form-control-label mt-3">Destination <span style="color: red;">*</span></label>
                        <textarea class="form-control" v-model="vehicleRequestData.destination" placeholder="Destination"></textarea>
                        <label for="example-text-input" class="form-control-label mt-3">Agenda <span style="color: red;">*</span></label>
                        <textarea class="form-control" v-model="vehicleRequestData.agenda" placeholder="Agenda"></textarea>
                        <label for="example-text-input" class="form-control-label mt-3">Departure Date <span style="color: red;">*</span></label>
                        <input type="date" class="form-control" placeholder="Departure Date" v-model="vehicleRequestData.departure_date" @change="getVehicle(), vehicleRequestData.vehicle = ''">
                        <label for="example-text-input" class="form-control-label mt-3">Return Date <span style="color: red;">*</span></label>
                        <input type="date" class="form-control" placeholder="Return Date" v-model="vehicleRequestData.return_date" :min="vehicleRequestData.departure_date" @change="getVehicle(), vehicleRequestData.vehicle = ''">
                        <label for="example-text-input" class="form-control-label mt-3">Clock Gone <span style="color: red;">*</span></label>
                        <flat-pickr :config="{ enableTime: 'true', noCalendar: 'true', dateFormat: 'H:i', time_24hr: 'true',}" class="form-control" v-model="vehicleRequestData.clock_gone" @change="getVehicle(), vehicleRequestData.vehicle = ''"/>
                        <label for="example-text-input" class="form-control-label mt-3">Clock Back <span style="color: red;">*</span></label>
                        <flat-pickr :config="{ enableTime: 'true', noCalendar: 'true', dateFormat: 'H:i', time_24hr: 'true', minTime:'vehicleRequestData.clock_gone'}" class="form-control" v-model="vehicleRequestData.clock_back" @change="getVehicle(), vehicleRequestData.vehicle = ''" />
                        <label for="example-text-input" class="form-control-label mt-3">Vehicle Type <span style="color: red;">*</span></label>
                        <select class="form-select" aria-label="Default select example" v-model="vehicleRequestData.vehicle_type" @change="getVehicle(), vehicleRequestData.vehicle = ''">
                          <option value="Passenger Car">Passenger Car</option>
                          <option value="Pick Up Car">Pick Up Car</option>
                          <option value="Truck">Truck</option>
                        </select>
                        <!-- <label for="example-text-input" class="form-control-label mt-3">Vehicle Available <span style="color: red;">*</span></label>
                        <select class="form-select mb-3" aria-label="Default select example" v-model="vehicleRequestData.vehicle" >
                          <option :value="row.code" v-for="(row, i) in tableVehicle.data" :key="i">{{ row.vehicle_name }} - {{ row.driver_name }}</option>
                        </select> -->
                      </div>
                      <div class="card-footer bg-dark mb-4" style="height: 60px;">
                        <button type="success" style="margin-top: -10px;" class="btn btn-sm btn-success btn-fill" @click="sendRequest()" :disabled="onLoading">
                          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
                          <span v-else>
                              <span>Send Request <i class="fa fa-paper-plane mr-5" aria-hidden="true"></i></span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </section>
  </main>
</template>

<script>
// import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import config from '@/configs/config';
import axios from 'axios';
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import plant from '@/services/plant.service';
import employee from '@/services/employee.service';
import vehicleList from '@/services/vehicleList.service';
import vehicleRequest from '@/services/vehicleRequest.service';

export default {
  name: "signin",
  components: {
    // ArgonButton,
    Multiselect,
    flatPickr,
  },
  data() {
    return {
      username: '',
      password: '',
      onLoading: false,
      vehicleRequestData: {},
      table: {
        data: []
      },
      tablePlant: {
        data: []
      },
      tablePic: {
        data: []
      },
      tablePassenger: {
        data: []
      },
      tableVehicle: {
        data: []
      },
    };
  },
  mounted(){
    // this.getPrivilage();
    // this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    // getPlant(param){
    //   let context = this;        
    //   Api(context, plant.getPlant({search: param.target.value})).onSuccess(function(response) {    
    //       context.tablePlant.data = response.data.data;
    //   }).onError(function(error) {                    
    //       if (error.response.status == 404) {
    //           context.tablePlant.data = [];
    //       }
    //   }).onFinish(function() { 
    //   })
    //   .call()
    // },
    getPic(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tablePic.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePic.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPassenger(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tablePassenger.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePassenger.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getVehicle(param){
      let context = this;        
      Api(context, vehicleList.getVehicleByPlant({plant: context.vehicleRequestData.plant, vehicle_type: context.vehicleRequestData.vehicle_type, departure_date: context.vehicleRequestData.departure_date, return_date: context.vehicleRequestData.return_date, clock_gone: context.vehicleRequestData.clock_gone, clock_back: context.vehicleRequestData.clock_back })).onSuccess(function(response) {    
          context.tableVehicle.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
            context.tableVehicle.data = [];
            if (context.vehicleRequestData.vehicle_type) {
              // context.notify('Kendaraan tidak tersedia, mungkin majukan schedule anda 1 jam kedepan' + error.response.data.data, 'error')
            }
          }
      }).onFinish(function() { 
      })
      .call()
    },
    sendRequest(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.vehicleRequestData.plant != undefined && context.vehicleRequestData.pic != undefined && context.vehicleRequestData.destination != undefined && context.vehicleRequestData.agenda != undefined && context.vehicleRequestData.departure_date != undefined && context.vehicleRequestData.return_date != undefined && context.vehicleRequestData.clock_gone != undefined && context.vehicleRequestData.clock_back != undefined && context.vehicleRequestData.vehicle_type != undefined) {
        formData.append('plant', context.vehicleRequestData.plant);
        formData.append('pic', JSON.stringify(context.vehicleRequestData.pic));
        formData.append('passenger', JSON.stringify(context.vehicleRequestData.passenger));
        formData.append('destination', context.vehicleRequestData.destination);
        formData.append('agenda', context.vehicleRequestData.agenda);
        formData.append('departure_date', context.vehicleRequestData.departure_date);
        formData.append('return_date', context.vehicleRequestData.return_date);
        formData.append('clock_gone', context.vehicleRequestData.clock_gone);
        formData.append('clock_back', context.vehicleRequestData.clock_back);
        formData.append('vehicle_type', context.vehicleRequestData.vehicle_type);
        // formData.append('vehicle', context.vehicleRequestData.vehicle);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, vehicleRequest.create(formData));
      api.onSuccess(function(response) {
        context.vehicleRequestData = {}
        context.notify('Request Successful', 'success')
      }).onError(function(error) { 
        context.notify('Request Failed', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped lang="scss">
.bg {
  background-image: url('@/assets/img/gif.gif');
  background-size: 60%;
}
.scroll { 
  overflow: auto; 
  height: 100; 
}
</style>
