<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/vehicle-request?request_no='+search.request_no+'&pic='+search.pic['nik']+'&plant='+search.plant['code']+'&destination='+search.destination+'&agenda='+search.agenda+'&departure_date='+search.departure_date+'&return_date='+search.return_date+'&vehicle_type='+search.vehicle_type+'&vehicle='+search.vehicle+'&approved_by='+search.approved_by['name']+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/vehicle-request?request_no='+search.request_no+'&pic='+search.pic['nik']+'&plant='+search.plant['code']+'&destination='+search.destination+'&agenda='+search.agenda+'&departure_date='+search.departure_date+'&return_date='+search.return_date+'&vehicle_type='+search.vehicle_type+'&vehicle='+search.vehicle+'&approved_by='+search.approved_by['name']+'&status='+search.status+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a> -->
    <div class="row">
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Draft"
          :value="convertRp(totalDraft)"
          iconClass="fa fa-newspaper-o"
          iconBackground="bg-gradient-secondary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Approved"
          :value="convertRp(totalApproved)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Not Approved"
          :value="convertRp(totalNotApproved)"
          iconClass="fa fa-times-circle"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="On Purchased"
          :value="convertRp(totalOnPurchased)"
          iconClass="fa fa-times-circle"
          iconBackground="bg-gradient-warning"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Done"
          :value="convertRp(totalDone)"
          iconClass="ni ni-satisfied"
          iconBackground="bg-gradient-info"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Total Purchase"
          :value="convertRp(totalItem)"
          iconClass="fa fa-tasks"
          iconBackground="bg-gradient-primary"
          directionReverse
        ></card>
      </div>
    </div>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Purchase List</h6>
                </div>
              </div>
              <div class="col-lg-6">
              </div>
              <div class="col-lg-2 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">PR Code</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">REQUEST BY</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">SECTION</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">DESCRIPTION</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">QTY</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">UOM</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">EXPECTED ARRIVAL</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">IMAGE</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">COMMENT</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">APPROVED BY</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">STATUS</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">REQUEST DATE</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                          <span class="badge badge-sm bg-gradient-primary">{{ row.pr_code }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.request_by }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.section }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.description }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.uom }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.expected_arrival).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <img
                          :src="storageUrl+'/image_pr/'+row.image"
                          class="avatar avatar-lg me-1"
                          alt="user1"
                        />
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.comment }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.approved_by }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-secondary" v-if="row.status == 'Draft'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-success" v-if="row.status == 'Approved'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-danger" v-if="row.status == 'Not Approved'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-warning" v-if="row.status == 'On Purchased'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-info" v-if="row.status == 'Done'">{{ row.status }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.date).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <!-- <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i> -->
                        <!-- <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i> -->

                        <i class="fa fa-bicycle" aria-hidden="true" title="On Purchased" style="cursor: pointer; margin-right: 20px;" @click="approval(row.id, 'On Purchased')" v-if="row.status == 'Approved'"></i>
                        <i class="fa fa-check-square fa-sm" aria-hidden="true" title="Done" style="cursor: pointer; margin-right: 20px;" @click="approval(row.id, 'Done')" v-if="row.status == 'Approved'"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">PR CODE</label>
        <input type="text" class="form-control" placeholder="PR CODE" v-model="search.pr_code" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Request By</label>
        <input type="text" class="form-control" placeholder="Request By" v-model="search.request_by" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Section</label>
        <input type="text" class="form-control" placeholder="Section" v-model="search.section" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Description</label>
        <input type="text" class="form-control" placeholder="Description" v-model="search.description" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Expected Arrival</label>
        <input type="date" class="form-control" placeholder="Expected Arrival" v-model="search.expected_arrival" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Request Date</label>
        <input type="date" class="form-control" placeholder="Request Date" v-model="search._date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select" aria-label="Default select example" v-model="search.status" v-on:keyup.enter="get()">
          <option value="Draft">Draft</option>
          <option value="Approved">Approved</option>
          <option value="Not Approved">Not Approved</option>
          <option value="On Purchased">On Purchased</option>
          <option value="Done">Done</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Approved By</label>
        <input type="text" class="form-control" placeholder="Approved By" v-model="search.approved_by" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
// import Multiselect from 'vue-multiselect'
// import 'vue-multiselect/dist/vue-multiselect.css';
// import flatPickr from 'vue-flatpickr-component';
// import 'flatpickr/dist/flatpickr.css';
var moment = require('moment');
import purchaseList from '@/services/purchaseList.service';
import vehicleList from '@/services/vehicleList.service';
import plant from '@/services/plant.service';
import employee from '@/services/employee.service';
import vehicleRequest from '@/services/vehicleRequest.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    // Multiselect,
    BasePagination,
    Card,
    // flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      vehicleRequestData: {},
      search: {
        pr_code: '',
        request_by: '',
        section: '',
        description: '',
        expected_arrival: '',
        date: '',
        status: '',
        approved_by: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tablePlant: {
        data: []
      },
      tablePic: {
        data: []
      },
      tablePassenger: {
        data: []
      },
      tableVehicle: {
        data: []
      },
      totalDraft: '',
      totalApproved: '',
      totalNotApproved: '',
      totalOnPurchased: '',
      totalDone: '',
      totalItem: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, purchaseList.index({pr_code: this.search.request_no, request_by: this.search.request_by, section: this.search.section, description: this.search.description, expected_arrival: this.search.expected_arrival, date: this.search.date, status: this.search.status, approved_by: this.search.approved_by, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page
          context.totalDraft            = response.data.data.totalDraft
          context.totalApproved         = response.data.data.totalApproved
          context.totalNotApproved      = response.data.data.totalNotApproved
          context.totalOnPurchased      = response.data.data.totalOnPurchased
          context.totalDone             = response.data.data.totalDone
          context.totalItem             = response.data.data.totalItem

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getPlant(param){
      let context = this;        
      Api(context, plant.getPlant({search: param.target.value})).onSuccess(function(response) {    
          context.tablePlant.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePlant.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPic(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tablePic.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePic.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPassenger(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tablePassenger.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePassenger.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getVehicle(param){
      let context = this;        
      Api(context, vehicleList.getVehicleByPlant({plant: context.vehicleRequestData.plant, vehicle_type: context.vehicleRequestData.vehicle_type, departure_date: context.vehicleRequestData.departure_date, return_date: context.vehicleRequestData.return_date, clock_gone: context.vehicleRequestData.clock_gone, clock_back: context.vehicleRequestData.clock_back })).onSuccess(function(response) {    
          context.tableVehicle.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableVehicle.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add           = true;
      this.form.show          = true;
      this.form.title         = "Add Data";
      this.vehicleRequestData = {};
    },
    edit(id) {
      let context = this;               
      Api(context, purchaseList.show(id)).onSuccess(function(response) {
        context.vehicleRequestData           = response.data.data.data;
        context.vehicleRequestData.plant     = response.data.data.data.plant_code;
        context.vehicleRequestData.pic       = response.data.data.dataPic[0];
        context.vehicleRequestData.passenger = response.data.data.dataPassenger;
        context.tableVehicle.data            = response.data.data.dataVehicle;
        context.vehicleRequestData.vehicle   = response.data.data.dataVehicle[0].code;
        context.form.show                    = true;
        context.form.title                   = 'Edit Data'; 
      })
      .call()        
    },
    sendRequest(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.vehicleRequestData.plant != undefined && context.vehicleRequestData.pic != undefined && context.vehicleRequestData.destination != undefined && context.vehicleRequestData.agenda != undefined && context.vehicleRequestData.departure_date != undefined && context.vehicleRequestData.return_date != undefined && context.vehicleRequestData.clock_gone != undefined && context.vehicleRequestData.clock_back != undefined && context.vehicleRequestData.vehicle_type != undefined && context.vehicleRequestData.vehicle != undefined) {
        formData.append('plant', context.vehicleRequestData.plant);
        formData.append('pic', JSON.stringify(context.vehicleRequestData.pic));
        formData.append('passenger', JSON.stringify(context.vehicleRequestData.passenger));
        formData.append('destination', context.vehicleRequestData.destination);
        formData.append('agenda', context.vehicleRequestData.agenda);
        formData.append('departure_date', context.vehicleRequestData.departure_date);
        formData.append('return_date', context.vehicleRequestData.return_date);
        formData.append('clock_gone', context.vehicleRequestData.clock_gone);
        formData.append('clock_back', context.vehicleRequestData.clock_back);
        formData.append('vehicle_type', context.vehicleRequestData.vehicle_type);
        formData.append('vehicle', context.vehicleRequestData.vehicle);
        if (context.form.title == 'Edit Data') {
          formData.append('status', context.vehicleRequestData.status);
        }
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, purchaseList.create(formData));
      } else {
          api = Api(context, purchaseList.update(this.vehicleRequestData.id, formData));
      }
      api.onSuccess(function(response) {
        context.get();
        // context.approval(response.data.data.id, 'Approved');
        context.vehicleRequestData = {}
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, purchaseList.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    approval(id, typeApprov){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      formData.append('type_approval', typeApprov);

      api = Api(context, purchaseList.approval(id, formData));
      api.onSuccess(function(response) {
        context.get()
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() { 
      })
      .call();
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
