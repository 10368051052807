import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('material-status-piping/index', {params});
    },
    show(id) {
        return Service().get('material-status-piping/show/'+id);
    },
    create(params) {
        return Service().post('material-status-piping/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('material-status-piping/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('material-status-piping/delete/'+id);
    },
    approval(id, params) {
        return Service().post('material-status-piping/approval/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    detailComponent(params) {
        return Service().get('material-status-piping/get-detail-component', {params});
    },
    updateAlocation(id, params) {
        return Service().post('material-status-piping/update-qty-alocation/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    import(params) {
        return Service().post('import/import-material-spool', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },

    // ----------- components ------------
    createComponent(params) {
        return Service().post('material-status-piping/create-component', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    importComponent(params) {
        return Service().post('import/import-master-component', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    checkReadyAllocationMaterial(params) {
        return Service().post('material-status-piping/check-ready-allocation-material', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}