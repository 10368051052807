<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/karyawan?id_karyawan='+search.id_karyawan+'&nama='+search.nama+'&nik='+search.nik+'&jabatan='+search.jabatan+'&unit='+search.unit+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-12 text-center">
                <div class="card-header pb-0">
                  <h6>MACHINE / ASSET DETAILS</h6><hr>
                </div>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="row">
                <div class="col-lg-6 col-sm-12 text-center">
                  <img :src="storageUrl+'image_maintenance_aset/'+dataMachine.image" class="rounded mb-4 p-3 img-fluid" />
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="container">
                    <p style="margin-top: 10px; font-size: 13px; font-weight: bold;">MACHINE CODE <span style="margin-left: 70px; margin-right: 10px;">:</span> {{ dataMachine.machine_code }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">MACHINE NAME <span style="margin-left: 68px; margin-right: 10px;">:</span> {{ dataMachine.machine_name }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">SERIAL NUMBER <span style="margin-left: 70px; margin-right: 10px;">:</span> {{ dataMachine.serial_number }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">BRAND <span style="margin-left: 127px; margin-right: 10px;">:</span> {{ dataMachine.brand }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">PRODUCTION YEAR <span style="margin-left: 50px; margin-right: 10px;">:</span> {{ dataMachine.production_year }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">PURCHASE DATE <span style="margin-left: 70px; margin-right: 10px;">:</span> {{ moment(dataMachine.purchase_date).locale('id').format('LL') }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">QTY <span style="margin-left: 150px; margin-right: 10px;">:</span> {{ dataMachine.qty }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">LOCATION <span style="margin-left: 110px; margin-right: 10px;">:</span> {{ dataMachine.asset_location }}</p>
                    <p style="margin-top: -7px; font-size: 13px; font-weight: bold;">MAINTENANCE SCHEDULE <span style="margin-left: 9px; margin-right: 10px;">:</span> <span class="badge bg-gradient-success">{{ moment(dataMachine.maintenance_schedule).locale('id').format('LL') }} </span></p>
                    <p style="margin-top: -10px; font-size: 13px; font-weight: bold;">REMARK <span style="margin-left: 123px; margin-right: 10px;">:</span> 
                      <span> {{ dataMachine.remark }} </span>
                    </p>
                  </div>
                </div>

                <div>
                  <div class="container">
                  </div>
                  <div class="container-fluid">
                    <nav class="mt-4">
                      <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#Maintenance" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><i class="fa fa-cogs" aria-hidden="true" style="margin-right: 10px;"></i> Maintenance</button>
                        <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#Sparepart" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><i class="fa fa-tags" style="margin-right: 10px;"></i> Sparepart</button>
                        <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#Documentation" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><i class="fa fa-book" style="margin-right: 10px;"></i> Documentation</button>
                        <!-- <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#Sparepart" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><i class="fa fa-calendar" style="margin-right: 10px;"></i> Mobilization</button> -->
                      </div>
                    </nav>
                    <hr style="height: 5px;">

                    <div class="tab-content mt-4" id="nav-tabContent">
                      <!-- Maintenance -->
                      <div class="tab-pane fade show active" id="Maintenance" role="tabpanel" aria-labelledby="nav-home-tab">
                        <h6><i class="fa fa-cogs" aria-hidden="true" style="margin-right: 10px;"></i> Maintenance History</h6>
                        <div class="table-responsive p-0 scroll">
                          <table class="table table-hover align-items-center mb-0">
                            <thead>
                              <tr style="background-color: #F0F8FF;">
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No.</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Maintenance No.</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Maintenance Type</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Created By</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Created Date</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Problem</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Cause Analysis</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Maintenance Plan</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Target</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Closed Date</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Improvement Results</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Status</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Notes</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Attachment</th>
                                <!-- <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(row, i) in tableMaintenanceHistory.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="badge bg-gradient-danger" v-if="row.maintenance_type == 'User Request' ">{{ row.request_no }}</span>
                                  <span class="badge bg-gradient-info" v-if="row.maintenance_type == 'Preventive' ">{{ row.request_no }}</span>
                                  <span class="badge bg-gradient-warning" v-if="row.maintenance_type == 'Scheduled' ">{{ row.request_no }}</span>
                                  <span class="badge bg-gradient-primary" v-if="row.maintenance_type == 'Predictive' ">{{ row.request_no }}</span>
                                  <span class="badge bg-gradient-success" v-if="row.maintenance_type == 'Corrective' ">{{ row.request_no }}</span>
                                  <span class="badge bg-gradient-secondary" v-if="row.maintenance_type == 'Breakdown' ">{{ row.request_no }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.maintenance_type }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.requested_by }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ moment(row.requested_date).locale('id').format('LL') }}</span>
                                </td>
                                <td class="align-middle text-left text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.problem }}</span>
                                </td>
                                <td class="align-middle text-left text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.cause_analysis }}</span>
                                </td>
                                <td class="align-middle text-left text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.maintenance_plan }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ moment(row.due_date).locale('id').format('LL') }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ moment(row.closed_date).locale('id').format('LL') }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.improvement_results }}</span>
                                </td>
                                 <td class="align-middle text-center text-sm">
                                  <span class="badge bg-gradient-danger" v-if="row.status == 'Open' ">Open</span>
                                  <span class="badge bg-gradient-info" v-if="row.status == 'In Progress' ">In Progress</span>
                                  <span class="badge bg-gradient-warning" v-if="row.status == 'Pending' ">Pending</span>
                                  <span class="badge bg-gradient-success" v-if="row.status == 'Closed' ">Closed</span>
                                </td>
                                <td class="align-middle text-left text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.notes }}</span>
                                </td>
                                <td class="align-middle text-left text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">
                                    <a :href="storageUrl+'image_maintenance_list/'+row.image" target="_BLANK" style="color: blue; size: 5px;">{{ row.image }}</a>
                                  </span>
                                </td>
                                <!-- <td class="align-middle text-center">
                                  <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                                  <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                                </td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <!-- Sparepart -->
                      <div class="tab-pane fade show" id="Sparepart" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div class="row mt-n3">
                          <div class="col-4">
                            <div class="card-header pb-0">
                              <h6><i class="fa fa-tags" style="margin-right: 10px;"></i>Sparepart</h6>
                            </div>
                          </div>
                          <div class="col-4">
                          </div>
                          <div class="col-4 float-right">
                            <argon-button
                              style="margin-right: 10px; margin-left: 60px;"
                              class="mt-4"
                              variant="gradient"
                              color="secondary"
                              size="sm"
                              @click="filterSparepart()"
                            ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                            <argon-button
                              class="mt-4"
                              variant="gradient"
                              color="success"
                              size="sm"
                              @click="createSparepart()"
                            ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
                          </div>
                        </div>
                        <div class="table-responsive p-0 scroll mt-3">
                          <table class="table table-hover align-items-center mb-0">
                            <thead>
                              <tr style="background-color: #6495ED; color: white;">
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">No</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Image</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Part Code</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Sparepart Name</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Specification</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Actual Stock</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">UOM</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Storage Location</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Stock In</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Stock Out</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Min Stock</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Notes</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Created By</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Updated By</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(row, i) in tableSparepart.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <img
                                    :src="storageUrl+'/image_sparepart/'+row.image"
                                    class="avatar avatar-lg me-1"
                                  />
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="badge bg-gradient-info">{{ row.part_code }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.component_name }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.specification }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="badge badge bg-gradient-success" v-if="row.actual_stock >= row.min_stock">{{ row.actual_stock }}</span>
                                  <span class="badge badge bg-gradient-danger" v-if="row.actual_stock < row.min_stock">{{ row.actual_stock }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.uom }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.location }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.stock_in }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.stock_out }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.min_stock }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.notes }}</span>
                                </td>
                                 <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.created_by }}</span>
                                </td>
                                 <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.updated_by }}</span>
                                </td>
                                <td class="align-middle text-center">
                                  <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="editSparepart(row.id)" title="Edit"></i>
                                  <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <!-- Documentation -->
                      <div class="tab-pane fade show" id="Documentation" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div class="row mt-n3">
                          <div class="col-4">
                            <div class="card-header pb-0">
                              <h6><i class="fa fa-book" style="margin-right: 10px;"></i>Documentation</h6>
                            </div>
                          </div>
                          <div class="col-4">
                          </div>
                          <div class="col-4 float-right">
                            <argon-button
                              style="margin-right: 10px; margin-left: 60px;"
                              class="mt-4"
                              variant="gradient"
                              color="secondary"
                              size="sm"
                              @click="filterDocumentation()"
                            ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                            <argon-button
                              class="mt-4"
                              variant="gradient"
                              color="success"
                              size="sm"
                              @click="createDocumentation()"
                            ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
                          </div>
                        </div>
                        <div class="table-responsive p-0 scroll mt-3">
                          <table class="table table-hover align-items-center mb-0">
                            <thead>
                              <tr style="background-color: #6495ED; color: white;">
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">No</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Title</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Description</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Attachment</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Link</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Created By</th>
                                <th class="text-center text-uppercase text-white text-secondary text-xxs font-weight-bolder">Updated By</th>
                                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(row, i) in tableDoc.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                                </td>
                                <td class="align-middle text-left text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.title }}</span>
                                </td>
                                <td class="align-middle text-left text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.description }}</span>
                                </td>
                                <td class="align-middle text-left text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">
                                    <a :href="storageUrl+'attachment_machine_documentation/'+row.attachment" target="_BLANK" style="color: blue; size: 5px;">{{ row.attachment }}</a>
                                  </span>
                                </td>
                                <td class="align-middle text-left text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">
                                    <a :href="row.link" target="_BLANK" style="color: blue; size: 5px;">{{ row.link }}</a>
                                  </span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.created_by }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.updated_by }}</span>
                                </td>
                                <td class="align-middle text-center">
                                  <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="editDocumentation(row.id)" title="Edit"></i>
                                  <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="removeDoc(row.id)"></i>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

              </div>
            </div>
            <div class="card-footer">
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA SPAREPART ======= -->
  <div class="container">
    <vue-final-modal v-model="formSparepart.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formSparepart.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formSparepart.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Image <span style="color: red;">*</span></label>
        <input type="file" ref="fileupload" class="form-control" placeholder="Image" @change="filesChange">
        <label for="example-text-input" class="form-control-label mt-3">Sparepart Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Sparepart Name" v-model="sparepartData.component_name">
        <label for="example-text-input" class="form-control-label mt-3">Specification <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Specification" v-model="sparepartData.specification">
        <!-- <label for="example-text-input" class="form-control-label mt-3">Actual Stock <span style="color: red;">*</span></label> -->
        <!-- <input type="text" class="form-control" placeholder="Actual Stock" v-model="sparepartData.actual_stock" disabled> -->
        <label for="example-text-input" class="form-control-label mt-3">UOM <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="UOM" v-model="sparepartData.uom">
        <label for="example-text-input" class="form-control-label mt-3">Storage Location <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Storage Location" v-model="sparepartData.location">
        <!-- <label for="example-text-input" class="form-control-label mt-3">Stock In <span style="color: red;">*</span></label> -->
        <!-- <input type="text" class="form-control" placeholder="Stock In" v-model="sparepartData.stock_in" disabled> -->
        <label for="example-text-input" class="form-control-label mt-3">Stock Out <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Stock_out" v-model="sparepartData.stock_out">
        <label for="example-text-input" class="form-control-label mt-3">Min Stock <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Min Stock" v-model="sparepartData.min_stock">
        <label for="example-text-input" class="form-control-label mt-3">Notes </label>
        <input type="text" class="form-control" placeholder="Notes" v-model="sparepartData.notes">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formSparepart.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="saveSparepart()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER SPAREPART ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilterSpare.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilterSpare.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilterSpare.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Sparepart Name</label>
        <input type="text" class="form-control" placeholder="Sparepart Name" v-model="searchSparepart.component_name" v-on:keyup.enter="getSparepart()">
        <label for="example-text-input" class="form-control-label mt-3">Specification</label>
        <input type="text" class="form-control" placeholder="Specification" v-model="searchSparepart.specification" v-on:keyup.enter="getSparepart()">
        <label for="example-text-input" class="form-control-label mt-3">Storage Location</label>
        <input type="text" class="form-control" placeholder="Storage Location" v-model="searchSparepart.location" v-on:keyup.enter="getSparepart()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="searchSparepart = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getSparepart()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL ADD DATA DOCUMENTATION ======= -->
  <div class="container">
    <vue-final-modal v-model="formDocumentation.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formDocumentation.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formDocumentation.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Title <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Title" v-model="docData.title">
        <label for="example-text-input" class="form-control-label mt-3">Description <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Description" v-model="docData.description">
        <label for="example-text-input" class="form-control-label mt-3">Attachment </label>
        <input type="file" ref="fileupload" class="form-control" placeholder="Attachment" @change="filesChangeDoc">
        <label for="example-text-input" class="form-control-label mt-3">Link </label>
        <input type="text" class="form-control" placeholder="Link" v-model="docData.link">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formDocumentation.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="saveDocumentation()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL FILTER DOCUMENTATION ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilterDocumentation.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilterDocumentation.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilterDocumentation.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Title</label>
        <input type="text" class="form-control" placeholder="Title" v-model="searchDoc.title" v-on:keyup.enter="getDocumentation()">
        <label for="example-text-input" class="form-control-label mt-3">Description</label>
        <input type="text" class="form-control" placeholder="Description" v-model="searchDoc.description" v-on:keyup.enter="getDocumentation()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="searchDoc = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getDocumentation()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
// import ArgonPagination from "@/components/ArgonPagination.vue";
// import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import machineList from '@/services/machineList.service';
import sparepartList from '@/services/sparepartList.service';
import documentationList from '@/services/documentationList.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    // ArgonPagination,
    // ArgonPaginationItem,
  },
  data() {
    return {
      moment:moment,
      table: {
        data: []
      },
      tableSparepart: {
        data: []
      },
      tableMaintenanceHistory: {
        data: []
      },
      tableDoc: {
        data: []
      },
      formSparepart: {
        add: true,
        title: "Add Sparepart",
        show: false
      },
      formFilterSpare: {
        add: true,
        title: "Filter",
        show: false
      },
      formDocumentation: {
        add: true,
        title: "Add Documentation",
        show: false
      },
      formFilterDocumentation: {
        add: true,
        title: "Filter",
        show: false
      },
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      dataMachine: {},
      sparepartData: {},
      docData: {},
      searchSparepart: {
        machine_code: '',
        component_name: '',
        specification: '',
        location: '',
      },
      searchDoc: {
        title: '',
        description: '',
      },
      apiUrl :config.apiUrl,
      backgroundRed: null,

    };
  },
  mounted(){
    this.get();
    this.getSparepart();
    this.getDocumentation();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, machineList.machineDetail({machine_code: context.$route.params.machine_code})).onSuccess(function(response) {    
          context.dataMachine                  = response.data.data.data;
          context.tableMaintenanceHistory.data = response.data.data.maintenanceHistory;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.dataMachine = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },

    // FOR TAB SPAREPART
    getSparepart(param){
      let context = this;               
      Api(context, sparepartList.index({machine_code: context.$route.params.machine_code, component_name: context.searchSparepart.component_name, specification: context.searchSparepart.specification, location: context.searchSparepart.location})).onSuccess(function(response) {    
          context.tableSparepart.data  = response.data.data.data.data;
          context.formFilterSpare.show = false;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.dataMachine = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    filterSparepart() {
      this.formFilterSpare.add   = true;
      this.formFilterSpare.show  = true;
      this.formFilterSpare.title = "Filter Sparepart";
      this.onLoading = false;
    },
    createSparepart() {
      this.formSparepart.add   = true;
      this.formSparepart.show  = true;
      this.formSparepart.title = "Add Sparepart";
      this.sparepartData   = {};
    },
    editSparepart(id) {
      let context = this;               
      Api(context, sparepartList.show(id)).onSuccess(function(response) {
          context.sparepartData       = response.data.data;
          context.formSparepart.show  = true;
          context.formSparepart.title = 'Edit Data';   
      })
      .call()        
    },
    filesChange(e) {
        this.sparepartData.image = e.target.files[0];
    },
    saveSparepart(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.$route.params.machine_code != undefined && context.sparepartData.component_name != undefined && context.sparepartData.specification != undefined && context.sparepartData.uom != undefined && context.sparepartData.location != undefined && context.sparepartData.stock_out != undefined && context.sparepartData.min_stock != undefined && context.sparepartData.image != undefined) {
        formData.append('machine_code', context.$route.params.machine_code);
        formData.append('component_name', context.sparepartData.component_name);
        formData.append('specification', context.sparepartData.specification);
        formData.append('actual_stock', context.sparepartData.actual_stock);
        formData.append('uom', context.sparepartData.uom);
        formData.append('location', context.sparepartData.location);
        formData.append('stock_in', context.sparepartData.stock_in);
        formData.append('stock_out', context.sparepartData.stock_out);
        formData.append('min_stock', context.sparepartData.min_stock);
        formData.append('image', context.sparepartData.image);
        formData.append('notes', (this.sparepartData.notes == undefined) ? '-' : this.sparepartData.notes);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.formSparepart.title == 'Add Sparepart') {
          api = Api(context, sparepartList.create(formData));
      } else {
          api = Api(context, sparepartList.update(this.sparepartData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getSparepart();
        context.formSparepart.show = false;
        context.notify((context.form === 'Add Sparepart') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Sparepart') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, sparepartList.delete(id)).onSuccess(function(response) {
            context.getSparepart();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },

    // FOR TAB DOCUMENTATION
    getDocumentation(param){
      let context = this;               
      Api(context, documentationList.index({machine_code: context.$route.params.machine_code, title: context.searchDoc.title, description: context.searchDoc.description })).onSuccess(function(response) {    
          context.tableDoc.data                = response.data.data.data.data;
          context.formFilterDocumentation.show = false;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.dataMachine = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    filterDocumentation() {
      this.formFilterDocumentation.add   = true;
      this.formFilterDocumentation.show  = true;
      this.formFilterDocumentation.title = "Filter Documentation";
      this.onLoading = false;
    },
    createDocumentation() {
      this.formDocumentation.add   = true;
      this.formDocumentation.show  = true;
      this.formDocumentation.title = "Add Documentation";
      this.docData   = {};
    },
    editDocumentation(id) {
      let context = this;               
      Api(context, documentationList.show(id)).onSuccess(function(response) {
          context.docData       = response.data.data;
          context.formDocumentation.show  = true;
          context.formDocumentation.title = 'Edit Data';   
      })
      .call()        
    },
    filesChangeDoc(e) {
        this.docData.attachment = e.target.files[0];
    },
    saveDocumentation(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.$route.params.machine_code != undefined && context.docData.title != undefined && context.docData.description != undefined) {
        formData.append('machine_code', context.$route.params.machine_code);
        formData.append('title', context.docData.title);
        formData.append('description', context.docData.description);
        formData.append('attachment', (this.docData.attachment == undefined) ? '' : this.docData.attachment);
        formData.append('link', (this.docData.link == undefined) ? '' : this.docData.link);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.formDocumentation.title == 'Add Documentation') {
          api = Api(context, documentationList.create(formData));
      } else {
          api = Api(context, documentationList.update(this.docData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getDocumentation();
        context.formDocumentation.show = false;
        context.notify((context.form === 'Add Documentation') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Documentation') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    removeDoc(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, documentationList.delete(id)).onSuccess(function(response) {
            context.getDocumentation();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },

    
    setBg(id) {
      this.backgroundRed = id
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll-min { 
  overflow: auto; 
  height: 300px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
