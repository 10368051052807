<template>
  <div class="py-4 container-fluid">
    <argon-button color="info" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button>
    <div class="row mb-4">
      <div class="col-lg-8">
      </div>
      <div class="col-lg-4">
        <select class="form-select" aria-label="Default select example" @change="changeProject()" v-model="paramProject">
          <option value="C0411" >C0411</option>
          <option value="C0393" >C0393</option>
          <option value="C0392" >C0392</option>
          <option value="E0027" >E0027</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <card
          title="Usable Stock"
          :value="convertRp(totalUsable)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <card
          title="NG Stock"
          :value="convertRp(totalNG)"
          iconClass="fa fa-times-circle"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
    </div>
    <a class="btn btn-sm btn-success mt-4" style="margin-right: 10px;" :href="apiUrl+'export-excel/material-stock-piping-resume?project_number='+paramProject+'&material_type='+search.material_type+'&component_name='+search.component_name+'&schedule='+search.schedule+'&size='+search.size+''" target="_BLANK"><i class="fa fa-excel fa-sm"></i> Export Stock</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Material Stock</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <!-- <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button> -->
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Project No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Material Type</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Component Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Schedule</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Size</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Usable Stock</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NG Stock</th>
                      <!-- <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" >
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.project_number }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.material_type }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.component_name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.schedule }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.size }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-info">{{ row.qty_ok }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-danger">{{ row.qty_ng }}</span>
                      </td>
                      <!-- <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>

    <!-- history of incoming materials -->
    <a class="btn btn-sm btn-success mt-4" style="margin-right: 10px;" :href="apiUrl+'export-excel/material-status-piping?project_number='+paramProject+'&material_type='+search.material_type+'&spool_no='+search.spool_no+'&isometric_no='+search.isometric_no+'&status='+search.status+''" target="_BLANK"><i class="fa fa-file-text-o"></i> Export History</a>
    <div class="row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>History Of Incoming Materials</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Project No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Material Type</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Component Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Schedule</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Size</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Usable Stock</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NG Stock</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Arrival Date</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in tableHistory.data" :key="i" >
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.project_number }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.material_type }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.component_name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.schedule }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.size }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-info">{{ row.qty_ok }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-danger">{{ row.qty_ng }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ moment(row.arrival_date).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <!-- <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i> -->
                        <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{tableHistory.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>

  </div>

  <!-- =======  MODAL ADD ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project Number <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Project Number" v-model="materialStockData.project_number">
        <label for="example-text-input" class="form-control-label mt-3">Material Type <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Material Type" v-model="materialStockData.material_type">
        <label for="example-text-input" class="form-control-label mt-3">Component Name <span style="color: red;">*</span></label>
        <multiselect v-model="materialStockData.component_name" :options="tableComponent.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Component" label="component_name" track-by="component_name" :preselect-first="true" @keyup="getComponent">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Schedule <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Schedule" v-model="materialStockData.schedule">
        <label for="example-text-input" class="form-control-label mt-3">Size <span style="color: red;">*</span></label>
        <multiselect v-model="materialStockData.size" :options="tableSize.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Size" label="size" track-by="size" :preselect-first="true" @click="getSize">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Usable Stock <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Usable Stock" v-model="materialStockData.qty_ok">
        <label for="example-text-input" class="form-control-label mt-3">NG Stock <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="NG Stock" v-model="materialStockData.qty_ng">
        <label for="example-text-input" class="form-control-label mt-3">Arrival Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Arrival Date" v-model="materialStockData.arrival_date">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = false">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project Number</label>
        <input type="text" class="form-control" placeholder="Project Number" v-model="search.project_number" v-on:keyup.enter="get(), getHistory()">
        <label for="example-text-input" class="form-control-label mt-3">Material Type</label>
        <input type="text" class="form-control" placeholder="Material Type" v-model="search.material_type" v-on:keyup.enter="get(), getHistory()">
        <label for="example-text-input" class="form-control-label mt-3">Component Name</label>
        <input type="text" class="form-control" placeholder="Component Name" v-model="search.component_name" v-on:keyup.enter="get(), getHistory()">
        <label for="example-text-input" class="form-control-label mt-3">Schedule</label>
        <input type="text" class="form-control" placeholder="Schedule" v-model="search.schedule" v-on:keyup.enter="get(), getHistory()">
        <label for="example-text-input" class="form-control-label mt-3">Size</label>
        <input type="text" class="form-control" placeholder="Size" v-model="search.size" v-on:keyup.enter="get(), getHistory()">
        <label for="example-text-input" class="form-control-label mt-3">Arrival Date</label>
        <input type="date" class="form-control" placeholder="Arrival Date" v-model="search.arrival_date" v-on:keyup.enter="get(), getHistory()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), getHistory()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL IMPORT SPOOL ======= -->
  <div class="container">
    <vue-final-modal v-model="formImport.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImport.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImport.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChangeImport" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import Material Stock.xlsx'" style="color: blue;"> Import Material Stock</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">No</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Column</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Error</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Row</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ i + 1 }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-lg-6 col-4"> 
      </div>
      <div class="col-lg-2 col-3" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImport.show = false">Close</argon-button>
      </div>
      <div class="col-lg-1 col-2">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importData()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>

</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
// import flatPickr from 'vue-flatpickr-component';
// import 'flatpickr/dist/flatpickr.css';
var moment = require('moment');
import materialStockPiping from '@/services/materialStockPiping.service';
import vehicleList from '@/services/vehicleList.service';
import plant from '@/services/plant.service';
import employee from '@/services/employee.service';
import vehicleRequest from '@/services/vehicleRequest.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    Card,
    // flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      tableHistory: {
        data: []
      },
      tableComponent: {
        data: []
      },
      tableSize: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Master Spool",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      materialStockData: {},
      search: {
        project_number: '',
        material_type: '',
        component_name: '',
        schedule: '',
        size: '',
        arrival_date: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tablePlant: {
        data: []
      },
      tablePassenger: {
        data: []
      },
      tableVehicle: {
        data: []
      },
      totalUsable: '',
      totalNG: '',

      tabComponent: '',
      paramProject: '',

    };
  },
  mounted(){
    this.paramProject = localStorage.getItem('paramProject');
    this.get();
    this.getHistory();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    changeProject(param){
      localStorage.setItem('paramProject', this.paramProject)
      location.reload();
    },
    get(param){
      let context = this;               
      Api(context, materialStockPiping.index({project_number: this.paramProject, material_type: this.search.material_type, component_name: this.search.component_name, schedule: this.search.schedule, size: this.search.size, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.totalUsable = response.data.data.totalUsable
          context.totalNG     = response.data.data.totalNG

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getHistory(param){
      let context = this;               
      Api(context, materialStockPiping.getHistory({project_number: this.paramProject, material_type: this.search.material_type, component_name: this.search.component_name, schedule: this.search.schedule, size: this.search.size, arrival_date: this.search.arrival_date, page: context.pagination.page})).onSuccess(function(response) {    
          context.tableHistory.data     = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableHistory.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add           = true;
      this.form.show          = true;
      this.form.title         = "Add Data";
      this.materialStockData = {};
      this.materialStockData.project_number = this.paramProject
      this.materialStockData.material_type  = '-'
      this.materialStockData.schedule       = 'SCH 10S'
      this.materialStockData.qty_ng         = 0
      this.defaultDate()
    },
    edit(id) {
      let context = this;               
      Api(context, materialStockPiping.show(id)).onSuccess(function(response) {
        context.materialStockData           = response.data.data.data;
        context.materialStockData.plant     = response.data.data.data.plant_code;
        context.materialStockData.pic       = response.data.data.dataPic[0];
        context.materialStockData.passenger = response.data.data.dataPassenger;
        context.tableVehicle.data           = response.data.data.dataVehicle;
        context.materialStockData.vehicle   = response.data.data.dataVehicle[0].code;
        context.form.show                   = true;
        context.form.title                  = 'Edit Data'; 
      })
      .call()        
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Materil Stock";
      this.tabelError.data  = [];
    },
    filesChangeImport(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api        = null;
      let context    = this;
      let formData   = new FormData();
      context.onLoading = true;

      if (context.dataImport != undefined) {
        formData.append('import_data', context.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, materialStockPiping.import(formData));
      api.onSuccess(function(response) {
          context.get();
          context.getHistory();
          context.onLoading       = false;
          context.formImport.show = false;
          context.notify('Import Master Spool Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data; 
          context.onLoading       = false;             
          context.notify('Import Master Spool Failed', 'error')
      }).onFinish(function() {  
          context.onLoading = false;
      })
      .call();
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.materialStockData.project_number != undefined && context.materialStockData.material_type != undefined && context.materialStockData.component_name != undefined && context.materialStockData.schedule != undefined && context.materialStockData.size != undefined && context.materialStockData.qty_ok != undefined && context.materialStockData.qty_ng != undefined && context.materialStockData.arrival_date != undefined) {
        formData.append('project_number', context.materialStockData.project_number);
        formData.append('material_type', context.materialStockData.material_type);
        formData.append('component_name', JSON.stringify(context.materialStockData.component_name));
        formData.append('schedule', context.materialStockData.schedule);
        formData.append('size', JSON.stringify(context.materialStockData.size));
        formData.append('qty_ok', context.materialStockData.qty_ok);
        formData.append('qty_ng', context.materialStockData.qty_ng);
        formData.append('arrival_date', context.materialStockData.arrival_date);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, materialStockPiping.create(formData));
      } else {
          api = Api(context, materialStockPiping.update(this.materialStockData.id, formData));
      }
      api.onSuccess(function(response) {
        context.get();
        context.getHistory();
        // context.approval(response.data.data.id, 'Approved');
        context.materialStockData = {}
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, materialStockPiping.delete(id)).onSuccess(function(response) {
            context.get();
            context.getHistory();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    getComponent(param){
      let context = this;        
      Api(context, materialStockPiping.getComponent({project_number: this.paramProject, component_name: param.target.value})).onSuccess(function(response) {    
          context.tableComponent.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableComponent.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getSize(param){
      let context = this;        
      Api(context, materialStockPiping.getSize({project_number: this.paramProject, component_name: context.materialStockData.component_name['component_name']})).onSuccess(function(response) {    
          context.tableSize.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableSize.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    updateQtyAlocation(id, spool_no){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      let qty      = event.target.value

      if (id != undefined && qty != undefined) {
        formData.append('qty', qty);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, materialStockPiping.updateAlocation(id, formData));

      api.onSuccess(function(response) {
        context.getDetailComponent(spool_no)
        context.get()
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    defaultDate(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var today = date.getFullYear() + "-" + (month) + "-" + (day);

      this.materialStockData.arrival_date = today
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}

/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 1090px;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
