import Service from '@/helpers/service'

export default {

    getRoleCustomer(params) {
        return Service().get('material-piping/get-role-customer', {params});
    },
    index(params) {
        return Service().get('material-piping/index', {params});
    },
    getVisual(params) {
        return Service().get('material-piping/get-visual', {params});
    },
    getLineChart(params) {
        return Service().get('material-piping/get-line-chart', {params});
    },
    show(id) {
        return Service().get('material-piping/show/'+id);
    },
    create(params) {
        return Service().post('material-piping/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('material-piping/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('material-piping/delete/'+id);
    },
    getMaterialPiping(params) {
        return Service().get('material-piping/get-material-piping', {params});
    },
    getMaterialJointId(params) {
        return Service().get('material-piping/get-material-joint-id', {params});
    },
    getMaterialIsometric(params) {
        return Service().get('material-piping/get-material-isometric', {params});
    },
    updateDone(params) {
        return Service().post('material-piping/update-done', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    copyValue(params) {
        return Service().post('material-piping/copy-value', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}