<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <h4 class="text-center font-weight-bold mb-3">Export Control Sheet (1)</h4>
              <div class="row">
                <div class="col-9">
                </div>
                <div class="col-3">
                  <a class="btn btn-sm btn-warning mb-5" style="margin-left: 130px; margin-top: -40px;" :href="apiUrl+'print-pdf/export-control-detail?project_number='+$route.params.project_number+''" target="_BLANK"><i class="fa fa-print fa-sm"></i> Print / PDF</a><br>
                </div>
              </div>

              <div class="p-0" style="margin-left: 100px; margin-right: 79px;">
                <!-- ============================================== EXPORT CONTROL SHEET (1) ================================ -->
                <table class="table table-hover table-bordered mb-6">
                  <thead>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 1000px;" colspan="4"><h5>PT. TOKKI ENGINEERING AND FABRICATION</h5></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 300px;"><h5>TOKKI LTD</h5></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;" colspan="2"><span style="font-size: 13px;">Marketing & Sales Div.</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="font-size: 13px;">Engineering Div</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;" rowspan="2"><span style="font-size: 13px;">Approved</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;" rowspan="3"></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="font-size: 13px;">Prepared</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="font-size: 13px;">Checked</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="font-size: 13px;">Reviewed</span></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;">
                        <br><br><br><br>
                        <span style="padding-top: 300px; font-size: 13px;">( {{exportControlData.sales_pic}} )</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="padding-top: 300px; font-size: 13px;">( Dindin Kusdiana )</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="padding-top: 300px; font-size: 13px;">( Asep Mulyadi )</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="padding-top: 300px; font-size: 13px;">( R. Yunomae )</span></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Date <br>
                        (Project No.)</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Y/M/D <br>
                        2023 / July / 27 <br>
                        ( {{exportControlData.project_number}} )</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Marketing Sales <br>
                        PIC (Name)</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="2">
                        <span style="font-size: 13px;"><i> {{exportControlData.sales_pic}} </i></span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;" colspan="5"><span style="font-size: 13px;">1. Summary of Products (Marketing Sales Sec)</span></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">User <br>
                        (End User)</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">{{ exportControlData.end_user_name }} </span><br><br>
                        Military Relation :&nbsp;&nbsp;&nbsp;&nbsp; 
                        <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.end_user_military_yes"> Yes  &nbsp;&nbsp;&nbsp;&nbsp;
                        <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.end_user_military_no"> No
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Destination (Final Destination)</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="2">
                        <span style="font-size: 13px;" class="text-uppercase">{{ exportControlData.country_plant }}</span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">
                          Content of <br> 
                          Commodity <br>
                          & Technology
                        </span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">Name : &nbsp;{{ exportControlData.product_name }} </span><br>
                        <span style="font-size: 13px;">Delivery Date : &nbsp;{{ moment(exportControlData.delivery_date).locale('en').format('ll') }} </span><br>
                        <span style="font-size: 13px;">Price : &nbsp;{{ exportControlData.currency }} &nbsp; {{ convertRp(exportControlData.total_price) }} </span><br>
                        <span style="font-size: 13px;">Contents : &nbsp;{{ exportControlData.product_name }} </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Judgement List</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.judgement_list_export_controlled"> Export Controlled &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.judgement_list_not_export_controlled"> Non Export Controlled &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.judgement_list_na"> N/A
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;" colspan="5"><span style="font-size: 13px;">2. Judgement Result (PT TEF Eng. Div. & Marketing Sales Sec)</span></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Judgement Result</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.judgement_result_export_controlled"> Export Controlled (No. &nbsp;&nbsp;&nbsp;&nbsp;)&nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.judgement_result_non_export_controlled"> Non Export Controlled &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Reason for Judgement <br><br> Regarding Japanese <br> Export License 3(2)</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_vessel"> : Vessel &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_tower"> : Tower &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_he"> : Heat Exchanger &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_tank"> : Tank <br>
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_piping"> : Pipings &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_other"> : Other &nbsp;&nbsp;&nbsp;&nbsp; <u>{{ exportControlData.product_name }}</u> <br>
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_volume"> : Volume or (Heat Transfer) Area &nbsp;&nbsp;&nbsp;&nbsp;  <br>
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_material"> : Material &nbsp;&nbsp;&nbsp;&nbsp;  <u>{{ exportControlData.main_material }}</u> <br>
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_maintenance"> : Maintenance /Site work &nbsp;&nbsp;&nbsp;&nbsp;  <br>
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_part"> : Parts of Non-controlled products &nbsp;&nbsp;&nbsp;&nbsp;  <br>
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.reason_judgement_product"> : Products is not related to Japanese Export License 3(2) &nbsp;&nbsp;&nbsp;&nbsp;  <br>
                        </span><br>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Engineering Div. Comment</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;"> Non Export Controlled &nbsp;&nbsp;&nbsp;&nbsp;</span><br>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Necessary Procedure</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.necessary_no"> No &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.necessary_prior"> Prior Consent from TOKKI &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 12px;">Vendor Comment</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.vendor_comment_na"> NA &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.vendor_comment_a"> A(Attached document) &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 12px;">Japanese Goverment Comment</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.japanese_goverment_ecs_na"> NA &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.japanese_goverment_ecs_a"> A(Attached document) &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 12px;">Related Document</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">1. Parmeter Sheet &nbsp;&nbsp;&nbsp;&nbsp; 2. Related Certificate &nbsp;&nbsp;&nbsp;&nbsp; 3. Others (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) </span><br>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Remarks</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          1. This sheet is used for export control for Oversease & Domestic Market Order in accordance with <br>
                          Letter of Assurance (16 Nov.2007)<br>
                          2. If TEF needs to delivery the product that conforms to Japanese Export License 3(2) to overseas <br>
                          & domestic Users, TEF should get the Prior Consent from TOKKI. <br>
                          3. Judgment is done basically by the Non-controlled products list of TOKKI.
                        </span><br>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;" colspan="5"><span style="font-size: 13px;">3. Use & User Check (Not Applicable as remarks below)</span></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Check Point</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          1. Are they any infomation from japanese government for Products ?&nbsp;
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.check_poin_a_yes"> Yes &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.check_poin_a_no"> No<br>
                          2. Product is catch all products andused in so called Whith Area  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.check_poin_b_yes"> Yes &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.check_poin_b_no"> No<br>
                          3. End user is listed in the "export list"  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.check_poin_c_yes"> Yes &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.check_poin_c_no"> No<br>
                          4. End user is located in UN arms export prohibited Countries & Area &nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.check_poin_d_yes"> Yes &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.check_poin_d_no"> No<br>
                          5. There is <b>O</b> or <b>?</b> Use & End User Check sheet  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.check_poin_e_yes"> Yes &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.check_poin_e_no"> No
                        </span><br>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Use & User Check Results</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 12px;">
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.use_user_is_not_necessary"> Prcedure is not necessary &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.use_user_is_necessary"> Prcedure is necessary &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.use_user_is_cannot"> Cannot be exportted 
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;">
                        <span style="font-size: 14px;">Reason for Judgement</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 14px;">Due to the product is not related to Japanese Export License </span><br>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Remarks</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">In PT. TEF this sheet is used for the overseas & domestic sales.But In case of falling under <br>
                        Japanese Export License 3(2), "Catch All" regulation in Japanese code is applied for overseas & <br>
                        domestic sales. </span><br>
                      </th>
                    </tr>
                  </thead>
                </table><hr>

                <!-- ============================================== CHECK SHEET ON USE AND END USER ================================ -->
                <h4 class="text-center font-weight-bold mb-3 mt-4">Check Sheet on Use and End User</h4>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 1000px;" colspan="4"><h5>PT. TOKKI ENGINEERING AND FABRICATION</h5></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 300px;"><h5>TOKKI LTD</h5></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;" colspan="2"><span style="font-size: 13px;">Marketing & Sales Div.</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="font-size: 13px;">Engineering Div</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;" rowspan="2"><span style="font-size: 13px;">Approved</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;" rowspan="3"></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="font-size: 13px;">Prepared</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="font-size: 13px;">Checked</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="font-size: 13px;">Reviewed</span></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;">
                        <br><br><br><br>
                        <span style="padding-top: 300px; font-size: 13px;">( {{exportControlData.sales_pic}} )</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="padding-top: 300px; font-size: 13px;">( Dindin Kusdiana )</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="padding-top: 300px; font-size: 13px;">( Asep Mulyadi )</span></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="width: 700px;"><span style="padding-top: 300px; font-size: 13px;">( R. Yunomae )</span></th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Date <br>
                        (Project No.)</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Y/M/D <br>
                        2023 / July / 27 <br>
                        ( {{exportControlData.project_number}} )</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Marketing Sales <br>
                        PIC (Name)</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="2">
                        <span style="font-size: 13px;"><i> {{exportControlData.sales_pic}} </i></span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Destination</span><br>
                        <span style="font-size: 13px;">( Final Destination)</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          {{ exportControlData.country_plant }}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Name and Summary</span><br>
                        <span style="font-size: 13px;">of (End) User</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          {{ exportControlData.end_user_name }}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Content of Commodity</span><br>
                        <span style="font-size: 13px;">& Technology</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          {{ exportControlData.product_name }}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">User</span><br>
                        <span style="font-size: 13px;">Assessment</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;"></span>
                      </th>
                    </tr> 
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Use</span><br>
                        <span style="font-size: 13px;">Assessment</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;"></span>
                      </th>
                    </tr> 
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Japanese Government</span><br>
                        <span style="font-size: 13px;">Comment</span>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;">
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.japanese_goverment_cs_na"> NA &nbsp;&nbsp;&nbsp;&nbsp; 
                          <input type="checkbox" @change="updateExportControl(exportControlData.project_number)" v-model="exportControlData.japanese_goverment_cs_a"> A(Attached document) &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </th>
                    </tr> 
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center align-middle" style="width: 700px;">
                        <span style="font-size: 13px;">Remark</span><br>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-left align-middle" style="width: 700px;" colspan="4">
                        <span style="font-size: 13px;"></span>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>

            </div>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import salesAdvisorPo from '@/services/salesAdvisorPo.service';

export default {
  name: "tables",
  components: {
  },
  data() {
    return {
      moment:moment,
      storageUrl : config.storageUrl,
      exportControlData: {},
      apiUrl :config.apiUrl,
    };
  },
  mounted(){
    this.getDetailInvoice();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    getDetailInvoice(param){
      let context = this;               
      Api(context, salesAdvisorPo.detailExportControl({project_number: context.$route.params.project_number})).onSuccess(function(response) {    
          context.exportControlData   = response.data.data.data;
          console.log(response.data.data.data)

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableDoc.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    updateExportControl(project_number){
      let api         = null;
      let context     = this;
      let formData    = new FormData();

      formData.append('end_user_military_yes', context.exportControlData.end_user_military_yes);
      formData.append('end_user_military_no', context.exportControlData.end_user_military_no);
      formData.append('judgement_list_export_controlled', context.exportControlData.judgement_list_export_controlled);
      formData.append('judgement_list_not_export_controlled', context.exportControlData.judgement_list_not_export_controlled);
      formData.append('judgement_list_na', context.exportControlData.judgement_list_na);
      formData.append('judgement_result_export_controlled', context.exportControlData.judgement_result_export_controlled);
      formData.append('judgement_result_non_export_controlled', context.exportControlData.judgement_result_non_export_controlled);
      formData.append('reason_judgement_vessel', context.exportControlData.reason_judgement_vessel);
      formData.append('reason_judgement_tower', context.exportControlData.reason_judgement_tower);
      formData.append('reason_judgement_he', context.exportControlData.reason_judgement_he);
      formData.append('reason_judgement_tank', context.exportControlData.reason_judgement_tank);
      formData.append('reason_judgement_piping', context.exportControlData.reason_judgement_piping);
      formData.append('reason_judgement_other', context.exportControlData.reason_judgement_other);
      formData.append('reason_judgement_volume', context.exportControlData.reason_judgement_volume);
      formData.append('reason_judgement_material', context.exportControlData.reason_judgement_material);
      formData.append('reason_judgement_maintenance', context.exportControlData.reason_judgement_maintenance);
      formData.append('reason_judgement_part', context.exportControlData.reason_judgement_part);
      formData.append('reason_judgement_product', context.exportControlData.reason_judgement_product);
      formData.append('necessary_no', context.exportControlData.necessary_no);
      formData.append('necessary_prior', context.exportControlData.necessary_prior);
      formData.append('vendor_comment_na', context.exportControlData.vendor_comment_na);
      formData.append('vendor_comment_a', context.exportControlData.vendor_comment_a);
      formData.append('japanese_goverment_ecs_na', context.exportControlData.japanese_goverment_ecs_na);
      formData.append('japanese_goverment_ecs_a', context.exportControlData.japanese_goverment_ecs_a);
      formData.append('check_poin_a_yes', context.exportControlData.check_poin_a_yes);
      formData.append('check_poin_a_no', context.exportControlData.check_poin_a_no);
      formData.append('check_poin_b_yes', context.exportControlData.check_poin_b_yes);
      formData.append('check_poin_b_no', context.exportControlData.check_poin_b_no);
      formData.append('check_poin_c_yes', context.exportControlData.check_poin_c_yes);
      formData.append('check_poin_c_no', context.exportControlData.check_poin_c_no);
      formData.append('check_poin_d_yes', context.exportControlData.check_poin_d_yes);
      formData.append('check_poin_d_no', context.exportControlData.check_poin_d_no);
      formData.append('check_poin_e_yes', context.exportControlData.check_poin_e_yes);
      formData.append('check_poin_e_no', context.exportControlData.check_poin_e_no);
      formData.append('use_user_is_not_necessary', context.exportControlData.use_user_is_not_necessary);
      formData.append('use_user_is_necessary', context.exportControlData.use_user_is_necessary);
      formData.append('use_user_is_cannot', context.exportControlData.use_user_is_cannot);
      formData.append('japanese_goverment_cs_na', context.exportControlData.japanese_goverment_cs_na);
      formData.append('japanese_goverment_cs_a', context.exportControlData.japanese_goverment_cs_a);

      api = Api(context, salesAdvisorPo.updateExportControl(project_number, formData));

      api.onSuccess(function(response) {
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    // filter() {
    //   this.formFilter.add   = true;
    //   this.formFilter.show  = true;
    //   this.formFilter.title = "Filter";
    //   this.onLoading        = false;
    //   this.pagination.page  = 1 ;
    // },
    // create() {
    //   this.form.add           = true;
    //   this.form.show          = true;
    //   this.form.title         = "Add Data";
    //   this.invoiceDoc = {};
    // },
    // edit(id) {
    //   let context = this;               
    //   Api(context, salesAdvisorPo.showDoc(id)).onSuccess(function(response) {
    //     context.invoiceDoc = response.data.data.data;
    //     context.form.show  = true;
    //     context.form.title = 'Edit Document'; 
    //   })
    //   .call()        
    // },
    // filesChange(e) {
    //   this.invoiceDoc.document_name = e.target.files[0];
    // },
    // save(){
    //   let api      = null;
    //   let context  = this;
    //   let formData = new FormData();
    //   context.onLoading = true;

    //   if (context.invoiceDoc.filename != undefined && context.invoiceDoc.document_name != undefined && context.invoiceDoc.sequence != undefined) {
    //     formData.append('invoice_number', context.$route.params.invoice_number);
    //     formData.append('filename', context.invoiceDoc.filename);
    //     formData.append('document_name', context.invoiceDoc.document_name);
    //     formData.append('sequence', context.invoiceDoc.sequence);
    //   }else{
    //     context.onLoading = false;
    //     return alert('The Red Star Field is Mandatory')
    //   }

    //   if (context.form.title == 'Add Data') {
    //     api = Api(context, salesAdvisorPo.createDoc(formData));
    //   }else {
    //     api = Api(context, salesAdvisorPo.updateDoc(this.invoiceDoc.id, formData));
    //   }
    //   api.onSuccess(function(response) {
    //     context.getDetailInvoice();
    //     context.invoiceDoc = {}
    //     context.form.show = false;
    //     context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
    //   }).onError(function(error) { 
    //     context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
    //   }).onFinish(function() {  
    //     context.onLoading = false;
    //   })
    //   .call();
    // },
    // remove(id) {
    //   var r = confirm("Are you sure you want to delete this data ?");
    //   if (r == true) {
    //     let context = this;

    //     Api(context, salesAdvisorPo.deleteDoc(id)).onSuccess(function(response) {
    //         context.getDetailInvoice();
    //         context.notify('Data Successfully Deleted', 'success')
    //     }).call();
    //   }
    // },
    // changePage(page){
    //   this.pagination.page = page;
    //   this.get();
    // },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: ;
}

table.table-bordered{
    border:1px solid black;
    /*margin-top:20px;*/
  }
table.table-bordered > thead > tr > th{
    border:1px solid black;
}
table.table-bordered > tbody > tr > td{
    border:1px solid black;
}

</style>
