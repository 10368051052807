<template>
  <div class="py-4 container-fluid">
    
    <div class="mb-4">
      <div class=" row">
        <div class="col-12">
            <div class="card">
            <div class="row">
                <div class="col-12">
                  <div class="card-header pb-0">
                    <h6>Reminder For Employee Work Ending This Month</h6>
                  </div>
                </div>
              </div> 
              <div class="card-body px-0 pt-0 pb-2 mt-4">
                <div class="table-responsive p-0">
                  <table class="table table-striped table-sm table-sm table-bordered table-hover align-items-center mb-0">
                    <thead>
                      <tr>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">NIK</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Name</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Position</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Department</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Job Title</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Plant</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Employment End Date</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Employee Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, i) in tableReminderEmployeeEnd.data" :key="i">
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.nik }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.name }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.position_name }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.department_name }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.job_title_name }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.plant_name }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ moment(row.empoyee_end_date).locale('id').format('LL') }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.employee_status }} </span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer">
              </div>
            </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-3">
        <div class=" row">
          <div class="col-12">
              <div class="card">
              <div class="row">
                  <div class="col-12">
                    <div class="card-header pb-0">
                      <h6>Position</h6>
                    </div>
                  </div>
                </div> 
                <div class="card-body px-0 pt-0 pb-2 mt-4">
                  <div class="table-responsive p-0">
                    <table class="table table-striped table-sm table-sm table-bordered table-hover align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-left text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Position</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, i) in tableListPosition.data" :key="i">
                          <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.name }} </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.total_qty_position_count }} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm font-weight-bold"><span class="text-secondary text-xs font-weight-bold" style="font-weight: bold;"> Total </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpAll}} </span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class=" row">
          <div class="col-12">
              <div class="card"> 
                <div class="row">
                  <div class="col-12">
                    <div class="card-header pb-0">
                      <h6>Employee Type</h6>
                    </div>
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 mt-4">
                  <div class="table-responsive p-0">
                    <table class="table table-striped table-sm table-sm table-bordered table-hover align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Employee Type</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> Japanese </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> 1 </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> Permanent </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpPermanent}} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> Contract TEF </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpContract}} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> Temporary Contract </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpContractTemporary}} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm font-weight-bold"><span class="text-secondary text-xs font-weight-bold" style="font-weight: bold;"> Total </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpAll}} </span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">
                </div>
              </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
              <div class="card"> 
                <div class="row">
                  <div class="col-12">
                    <div class="card-header pb-0">
                      <h6>Gender Composition</h6>
                    </div>
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 mt-4">
                  <div class="table-responsive p-0">
                    <table class="table table-striped table-sm table-sm table-bordered table-hover align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Gender</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> Female </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpFemale}} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> Male </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpMale}} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm font-weight-bold"><span class="text-secondary text-xs font-weight-bold" style="font-weight: bold;"> Total </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpAll}} </span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-3">
        
      </div> -->
      <div class="col-6">
        <div class=" row">
          <div class="col-12">
              <div class="card"> 
                <div class="row">
                  <div class="col-7">
                    <div class="card-header pb-0">
                      <h6>LABOR HR & GA</h6>
                    </div>
                  </div>
                  <div class="col-4">
                    <argon-button
                      style="margin-right: 10px; margin-left: 60px;"
                      class="mt-4"
                      variant="gradient"
                      color="secondary"
                      size="sm"
                      @click="filterLabor()"
                    ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 mt-4">
                  <div class="table-responsive p-0">
                    <table class="table table-striped table-sm table-sm table-bordered table-hover align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Remark</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Permanent</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Contract</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Japanese</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> Number Of Employee </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpPermanent}} </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalEmpContract + totalEmpContractTemporary) }} </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> 1 </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpAll}} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> IN </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpLaborPermanentIn}} </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpLaborContractIn}} </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> 0 </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalEmpLaborPermanentIn + totalEmpLaborContractIn)}} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> OUT </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpLaborPermanentOut}} </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpLaborContractOut}} </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> 0 </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpLaborPermanentOut + totalEmpLaborContractOut}} </span></td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="row mt-3">
                      <div class="col-6">
                        <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                          <thead>
                            <tr>
                              <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">Employee In <i class="fa fa-arrow-down" aria-hidden="true"></i></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(inValue, i) in tableEmpLaborNameIn.data" :key="i">
                              <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}. {{ inValue.name }} </span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-6 mr-4">
                        <table class="table table-striped table-sm table-sm table-bordered table-hover align-items-center mb-0">
                          <thead>
                            <tr>
                              <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">Employee Out <i class="fa fa-arrow-up" aria-hidden="true"></i></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(out, i) in tableEmpLaborNameOut.data" :key="i">
                              <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}. {{ out.name }} </span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                </div>
              </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
              <div class="card"> 
                <div class="row">
                  <div class="col-12">
                    <div class="card-header pb-0">
                      <h6>Plant Composition</h6>
                    </div>
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 mt-4">
                  <div class="table-responsive p-0">
                    <table class="table table-striped table-sm table-sm table-bordered table-hover align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Plant</th>
                          <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> Discovery </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpDsc}} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> Endeavor </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpEdv}} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> Simba </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpSmb}} </span></td>
                        </tr>
                        <tr>
                          <td class="align-middle text-center text-sm font-weight-bold"><span class="text-secondary text-xs font-weight-bold" style="font-weight: bold;"> Total </span></td>
                          <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{totalEmpAll}} </span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer">
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <!-- EMPLOYEE MASTER DATA -->
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/employee-master-data?nik='+search.nik+'&name='+search.name+'&npwp='+search.npwp+'&position='+search.position+'&group='+search.group+'&department='+search.department+'&job_title='+search.job_title+'&plant='+search.plant+'&member_spsi='+search.member_spsi+'&join_date='+search.join_date+'&employee_status='+search.employee_status+'&permanent_date='+search.permanent_date+'&employee_end_date='+search.employee_end_date+'&ktp='+search.ktp+'&place_birth='+search.place_birth+'&date_birth='+search.date_birth+'&gender='+search.gender+'&blood_group='+search.blood_group+'&mother_name='+search.mother_name+'&religion='+search.religion+'&home_address='+search.home_address+'&ktp_address='+search.ktp_address+'&no_kk='+search.no_kk+'&maried_status='+search.maried_status+'&total_amenability='+search.total_amenability+'&an_bank='+search.an_bank+'&no_rek='+search.no_rek+'&brance='+search.brance+'&bpjs_ket_mmf='+search.bpjs_ket_mmf+'&bpjs_ket_tokki='+search.bpjs_ket_tokki+'&bpjs_kesehatan='+search.bpjs_kesehatan+'&asuransi_mandiri_inhelath='+search.asuransi_mandiri_inhelath+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Employee Master Data</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">No</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Photo</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Name</th>
                      <th colspan="13" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Company Information</th>
                      <th colspan="18" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Personal Information</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Bank Information</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS Information</th>
                      <th colspan="10" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">File Information</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Action</th>
                    </tr>
                    <tr>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">NPWP</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Position</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Golongan</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Department</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Job Title</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Plant</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">SPSI Member</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">JOIN DATE</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Join Date MMF</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">EMPLOYEE STATUS</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">EMPLOYEE Activity</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">permanent date</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Employment End Date</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">KTP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Place Of Birth</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Date Of Birth</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Age</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Gender</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Blood Group</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Mother Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Religion</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Home Address</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">KTP Address</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO KK.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO HP 1</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO HP 2</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO TLP EMERGENCY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">EMAIL</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Married Status</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">total amenability</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">last education</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">A/N Bank</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">TYPE Bank</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">No Rek.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Brance</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS KETENAGAKERJAAN MMF</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS KETENAGAKERJAAN TOKKI</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS KESEHATAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">ASURANSI MANDIRI IN HEALTH</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">KTP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">KK</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">NPWP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">BUKU REKENING</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">BPJS KESEHATAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">BPJS KETENAGAKERJAAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">CV</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">VAKSIN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">SERTIFIKAT KEAHLIAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">IJAZAH TERAKHIR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                          <div>
                            <img
                              v-if="row.photo"
                              :src="storageUrl+'/document_employee/'+row.photo"
                              class="avatar avatar-sm me-3"
                              alt="user1"
                            />
                            <img
                              v-else
                              src="../../assets/img/avatar_karyawan.png"
                              class="avatar avatar-sm me-3"
                              alt="user1"
                            />
                          </div>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <div class="d-flex flex-column justify-content-left">
                          <h6 class="mb-0 text-sm">{{ row.name }}</h6>
                          <p class="text-xs text-secondary mb-0">{{ row.nik}}</p>
                        </div>
                      </td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.npwp }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.position_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.group }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.department_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.job_title_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.plant_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.member_spsi }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.join_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.join_date_mmf).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.employee_status }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.employee_activity }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.permanent_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.empoyee_end_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.ktp }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.place_birth }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.date_birth).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment().diff(row.date_birth, 'years') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.gender }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.blood_group }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.mother_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.religion }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.home_address }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.ktp_address }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_kk }}</span></td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          <a target="_blank" :href="'https://api.whatsapp.com/send?phone=62'+row.no_hp_first+'&text=Assalamualaikum Hallo ' +row.name+','" style="color: blue;">{{row.no_hp_first}} </a>
                        </span>
                      </td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_hp_second }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_emergency }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.email }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.maried_status }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.total_amenability }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.last_education }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.an_bank }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.type_bank }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_rek }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.brance }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_ket_mmf }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_ket_tokki }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_kesehatan }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.asuransi_mandiri_inhelath }}</span></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_ktp" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_ktp">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_kk" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_kk">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_npwp" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_npwp">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_buku_rekening" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_buku_rekening">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_bpjs_kesehatan" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_bpjs_kesehatan">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_bpjs_ketenagakerjaan" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_bpjs_ketenagakerjaan">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_cv" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_cv">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_vaksin" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_vaksin">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_sertefikat_keahlian" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_sertefikat_keahlian">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_ijazah_terakhir" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_ijazah_terakhir">View Files</a></td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                        <i class="fa fa-copy fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="copyLink(row.safety_code)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>

    <!-- PRJECT EMPLOYEE DATA -->
    <div class=" row mt-4">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Project Employees</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">No</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Photo</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Name</th>
                      <th colspan="12" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Company Information</th>
                      <th colspan="18" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Personal Information</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Bank Information</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS Information</th>
                      <th colspan="10" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">File Information</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Action</th>
                    </tr>
                    <tr>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">NPWP</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Position</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Golongan</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Department</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Job Title</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Plant</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">SPSI Member</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">JOIN DATE</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">EMPLOYEE STATUS</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">EMPLOYEE Activity</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">permanent date</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Employement End Date</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">KTP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Place Of Birth</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Date Of Birth</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Age</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Gender</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Blood Group</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Mother Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Religion</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Home Address</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">KTP Address</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO KK.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO HP 1</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO HP 2</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO TLP EMERGENCY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">EMAIL</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Married Status</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">total amenability</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">last education</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">A/N Bank</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">TYPE Bank</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">No Rek.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Brance</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS KETENAGAKERJAAN MMF</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS KETENAGAKERJAAN TOKKI</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS KESEHATAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">ASURANSI MANDIRI IN HEALTH</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">KTP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">KK</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">NPWP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">BUKU REKENING</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">BPJS KESEHATAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">BPJS KETENAGAKERJAAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">CV</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">VAKSIN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">SERTIFIKAT KEAHLIAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">IJAZAH TERAKHIR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in tableEmpProject.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                          <div>
                            <img
                              v-if="row.photo"
                              :src="storageUrl+'/document_employee/'+row.photo"
                              class="avatar avatar-sm me-3"
                              alt="user1"
                            />
                            <img
                              v-else
                              src="../../assets/img/avatar_karyawan.png"
                              class="avatar avatar-sm me-3"
                              alt="user1"
                            />
                          </div>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <div class="d-flex flex-column justify-content-left">
                          <h6 class="mb-0 text-sm">{{ row.name }}</h6>
                          <p class="text-xs text-secondary mb-0">{{ row.nik}}</p>
                        </div>
                      </td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.npwp }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.position_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.group }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.department_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.job_title_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.plant_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.member_spsi }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.join_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.employee_status }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.employee_activity }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.permanent_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.empoyee_end_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.ktp }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.place_birth }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.date_birth).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment().diff(row.date_birth, 'years') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.gender }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.blood_group }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.mother_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.religion }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.home_address }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.ktp_address }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_kk }}</span></td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          <a target="_blank" :href="'https://api.whatsapp.com/send?phone=62'+row.no_hp_first+'&text=Assalamualaikum Hallo ' +row.name+','" style="color: blue;">{{row.no_hp_first}} </a>
                        </span>
                      </td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_hp_second }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_emergency }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.email }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.maried_status }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.total_amenability }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.last_education }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.an_bank }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.type_bank }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_rek }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.brance }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_ket_mmf }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_ket_tokki }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_kesehatan }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.asuransi_mandiri_inhelath }}</span></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_ktp" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_ktp">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_kk" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_kk">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_npwp" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_npwp">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_buku_rekening" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_buku_rekening">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_bpjs_kesehatan" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_bpjs_kesehatan">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_bpjs_ketenagakerjaan" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_bpjs_ketenagakerjaan">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_cv" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_cv">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_vaksin" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_vaksin">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_sertefikat_keahlian" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_sertefikat_keahlian">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_ijazah_terakhir" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_ijazah_terakhir">View Files</a></td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                        <i class="fa fa-copy fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="copyLink(row.safety_code)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{tableEmpProject.data.length}} </div>
            </div>
          </div>
      </div>
    </div>

    <!-- MAGANG DATA -->
    <div class=" row mt-4">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6> Magang</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">No</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Photo</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Name</th>
                      <th colspan="12" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Company Information</th>
                      <th colspan="18" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Personal Information</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Bank Information</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS Information</th>
                      <th colspan="10" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">File Information</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Action</th>
                    </tr>
                    <tr>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">NPWP</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Position</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Golongan</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Department</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Job Title</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Plant</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">SPSI Member</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">JOIN DATE</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">EMPLOYEE STATUS</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">EMPLOYEE Activity</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">permanent date</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Employement End Date</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">KTP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Place Of Birth</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Date Of Birth</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Age</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Gender</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Blood Group</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Mother Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Religion</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Home Address</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">KTP Address</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO KK.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO HP 1</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO HP 2</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NO TLP EMERGENCY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">EMAIL</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Married Status</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">total amenability</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">last education</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">A/N Bank</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">TYPE Bank</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">No Rek.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Brance</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS KETENAGAKERJAAN MMF</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS KETENAGAKERJAAN TOKKI</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">BPJS KESEHATAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">ASURANSI MANDIRI IN HEALTH</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">KTP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">KK</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">NPWP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">BUKU REKENING</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">BPJS KESEHATAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">BPJS KETENAGAKERJAAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">CV</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">VAKSIN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">SERTIFIKAT KEAHLIAN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #F4A460;">IJAZAH TERAKHIR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in tableEmpMagang.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                          <div>
                            <img
                              v-if="row.photo"
                              :src="storageUrl+'/document_employee/'+row.photo"
                              class="avatar avatar-sm me-3"
                              alt="user1"
                            />
                            <img
                              v-else
                              src="../../assets/img/avatar_karyawan.png"
                              class="avatar avatar-sm me-3"
                              alt="user1"
                            />
                          </div>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <div class="d-flex flex-column justify-content-left">
                          <h6 class="mb-0 text-sm">{{ row.name }}</h6>
                          <p class="text-xs text-secondary mb-0">{{ row.nik}}</p>
                        </div>
                      </td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.npwp }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.position_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.group }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.department_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.job_title_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.plant_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.member_spsi }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.join_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.employee_status }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.employee_activity }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.permanent_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.empoyee_end_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.ktp }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.place_birth }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.date_birth).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment().diff(row.date_birth, 'years') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.gender }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.blood_group }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.mother_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.religion }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.home_address }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.ktp_address }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_kk }}</span></td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          <a target="_blank" :href="'https://api.whatsapp.com/send?phone=62'+row.no_hp_first+'&text=Assalamualaikum Hallo ' +row.name+','" style="color: blue;">{{row.no_hp_first}} </a>
                        </span>
                      </td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_hp_second }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_emergency }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.email }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.maried_status }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.total_amenability }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.last_education }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.an_bank }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.type_bank }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_rek }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.brance }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_ket_mmf }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_ket_tokki }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_kesehatan }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.asuransi_mandiri_inhelath }}</span></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_ktp" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_ktp">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_kk" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_kk">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_npwp" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_npwp">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_buku_rekening" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_buku_rekening">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_bpjs_kesehatan" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_bpjs_kesehatan">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_bpjs_ketenagakerjaan" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_bpjs_ketenagakerjaan">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_cv" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_cv">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_vaksin" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_vaksin">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_sertefikat_keahlian" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_sertefikat_keahlian">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_ijazah_terakhir" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_ijazah_terakhir">View Files</a></td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                        <i class="fa fa-copy fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="copyLink(row.safety_code)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{tableEmpMagang.data.length}} </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content-width" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="row">
          <p class="text-uppercase text-sm font-weight-bold" style="color: #FF4500;">Company Information</p>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">NIK <span style="color: red;">*</span></label>
            <input type="text" class="form-control" placeholder="NIK" v-model="employeeData.nik">
            <label for="example-text-input" class="form-control-label mt-3">Name <span style="color: red;">*</span></label>
            <input type="text" class="form-control" placeholder="Name" v-model="employeeData.name">
            <label for="example-text-input" class="form-control-label mt-3">NPWP </label>
            <input type="text" class="form-control" placeholder="NPWP" v-model="employeeData.npwp">
            <label for="example-text-input" class="form-control-label mt-3">Position <span style="color: red;">*</span></label>
            <multiselect v-model="employeeData.position" :options="tablePosition.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Position" label="name" track-by="name" :preselect-first="true" @keyup="getPosition">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
            <label for="example-text-input" class="form-control-label mt-3">Golongan <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="employeeData.group">
              <option value="III">III</option>
              <option value="III A">III A</option>
              <option value="IV">IV</option>
              <option value="V">V</option>
              <option value="VI">VI</option>
              <option value="VII">VII</option>
              <option value="VIII">VIII</option>
              <option value="IX">IX</option>
              <option value="S">S</option>
              <option value="-">-</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Department <span style="color: red;">*</span></label>
            <multiselect v-model="employeeData.department" :options="tableDepartment.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Department" label="name" track-by="name" :preselect-first="true" @keyup="getDepartment">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
            <label for="example-text-input" class="form-control-label mt-3">Job Title <span style="color: red;">*</span></label>
            <multiselect v-model="employeeData.job_title" :options="tableJobTitle.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Job Title" label="name" track-by="name" :preselect-first="true" @keyup="getJobTitle">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
            <label for="example-text-input" class="form-control-label mt-3">Safety Code</label>
            <input type="text" class="form-control" placeholder="Safety Code" v-model="employeeData.safety_code" disabled>
          </div>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">SPSI Member <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="employeeData.member_spsi">
              <option value="SPSI">SPSI</option>
              <option value="Non SPSI">Non SPSI</option>
              <option value="-">-</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Join Date <span style="color: red;">*</span></label>
            <input type="date" class="form-control" placeholder="Join Date" v-model="employeeData.join_date">
            <label for="example-text-input" class="form-control-label mt-3">Join Date MMF</label>
            <input type="date" class="form-control" placeholder="Join Date MMF" v-model="employeeData.join_date_mmf">
            <label for="example-text-input" class="form-control-label mt-3">Employee Status <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="employeeData.employee_status">
              <option value="Expat">Expat</option>
              <option value="Kontrak">Kontrak</option>
              <option value="Kontrak I">Kontrak I</option>
              <option value="Kontrak II">Kontrak II</option>
              <option value="Kontrak III">Kontrak III</option>
              <option value="Kontrak IV">Kontrak IV</option>
              <option value="Kontrak V">Kontrak V</option>
              <option value="Kontrak VI">Kontrak VI</option>
              <option value="Kontrak VII">Kontrak VII</option>
              <option value="Kontrak Project">Kontrak Project</option>
              <option value="Pegawai Tetap">Pegawai Tetap</option>
              <option value="Magang">Magang</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Employee Activity <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="employeeData.employee_activity">
              <option value="Active">Active</option>
              <option value="Contract Ends">Contract Ends</option>
              <option value="Resign">Resign</option>
              <option value="Fired">Fired</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Permanent Date </label>
            <input type="date" class="form-control" placeholder="Permanent Date" v-model="employeeData.permanent_date">
            <label for="example-text-input" class="form-control-label mt-3">Employement End Date </label>
            <input type="date" class="form-control" placeholder="Employement End Date" v-model="employeeData.empoyee_end_date">
            <label for="example-text-input" class="form-control-label mt-3">Plant <span style="color: red;">*</span></label>
            <multiselect v-model="employeeData.plant" :options="tablePlant.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Plant" label="name" track-by="name" :preselect-first="true" @keyup="getPlant">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </div>

          <hr class="horizontal dark mt-4" />
          <p class="text-uppercase text-sm font-weight-bold" style="color: #DAA520;">Personal Information</p>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">NO KTP </label>
            <input type="text" class="form-control" placeholder="NO KTP" v-model="employeeData.ktp">
            <label for="example-text-input" class="form-control-label mt-3">Place Of Birth </label>
            <input type="text" class="form-control" placeholder="Place Of Birth" v-model="employeeData.place_birth">
            <label for="example-text-input" class="form-control-label mt-3">Date Of Birth </label>
            <input type="date" class="form-control" placeholder="Date Of Birth" v-model="employeeData.date_birth">
            <label for="example-text-input" class="form-control-label mt-3">Gender </label>
            <select class="form-select" aria-label="Default select example" v-model="employeeData.gender">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Blood Group </label>
            <select class="form-select" aria-label="Default select example" v-model="employeeData.blood_group">
              <option value="A">A</option>
              <option value="A+">A+</option>
              <option value="B">B</option>
              <option value="B+">B+</option>
              <option value="AB">AB</option>
              <option value="AB+">AB+</option>
              <option value="O">O</option>
              <option value="O+">O+</option>
              <option value="-">-</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Mother Name </label>
            <input type="text" class="form-control" placeholder="Mother Name" v-model="employeeData.mother_name">
            <label for="example-text-input" class="form-control-label mt-3">Religion </label>
            <select class="form-select" aria-label="Default select example" v-model="employeeData.religion">
              <option value="Islam">Islam</option>
              <option value="Protestan">Protestan</option>
              <option value="Katolik">Katolik</option>
              <option value="Hindu">Hindu</option>
              <option value="Buddha">Buddha</option>
              <option value="Konghucu">Konghucu</option>
              <option value="-">-</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Home Address </label>
            <textarea class="form-control" v-model="employeeData.home_address" placeholder="Home Address"></textarea>
            <label for="example-text-input" class="form-control-label mt-3">KTP Address </label>
            <textarea class="form-control" v-model="employeeData.ktp_address" placeholder="KTP Address"></textarea>
          </div>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">NO KK. </label>
            <input type="text" class="form-control" placeholder="NO KK." v-model="employeeData.no_kk">
            <label for="example-text-input" class="form-control-label mt-3">NO HP 1 </label>
            <input type="text" class="form-control" placeholder="NO HP 1" v-model="employeeData.no_hp_first">
            <label for="example-text-input" class="form-control-label mt-3">NO HP 2 </label>
            <input type="text" class="form-control" placeholder="NO HP 2" v-model="employeeData.no_hp_second">
            <label for="example-text-input" class="form-control-label mt-3">No Tlp Emergency </label>
            <input type="text" class="form-control" placeholder="No Tlp Emergency" v-model="employeeData.no_emergency">
            <label for="example-text-input" class="form-control-label mt-3">Email </label>
            <input type="text" class="form-control" placeholder="Email" v-model="employeeData.email">
            <label for="example-text-input" class="form-control-label mt-3">Married Status </label>
            <select class="form-select" aria-label="Default select example" v-model="employeeData.maried_status">
              <option value="Married">Married</option>
              <option value="Not Married Yet">Not Married Yet</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Total Amenability </label>
            <input type="number" class="form-control" placeholder="Total Amenability" v-model="employeeData.total_amenability">
            <label for="example-text-input" class="form-control-label mt-3">Last Education </label>
            <input type="text" class="form-control" placeholder="Last Education" v-model="employeeData.last_education">
          </div>

          <hr class="horizontal dark mt-4" />
          <p class="text-uppercase text-sm font-weight-bold" style="color: #6495ED;">Bank Information</p>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">A/N Bank </label>
            <input type="text" class="form-control" placeholder="A/N Bank" v-model="employeeData.an_bank">
            <label for="example-text-input" class="form-control-label mt-3">Type Bank </label>
            <input type="text" class="form-control" placeholder="Type Bank" v-model="employeeData.type_bank">
          </div>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">No Rek. </label>
            <input type="text" class="form-control" placeholder="No Rek." v-model="employeeData.no_rek">
            <label for="example-text-input" class="form-control-label mt-3">Brance </label>
            <input type="text" class="form-control" placeholder="Brance" v-model="employeeData.brance">
          </div>

          <hr class="horizontal dark mt-4" />
          <p class="text-uppercase text-sm font-weight-bold" style="color: #32CD32;">BPJS Information</p>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">No BPJS Ketenagakerjaan MMF </label>
            <input type="text" class="form-control" placeholder="No BPJS Ketenagakerjaan MMF" v-model="employeeData.bpjs_ket_mmf">
            <label for="example-text-input" class="form-control-label mt-3">No BPJS Ketenagakerjaan Tokki </label>
            <input type="text" class="form-control" placeholder="No BPJS Ketenagakerjaan Tokki" v-model="employeeData.bpjs_ket_tokki">
          </div>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">No BPJS Kesehatan </label>
            <input type="text" class="form-control" placeholder="No BPJS Kesehatan" v-model="employeeData.bpjs_kesehatan">
            <label for="example-text-input" class="form-control-label mt-3">No Asuransi Mandiri In Health </label>
            <input type="text" class="form-control" placeholder="No Asuransi Mandiri In Health" v-model="employeeData.asuransi_mandiri_inhelath">
          </div>

          <hr class="horizontal dark mt-4" />
          <p class="text-uppercase text-sm font-weight-bold" style="color: #F4A460;">File Information</p>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">Photo </label>
            <input type="file" class="form-control" placeholder="Photo" @change="filesChangePhoto">
            <label for="example-text-input" class="form-control-label mt-3">File KTP </label>
            <input type="file" class="form-control" placeholder="File KTP" @change="filesChangeKtp">
            <label for="example-text-input" class="form-control-label mt-3">File KK </label>
            <input type="file" class="form-control" placeholder="File KK" @change="filesChangeKk">
            <label for="example-text-input" class="form-control-label mt-3">File NPWP </label>
            <input type="file" class="form-control" placeholder="File NPWP" @change="filesChangeNpwp">
            <label for="example-text-input" class="form-control-label mt-3">File Buku Rekening </label>
            <input type="file" class="form-control" placeholder="File Buku Rekening" @change="filesChangeBukuRekening">
          </div>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">File BPJS Kesehatan </label>
            <input type="file" class="form-control" placeholder="File BPJS Kesehatan" @change="filesChangeBpjsKesehatan">
            <label for="example-text-input" class="form-control-label mt-3">File BPJS Ketenagakerjaan </label>
            <input type="file" class="form-control" placeholder="File BPJS Ketenagakerjaan" @change="filesChangeBpjsKetenagakerjaan">
            <label for="example-text-input" class="form-control-label mt-3">File CV </label>
            <input type="file" class="form-control" placeholder="File CV" @change="filesChangeCv">
            <label for="example-text-input" class="form-control-label mt-3">File Vaksin </label>
            <input type="file" class="form-control" placeholder="File Vaksin" @change="filesChangeVaksin">
            <label for="example-text-input" class="form-control-label mt-3">File Sertefikat Keahlian </label>
            <input type="file" class="form-control" placeholder="File Sertefikat Keahlian" @change="filesChangeSertefikatKeahlian">
            <label for="example-text-input" class="form-control-label mt-3">File Ijazah Terakhir </label>
            <input type="file" class="form-control" placeholder="File Ijazah Terakhir" @change="filesChangeIjazahTerakhir">
          </div>

        </div> 
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">NIK</label>
        <input type="text" class="form-control" placeholder="NIK" v-model="search.nik" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Name</label>
        <input type="text" class="form-control" placeholder="Name" v-model="search.name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">NPWP</label>
        <input type="text" class="form-control" placeholder="NPWP" v-model="search.npwp" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Position</label>
        <input type="text" class="form-control" placeholder="Position" v-model="search.position" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Golongan</label>
        <select class="form-select" aria-label="Default select example" v-model="search.group" v-on:keyup.enter="get()">
          <option value="III">III</option>
          <option value="III A">III A</option>
          <option value="IV">IV</option>
          <option value="V">V</option>
          <option value="VI">VI</option>
          <option value="VII">VII</option>
          <option value="VIII">VIII</option>
          <option value="IX">IX</option>
          <option value="S">S</option>
          <option value="-">-</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Department</label>
        <input type="text" class="form-control" placeholder="Department" v-model="search.department" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Job Title</label>
        <input type="text" class="form-control" placeholder="Job Title" v-model="search.job_title" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Plant</label>
        <select class="form-select" aria-label="Default select example" v-model="search.plant" v-on:keyup.enter="get()">
          <option value="Discovery">Discovery</option>
          <option value="Endeavor">Endeavor</option>
          <option value="Simba">Simba</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">SPSI Member</label>
        <select class="form-select" aria-label="Default select example" v-model="search.member_spsi" v-on:keyup.enter="get()">
          <option value="SPSI">SPSI</option>
          <option value="Non SPSI">Non SPSI</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Join Date</label>
        <input type="date" class="form-control" placeholder="Join Date" v-model="search.join_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Employee Status</label>
        <select class="form-select" aria-label="Default select example" v-model="search.employee_status" v-on:keyup.enter="get()">
          <option value="Expat">Expat</option>
          <option value="Kontrak">Kontrak</option>
          <option value="Kontrak I">Kontrak I</option>
          <option value="Kontrak II">Kontrak II</option>
          <option value="Kontrak III">Kontrak III</option>
          <option value="Kontrak IV">Kontrak IV</option>
          <option value="Kontrak V">Kontrak V</option>
          <option value="Kontrak VI">Kontrak VI</option>
          <option value="Kontrak VII">Kontrak VII</option>
          <option value="Kontrak Project">Kontrak Project</option>
          <option value="Pegawai Tetap">Pegawai Tetap</option>
          <option value="Magang">Magang</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Permanent Date</label>
        <input type="date" class="form-control" placeholder="Permanent Date" v-model="search.permanent_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Employment End Date</label>
        <input type="date" class="form-control" placeholder="Employment End Date" v-model="search.employee_end_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">KTP</label>
        <input type="text" class="form-control" placeholder="KTP" v-model="search.ktp" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Place Of Birth</label>
        <input type="text" class="form-control" placeholder="Place Of Birth" v-model="search.place_birth" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Date Of Birth</label>
        <input type="date" class="form-control" placeholder="Date Of Birth" v-model="search.date_birth" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Gender </label>
        <select class="form-select" aria-label="Default select example" v-model="search.gender">
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Blood Group</label>
        <select class="form-select" aria-label="Default select example" v-model="search.blood_group" v-on:keyup.enter="get()">
          <option value="A">A</option>
          <option value="A+">A+</option>
          <option value="B">B</option>
          <option value="B+">B+</option>
          <option value="AB">AB</option>
          <option value="AB+">AB+</option>
          <option value="O">O</option>
          <option value="O+">O+</option>
          <option value="-">-</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Mother Name</label>
        <input type="text" class="form-control" placeholder="Mother Name" v-model="search.mother_name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Religion</label>
        <select class="form-select" aria-label="Default select example" v-model="search.religion" v-on:keyup.enter="get()">
          <option value="Islam">Islam</option>
          <option value="Protestan">Protestan</option>
          <option value="Katolik">Katolik</option>
          <option value="Hindu">Hindu</option>
          <option value="Buddha">Buddha</option>
          <option value="Konghucu">Konghucu</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Home Address</label>
        <input type="text" class="form-control" placeholder="Home Address" v-model="search.home_address" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">KTP Address</label>
        <input type="text" class="form-control" placeholder="KTP Address" v-model="search.ktp_address" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">NO KK.</label>
        <input type="text" class="form-control" placeholder="NO KK." v-model="search.no_kk" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Married Status</label>
        <select class="form-select" aria-label="Default select example" v-model="search.maried_status" v-on:keyup.enter="get()">
          <option value="Married">Married</option>
          <option value="Not Married Yet">Not Married Yet</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Total Amenability </label>
        <input type="number" class="form-control" placeholder="Total Amenability " v-model="search.total_amenability" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Last Education </label>
        <input type="text" class="form-control" placeholder="Last Education " v-model="search.last_education" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">A/N Bank </label>
        <input type="text" class="form-control" placeholder="A/N Bank " v-model="search.an_bank" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">NO Rek. </label>
        <input type="text" class="form-control" placeholder="NO Rek. " v-model="search.no_rek" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Brance </label>
        <input type="text" class="form-control" placeholder="Brance " v-model="search.brance" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">BPJS Ketenagakerjaan MMF </label>
        <input type="text" class="form-control" placeholder="BPJS Ketenagakerjaan MMF " v-model="search.bpjs_ket_mmf" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">BPJS Ketenagakerjaan Tokki </label>
        <input type="text" class="form-control" placeholder="BPJS Ketenagakerjaan Tokki " v-model="search.bpjs_ket_tokki" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">BPJS Kesehatan </label>
        <input type="text" class="form-control" placeholder="BPJS Kesehatan " v-model="search.bpjs_kesehatan" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Asuransi No. </label>
        <input type="text" class="form-control" placeholder="Asuransi No. " v-model="search.asuransi_mandiri_inhelath" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER LABOR HR & GA ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilterLabor.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilterLabor.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilterLabor.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Periode</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.periode_labor" v-on:keyup.enter="get()"/>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL IMPORT ======= -->
  <div class="container">
    <vue-final-modal v-model="formImport.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImport.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImport.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChange" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import Data Users.xlsx'" style="color: blue;"> Import Data Setting Approval</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center;">Column</th>
                  <th style="font-size: 13px; text-align: center;">Error</th>
                  <th style="font-size: 13px; text-align: center;">Row</th>
                  <th style="font-size: 13px; text-align: center;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-6"> 
      </div>
      <div class="col-2" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImport.show = false">Close</argon-button>
      </div>
      <div class="col-1">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importData()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import employee from '@/services/employee.service';
import plant from '@/services/plant.service';
import department from '@/services/department.service';
import position from '@/services/position.service';
import jobTitle from '@/services/jobTitle.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      tableReminderEmployeeEnd: {
        data: []
      },
      table: {
        data: []
      },
      tableEmpProject: {
        data: []
      },
      tableEmpMagang: {
        data: []
      },
      tableListPosition: {
        data: []
      },
      tableEmpLaborNameIn: {
        data: []
      },
      tableEmpLaborNameOut: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formFilterLabor: {
        add: true,
        title: "Filter Labor HR & GA",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      employeeData: {},
      search: {
        nik: '',
        name: '',
        npwp: '',
        position: '',
        group: '',
        department: '',
        job_title: '',
        plant: '',
        member_spsi: '',
        join_date: '',
        employee_status: '',
        permanent_date: '',
        employee_end_date: '',
        ktp: '',
        place_birth: '',
        date_birth: '',
        gender: '',
        blood_group: '',
        mother_name: '',
        religion: '',
        home_address: '',
        ktp_address: '',
        no_kk: '',
        maried_status: '',
        total_amenability: '',
        an_bank: '',
        no_rek: '',
        brance: '',
        bpjs_ket_mmf: '',
        bpjs_ket_tokki: '',
        bpjs_kesehatan: '',
        asuransi_mandiri_inhelath: '',

        periode_labor: '',
      },
      apiUrl :config.apiUrl,
      tablePlant: {
        data: []
      },
      tableDepartment: {
        data: []
      },
      tablePosition: {
        data: []
      },
      tableJobTitle: {
        data: []
      },
      totalEmpAll: '',
      totalEmpPermanent: '',
      totalEmpContract: '',
      totalEmpContractTemporary: '',
      totalEmpFemale: '',
      totalEmpMale: '',
      totalEmpDsc: '',
      totalEmpEdv: '',
      totalEmpSmb: '',

      totalEmpLaborPermanentIn: '',
      totalEmpLaborPermanentOut: '',
      totalEmpLaborContractIn: '',
      totalEmpLaborContractOut: '',
      backgroundRed: null,

    };
  },
  mounted(){
    this.get();
    this.getReminderEmployeeEnd();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, employee.index({nik: this.search.nik, name: this.search.name, npwp: this.search.npwp, position: this.search.position, group: this.search.group, department: this.search.department, job_title: this.search.job_title, plant: this.search.plant, member_spsi: this.search.member_spsi,  join_date: this.search.join_date, employee_status: this.search.employee_status, permanent_date: this.search.permanent_date, employee_end_date: this.search.employee_end_date, ktp: this.search.ktp, place_birth: this.search.place_birth, date_birth: this.search.date_birth, gender: this.search.gender, blood_group: this.search.blood_group, mother_name: this.search.mother_name, religion: this.search.religion, home_address: this.search.home_address, ktp_address: this.search.ktp_address, no_kk: this.search.no_kk, maried_status: this.search.maried_status, total_amenability: this.search.total_amenability, an_bank: this.search.an_bank, no_rek: this.search.no_rek, brance: this.search.brance, bpjs_ket_mmf: this.search.bpjs_ket_mmf, bpjs_ket_tokki: this.search.bpjs_ket_tokki, bpjs_kesehatan: this.search.bpjs_kesehatan, asuransi_mandiri_inhelath: this.search.asuransi_mandiri_inhelath, periode_labor: this.search.periode_labor, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.tableEmpProject.data = response.data.data.dataEmpProject;
          context.tableEmpMagang.data  = response.data.data.dataEmpMagang;

          context.totalEmpAll   = response.data.data.dataEmpAll;
          context.tableListPosition.data    = response.data.data.dataPosition;
          context.totalEmpPermanent         = response.data.data.dataEmpPermanent;
          context.totalEmpContract          = response.data.data.dataEmpContract;
          context.totalEmpContractTemporary = response.data.data.dataEmpContractTemporary;
          context.totalEmpFemale = response.data.data.dataEmpFemale;
          context.totalEmpMale   = response.data.data.dataEmpMale;
          context.totalEmpDsc = response.data.data.dataEmpDsc;
          context.totalEmpEdv = response.data.data.dataEmpEdv;
          context.totalEmpSmb = response.data.data.dataEmpSmb;

          context.totalEmpLaborPermanentIn  = response.data.data.dataEmpLaborPermanentIn;
          context.totalEmpLaborPermanentOut = response.data.data.dataEmpLaborPermanentOut;
          context.totalEmpLaborContractIn   = response.data.data.dataEmpLaborContractIn;
          context.totalEmpLaborContractOut  = response.data.data.dataEmpLaborContractOut;

          context.tableEmpLaborNameIn.data  = response.data.data.dataEmpLaborNameIn;
          context.tableEmpLaborNameOut.data = response.data.data.dataEmpLaborNameOut;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show      = false;
         context.formFilterLabor.show = false;
      })
      .call()
    },
    getReminderEmployeeEnd(param){
      let context = this;               
      Api(context, employee.getReminderEmployeeEnd()).onSuccess(function(response) {    
          context.tableReminderEmployeeEnd.data = response.data.data;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableReminderEmployeeEnd.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getPlant(param){
      let context = this;        
      Api(context, plant.getPlant({search: param.target.value})).onSuccess(function(response) {    
          context.tablePlant.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePlant.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getDepartment(param){
      let context = this;        
      Api(context, department.getDepartment({search: param.target.value})).onSuccess(function(response) {    
          context.tableDepartment.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableDepartment.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPosition(param){
      let context = this;        
      Api(context, position.getPosition({search: param.target.value})).onSuccess(function(response) {    
          context.tablePosition.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePosition.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getJobTitle(param){
      let context = this;        
      Api(context, jobTitle.getJobTitle({search: param.target.value})).onSuccess(function(response) {    
          context.tableJobTitle.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableJobTitle.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    filterLabor() {
      this.formFilterLabor.add   = true;
      this.formFilterLabor.show  = true;
      this.formFilterLabor.title = "Filter Labor HR & GA";
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.employeeData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, employee.show(id)).onSuccess(function(response) {
          context.employeeData            = response.data.data.data;
          context.employeeData.plant      = response.data.data.dataPlant
          context.employeeData.department = response.data.data.dataDept
          context.employeeData.position   = response.data.data.dataPos
          context.employeeData.job_title  = response.data.data.dataJT
          context.form.show               = true;
          context.form.title              = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.employeeData.nik != undefined && context.employeeData.name != undefined && context.employeeData.position != undefined && context.employeeData.group != undefined && context.employeeData.department != undefined && context.employeeData.job_title != undefined && context.employeeData.member_spsi != undefined && context.employeeData.join_date != undefined && context.employeeData.employee_status != undefined && context.employeeData.employee_activity != undefined && context.employeeData.plant != undefined ) {
        // company Information
        formData.append('nik', context.employeeData.nik);
        formData.append('name', context.employeeData.name);
        formData.append('npwp', (context.employeeData.npwp == undefined) ? '' : context.employeeData.npwp);
        formData.append('position', JSON.stringify(context.employeeData.position));
        formData.append('group', context.employeeData.group);
        formData.append('department', JSON.stringify(context.employeeData.department));
        formData.append('job_title', JSON.stringify(context.employeeData.job_title));
        formData.append('member_spsi', context.employeeData.member_spsi);
        formData.append('join_date', context.employeeData.join_date);
        formData.append('join_date_mmf', (context.employeeData.join_date_mmf == undefined) ? '' : context.employeeData.join_date_mmf);
        formData.append('employee_status', context.employeeData.employee_status);
        formData.append('employee_activity', context.employeeData.employee_activity);
        formData.append('permanent_date', (context.employeeData.permanent_date == undefined) ? '' : context.employeeData.permanent_date);
        formData.append('empoyee_end_date', (context.employeeData.empoyee_end_date == undefined) ? '' : context.employeeData.empoyee_end_date);
        formData.append('plant', JSON.stringify(context.employeeData.plant));
        formData.append('safety_code', (context.employeeData.safety_code == undefined) ? '' : context.employeeData.safety_code);
        // Personal Information
        formData.append('ktp', (context.employeeData.ktp == undefined) ? '' : context.employeeData.ktp);
        formData.append('place_birth', (context.employeeData.place_birth == undefined) ? '' : context.employeeData.place_birth);
        formData.append('date_birth', (context.employeeData.date_birth == undefined) ? '' : context.employeeData.date_birth);
        formData.append('gender', (context.employeeData.gender == undefined) ? '' : context.employeeData.gender);
        formData.append('blood_group', (context.employeeData.blood_group == undefined) ? '' : context.employeeData.blood_group);
        formData.append('mother_name', (context.employeeData.mother_name == undefined) ? '' : context.employeeData.mother_name);
        formData.append('religion', (context.employeeData.religion == undefined) ? '' : context.employeeData.religion);
        formData.append('home_address', (context.employeeData.home_address == undefined) ? '' : context.employeeData.home_address);
        formData.append('ktp_address', (context.employeeData.ktp_address == undefined) ? '' : context.employeeData.ktp_address);
        formData.append('no_kk', (context.employeeData.no_kk == undefined) ? '' : context.employeeData.no_kk);
        formData.append('no_hp_first', (context.employeeData.no_hp_first == undefined) ? '' : context.employeeData.no_hp_first);
        formData.append('no_hp_second', (context.employeeData.no_hp_second == undefined) ? '' : context.employeeData.no_hp_second);
        formData.append('no_emergency', (context.employeeData.no_emergency == undefined) ? '' : context.employeeData.no_emergency);
        formData.append('email', (context.employeeData.email == undefined) ? '' : context.employeeData.email);
        formData.append('maried_status', (context.employeeData.maried_status == undefined) ? '' : context.employeeData.maried_status);
        formData.append('total_amenability', (context.employeeData.total_amenability == undefined) ? '' : context.employeeData.total_amenability);
        formData.append('last_education', (context.employeeData.last_education == undefined) ? '' : context.employeeData.last_education);
        // bank Information
        formData.append('an_bank', (context.employeeData.an_bank == undefined) ? '' : context.employeeData.an_bank);
        formData.append('type_bank', (context.employeeData.type_bank == undefined) ? '' : context.employeeData.type_bank);
        formData.append('no_rek', (context.employeeData.no_rek == undefined) ? '' : context.employeeData.no_rek);
        formData.append('brance', (context.employeeData.brance == undefined) ? '' : context.employeeData.brance);
        // bpjs Information
        formData.append('bpjs_ket_mmf', (context.employeeData.bpjs_ket_mmf == undefined) ? '' : context.employeeData.bpjs_ket_mmf);
        formData.append('bpjs_ket_tokki', (context.employeeData.bpjs_ket_tokki == undefined) ? '' : context.employeeData.bpjs_ket_tokki);
        formData.append('bpjs_kesehatan', (context.employeeData.bpjs_kesehatan == undefined) ? '' : context.employeeData.bpjs_kesehatan);
        formData.append('asuransi_mandiri_inhelath', (context.employeeData.asuransi_mandiri_inhelath == undefined) ? '' : context.employeeData.asuransi_mandiri_inhelath);
        // file Information
        formData.append('photo', (context.employeeData.photo == undefined) ? '' : context.employeeData.photo);
        formData.append('file_ktp', (context.employeeData.file_ktp == undefined) ? '' : context.employeeData.file_ktp);
        formData.append('file_kk', (context.employeeData.file_kk == undefined) ? '' : context.employeeData.file_kk);
        formData.append('file_npwp', (context.employeeData.file_npwp == undefined) ? '' : context.employeeData.file_npwp);
        formData.append('file_buku_rekening', (context.employeeData.file_buku_rekening == undefined) ? '' : context.employeeData.file_buku_rekening);
        formData.append('file_bpjs_kesehatan', (context.employeeData.file_bpjs_kesehatan == undefined) ? '' : context.employeeData.file_bpjs_kesehatan);
        formData.append('file_bpjs_ketenagakerjaan', (context.employeeData.file_bpjs_ketenagakerjaan == undefined) ? '' : context.employeeData.file_bpjs_ketenagakerjaan);
        formData.append('file_cv', (context.employeeData.file_cv == undefined) ? '' : context.employeeData.file_cv);
        formData.append('file_vaksin', (context.employeeData.file_vaksin == undefined) ? '' : context.employeeData.file_vaksin);
        formData.append('file_sertefikat_keahlian', (context.employeeData.file_sertefikat_keahlian == undefined) ? '' : context.employeeData.file_sertefikat_keahlian);
        formData.append('file_ijazah_terakhir', (context.employeeData.file_ijazah_terakhir == undefined) ? '' : context.employeeData.file_ijazah_terakhir);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, employee.create(formData));
      } else {
          api = Api(context, employee.update(this.employeeData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, employee.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    filesChange(e) {
        this.dataImport = e.target.files[0];
    },
    filesChangePhoto(e) {
        this.employeeData.photo = e.target.files[0];
    },
    filesChangeKtp(e) {
        this.employeeData.file_ktp = e.target.files[0];
    },
    filesChangeKk(e) {
        this.employeeData.file_kk = e.target.files[0];
    },
    filesChangeNpwp(e) {
        this.employeeData.file_npwp = e.target.files[0];
    },
    filesChangeBukuRekening(e) {
        this.employeeData.file_buku_rekening = e.target.files[0];
    },
    filesChangeBpjsKesehatan(e) {
        this.employeeData.file_bpjs_kesehatan = e.target.files[0];
    },
    filesChangeBpjsKetenagakerjaan(e) {
        this.employeeData.file_bpjs_ketenagakerjaan = e.target.files[0];
    },
    filesChangeCv(e) {
        this.employeeData.file_cv = e.target.files[0];
    },
    filesChangeVaksin(e) {
        this.employeeData.file_vaksin = e.target.files[0];
    },
    filesChangeSertefikatKeahlian(e) {
        this.employeeData.file_sertefikat_keahlian = e.target.files[0];
    },
    filesChangeIjazahTerakhir(e) {
        this.employeeData.file_ijazah_terakhir = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, employee.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    copyLink(safety_code) {
      navigator.clipboard.writeText('https://system.tef.co.id/detail-employee/'+safety_code);
      try {
        this.notify('Contents Successfully Copied', 'success')
      }catch (err) {
        this.notify('Content Failed To Copied', 'error')
      }
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    setBg(id) {
      this.backgroundRed = id
    }

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}

.scroll { 
  overflow: auto; 
  height: 600px; 
}

.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED; 
}

/*for freze ke samping*/
.scroll table td:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: #7FFF00;
}

/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 1090px;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
