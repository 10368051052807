import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('invoice-monitoring/index', {params});
    },
    getMyTask(params) {
        return Service().get('invoice-monitoring/get-my-task', {params});
    },
    getCustomer(params) {
        return Service().get('invoice-monitoring/get-customer', {params});
    },
    show(id) {
        return Service().get('invoice-monitoring/show/'+id);
    },
    create(params) {
        return Service().post('invoice-monitoring/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('invoice-monitoring/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('invoice-monitoring/delete/'+id);
    },
    import(params) {
        return Service().post('import/import-invoice-list', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },

    createSchedulePayement(id, params) {
        return Service().post('invoice-monitoring/create-schedule-payment/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    approvedTask(id) {
        return Service().post('invoice-monitoring/approved-task/'+id);
    },
    rejectedTask(id, params) {
        return Service().post('invoice-monitoring/reject-task/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}