<template>
  <div class="py-4 container-fluid">

    <div class=" row">
      <div class="col-12">
        <!-- CHART BAR DATA -->
        <div class="card mb-3"> 
          <div class="card-header pb-0 text-center align-content-center justify-content-center">
            <div class="row">
              <div class="col-12">
                <h5 class="text-center">ELECTRICITY USAGE GRAPH</h5>
              </div>
              <!-- <div class="col-2">
                <select class="form-select" aria-label="Default select example" @change="changeBarChart()" v-model="yearBarChart">
                  <option value="2026" >2026</option>
                  <option value="2025" >2025</option>
                  <option value="2024" >2024</option>
                  <option value="2023" >2023</option>
                  <option value="2022" >2022</option>
                  <option value="2021" >2021</option>
                  <option value="2019" >2019</option>
                  <option value="2018" >2018</option>
                </select>
              </div> -->
            </div>
          </div>
          <div class="card-body mt-4">
            <div class="pt-3 chart">
              <canvas id="chart-cons-week" class="chart-canvas" height="250"></canvas>
            </div>
          </div>
        </div>

          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Electrical Control</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PERIODE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TAGIHAN LISTRIK PLN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Kelebihan KVARH</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Pemakaian listrik BCS</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">BEBAN PEMBAYARAN LISTRIK</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.periode).format('MMMM YYYY') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">Rp. {{ convertRp(row.kwh) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">Rp. {{ convertRp(row.meter) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">Rp. {{ convertRp(row.price_kwh) }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">Rp. {{ convertRp(row.notes) }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Periode <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Periode" v-model="electricalControlData.periode">
        <label for="example-text-input" class="form-control-label mt-3">Tagihan Listrik PLN <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Tagihan Listrik PLN" v-model="electricalControlData.kwh">
        <label for="example-text-input" class="form-control-label mt-3">Kelebihan KVARH <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Kelebihan KVARH" v-model="electricalControlData.meter">
        <label for="example-text-input" class="form-control-label mt-3">Pemakaian listrik BCS <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Pemakaian listrik BCS" v-model="electricalControlData.price_kwh">
        <label for="example-text-input" class="form-control-label mt-3">Beban Pembayaran Listrik </label>
        <input type="number" class="form-control" placeholder="Beban Pembayaran Listrik" v-model="electricalControlData.notes">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Done </span>
            </span>
          </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Periode</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.periode" v-on:keyup.enter="get()"/>
        <label for="example-text-input" class="form-control-label mt-3">Notes</label>
        <input type="text" class="form-control" placeholder="Notes" v-model="search.notes" v-on:keyup.enter="get()">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import BasePagination from "@/components/BasePagination";
import { VueFinalModal } from 'vue-final-modal'
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import Chart from "chart.js/auto";
ChartJS.register(ArcElement, Tooltip, Legend)
import dashboardMaintenance from '@/services/dashboardMaintenance.service';
import electricalControl from '@/services/electricalControl.service';

export default {
  name: "tables",
  components: {
    BasePagination,
    ArgonButton,
    VueFinalModal,
    flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      backgroundRed: null,
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      electricalControlData: {},
      requestedDate:'',
      search: {
        periode: '',
        notes: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,

      dateElct: [],
      totKwh: [],
      totMtr: [],
      totPrice: [],
    };
  },
  mounted(){
    this.get();
    this.getChart();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, electricalControl.index({periode: this.search.periode, notes: this.search.notes})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page 

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getChart(param){
      let context = this;               
      Api(context, electricalControl.getChart()).onSuccess(function(response) {    
          context.dateElct = response.data.data.dateElct
          context.totKwh   = response.data.data.totKwh
          context.totMtr   = response.data.data.totMtr
          context.totPrice = response.data.data.totPrice

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.totalAsset = '';
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
         context.getChartBar();
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading = false;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.customerData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, electricalControl.show(id)).onSuccess(function(response) {
        context.electricalControlData = response.data.data;
        context.form.show             = true;
        context.form.title            = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.electricalControlData.kwh != undefined && context.electricalControlData.meter != undefined && context.electricalControlData.price_kwh != undefined && context.electricalControlData.periode != undefined) {
        formData.append('kwh', context.electricalControlData.kwh);
        formData.append('meter', context.electricalControlData.meter);
        formData.append('price_kwh', context.electricalControlData.price_kwh);
        formData.append('periode', context.electricalControlData.periode);
        formData.append('notes', (context.electricalControlData.notes == undefined) ? '' : context.electricalControlData.notes);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, electricalControl.create(formData));
      } else {
          api = Api(context, electricalControl.update(this.electricalControlData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    leadTime(startDate, endDate){
      var start = moment(startDate, "YYYY-MM-DD H:i:s");
      var end   = moment(endDate, "YYYY-MM-DD H:i:s");
      return moment.duration(end.diff(start)).asHours();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, electricalControl.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    filesChange(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, dashboardMaintenance.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    setBg(id) {
      this.backgroundRed = id
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    // CHART BAR
    getChartBar() {
    var ctx = document.getElementById("chart-cons-week").getContext("2d");

    new Chart(ctx, {
      type: "bar",
      data: {
        labels: Object.values(this.dateElct),
        datasets: [
          {
            label: "TagLis PLN (Rp)",
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "blue",
            data: Object.values(this.totKwh),
            maxBarThickness: 6,
          },
          {
            label: "kVarh (Rp)",
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "red",
            data: Object.values(this.totMtr),
            maxBarThickness: 6,
          },
          {
            label: "Pem. BCS (Rp)",
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "#4BB543",
            data: Object.values(this.totPrice),
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              beginAtZero: true,
              font: {
                size: 12,
                family: "Open Sans",
                style: "normal",
              },
              color: "#9ca2b7",
            },
          },
          // eslint-disable-next-line no-dupe-keys
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
          // eslint-disable-next-line no-dupe-keys
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
        },
      },
    });
  },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
