import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('job-title/index', {params});
    },
    getJobTitle(params) {
        return Service().get('job-title/get-job-title', {params});
    },
    show(id) {
        return Service().get('job-title/show/'+id);
    },
    create(params) {
        return Service().post('job-title/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('job-title/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('job-title/delete/'+id);
    },
}