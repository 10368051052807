import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
// import Signin from "../views/Signin.vue";
import Login from "../views/Login.vue";
import MainPrivilage from "../views/MainPrivilage.vue";

// SYSTEM ADMINISTRATOR 
import Account from "../views/admin/Account.vue";
import Privileges from "../views/admin/Privileges.vue";
import Menu from "../views/admin/Menu.vue";
import SubMenu from "../views/admin/SubMenu.vue";
// HUMAN RESOURCE
import Plant from "../views/employee/Plant.vue";
import Department from "../views/employee/Department.vue";
import Position from "../views/employee/Position.vue";
import JobTitle from "../views/employee/JobTitle.vue";
import Employee from "../views/employee/Employee.vue";
import DetailEmployee from "../views/employee/DetailEmployee.vue";
import EmployeesFamily from "../views/employee/EmployeesFamily.vue";
import Attandance from "../views/employee/Attandance.vue";
// PROJECT MANAGEMENT
import Customer from "../views/project_management/Customer.vue";
import Equipment from "../views/project_management/Equipment.vue";
import ProjectList from "../views/project_management/ProjectList.vue";
// INVENTORY WAREHOUSE
import UnitOfMeasurement from "../views/inventory/UnitOfMeasurement.vue";
import Supplier from "../views/inventory/Supplier.vue";
import WarehouseLocation from "../views/inventory/WarehouseLocation.vue";
import StorageLocation from "../views/inventory/StorageLocation.vue";
import InventoryList from "../views/inventory/InventoryList.vue";
import ItemsDetail from "../views/inventory/ItemsDetail.vue";
import TransactionInventory from "../views/inventory/TransactionInventory.vue";
import TransactionInventoryDetail from "../views/inventory/TransactionInventoryDetail.vue";
import RemaindStock from "../views/inventory/RemaindStock.vue";
import ReportByProject from "../views/inventory/ReportByProject.vue";
import ReportByDate from "../views/inventory/ReportByDate.vue";
import ReportByUser from "../views/inventory/ReportByUser.vue";
// INVENTORY HSE
import DashboardHse from "../views/inventory_hse/DashboardHse.vue";
import BarangMasuk from "../views/inventory_hse/BarangMasuk.vue";
import BarangKeluar from "../views/inventory_hse/BarangKeluar.vue";
import StockBarang from "../views/inventory_hse/StockBarang.vue";
import ReturnStock from "../views/inventory_hse/ReturnStock.vue";
import StockRemainder from "../views/inventory_hse/StockRemainder.vue";
import DetailBarang from "../views/inventory_hse/DetailBarang.vue";
// MEDICAL REIMBUSHMENT
import FrameAndLensGlasses from "../views/medical_reimbushment/FrameAndLensGlasses.vue";
// MEETING ROOM
import MeetingSchedule from "../views/meeting_room/MeetingSchedule.vue";
import ListMeetingRoom from "../views/meeting_room/ListMeetingRoom.vue";
import MeetingRoomBooking from "../views/meeting_room/MeetingRoomBooking.vue";
// WORKING GROUP
import WorkingGroup from "../views/working_group/WorkingGroup.vue";
import WorkingGroupMembers from "../views/working_group/WorkingGroupMembers.vue";
// WORKING HOUR
import ActivityList from "../views/working_hour/ActivityList.vue";
import DailyActivity from "../views/working_hour/DailyActivity.vue";
import DailyControlBoard from "../views/working_hour/DailyControlBoard.vue";
import WhSummaryProjectActivity from "../views/working_hour/WhSummaryProjectActivity.vue";
import WhSummaryProjectJobTitle from "../views/working_hour/WhSummaryProjectJobTitle.vue";
import UserDailyActivity from "../views/working_hour/UserDailyActivity.vue";
// VEHICLE MANAGEMENT
import DriverList from "../views/vehicle_management/DriverList.vue";
import VehicleList from "../views/vehicle_management/VehicleList.vue";
import VehicleManagement from "../views/vehicle_management/VehicleManagement.vue";
import VehicleRequest from "../views/vehicle_management/VehicleRequest.vue";
// PROCUREMENT
import PurchaseRequisition from "../views/procurement/PurchaseRequisition.vue";
import PurchaseList from "../views/procurement/PurchaseList.vue";
// MONITORING PIPING PROJECT
import DashboardMonitoring from "../views/piping_project/DashboardMonitoring.vue";
import MonitoringExoticPiping from "../views/piping_project/MonitoringExoticPiping.vue";
import MaterialStatusPiping from "../views/piping_project/MaterialStatusPiping.vue";
import InputMaterialStock from "../views/piping_project/InputMaterialStock.vue";
// LOAN
import Loan from "../views/loan/Loan.vue";
// SALES
import SalesDashboard from "../views/sales/SalesDashboard.vue";
import SalesAdvisorPo from "../views/sales/SalesAdvisorPo.vue";
import Invoice from "../views/sales/Invoice.vue";
import InvoiceDetail from "../views/sales/InvoiceDetail.vue";
import SalesReceivedOrderReport from "../views/sales/SalesReceivedOrderReport.vue";
import SalesReport from "../views/sales/SalesReport.vue";
import SalesPlanning from "../views/sales/SalesPlanning.vue";
import SalesExperienceList from "../views/sales/SalesExperienceList.vue";
import SalesExportControlled from "../views/sales/SalesExportControlled.vue";
import ExportControlDetail from "../views/sales/ExportControlDetail.vue";
import ProjectCostReport from "../views/sales/ProjectCostReport.vue";
// MAINTENANCE
import DashboardCmms from "../views/maintenance/DashboardCmms.vue";
// import DashboardMaintenance from "../views/maintenance/DashboardMaintenance.vue";
import MachineList from "../views/maintenance/MachineList.vue";
import MachineDetail from "../views/maintenance/MachineDetail.vue";
import MaintenanceRequestList from "../views/maintenance/MaintenanceRequestList.vue";
import RequestSparepart from "../views/maintenance/RequestSparepart.vue";
import ElectricalControl from "../views/maintenance/ElectricalControl.vue";
import WaterControl from "../views/maintenance/WaterControl.vue";
// ACCOUNTING
import InvoiceMonitoringSystem from "../views/accounting/InvoiceMonitoringSystem.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/main-privilage",
  },


  // {
  //   path: '/detail-slip/:id_karyawan/:periode_start/:periode_end',
  //   name: 'DetailSlip',
  //   component: DetailSlip
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/main-privilage",
    name: "MainPrivilage",
    component: MainPrivilage,
  },

  // =================  SYSTEM ADMINISTRATOR  =========================
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/management-privileges",
    name: "Privileges",
    component: Privileges,
  },
  {
    path: "/management-menu",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/management-sub-menu",
    name: "SubMenu",
    component: SubMenu,
  },
  // ================= HUMAN RESOURCE =========================
  {
    path: "/management-plant",
    name: "Plant",
    component: Plant,
  },
  {
    path: "/management-department",
    name: "Department",
    component: Department,
  },
  {
    path: "/management-position",
    name: "Position",
    component: Position,
  },
  {
    path: "/management-job-title",
    name: "JobTitle",
    component: JobTitle,
  },
  {
    path: "/management-employee",
    name: "Employee",
    component: Employee,
  },
  {
    path: "/detail-employee/:safety_code",
    name: "DetailEmployee",
    component: DetailEmployee,
  },
  {
    path: "/hr-employee-family",
    name: "EmployeesFamily",
    component: EmployeesFamily,
  },
  {
    path: "/hr-attandance",
    name: "Attandance",
    component: Attandance,
  },
  // ================= PROJECT MANAGEMENT =========================
  {
    path: "/pm-customer",
    name: "Customer",
    component: Customer,
  },
  {
    path: "/pm-equipment",
    name: "Equipment",
    component: Equipment,
  },
  {
    path: "/pm-project-list",
    name: "ProjectList",
    component: ProjectList,
  },
  // ================= INVENTORY WAREHOUSE=========================
  {
    path: "/management-uom",
    name: "UnitOfMeasurement",
    component: UnitOfMeasurement,
  },
  {
    path: "/management-supplier",
    name: "Supplier",
    component: Supplier,
  },
  {
    path: "/management-warehouse-location",
    name: "WarehouseLocation",
    component: WarehouseLocation,
  },
  {
    path: "/inventory-storage-location",
    name: "StorageLocation",
    component: StorageLocation,
  },
  {
    path: "/inventory-list",
    name: "InventoryList",
    component: InventoryList,
  },
  {
    path: '/items-detail/:code',
    name: 'ItemsDetail',
    component: ItemsDetail
  },
  {
    path: '/transaction-inventory',
    name: 'TransactionInventory',
    component: TransactionInventory
  },
  {
    path: '/transaction-detail/:type/:code',
    name: 'TransactionInventoryDetail',
    component: TransactionInventoryDetail
  },
  {
    path: "/remaind-stock",
    name: "RemaindStock",
    component: RemaindStock,
  },
  {
    path: "/inv-report-by-project",
    name: "ReportByProject",
    component: ReportByProject,
  },
  {
    path: "/inv-report-by-date",
    name: "ReportByDate",
    component: ReportByDate,
  },
  {
    path: "/inv-report-by-user",
    name: "ReportByUser",
    component: ReportByUser,
  },

  // ================= INVENTORY HSE =========================
  {
    path: "/dashboard-inv-hse",
    name: "DashboardHse",
    component: DashboardHse,
  },
  {
    path: "/barang-masuk",
    name: "BarangMasuk",
    component: BarangMasuk,
  },
  {
    path: "/barang-keluar",
    name: "BarangKeluar",
    component: BarangKeluar,
  },
  {
    path: "/remaind-stock",
    name: "RemaindStock",
    component: RemaindStock,
  },
  {
    path: "/stock-barang",
    name: "StockBarang",
    component: StockBarang,
  },
  {
    path: "/hse-stock-return",
    name: "ReturnStock",
    component: ReturnStock,
  },
  {
    path: "/stock-remainder",
    name: "StockRemainder",
    component: StockRemainder,
  },
  {
    path: '/detail-barang/:material_code',
    name: 'DetailBarang',
    component: DetailBarang
  },
  // ================= MEDICAL REIMBUSHMENT =========================
  {
    path: "/medical-reimbuhment-glasses",
    name: "FrameAndLensGlasses",
    component: FrameAndLensGlasses,
  },

  // ================= MEETING ROOM =========================
  {
    path: "/mr-meeting-schedule",
    name: "MeetingSchedule",
    component: MeetingSchedule,
  },
  {
    path: "/mr-list-meeting-room",
    name: "ListMeetingRoom",
    component: ListMeetingRoom,
  },
  {
    path: "/mr-meeting-room-booking",
    name: "MeetingRoomBooking",
    component: MeetingRoomBooking,
  },
  // ================= WORKING GROUP =========================
  {
    path: "/wg-group",
    name: "WorkingGroup",
    component: WorkingGroup,
  },
  {
    path: "/wg-group-members",
    name: "WorkingGroupMembers",
    component: WorkingGroupMembers,
  },
  // ================= WORKING HOUR =========================
  {
    path: "/wh-activity-list",
    name: "ActivityList",
    component: ActivityList,
  },
  {
    path: "/wh-daily-activity",
    name: "DailyActivity",
    component: DailyActivity,
  },
  {
    path: "/wh-daily-control-board",
    name: "DailyControlBoard",
    component: DailyControlBoard,
  },
  {
    path: "/wh-summary-project-activity",
    name: "WhSummaryProjectActivity",
    component: WhSummaryProjectActivity,
  },
  {
    path: "/wh-summary-project-job-title",
    name: "WhSummaryProjectJobTitle",
    component: WhSummaryProjectJobTitle,
  },
  {
    path: "/wh-user-daily-activity",
    name: "UserDailyActivity",
    component: UserDailyActivity,
  },
  // ================= VEHICLE MANAGEMENT =========================
  {
    path: "/vm-driver-list",
    name: "DriverList",
    component: DriverList,
  },
  {
    path: "/vm-vehicle-list",
    name: "VehicleList",
    component: VehicleList,
  },
  {
    path: "/vm-vehicle-management",
    name: "VehicleManagement",
    component: VehicleManagement,
  },
  {
    path: "/vm-vehicle-request",
    name: "VehicleRequest",
    component: VehicleRequest,
  },
  // ================= PROCUREMENT =========================
  {
    path: "/proc-pr",
    name: "PurchaseRequisition",
    component: PurchaseRequisition,
  },
    {
    path: "/proc-purchase-list",
    name: "PurchaseList",
    component: PurchaseList,
  },
  // ================= MONITORING PROJECT =========================
  {
    path: "/dashboard-monitoring",
    name: "DashboardMonitoring",
    component: DashboardMonitoring,
  },
  {
    path: "/monitoring-exotic-piping",
    name: "MonitoringExoticPiping",
    component: MonitoringExoticPiping,
  },
  {
    path: "/piping-material-status",
    name: "MaterialStatusPiping",
    component: MaterialStatusPiping,
  },
  {
    path: "/piping-material-stock",
    name: "InputMaterialStock",
    component: InputMaterialStock,
  },
  // ================= LOAN =========================
  {
    path: "/loan",
    name: "Loan",
    component: Loan,
  },
  // ================= SALES =========================
  {
    path: "/sales-dashboard",
    name: "SalesDashboard",
    component: SalesDashboard,
  },
  {
    path: "/sales-advisor-po",
    name: "SalesAdvisorPo",
    component: SalesAdvisorPo,
  },
  {
    path: "/sales-invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: '/invoice-detail/:invoice_number',
    name: 'InvoiceDetail',
    component: InvoiceDetail
  },
  {
    path: "/sales-received-order-report",
    name: "SalesReceivedOrderReport",
    component: SalesReceivedOrderReport,
  },
  {
    path: "/sales-report",
    name: "SalesReport",
    component: SalesReport,
  },
  {
    path: "/sales-planning",
    name: "SalesPlanning",
    component: SalesPlanning,
  },
  {
    path: "/sales-experience-list",
    name: "SalesExperienceList",
    component: SalesExperienceList,
  },
  {
    path: "/sales-export-controlled",
    name: "SalesExportControlled",
    component: SalesExportControlled,
  },
  {
    path: '/export-control-detail/:project_number',
    name: 'ExportControlDetail',
    component: ExportControlDetail
  },
  {
    path: "/sales-project-cost-report",
    name: "ProjectCostReport",
    component: ProjectCostReport,
  },

  // ================= MAINTENANCE =========================
  {
    path: "/mms-dashboard",
    name: "DashboardCmms",
    component: DashboardCmms,
  },
  // {
  //   path: "/maintenance-dashboard",
  //   name: "DashboardMaintenance",
  //   component: DashboardMaintenance,
  // },
  {
    path: "/mms-machine-list",
    name: "MachineList",
    component: MachineList,
  },
  {
    path: '/machine-detail/:machine_code',
    name: 'MachineDetail',
    component: MachineDetail
  },
  {
    path: "/mms-maintenance-request-list",
    name: "MaintenanceRequestList",
    component: MaintenanceRequestList,
  },
  {
    path: "/mms-psppe",
    name: "RequestSparepart",
    component: RequestSparepart,
  },
  {
    path: "/mms-electrical-control",
    name: "ElectricalControl",
    component: ElectricalControl,
  },
  {
    path: "/mms-water-control",
    name: "WaterControl",
    component: WaterControl,
  },
  // ================= ACCOUNTING =========================
  {
    path: "/ac-inv-monitoring",
    name: "InvoiceMonitoringSystem",
    component: InvoiceMonitoringSystem,
  },


// =====================================
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  // {
  //   path: "/signin",
  //   name: "Signin",
  //   component: Signin,
  // },
  
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const publicPages = ['Login','DetailBarang','ItemsDetail','VehicleRequest','PurchaseRequisition','DetailEmployee'];
  const authRequired = !publicPages.includes(to.name);
  const authenticated = JSON.parse(localStorage.getItem('authenticated'));  

  if (authRequired && !authenticated) {
      alert('Session Kamu Habis Ayo Login Lagi !!!')
      return next({
          name: 'Login',
          query: {redirect: to.fullPath}
      });
  }

  if (authenticated) {
      const auth = JSON.parse(authenticated);
      if (to.name == 'Login') {
          return next({
              name: 'MainPrivilage'
          });
      }
      if (to.name != 'Relogin') {
          if (auth.expired) {
            alert('Session Kamu Habis Ayo Login Lagi !!!')
            localStorage.removeItem('token');
            localStorage.setItem('authenticated', false)  
            return next({
                name: 'Login'
            });
          }
      }
  }
  next();
})

export default router;
