import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('transaction-inventory/index', {params});
    },
    getWarehouseLocation(params) {
        return Service().get('transaction-inventory/get-transaction-inventory', {params});
    },
    show(id) {
        return Service().get('transaction-inventory/show/'+id);
    },
    create(params) {
        return Service().post('transaction-inventory/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('transaction-inventory/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id, params) {
        return Service().post('transaction-inventory/delete/'+id, params);
    },
    transactionDetail(params) {
        return Service().get('transaction-inventory/transaction-detail', {params});
    },
    createItem(params) {
        return Service().post('transaction-inventory/create-transaction-item', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteItem(id, params) {
        return Service().post('transaction-inventory/delete-item/'+id, params);
    },
    fixData(params) {
        return Service().post('transaction-inventory/fix-data', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateItemSJ(params) {
        return Service().post('transaction-inventory/update-item-sj', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteMaterial(id, params) {
        return Service().post('transaction-inventory/delete-material/'+id, params);
    },
}