<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/inv-usage-report?project='+search.project['project_number']+'&item_name='+search.item_name['code']+'&category='+search.category+'&user='+search.user['name']+'&warehouse_location='+search.warehouse_location['name']+'&date='+search.date+'&group_by_item='+search.group_by_item+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/inv-usage-report?project='+search.project['project_number']+'&item_name='+search.item_name['code']+'&category='+search.category+'&user='+search.user['name']+'&warehouse_location='+search.warehouse_location['name']+'&date='+search.date+'&group_by_item='+search.group_by_item+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Usage Report</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <!-- <argon-button
                  class="mt-4"
                  color="warning"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Create PR</argon-button> -->
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Transaction No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Image</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Item</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Project</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Category</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Qty</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">User</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Warehouse Location</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <router-link :to="{ name: 'TransactionInventoryDetail', params: { type: row.transaction_type, code: row.transaction_number }}">
                          <span class="badge bg-gradient-primary">{{ row.transaction_number }}</span>
                        </router-link>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <img
                          :src="storageUrl+'/image_warehouse/'+row.image"
                          class="avatar avatar-lg me-1"
                          alt="user1"
                        />
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.item_code }} - {{ row.description }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.project_number }} - {{ row.project_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.category }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="(search.group_by_item == '' || search.group_by_item == 'No')">{{ row.user }}</span>
                        <span class="text-secondary text-xs font-weight-bold" v-if="search.group_by_item == 'Yes'">-</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="(search.group_by_item == '' || search.group_by_item == 'No')">{{ row.warehouse_location }}</span>
                        <span class="text-secondary text-xs font-weight-bold" v-if="search.group_by_item == 'Yes'">-</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="(search.group_by_item == '' || search.group_by_item == 'No')">{{ moment(row.date).locale('id').format('ll') }}</span>
                        <span class="text-secondary text-xs font-weight-bold" v-if="search.group_by_item == 'Yes'">-</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project </label>
        <multiselect v-model="search.project" :options="tableProject.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Project" :custom-label="nameWithLang" label="project_name" track-by="project_name" :preselect-first="true" @keyup="getProject">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Item Name </label>
        <multiselect v-model="search.item_name" :options="tableItem.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Item" label="name" track-by="name" :preselect-first="true" @keyup="getItem" :custom-label="nameWithLangItem">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Category </label>
        <select class="form-select" aria-label="Default select example" v-model="search.category">
          <option selected>Category</option>
          <option value="Machine">Machine</option>
          <option value="Tools">Tools</option>
          <option value="Consumable">Consumable</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">User </label>
        <multiselect v-model="search.user" :options="tableUser.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Users" label="name" track-by="name" :preselect-first="true" @keyup="getUser">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Warehouse Location </label>
        <multiselect v-model="search.warehouse_location" :options="tableWarehouseLocation.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Warehouse Location" label="name" track-by="name" :preselect-first="true" @keyup="getWarehouseLocation">
            <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Date Range</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.date"/>
        <label for="example-text-input" class="form-control-label mt-3">Group by Item Name </label>
        <select class="form-select" aria-label="Default select example" v-model="search.group_by_item">
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {project: '', item_name: '', category: '', user: '', warehouse_location: '', date: '', group_by_item: ''}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import reportInventory from '@/services/reportInventory.service';
import projectList from '@/services/projectList.service';
import inventoryList from '@/services/inventoryList.service';
import employee from '@/services/employee.service';
import warehouseLocation from '@/services/warehouseLocation.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      inventoryListData: {},
      search: {
        project: '',
        item_name: '',
        category: '',
        user: '',
        warehouse_location: '',
        date: '',
        group_by_item: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tableProject: {
        data: []
      },
      tableItem: {
        data: []
      },
      tableUser: {
        data: []
      },
      tableWarehouseLocation: {
        data: []
      },

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    nameWithLang ({ project_number, project_name }) {
      return `${project_number} - ${project_name}`
    },
    nameWithLangItem ({ code, name }) {
      return `${code} - ${name}`
    },
    get(param){
      let context = this;               
      Api(context, reportInventory.getReport({project: this.search.project['project_number'], item_name: this.search.item_name['code'], category: this.search.category, user: this.search.user['name'], warehouse_location: this.search.warehouse_location['name'], date: context.search.date, group_by_item: context.search.group_by_item, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getProject(param){
      let context = this; 
      Api(context, projectList.getProject({search: param.target.value})).onSuccess(function(response) {    
      // console.log(response.data.data)       
          context.tableProject.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableProject.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getItem(param){
      let context = this;        
      Api(context, inventoryList.getItem({item_name: param.target.value, type_transaction: '', warehouse_location: '', user: '', project: ''})).onSuccess(function(response) {    
          context.tableItem.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableItem.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getUser(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tableUser.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableUser.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getWarehouseLocation(param){
      let context = this;        
      Api(context, warehouseLocation.getWarehouseLocation({search: param.target.value})).onSuccess(function(response) {    
          context.tableWarehouseLocation.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableWarehouseLocation.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
