<template>
  <div class="py-4 container-fluid">
    <div class="mb-4">
      <div class=" row">
        <div class="col-lg-6">
            <div class="card">
            <div class="row">
                <div class="col-12">
                  <div class="card-header pb-0">
                    <h6>Top 10 Sales FY2024 (IDR)</h6>
                  </div>
                </div>
              </div> 
              <div class="card-body px-0 pt-0 pb-2 mt-4">
                <div class="table-responsive p-0">
                  <table class="table table-striped table-bordered table-hover align-items-center mb-0">
                    <thead>
                      <tr>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">No.</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Customer</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Vessel</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">H/E</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Piping</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">R/T</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Muffle</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Others</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Service</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, i) in tableTopSales.data" :key="i">
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span></td>
                        <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.customer_name }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_vessels) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_he) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_piping) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_radiant) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_mufle) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_other) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_service) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price) }} </span></td>
                      </tr>
                      <tr style="background-color: #F5FFFA;">
                        <td class="align-middle text-center text-sm" colspan="2"><span class="text-secondary text-xs font-weight-bold">Total</span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalVessel) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalHe) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalPiping) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalRadiant) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalMuffle) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalOther) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalService) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) }} </span></td>
                      </tr>
                      <tr style="background-color: #F5FFFA;">
                        <td class="align-middle text-center text-sm" colspan="2"><span class="text-secondary text-xs font-weight-bold">Ratio (%)</span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalVessel / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} %</span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalHe / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalPiping / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalRadiant / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalMuffle / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalOther / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalService / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> </span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer">
              </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
            <div class="row">
                <div class="col-12">
                  <div class="card-header pb-0">
                    <h6>Top 10 Sales FY2024 (K.IDR)</h6>
                  </div>
                </div>
              </div> 
              <div class="card-body px-0 pt-0 pb-2 mt-4">
                <div class="table-responsive p-0">
                  <table class="table table-striped table-bordered table-hover align-items-center mb-0">
                    <thead>
                      <tr>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">No.</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Customer</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Vessel</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">H/E</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Piping</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">R/T</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Muffle</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Others</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Service</th>
                        <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, i) in tableTopSales.data" :key="i">
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span></td>
                        <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ row.customer_name }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_vessels / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_he / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_piping / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_radiant / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_mufle / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_other / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price_service / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(row.total_price / 1000) }} </span></td>
                      </tr>
                      <tr style="background-color: #F5FFFA;">
                        <td class="align-middle text-center text-sm" colspan="2"><span class="text-secondary text-xs font-weight-bold">Total</span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalVessel / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalHe / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalPiping / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalRadiant / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalMuffle / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalOther / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp(totalService / 1000) }} </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ convertRp((totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) / 1000) }} </span></td>
                      </tr>
                      <tr style="background-color: #F5FFFA;">
                        <td class="align-middle text-center text-sm" colspan="2"><span class="text-secondary text-xs font-weight-bold">Ratio (%)</span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalVessel / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} %</span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalHe / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalPiping / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalRadiant / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalMuffle / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalOther / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> {{ (totalService / (totalVessel + totalHe + totalPiping + totalRadiant + totalMuffle + totalOther + totalService) * 100).toFixed(1) }} % </span></td>
                        <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold"> </span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer">
              </div>
            </div>
        </div>
      </div>
    </div>
 
    <!-- CHART PIE DATA -->
    <div class="card mb-3"> 
      <div class="card-header pb-0 text-center align-content-center justify-content-center">
        <h5 class="text-center">PERCENTAGE OF TOP PRODUCT</h5>
      </div>
      <div class="card-body mt-4 scroll-pie-chart">
        <div class="row">
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">SALES <span style="size:90px; color: red;">{{progresPersenDwg}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartData"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">MARGIN <span style="size:90px; color: red;">{{progresPersenMto}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataMto"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">MARGIN / WH <span style="size:90px; color: red;">{{progresPersenPreparation}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataPreparation"
              :height="300" 
            />
          </div>
          <div class="col-lg-3 mt-4 mb-3" >
            <p class="text-right font-weight-bold">W/H (HR) <span style="size:90px; color: red;">{{progresPersenFitUp}} %</span></p>
            <Pie
              id="my-chart-id"
              :options="chartOptions"
              :data="chartDataFitUp"
              :height="300" 
            />
          </div>
        </div>
      </div>
    </div>

  </div>

   <!-- =======  MODAL TERM OF PAYMENT ======= -->
  <div class="container">
    <vue-final-modal v-model="formTermPay.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formTermPay.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formTermPay.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content">
        <div class="row">
          <div class="col-lg-4">
            <div class="card-header pb-0">
            </div>
          </div>
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4 float-right">
            <argon-button
              class="mt-0 mb-2"
              variant="gradient"
              color="success"
              size="sm"
              @click="createTermPay()"
            ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add Term</argon-button>
          </div>
        </div>
        <div class="table-responsive p-0 scroll">
          <table class="table table-hover align-items-center mb-0">
            <thead>
              <tr style="background-color: #F0F8FF;">
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TERM OF PAYMENT</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PERCENTAGE</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PERIODE</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NOTE</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tableTopSales.data" :key="i">
                <td class="align-middle text-left text-sm">
                    <span class="text-secondary text-xs font-weight-bold">{{ row.term_payment }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.percentage }} %</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.periode }} Days After Invoice</span>
                </td>
                <td class="align-middle text-left text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.note }}</span>
                </td>
                <td class="align-middle text-center">
                  <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="editTermPay(row.id)" title="Edit"></i>
                  <i class="fas fa-trash fa-sm" aria-hidden="true" title="Delete" style="cursor: pointer; margin-right: 20px;" @click="removeTermPay(row.id)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button> -->
        </div>
        <div class="col-lg-1 col-2">
          <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button> -->
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

</template>

<script>
// import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
// import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'
import Chart from "chart.js/auto";
// import Multiselect from 'vue-multiselect'
// import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import salesDashboard from '@/services/salesDashboard.service';

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Pie,
    // Multiselect,
    // BasePagination,
    // Card,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      tableTopSales: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formTermPay: {
        add: true,
        title: "Term Of Payment",
        show: false
      },
      formAddTermPay: {
        add: true,
        title: "Add Term Of Payment",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      salesAdvisorData: {},
      termPayData: {},
      searchMenu: '',
      apiUrl :config.apiUrl,
      tablePlant: {
        data: []
      },
      tablePic: {
        data: []
      },
      tableCustomer: {
        data: []
      },
      totalVessel: '',
      totalHe: '',
      totalPiping: '',
      totalRadiant: '',
      totalMuffle: '',
      totalOther: '',
      totalService: '',
      totalAll: '',

      progresPersenDwg: '',
      pendingPersenDwg: '',
      progresPersenMto: '',
      pendingPersenMto: '',

      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            labelTwo: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataMto: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataPreparation: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },
      chartDataFitUp: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },

    };
  },
  mounted(){
    this.getTopTenSales();
    this.fillData();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    getTopTenSales(param){
      let context = this;               
      Api(context, salesDashboard.getTopTenSales()).onSuccess(function(response) {    
          context.tableTopSales.data = response.data.data.data;

          context.totalVessel  = response.data.data.totalVessel
          context.totalHe      = response.data.data.totalHe
          context.totalPiping  = response.data.data.totalPiping
          context.totalRadiant = response.data.data.totalRadiant
          context.totalMuffle  = response.data.data.totalMuffle
          context.totalOther   = response.data.data.totalOther
          context.totalService = response.data.data.totalService
          context.totalAll = (+response.data.data.total_price_vessels + +response.data.data.totalHe + +response.data.data.totalPiping + +response.data.data.totalRadiant + +response.data.data.totalMuffle + +response.data.data.totalOther + +response.data.data.totalService)

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableTopSales.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    fillData () {
      this.chartData = {
        labels: ['Vessel', 'H/E', 'Piping', 'R/T', 'Muffle', 'Other', 'Service'],
        datasets: [
          {
            backgroundColor: ['#4682B4','#FF7F50','#C0C0C0','#FFD700','#CD853F','#7CFC00','#48D1CC'],
            data: [32,34,12,43,53,12,78]
          }, 
        ],
      }
      this.chartDataMto = {
        labels: ['Vessel', 'H/E', 'Piping', 'R/T', 'Muffle', 'Other', 'Service'],
        datasets: [
          {
            backgroundColor: ['#4682B4','#FF7F50','#C0C0C0','#FFD700','#CD853F','#7CFC00','#48D1CC'],
            data: [65,34,63,31,43,53,23]
          }, 
        ],
      }
      this.chartDataPreparation = {
        labels: ['Vessel', 'H/E', 'Piping', 'R/T', 'Muffle', 'Other', 'Service'],
        datasets: [
          {
            backgroundColor: ['#4682B4','#FF7F50','#C0C0C0','#FFD700','#CD853F','#7CFC00','#48D1CC'],
            data: [25,42,75,23,54,63,69]
          }, 
        ],
      }
      this.chartDataFitUp = {
        labels: ['Vessel', 'H/E', 'Piping', 'R/T', 'Muffle', 'Other', 'Service'],
        datasets: [
          {
            backgroundColor: ['#4682B4','#FF7F50','#C0C0C0','#FFD700','#CD853F','#7CFC00','#48D1CC'],
            data: [53,75,22,59,85,12,37]
          }, 
        ],
      }
    },
    getChartLine(){
      var ctx1 = document.getElementById("chart-line").getContext("2d");

      var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, "rgba(94, 114, 228, 0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(94, 114, 228, 0.0)");
      gradientStroke1.addColorStop(0, "rgba(94, 114, 228, 0)");

      new Chart(ctx1, {
        type: "line",
        data: {
          labels: Object.values(this.dateWeek),
          datasets: [
            {
              label: "Joint",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              borderColor: "#3A416F ",
              backgroundColor: gradientStroke1,
              // eslint-disable-next-line no-dupe-keys
              borderWidth: 3,
              fill: true,
              data: Object.values(this.qtyWeek),
              maxBarThickness: 6,
            },
            {
              label: "DB",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              borderColor: "#4BB543 ",
              backgroundColor: gradientStroke1,
              // eslint-disable-next-line no-dupe-keys
              borderWidth: 3,
              fill: true,
              data: Object.values(this.dbWeek),
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#fbfbfb",
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#ccc",
                padding: 20,
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    },
    getChartBar() {
    // Chart Consumption by day
    var ctx = document.getElementById("chart-cons-week").getContext("2d");

    new Chart(ctx, {
        type: "bar",
        data: {
          labels: Object.values(this.dateWcn),
          datasets: [
            {
              label: "Joint",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "#3A416F",
              data: Object.values(this.qtyWcn),
              maxBarThickness: 6,
            },
            {
              label: "DB",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "#4BB543",
              data: Object.values(this.dbWcn),
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                beginAtZero: true,
                font: {
                  size: 12,
                  family: "Open Sans",
                  style: "normal",
                },
                color: "#9ca2b7",
              },
            },
            // eslint-disable-next-line no-dupe-keys
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            // eslint-disable-next-line no-dupe-keys
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
          },
        },
      });
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
