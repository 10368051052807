import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('purchase-requisition/index', {params});
    },
    show(id) {
        return Service().get('purchase-requisition/show/'+id);
    },
    create(params) {
        return Service().post('purchase-requisition/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('purchase-requisition/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('purchase-requisition/delete/'+id);
    },
    generatePrCode(params) {
        return Service().get('purchase-requisition/generate-pr-code', {params});
    },
    getDetailPr(params) {
        return Service().get('purchase-requisition/get-detail-pr', {params});
    },
    sendRequest(params) {
        return Service().post('purchase-requisition/send-to-approved', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}