import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('daily-activity/index', {params});
    },
    getActivityByUser(params) {
        return Service().get('daily-activity/index-by-user', {params});
    },
    getUom(params) {
        return Service().get('daily-activity/get-daily-activity', {params});
    },
    show(id) {
        return Service().get('daily-activity/show/'+id);
    },
    create(params) {
        return Service().post('daily-activity/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('daily-activity/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('daily-activity/delete/'+id);
    },
}