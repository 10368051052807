<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/machine-list?machine_code='+search.machine_code+'&serial_number='+search.serial_number+'&machine_name='+search.machine_name+'&brand='+search.brand+'&production_year='+search.production_year+'&purchase_date='+search.purchase_date+'&asset_location='+search.asset_location+'&remark='+search.remark+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export to Excel</a>

    <a class="btn btn-sm btn-danger" style="margin-left: 13px;" :href="apiUrl+'print-pdf/machine-list?machine_code='+search.machine_code+'&serial_number='+search.serial_number+'&machine_name='+search.machine_name+'&brand='+search.brand+'&production_year='+search.production_year+'&purchase_date='+search.purchase_date+'&asset_location='+search.asset_location+'&remark='+search.remark+'&status='+search.status+''" target="_BLANK"><i class="fa fa-file fa-sm"></i> Print PDF</a>

    <a class="btn btn-sm btn-warning" style="margin-left: 13px;" :href="apiUrl+'print-pdf/machine-asset-qr-code?machine_code='+search.machine_code+'&serial_number='+search.serial_number+'&machine_name='+search.machine_name+'&brand='+search.brand+'&production_year='+search.production_year+'&purchase_date='+search.purchase_date+'&asset_location='+search.asset_location+'&remark='+search.remark+'&status='+search.status+''" target="_BLANK"><i class="fa fa-qrcode fa-sm"></i> Print QR Code</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Employee Master Data</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">No</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Employee Name</th>
                      <th colspan="6" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Data Istri / Suami</th>
                      <th colspan="7" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Anak ke 1</th>
                      <th colspan="7" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">Anak ke 2</th>
                      <th colspan="7" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #228B22;">Anak ke 3</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #48D1CC;">Action</th>
                    </tr>
                    <tr>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">NIK KTP</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">No BPJS KES.</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Nama Suami / Istri</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Tanggal Lahir Suami / Istri</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Usia</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C;">Status</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NIK KTP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">No BPJS KES.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Nama Anak ke 1</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Tanggal Lahir</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Usia</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Jenis Kelamin</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Status</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NIK KTP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">No BPJS KES.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Nama Anak ke 2</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Tanggal Lahir</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Usia</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Jenis Kelamin</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Status</th>

                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">NIK KTP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">No BPJS KES.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Nama Anak ke 3</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Tanggal Lahir</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Usia</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Jenis Kelamin</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #BC8F8F;">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <div class="d-flex flex-column justify-content-left">
                          <h6 class="mb-0 text-sm">{{ row.name }}</h6>
                          <p class="text-xs text-secondary mb-0">{{ row.nik}}</p>
                        </div>
                      </td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.npwp }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.position_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.group }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.department_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.job_title_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.plant_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.member_spsi }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.join_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.join_date_mmf).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.employee_status }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.employee_activity }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.permanent_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.empoyee_end_date).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.ktp }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.place_birth }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment(row.date_birth).locale('id').format('LL') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ moment().diff(row.date_birth, 'years') }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.gender }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.blood_group }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.mother_name }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.religion }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.home_address }}</span></td>
                      <td class="align-middle text-left text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.ktp_address }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_kk }}</span></td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          <a target="_blank" :href="'https://api.whatsapp.com/send?phone=62'+row.no_hp_first+'&text=Assalamualaikum Hallo ' +row.name+','" style="color: blue;">{{row.no_hp_first}} </a>
                        </span>
                      </td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_hp_second }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_emergency }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.email }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.maried_status }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.total_amenability }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.last_education }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.an_bank }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.type_bank }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.no_rek }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.brance }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_ket_mmf }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_ket_tokki }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.bpjs_kesehatan }}</span></td>
                      <td class="align-middle text-center text-sm"><span class="text-secondary text-xs font-weight-bold">{{ row.asuransi_mandiri_inhelath }}</span></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_ktp" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_ktp">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_kk" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_kk">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_npwp" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_npwp">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_buku_rekening" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_buku_rekening">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_bpjs_kesehatan" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_bpjs_kesehatan">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_bpjs_ketenagakerjaan" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_bpjs_ketenagakerjaan">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_cv" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_cv">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_vaksin" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_vaksin">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_sertefikat_keahlian" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_sertefikat_keahlian">View Files</a></td>
                      <td class="align-middle text-center text-sm"><a :href="storageUrl+'document_employee/'+row.file_ijazah_terakhir" target="_BLANK" style="color: blue; size: 5px;" v-if="row.file_ijazah_terakhir">View Files</a></td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                        <i class="fa fa-copy fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="copyLink(row.safety_code)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Image <span style="color: red;">*</span></label>
        <input type="file" ref="fileupload" class="form-control" placeholder="Image" @change="filesChange">
        <label for="example-text-input" class="form-control-label mt-3">Serial Number <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Serial Number" v-model="machineListData.serial_number">
        <label for="example-text-input" class="form-control-label mt-3">Machine Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Machine Name" v-model="machineListData.machine_name">
        <label for="example-text-input" class="form-control-label mt-3">Brand <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Brand" v-model="machineListData.brand">
        <label for="example-text-input" class="form-control-label mt-3">Production Year <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Production Year" v-model="machineListData.production_year">
        <label for="example-text-input" class="form-control-label mt-3">Purchase Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Purchase Date" v-model="machineListData.purchase_date">
        <label for="example-text-input" class="form-control-label mt-3">Qty <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Qty" v-model="machineListData.qty">
        <label for="example-text-input" class="form-control-label mt-3">Maintenance Schedule <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Maintenance Schedule" v-model="machineListData.maintenance_schedule">
        <label for="example-text-input" class="form-control-label mt-3">Asset Location <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Asset Location" v-model="machineListData.asset_location">
        <label for="example-text-input" class="form-control-label mt-3">Remark <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Remark" v-model="machineListData.remark">
        <label for="example-text-input" class="form-control-label mt-3">Status <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="machineListData.status">
          <option value="Ready">Ready</option>
          <option value="Maintenance">Maintenance</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save </span>
            </span>
          </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Serial Number</label>
        <input type="text" class="form-control" placeholder="Serial Number" v-model="search.serial_number" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Machine Name</label>
        <input type="text" class="form-control" placeholder="Machine Name" v-model="search.machine_name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Brand</label>
        <input type="text" class="form-control" placeholder="Brand" v-model="search.brand" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Production Year</label>
        <input type="text" class="form-control" placeholder="Production Year" v-model="search.production_year" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Purchase Date</label>
        <input type="text" class="form-control" placeholder="Purchase Date" v-model="search.purchase_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Asset Location</label>
        <input type="text" class="form-control" placeholder="Asset Location" v-model="search.asset_location" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Remark</label>
        <input type="text" class="form-control" placeholder="Remark" v-model="search.remark" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Status </label>
        <select class="form-select" aria-label="Default select example" v-model="search.status">
          <option value="Ready">Ready</option>
          <option value="Maintenance">Maintenance</option>
        </select>
        
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import machineList from '@/services/machineList.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    BasePagination,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      machineListData: {},
      search: {
        machine_code: '',
        serial_number: '',
        machine_name: '',
        brand: '',
        production_year: '',
        purchase_date: '',
        asset_location: '',
        remark: '',
        status: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      backgroundRed: null,

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, machineList.index({machine_code: this.search.machine_code, serial_number: this.search.serial_number, machine_name: this.search.machine_name, brand: this.search.brand, production_year: this.search.production_year, purchase_date: this.search.purchase_date, asset_location: this.search.asset_location, remark: this.search.remark, status: this.search.status})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page 

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.machineListData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, machineList.show(id)).onSuccess(function(response) {
          context.machineListData = response.data.data;
          context.form.show      = true;
          context.form.title     = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.machineListData.serial_number != undefined && context.machineListData.machine_name != undefined && context.machineListData.brand != undefined && context.machineListData.production_year != undefined && context.machineListData.purchase_date != undefined && context.machineListData.qty != undefined && context.machineListData.asset_location != undefined && context.machineListData.remark != undefined && context.machineListData.image != undefined && context.machineListData.maintenance_schedule != undefined && context.machineListData.status != undefined) {
        formData.append('serial_number', context.machineListData.serial_number);
        formData.append('machine_name', context.machineListData.machine_name);
        formData.append('brand', context.machineListData.brand);
        formData.append('production_year', context.machineListData.production_year);
        formData.append('purchase_date', context.machineListData.purchase_date);
        formData.append('qty', context.machineListData.qty);
        formData.append('maintenance_schedule', context.machineListData.maintenance_schedule);
        formData.append('asset_location', context.machineListData.asset_location);
        formData.append('remark', context.machineListData.remark);
        formData.append('status', context.machineListData.status);
        formData.append('image', context.machineListData.image);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, machineList.create(formData));
      } else {
          api = Api(context, machineList.update(this.machineListData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, machineList.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    filesChange(e) {
        this.machineListData.image = e.target.files[0];
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, machineList.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    setBg(id) {
      this.backgroundRed = id
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
