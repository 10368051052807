import Service from '@/helpers/service'

export default {

    getCard(params) {
        return Service().get('dashboard-maintenance/get-card', {params});
    },
    getChart(params) {
        return Service().get('dashboard-maintenance/get-chart', {params});
    },
    getAssetMaintenance(params) {
        return Service().get('dashboard-maintenance/get-asset-maintenance', {params});
    },

    // getMachine(params) {
    //     return Service().get('dashboard-maintenance/get-machine', {params});
    // },
    // show(id) {
    //     return Service().get('dashboard-maintenance/show/'+id);
    // },
    // create(params) {
    //     return Service().post('dashboard-maintenance/create', params, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data'
    //         }
    //     });
    // },
    // update(id, params) {
    //     return Service().post('dashboard-maintenance/update/'+id, params, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data'
    //         }
    //     });
    // },
    // delete(id) {
    //     return Service().post('dashboard-maintenance/delete/'+id);
    // },
    // machineDetail(params) {
    //     return Service().get('dashboard-maintenance/machine-detail', {params});
    // },
}