import Service from '@/helpers/service'

export default {

    getAttLogs(params) {
        return Service().get('attandance/get-att-logs', {params});
    },
    getDepartment(params) {
        return Service().get('attandance/get-attandance', {params});
    },
    show(id) {
        return Service().get('attandance/show/'+id);
    },
    create(params) {
        return Service().post('attandance/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('attandance/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('attandance/delete/'+id);
    },
}