<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/sales-advisor-po?project_number='+search.project_number+'&po_number='+search.po_number+'&customer_name='+search.customer_name['name']+'&end_user_name='+search.end_user_name['name']+'&product_name='+search.product_name+'&main_material='+search.main_material+'&material_category='+search.material_category+'&po_received_date='+search.po_received_date+'&delivery_date='+search.delivery_date+'&currency='+search.currency+'&term_of_payment='+search.term_of_payment+'&vat='+search.vat+'&quotation_number='+search.quotation_number+'&code_regulation='+search.code_regulation+'&type_equipment='+search.type_equipment+'&work_location='+search.work_location+'&plant='+search.plant+'&country_plant='+search.country_plant+'&sales_pic='+search.sales_pic+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/sales-advisor-po?project_number='+search.project_number+'&po_number='+search.po_number+'&customer_name='+search.customer_name['name']+'&end_user_name='+search.end_user_name['name']+'&product_name='+search.product_name+'&main_material='+search.main_material+'&material_category='+search.material_category+'&po_received_date='+search.po_received_date+'&delivery_date='+search.delivery_date+'&currency='+search.currency+'&term_of_payment='+search.term_of_payment+'&vat='+search.vat+'&quotation_number='+search.quotation_number+'&code_regulation='+search.code_regulation+'&type_equipment='+search.type_equipment+'&work_location='+search.work_location+'&plant='+search.plant+'&country_plant='+search.country_plant+'&sales_pic='+search.sales_pic+'&status='+search.status+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a>
   <!--  <div class="row">
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Total Pending"
          :value="convertRp(totalPending)"
          iconClass="fa fa-newspaper-o"
          iconBackground="bg-gradient-secondary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Approved"
          :value="convertRp(totalApproved)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Not Approved"
          :value="convertRp(totalNotApproved)"
          iconClass="fa fa-times-circle"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="On Duty"
          :value="convertRp(totalOnDuty)"
          iconClass="fa fa-car"
          iconBackground="bg-gradient-warning"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Done"
          :value="convertRp(totalDone)"
          iconClass="ni ni-satisfied"
          iconBackground="bg-gradient-info"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Total Request"
          :value="convertRp(totalRequest)"
          iconClass="fa fa-tasks"
          iconBackground="bg-gradient-primary"
          directionReverse
        ></card>
      </div>
    </div> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Project Database</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-sm table-striped table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TEF PROJECT NUMBER</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PO NUMBER</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CUSTOMER NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">SALES AGENT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">END USER NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PRODUCT NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MAIN MATERIAL</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MATERIAL CATEGORY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PO RECEIVED DATE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DELIVERY DATE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DELIVERY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">QTY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">UOM</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">UNIT PRICE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TOTAL PRICE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CURRENCY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">EXCHANGE RATE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TERM OF PAYMENT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">VAT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DELAY PENALTY CLAUSE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">COST ESTIMATION PRICE (IDR)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">QUOTATION NUMBER</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CODE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">REGULATION</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TYPE OF EQUIPMENT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PROJECT TYPE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">WORK LOCATION</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PLANT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">COUNTRY PLANT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">REVISI</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">SALES PIC</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">BALANCE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PAYMENT PERCENTAGE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">STATUS</th>
                      <!-- <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ATTACHMENT</th> -->
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                          <span class="badge badge-sm bg-gradient-primary">{{ row.project_number }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.po_number }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.customer_name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.sales_agent }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.end_user_name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.product_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.main_material }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.material_category }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.po_received_date).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.delivery_date).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.delivery }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.uom }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ (row.unit_price) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ (row.total_price) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.currency }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.exchange_rate }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-info text-xs font-weight-bold" @click="paymentTerm(row.project_number)" style="cursor: pointer;">{{ row.term_of_payment }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.vat }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.delay_penalty_clause }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.cost_estimation_price) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.quotation_number }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.code_regulation }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.regulation_project }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.type_equipment }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.project_type }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.work_location }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.plant }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.country_plant }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.revisi }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.sales_pic }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ (row.balance) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ ((row.balance / row.total_price * 100)) }} %</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-success" v-if="row.status == 'Already Invoiced'">{{ row.status }}</span>
                        <span class="badge bg-gradient-danger" v-if="row.status == 'Not Invoiced Yet'">{{ row.status }}</span>
                      </td>
                      <!-- <td class="align-middle text-center text-sm">
                        <a :href="storageUrl+'document_sales_po/'+row.attachment" target="_BLANK" style="color: blue; size: 5px;" v-if="row.attachment">See Attachment
                        </a>
                      </td> -->
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" ></i>
                        <i class="fas fa-trash fa-sm" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                        <i class="fa fa-clone fa-sm" style="cursor: pointer; margin-right: 20px;" @click="copy(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content-width" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="row">
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">TEF Project Number <span style="color: red;">*</span></label>
            <input type="text" class="form-control" placeholder="TEF Project Number" v-model="salesAdvisorData.project_number" @change="getAttachEmail()">
            <label for="example-text-input" class="form-control-label mt-3">PO Number <span style="color: red;">*</span></label>
            <input type="text" class="form-control" placeholder="PO Number" v-model="salesAdvisorData.po_number">
            <label for="example-text-input" class="form-control-label mt-3">Customer <span style="color: red;">*</span></label>
            <multiselect v-model="salesAdvisorData.customer_name" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Customer" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
            <label for="example-text-input" class="form-control-label mt-3">Sales Agent </label>
            <multiselect v-model="salesAdvisorData.sales_agent" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Sales Agent" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
            <label for="example-text-input" class="form-control-label mt-3">End User Name <span style="color: red;">*</span></label>
            <multiselect v-model="salesAdvisorData.end_user_name" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Customer" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
            <label for="example-text-input" class="form-control-label mt-3">Product Name <span style="color: red;">*</span></label>
            <input type="text" class="form-control" placeholder="Product Name" v-model="salesAdvisorData.product_name">
            <label for="example-text-input" class="form-control-label mt-3">Main Material <span style="color: red;">*</span></label>
            <input type="text" class="form-control" placeholder="Main Material" v-model="salesAdvisorData.main_material">
            <label for="example-text-input" class="form-control-label mt-3">Material Category <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.material_category">
              <option value="CS">CS</option>
              <option value="Cu-Ni">Cu-Ni</option>
              <option value="Duplex">Duplex</option>
              <option value="Ni Alloy">Ni Alloy</option>
              <option value="SUS">SUS</option>
              <option value="Ti">Ti</option>
              <option value="Other">Other</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">PO Received Date <span style="color: red;">*</span></label>
            <input type="date" class="form-control" placeholder="PO Received Date" v-model="salesAdvisorData.po_received_date">
            <label for="example-text-input" class="form-control-label mt-3">Delivery Date <span style="color: red;">*</span></label>
            <input type="date" class="form-control" placeholder="Delivery Date" v-model="salesAdvisorData.delivery_date">
            <label for="example-text-input" class="form-control-label mt-3">Delivery Term<span style="color: red;">*</span></label>
            <input type="text" class="form-control" placeholder="Delivery Term" v-model="salesAdvisorData.delivery">
            <!-- <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.delivery">
              <option value="FOT">FOT</option>
              <option value="FOB">FOB</option>
              <option value="DDP">DDP</option>
              <option value="CIF">CIF</option>
              <option value="EXWORK">EXWORK</option>
            </select> -->
            <label for="example-text-input" class="form-control-label mt-3">Qty <span style="color: red;">*</span></label>
            <input type="number" class="form-control" placeholder="Qty" v-model="salesAdvisorData.qty" @change="changeTotalPrice()">
            <label for="example-text-input" class="form-control-label mt-3">UoM <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.uom">
              <option value="Lot">Lot</option>
              <option value="Unit">Unit</option>
              <option value="Pcs">Pcs</option>
              <option value="Set">Set</option>
              <option value="KG">KG</option>
              <option value="M">M</option>
              <option value="EA">EA</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Unit Price <span style="color: red;">*</span></label>
            <input type="number" class="form-control" placeholder="Unit Price" v-model="salesAdvisorData.unit_price" @change="changeTotalPrice()">
            <label for="example-text-input" class="form-control-label mt-3">Total Price <span style="color: red;">*</span></label>
            <input type="number" class="form-control" placeholder="Total Price" v-model="salesAdvisorData.total_price" >
            <label for="example-text-input" class="form-control-label mt-3">Currency <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.currency">
              <option value="IDR">IDR - Indonesian Rupiah</option>
              <option value="USD">USD - US Dollar</option>
              <option value="KRW">KRW - South Korean Won</option>
              <option value="SGD">SGD - Singapore Dollar</option>
              <option value="JPY">JPY - Japanese Yen</option>
              <option value="EUR">EUR - Euro</option>
              <option value="GBP">GBP - British Pound</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Exchange Rate <span style="color: red;">*</span></label>
            <input type="number" class="form-control" placeholder="Exchange Rate" v-model="salesAdvisorData.exchange_rate">
          </div>
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">Term Of Payment <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.term_of_payment">
              <option value="DP">DP</option>
              <option value="DEL">DEL</option>
              <option value="2nd Payment">2nd Payment</option>
              <option value="3rd Payment">3rd Payment</option>
              <option value="4th Payment">4th Payment</option>
              <option value="5th Payment">5th Payment</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">VAT (11 %) <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.vat">
              <option value="Include">Include</option>
              <option value="Exclude">Exclude</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Delay Penalty Clause <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.delay_penalty_clause">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Cost Estimation Price (IDR) <span style="color: red;">*</span></label>
            <input type="number" class="form-control" placeholder="Cost Estimation Price (IDR)" v-model="salesAdvisorData.cost_estimation_price">
            <label for="example-text-input" class="form-control-label mt-3">Quotation Number <span style="color: red;">*</span></label>
            <input type="text" class="form-control" placeholder="Quotation Number" v-model="salesAdvisorData.quotation_number">
            <label for="example-text-input" class="form-control-label mt-3">Code <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.code_regulation">
              <option value="ASME">ASME</option>
              <option value="NON ASME">NON ASME</option>
              <option value="ASME NO STAMP">ASME NO STAMP</option>
              <option value="HPGSL/TOKUTEI">HPGSL/TOKUTEI</option>
              <option value="ICHIATSU">ICHIATSU</option>
              <option value="NIATSU">NIATSU</option>
              <option value="SHOBO">SHOBO</option>
              <option value="KEMENAKER">KEMENAKER</option>
              <option value="NON-CODE">NON-CODE</option>
              <option value="-">-</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Regulation <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.regulation_project">
              <option value="EBTKE">EBTKE</option>
              <option value="MIGAS">MIGAS</option>
              <option value="DISNAKER">DISNAKER</option>
              <option value="NON REGULATION">NON REGULATION</option>
              <option value="-">-</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Type Of Equipment / Works <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.type_equipment">
              <option value="V">V - Vessel</option>
              <option value="H">H - Heat Exchanger</option>
              <option value="P">P - Piping</option>
              <option value="M">M - Mufle</option>
              <option value="R">R - Radiant Tube</option>
              <option value="S">S - Service</option>
              <option value="O">O - Other</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Project Type <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.project_type">
              <option value="Renewal">Renewal</option>
              <option value="Replacement">Replacement</option>
              <option value="-">-</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Work Location <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.work_location">
              <option value="Workshop">Workshop</option>
              <option value="Site">Site</option>
              <option value="Workshop + Site">Workshop + Site</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">TEF Plant <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.plant">
              <option value="Discovery">Discovery</option>
              <option value="Endeavor">Endeavor</option>
              <option value="Simba">Simba</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Country Plant <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.country_plant">
              <option value="Indonesia">Indonesia</option>
              <option value="Japan">Japan</option>
              <option value="Singapore">Singapore</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Thailand">Thailand</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Sales PIC <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="salesAdvisorData.sales_pic">
              <option value="Agus Setiyono">Agus Setiyono</option>
              <option value="Anwar Budi S">Anwar Budi S</option>
              <option value="Dwi Febiyanti">Dwi Febiyanti</option>
              <option value="Fitri Hayati">Fitri Hayati</option>
              <option value="Fauzul Umam">Fauzul Umam</option>
              <option value="Hendrayana">Hendrayana</option>
              <option value="Sutriyono">Sutriyono</option>
              <option value="Imam Febriawan">Imam Febriawan</option>
              <option value="Ine Putri Astuti">Ine Putri Astuti</option>
              <option value="Rachmat H">Rachmat H</option>
              <option value="Rahadian Akmal Wildanum">Rahadian Akmal Wildanum</option>
            </select>
            <!-- <label for="example-text-input" class="form-control-label mt-3">Attachment PO</label>
            <input type="file" class="form-control" placeholder="Attachment" @change="filesChange" accept="application/pdf"> -->
            <label for="example-text-input" class="form-control-label mt-3">Select Email </label>
            <multiselect v-model="emailTo" :options="tableEmail.data" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Email" label="email" track-by="email" :preselect-first="false">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
            <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="warning"
                  size="xs"
                  @click="addAttachEmail()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add Attachment For Email</argon-button>
            <table class="table table-hover align-items-center mt-2">
              <thead>
                <tr style="background-color: #F0F8FF;">
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Attachment Name</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in tableAttachEmail.data" :key="i">
                  <td class="align-middle text-center text-sm">
                    <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                  </td>
                  <td class="align-middle text-left text-sm">
                    <span class="text-secondary text-xs font-weight-bold">
                      <a :href="storageUrl+'doc_attach_email/'+row.attachment_email" target="_BLANK" style="color: blue; size: 5px;">{{ row.attachment_email }}</a>
                    </span>
                  </td>
                  <td class="align-middle text-center">
                    <i class="fas fa-trash fa-sm" aria-hidden="true" title="Delete" style="cursor: pointer; margin-right: 20px;" @click="removeAttachEmail(row.id)"></i>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <!-- footer -->
      <div class="row mt-5">
        <div class="col-lg-3"> 
        </div>
        <div class="col-lg-4">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="save()" :disabled="onLoading" style="padding-right: 200px; padding-left: 200px;">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span style="white-space: nowrap;"><i class="fa fa-paper-plane-o" aria-hidden="true" style="margin-right: 10px;"></i> Save</span>
            </span>
          </button>
        </div>
        <div class="col-lg-3">
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">TEF Project Number </label>
        <input type="text" class="form-control" placeholder="TEF Project Number" v-on:keyup.enter="get()" v-model="search.project_number">
        <label for="example-text-input" class="form-control-label mt-3">PO Number </label>
        <input type="text" class="form-control" placeholder="PO Number" v-on:keyup.enter="get()" v-model="search.po_number">
        <label for="example-text-input" class="form-control-label mt-3">Customer </label>
        <multiselect v-on:keyup.enter="get()" v-model="search.customer_name" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Customer" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">End User Name </label>
        <multiselect v-on:keyup.enter="get()" v-model="search.end_user_name" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Customer" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Product Name </label>
        <input type="text" class="form-control" placeholder="Product Name" v-on:keyup.enter="get()" v-model="search.product_name">
        <label for="example-text-input" class="form-control-label mt-3">Main Material </label>
        <input type="text" class="form-control" placeholder="Main Material" v-on:keyup.enter="get()" v-model="search.main_material">
        <label for="example-text-input" class="form-control-label mt-3">Material Category </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.material_category">
          <option value="CS">CS</option>
          <option value="Cu-Ni">Cu-Ni</option>
          <option value="Duplex">Duplex</option>
          <option value="Ni Alloy">Ni Alloy</option>
          <option value="SUS">SUS</option>
          <option value="Ti">Ti</option>
          <option value="Other">Other</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">PO Received Date </label>
        <input type="date" class="form-control" placeholder="PO Received Date" v-on:keyup.enter="get()" v-model="search.po_received_date">
        <label for="example-text-input" class="form-control-label mt-3">Delivery Date </label>
        <input type="date" class="form-control" placeholder="Delivery Date" v-on:keyup.enter="get()" v-model="search.delivery_date">
        <label for="example-text-input" class="form-control-label mt-3">Currency </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.currency">
          <option value="IDR">IDR - Indonesian Rupiah</option>
          <option value="USD">USD - US Dollar</option>
          <option value="KRW">KRW - South Korean Won</option>
          <option value="SGD">SGD - Singapore Dollar</option>
          <option value="JPY">JPY - Japanese Yen</option>
          <option value="EUR">EUR - Euro</option>
          <option value="GBP">GBP - British Pound</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Term Of Payment </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.term_of_payment">
          <option value="DP">DP</option>
          <option value="DEL">DEL</option>
          <option value="2nd Payment">2nd Payment</option>
          <option value="3rd Payment">3rd Payment</option>
          <option value="4th Payment">4th Payment</option>
          <option value="5th Payment">5th Payment</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">VAT (11 %) </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.vat">
          <option value="Include">Include</option>
          <option value="Exclude">Exclude</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Quotation Number </label>
        <input type="text" class="form-control" placeholder="Quotation Number" v-on:keyup.enter="get()" v-model="search.quotation_number">
        <label for="example-text-input" class="form-control-label mt-3">Code/Regulation </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.code_regulation">
          <option value="ASME">ASME</option>
          <option value="ASME NO STAMP">ASME NO STAMP</option>
          <option value="HPGSL/TOKUTEI">HPGSL/TOKUTEI</option>
          <option value="ICHIATSU">ICHIATSU</option>
          <option value="NIATSU">NIATSU</option>
          <option value="SHOBO">SHOBO</option>
          <option value="KEMENAKER">KEMENAKER</option>
          <option value="NON-CODE">NON-CODE</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Type Of Equipment / Works </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.type_equipment">
          <option value="V">V - Vessel</option>
          <option value="H">H - Heat Exchanger</option>
          <option value="P">P - Piping</option>
          <option value="M">M - Mufle</option>
          <option value="R">R - Radiant Tube</option>
          <option value="S">S - Service</option>
          <option value="O">O - Other</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Work Location </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.work_location">
          <option value="Workshop">Workshop</option>
          <option value="Site">Site</option>
          <option value="Workshop + Site">Workshop + Site</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">TEF Plant </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.plant">
          <option value="Discovery">Discovery</option>
          <option value="Endeavor">Endeavor</option>
          <option value="Simba">Simba</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Country Plant </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.country_plant">
          <option value="Indonesia">Indonesia</option>
          <option value="Japan">Japan</option>
          <option value="Singapore">Singapore</option>
          <option value="Malaysia">Malaysia</option>
          <option value="Thailand">Thailand</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Sales PIC </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.sales_pic">
          <option value="Agus Setiyono">Agus Setiyono</option>
          <option value="Anwar Budi S">Anwar Budi S</option>
          <option value="Dwi Febiyanti">Dwi Febiyanti</option>
          <option value="Fitri Hayati">Fitri Hayati</option>
          <option value="Fauzul Umam">Fauzul Umam</option>
          <option value="Hendrayana">Hendrayana</option>
          <option value="Sutriyono">Sutriyono</option>
          <option value="Imam Febriawan">Imam Febriawan</option>
          <option value="Ine Putri Astuti">Ine Putri Astuti</option>
          <option value="Rachmat H">Rachmat H</option>
          <option value="Rahadian Akmal Wildanum">Rahadian Akmal Wildanum</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Status </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.status">
          <option value="Already Invoiced">Already Invoiced</option>
          <option value="Not Invoiced Yet">Not Invoiced Yet</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>


   <!-- =======  MODAL TERM OF PAYMENT ======= -->
  <div class="container">
    <vue-final-modal v-model="formTermPay.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formTermPay.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formTermPay.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content">
        <div class="row">
          <div class="col-lg-4">
            <div class="card-header pb-0">
            </div>
          </div>
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4 float-right">
            <argon-button
              class="mt-0 mb-2"
              variant="gradient"
              color="success"
              size="sm"
              @click="createTermPay()"
            ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add Term</argon-button>
          </div>
        </div>
        <div class="table-responsive p-0 scroll">
          <table class="table table-hover align-items-center mb-0">
            <thead>
              <tr style="background-color: #F0F8FF;">
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TERM OF PAYMENT</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PERCENTAGE</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PERIODE / DAY</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NOTE</th>
                <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tablePayTerm.data" :key="i">
                <td class="align-middle text-left text-sm">
                    <span class="text-secondary text-xs font-weight-bold">{{ row.term_payment }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.percentage }} %</span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.periode }} Days After Invoice</span>
                </td>
                <td class="align-middle text-left text-sm">
                  <span class="text-secondary text-xs font-weight-bold">{{ row.note }}</span>
                </td>
                <td class="align-middle text-center">
                  <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="editTermPay(row.id)" title="Edit"></i>
                  <i class="fas fa-trash fa-sm" aria-hidden="true" title="Delete" style="cursor: pointer; margin-right: 20px;" @click="removeTermPay(row.id)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button> -->
        </div>
        <div class="col-lg-1 col-2">
          <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button> -->
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL ADD DATA TERM PAYMENT======= -->
  <div class="container">
    <vue-final-modal v-model="formAddTermPay.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formAddTermPay.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formAddTermPay.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Term Of Payment <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="termPayData.term_payment">
          <option value="DP">DP</option>
          <option value="DEL">DEL</option>
          <option value="2nd Payment">2nd Payment</option>
          <option value="3rd Payment">3rd Payment</option>
          <option value="4th Payment">4nd Payment</option>
          <option value="5th Payment">5nd Payment</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Percentage Payment<span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Percentage" v-model="termPayData.percentage">
        <label for="example-text-input" class="form-control-label mt-3">Periode / Day<span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="termPayData.periode">
          <option value="1">1 Days</option>
          <option value="15">15 Days</option>
          <option value="30">30 Days</option>
          <option value="45">45 Days</option>
          <option value="60">60 Days</option>
        </select>
        <!-- <input type="number" class="form-control" placeholder="Periode" v-model="termPayData.periode"> -->
        <label for="example-text-input" class="form-control-label mt-3">Note </label>
        <input type="text" class="form-control" placeholder="Note" v-model="termPayData.note">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="saveTermPay()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Save</span>
          </span>
        </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL ADD ATTACHMENT EMAIL ======= -->
  <div class="container">
    <vue-final-modal v-model="formAttachEmail.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formAttachEmail.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formAttachEmail.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Attachment <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Attachment" @change="filesChangeAttachEmail" accept="application/pdf">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formAttachEmail.show = true">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="saveAttachment()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Save</span>
          </span>
        </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
// import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import salesAdvisorPo from '@/services/salesAdvisorPo.service';
import customer from '@/services/customer.service';
import plant from '@/services/plant.service';
import employee from '@/services/employee.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    // Card,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      tablePayTerm: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formTermPay: {
        add: true,
        title: "Term Of Payment",
        show: false
      },
      formAddTermPay: {
        add: true,
        title: "Add Term Of Payment",
        show: false
      },
      formAttachEmail: {
        add: true,
        title: "Add Attachment For Email",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      salesAdvisorData: {},
      termPayData: {},
      search: {
        project_number: '',
        po_number: '',
        customer_name: '',
        end_user_name: '',
        product_name: '',
        main_material: '',
        material_category: '',
        po_received_date: '',
        delivery_date: '',
        currency: '',
        term_of_payment: '',
        vat: '',
        quotation_number: '',
        code_regulation: '',
        type_equipment: '',
        work_location: '',
        plant: '',
        country_plant: '',
        sales_pic: '',
        status: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tablePlant: {
        data: []
      },
      tablePic: {
        data: []
      },
      tableCustomer: {
        data: []
      },
      tableAttachEmail: {
        data: []
      },
      attachEmail: '',
      tableEmail: {
        data: [
          {email :'dindin@tef.co.id'},
          {email :'yunomae@tef.co.id'},
          {email :'agus.setiyono@tef.co.id'},
          {email :'imam.febriawan@tef.co.id'},
          {email :'dwi@tef.co.id'},
          {email :'sutriyono@tef.co.id'},
          {email :'umam@tef.co.id'},
          {email :'hermawan@tef.co.id'},
          {email :'hendra@tef.co.id'},
          {email :'fitri@tef.co.id'},
          {email :'arief@tef.co.id'},

          {email :'rivaldi@tef.co.id'},
          {email :'denny@tef.co.id'},
          {email :'akmal@tef.co.id'},
          {email :'mamad@tef.co.id'},
          {email :'dwika@tef.co.id'},
          {email :'arifurrohman@tef.co.id'},
          {email :'qodratullah@tef.co.id'},
          {email :'rudi@tef.co.id'},
          {email :'dehan.nantiarno@tef.co.id'},
          {email :'rhodin.santoso@tef.co.id'},
          {email :'bayu@tef.co.id'},
          {email :'adesundana@tef.co.id'},
          {email :'sidrin@tef.co.id'},
          {email :'fia@tef.co.id'},
          {email :'sinta@tef.co.id'},
          {email :'dimas@tef.co.id'},
          {email :'prayuda@tef.co.id'},
          {email :'surtini@tef.co.id'},
          {email :'muhazir@tef.co.id'},
          
          {email :'amulyadi@tef.co.id'},
          {email :'johan@tef.co.id'},
          
          {email :'subandi@tef.co.id'},
          {email :'asef@tef.co.id'},
          {email :'andi@tef.co.id'},

          {email :'ysuriyat@tef.co.id'},
          {email :'windoyoko@tef.co.id'},
          {email :'suhardo@tef.co.id'},
          {email :'hen27@tef.co.id'},
          {email :'cecep@tef.co.id'},
          {email :'henry@tef.co.id'},
          {email :'haryanto@tef.co.id'},
          {email :'ali.yusni@tef.co.id'},
          {email :'rina@tef.co.id'},
          {email :'farid@tef.co.id'},
          {email :'tatang@tef.co.id'},
          {email :'nimang@tef.co.id'},
          {email :'awal.rahmat@tef.co.id'},
          {email :'sukendar@tef.co.id'},
          {email :'hartono@tef.co.id'},
          {email :'widoyo@tef.co.id'},

        ]
      },
      emailTo: [],
      backgroundRed: null,
      
      totalPending: '',
      totalApproved: '',
      totalNotApproved: '',
      totalOnDuty: '',
      totalDone: '',
      totalRequest: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, salesAdvisorPo.index({project_number: this.search.project_number, po_number: this.search.po_number, customer_name: this.search.customer_name['name'], end_user_name: this.search.end_user_name['name'], product_name: this.search.product_name, main_material: this.search.main_material, material_category: this.search.material_category, po_received_date: this.search.po_received_date, delivery_date: this.search.delivery_date, currency: this.search.currency, term_of_payment: this.search.term_of_payment, vat: this.search.vat, quotation_number: this.search.quotation_number, code_regulation: this.search.code_regulation, type_equipment: this.search.type_equipment, work_location: this.search.work_location, plant: this.search.plant, country_plant: this.search.country_plant, sales_pic: this.search.sales_pic, status: this.search.status, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page
          context.totalPending          = response.data.data.totalPending
          context.totalApproved         = response.data.data.totalApproved
          context.totalNotApproved      = response.data.data.totalNotApproved
          context.totalOnDuty           = response.data.data.totalOnDuty
          context.totalDone             = response.data.data.totalDone
          context.totalRequest          = response.data.data.totalRequest

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getCustomer(param){
      let context = this;        
      Api(context, customer.getCustomer({search: param.target.value})).onSuccess(function(response) {    
          context.tableCustomer.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableCustomer.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add           = true;
      this.form.show          = true;
      this.form.title         = "Add Data";
      this.salesAdvisorData = {};
    },
    edit(id) {
      let context = this;               
      Api(context, salesAdvisorPo.show(id)).onSuccess(function(response) {
        context.salesAdvisorData               = response.data.data.data;
        context.salesAdvisorData.customer_name = response.data.data.dataCustomerName[0];
        context.salesAdvisorData.end_user_name = response.data.data.dataEndUserName[0];
        context.form.show                      = true;
        context.form.title                     = 'Edit Data'; 
        context.getAttachEmail()
      })
      .call()        
    },
    copy(id) {
      let context = this;               
      Api(context, salesAdvisorPo.show(id)).onSuccess(function(response) {
        context.salesAdvisorData               = response.data.data.data;
        context.salesAdvisorData.customer_name = response.data.data.data.dataCustomerName;
        context.salesAdvisorData.end_user_name = response.data.data.data.dataEndUserName;
        context.form.show                      = true;
        context.form.title                     = 'Copy Value'; 
      })
      .call()        
    },
    filesChange(e) {
      this.salesAdvisorData.attachment = e.target.files[0];
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.salesAdvisorData.project_number != undefined && context.salesAdvisorData.po_number != undefined && context.salesAdvisorData.customer_name != undefined && context.salesAdvisorData.end_user_name != undefined && context.salesAdvisorData.product_name != undefined && context.salesAdvisorData.  main_material != undefined && context.salesAdvisorData.material_category != undefined && context.salesAdvisorData.po_received_date != undefined && context.salesAdvisorData.delivery_date != undefined && context.salesAdvisorData.delivery != undefined && context.salesAdvisorData.qty != undefined && context.salesAdvisorData.uom != undefined && context.salesAdvisorData.currency != undefined && context.salesAdvisorData.unit_price != undefined && context.salesAdvisorData.total_price != undefined && context.salesAdvisorData.exchange_rate != undefined && context.salesAdvisorData.term_of_payment != undefined && context.salesAdvisorData.vat != undefined && context.salesAdvisorData.delay_penalty_clause != undefined && context.salesAdvisorData.cost_estimation_price != undefined && context.salesAdvisorData.quotation_number != undefined && context.salesAdvisorData.code_regulation != undefined && context.salesAdvisorData.regulation_project != undefined && context.salesAdvisorData.type_equipment != undefined && context.salesAdvisorData.project_type != undefined && context.salesAdvisorData.work_location != undefined && context.salesAdvisorData.plant != undefined && context.salesAdvisorData.country_plant != undefined && context.salesAdvisorData.sales_pic != undefined && context.emailTo != undefined) {
        formData.append('project_number', context.salesAdvisorData.project_number);
        formData.append('po_number', context.salesAdvisorData.po_number);
        formData.append('customer_name', JSON.stringify(context.salesAdvisorData.customer_name));
        formData.append('sales_agent', (context.salesAdvisorData.sales_agent == undefined) ? '-' : JSON.stringify(context.salesAdvisorData.sales_agent));
        formData.append('end_user_name', JSON.stringify(context.salesAdvisorData.end_user_name));
        formData.append('product_name', context.salesAdvisorData.product_name);
        formData.append('main_material', context.salesAdvisorData.main_material);
        formData.append('material_category', context.salesAdvisorData.material_category);
        formData.append('po_received_date', context.salesAdvisorData.po_received_date);
        formData.append('delivery_date', context.salesAdvisorData.delivery_date);
        formData.append('delivery', context.salesAdvisorData.delivery);
        formData.append('qty', context.salesAdvisorData.qty);
        formData.append('uom', context.salesAdvisorData.uom);
        formData.append('currency', context.salesAdvisorData.currency);
        formData.append('unit_price', context.salesAdvisorData.unit_price);
        formData.append('total_price', context.salesAdvisorData.total_price);
        formData.append('exchange_rate', context.salesAdvisorData.exchange_rate);
        formData.append('term_of_payment', context.salesAdvisorData.term_of_payment);
        formData.append('vat', context.salesAdvisorData.vat);
        formData.append('delay_penalty_clause', context.salesAdvisorData.delay_penalty_clause);
        formData.append('cost_estimation_price', context.salesAdvisorData.cost_estimation_price);
        formData.append('quotation_number', context.salesAdvisorData.quotation_number);
        formData.append('code_regulation', context.salesAdvisorData.code_regulation);
        formData.append('regulation_project', context.salesAdvisorData.regulation_project);
        formData.append('type_equipment', context.salesAdvisorData.type_equipment);
        formData.append('project_type', context.salesAdvisorData.project_type);
        formData.append('work_location', context.salesAdvisorData.work_location);
        formData.append('plant', context.salesAdvisorData.plant);
        formData.append('country_plant', context.salesAdvisorData.country_plant);
        formData.append('sales_pic', context.salesAdvisorData.sales_pic);
        formData.append('attachment', (context.salesAdvisorData.attachment == undefined) ? '' : context.salesAdvisorData.attachment);
        formData.append('emailTo', JSON.stringify(context.emailTo));
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
        api = Api(context, salesAdvisorPo.create(formData));
      } else if(context.form.title == 'Copy Value'){
        api = Api(context, salesAdvisorPo.create(formData));
      }else {
        api = Api(context, salesAdvisorPo.update(this.salesAdvisorData.id, formData));
      }
      api.onSuccess(function(response) {
        context.get();
        context.salesAdvisorData = {}
        context.form.show        = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, salesAdvisorPo.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    paymentTerm(project_number) {
      let context = this;               
      context.termPayData.project_number = project_number;
      
      Api(context, salesAdvisorPo.showPaymentTerm(project_number)).onSuccess(function(response) {
        context.tablePayTerm.data  = response.data.data;
        context.formTermPay.show   = true;
        context.formTermPay.title  = 'Term Of Payment'; 
      })
      .call()        
    },
    createTermPay() {
      this.formAddTermPay.add   = true;
      this.formAddTermPay.show  = true;
      this.formAddTermPay.title = "Add Term Of Payment";
      // this.termPayData          = {};
    },
    editTermPay(id) {
      let context = this;               
      Api(context, salesAdvisorPo.showEditTermPay(id)).onSuccess(function(response) {
        context.termPayData          = response.data.data;
        context.formAddTermPay.show  = true;
        context.formAddTermPay.title = 'Edit Term Of Payment'; 
      })
      .call()        
    },
    saveTermPay(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.termPayData.term_payment != undefined && context.termPayData.percentage != undefined && context.termPayData.periode != undefined) {
        formData.append('project_number', context.termPayData.project_number);
        formData.append('term_payment', context.termPayData.term_payment);
        formData.append('percentage', context.termPayData.percentage);
        formData.append('periode', context.termPayData.periode);
        formData.append('note', (context.termPayData.note == undefined) ? '' : context.termPayData.note);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.formAddTermPay.title == 'Add Term Of Payment') {
        api = Api(context, salesAdvisorPo.createTermPay(formData));
      } else {
        api = Api(context, salesAdvisorPo.updateTermPay(this.termPayData.id, formData));
      }
      api.onSuccess(function(response) {
        context.paymentTerm(context.termPayData.project_number);
        // context.termPayData = {}
        context.formAddTermPay.show   = false;
        context.notify((context.form === 'Add Term Of Payment') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Term Of Payment') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    removeTermPay(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, salesAdvisorPo.deleteTermPay(id)).onSuccess(function(response) {
            context.paymentTerm(context.tablePayTerm.data[0].project_number);
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    addAttachEmail() {
      this.formAttachEmail.add   = true;
      this.formAttachEmail.show  = true;
      this.formAttachEmail.title = "Add Attachment For Email";
      this.attachEmail           = {};
    },
    filesChangeAttachEmail(e) {
      this.attachEmail = e.target.files[0];
    },
    getAttachEmail(param){
      let context = this;               
      Api(context, salesAdvisorPo.showAttachment(context.salesAdvisorData.project_number)).onSuccess(function(response) {
          context.tableAttachEmail.data = response.data.data.data;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableAttachEmail.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    saveAttachment(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.attachEmail != undefined && context.salesAdvisorData.project_number != undefined) {
        formData.append('project_number', context.salesAdvisorData.project_number);
        formData.append('attachment_email', context.attachEmail);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, salesAdvisorPo.createAttachEmail(formData));
      
      api.onSuccess(function(response) {
        context.getAttachEmail();
        context.attachEmail          = {}
        context.formAttachEmail.show = false;
        context.notify((context.formAttachEmail === 'Add Attachment For Email') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.formAttachEmail != 'Add Attachment For Email') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    removeAttachEmail(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, salesAdvisorPo.deleteAttachEmail(id)).onSuccess(function(response) {
            context.getAttachEmail();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    changeTotalPrice(){
      this.salesAdvisorData.total_price = (this.salesAdvisorData.qty * this.salesAdvisorData.unit_price)
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    setBg(id) {
      this.backgroundRed = id
    }

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}

/*for freze ke samping*/
.scroll table td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: #F0E68C;
}

/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 1090px;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
