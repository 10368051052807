<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <card
          title="Paid Off"
          :value="convertRp(totalPaidOff)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <card
          title="Not Yet Paid Off"
          :value="convertRp(totalNotYetPaidOff)"
          iconClass="fa fa-times-circle"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
    </div>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Loan Reminder</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Transaction Code</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Employee Name</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Besar Pinjaman</th>
                      <th colspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #D2691E;">Lama Potongan</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Installment</th>
                      <th colspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #32CD32;">Potongan</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Balance</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Type Of Loan</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Status</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #D2691E;"></th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #D2691E;">x</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #32CD32;">Mulai Potongan</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #32CD32;">Berakhir Potongan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in tableLoanReminder.data" :key="i" >
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-primary" @click="getDetailLoan(row.transaction_code)" style="cursor: pointer;">{{ row.transaction_code }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.employee_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">Rp. {{ convertRp(row.besar_pinjaman) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.lama_potongan }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">x</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">Rp. {{ convertRp(row.installment) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ moment(row.mulai_potongan).locale('en').format('MMMM YYYY') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ moment(row.berakhir_potongan).locale('en').format('MMMM YYYY') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">Rp. {{ convertRp(row.balance) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-info">{{ row.type_loan }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-success" v-if="row.status == 'Paid Off'">{{ row.status }}</span>
                        <span class="badge bg-gradient-danger" v-if="row.status == 'Not Yet Paid Off'">{{ row.status }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fa fa-credit-card fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="payLoan(row.id)" title="Pay"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{tableLoanReminder.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>

    <!-- Loan History -->
    <a class="btn btn-sm btn-success mt-4" style="margin-right: 10px;" :href="apiUrl+'export-excel/loan-data?employee_name='+search.employee_name['name']+'&lama_potongan='+search.lama_potongan+'&mulai_potongan='+search.mulai_potongan+'&berakhir_potongan='+search.berakhir_potongan+'&type_loan='+search.type_loan+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export Loan History</a>
    <a class="btn btn-sm btn-warning mt-4" style="margin-right: 10px;" :href="apiUrl+'export-excel/loan-pay-history?employee_name='+search.employee_name['name']+'&lama_potongan='+search.lama_potongan+'&mulai_potongan='+search.mulai_potongan+'&berakhir_potongan='+search.berakhir_potongan+'&type_loan='+search.type_loan+'&status='+search.status+'&periode_pay='+search.periode_pay+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export Pay History</a>
    <div class="row ">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Loan History</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Transaction Code</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Employee Code</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Employee Name</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Besar Pinjaman</th>
                      <th colspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #D2691E;">Lama Potongan</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Installment</th>
                      <th colspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #32CD32;">Potongan</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Balance</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Type Of Loan</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Status</th>
                      <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #D2691E;"></th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #D2691E;">x</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #32CD32;">Mulai Potongan</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #32CD32;">Berakhir Potongan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" >
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-primary" @click="getDetailLoan(row.transaction_code)" style="cursor: pointer;">{{ row.transaction_code }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.employee_id }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.employee_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">Rp. {{ convertRp(row.besar_pinjaman) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.lama_potongan }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">x</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">Rp. {{ convertRp(row.installment) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ moment(row.mulai_potongan).locale('en').format('MMMM YYYY') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ moment(row.berakhir_potongan).locale('en').format('MMMM YYYY') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">Rp. {{ convertRp(row.balance) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-info">{{ row.type_loan }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-success" v-if="row.status == 'Paid Off'">{{ row.status }}</span>
                        <span class="badge bg-gradient-danger" v-if="row.status == 'Not Yet Paid Off'">{{ row.status }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit" v-if="row.status == 'Not Yet Paid Off'"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)" v-if="row.status == 'Not Yet Paid Off'"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>

  </div>

  <!-- =======  MODAL ADD ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Employee Name <span style="color: red;">*</span></label>
        <multiselect v-model="loanData.employee_name" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Besar Pinjaman <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Besar Pinjaman" v-model="loanData.besar_pinjaman" @change="changeInstallment()">
        <label for="example-text-input" class="form-control-label mt-3">Lama Potongan <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Lama Potongan" v-model="loanData.lama_potongan" @change="changeInstallment()">
        <label for="example-text-input" class="form-control-label mt-3">Installment <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Installment" v-model="loanData.installment" disabled>
        <label for="example-text-input" class="form-control-label mt-3">Mulai Potongan <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Mulai Potongan" v-model="loanData.mulai_potongan" @change="updateBerakhirPotongan()">
        <label for="example-text-input" class="form-control-label mt-3">Berakhir Potongan <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Berakhir Potongan" v-model="loanData.berakhir_potongan">
        <label for="example-text-input" class="form-control-label mt-3">Type Of Loan <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="loanData.type_loan">
          <option value="Company">Company</option>
          <option value="Education">Education</option>
          <option value="Medical">Medical</option>
          <option value="J Trust">J Trust</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = false">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Employee Name</label>
        <multiselect v-model="search.employee_name" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Lama Potongan</label>
        <input type="text" class="form-control" placeholder="Lama Potongan" v-model="search.lama_potongan" v-on:keyup.enter="get(), getLoanRemember()">
        <label for="example-text-input" class="form-control-label mt-3">Mulai Potongan</label>
        <input type="text" class="form-control" placeholder="Mulai Potongan" v-model="search.mulai_potongan" v-on:keyup.enter="get(), getLoanRemember()">
        <label for="example-text-input" class="form-control-label mt-3">Berakhir Potongan</label>
        <input type="text" class="form-control" placeholder="Berakhir Potongan" v-model="search.berakhir_potongan" v-on:keyup.enter="get(), getLoanRemember()">
        <label for="example-text-input" class="form-control-label mt-3">Type Loan</label>
        <select class="form-select" aria-label="Default select example" v-model="search.type_loan" v-on:keyup.enter="get(), getLoanRemember()">
          <option value="Company">Company</option>
          <option value="Education">Education</option>
          <option value="Medical">Medical</option>
          <option value="J Trust">J Trust</option>
          <option value="Other">Other</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select " aria-label="Default select example" v-model="search.status" v-on:keyup.enter="get(), getLoanRemember()">
          <option value="Paid Off">Paid Off</option>
          <option value="Not Yet Paid Off">Not Yet Paid Off</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Periode for Export to Excel</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control mb-3" v-model="search.periode_pay" v-on:keyup.enter="get()"/>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), getLoanRemember()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- ===========================================  MODAL DETAIL LOAN =============================================== -->
  <div class="container">
    <vue-final-modal v-model="formDetailLoan.show" classes="modal-container" content-class="modal-content-width" :z-index="10000" :focus-retain="false">
      <!-- header -->
      <div class="row">
        <div class="col-11 text-center">
          <span class="modal__title">{{formDetailLoan.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formDetailLoan.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <span class="modal__title">TRANSACTION CODE &nbsp;&nbsp;:&nbsp;  {{ loanDetailHistory.transaction_code }} </span><br>
                <span class="modal__title">EMPLOYEE NAME &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;  {{ loanDetailHistory.employee_name }} </span><br>
                <span class="modal__title">BESAR PINJAMAN &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;  Rp. {{ convertRp(loanDetailHistory.besar_pinjaman) }} </span><br>
                <span class="modal__title">LAMA POTONGAN &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;  {{ loanDetailHistory.lama_potongan }} x </span><br>
                <span class="modal__title">INSTALLMENT &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;  Rp. {{ convertRp(loanDetailHistory.installment) }} </span><br>
              </div>
              <div class="col-6">
                <span class="modal__title">TYPE OF LOAN &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;  <span class="badge bg-gradient-info">{{ loanDetailHistory.type_loan }}</span></span><br>
                <span class="modal__title">MULAI POTONGAN &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;  {{ moment(loanDetailHistory.mulai_potongan).locale('en').format('MMMM YYYY') }} </span><br>
                <span class="modal__title">BERAKHIR POTONGAN &nbsp;&nbsp;:&nbsp;  {{ moment(loanDetailHistory.berakhir_potongan).locale('en').format('MMMM YYYY') }} </span><br>
                <span class="modal__title">BALANCE &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;  Rp. {{ convertRp(loanDetailHistory.balance) }} </span><br>
                <span class="modal__title">STATUS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;  
                  <span class="badge bg-gradient-success" v-if="loanDetailHistory.status == 'Paid Off'">Paid Off</span> 
                  <span class="badge bg-gradient-danger" v-if="loanDetailHistory.status == 'Not Yet Paid Off'">Not Yet Paid Off</span> 
                </span><br>
              </div>
            </div><hr>
            <div class="row mb-2 mt-4">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6><i class="fas fa-list-ol" style="margin-right: 10px;"></i> Loan Payment History</h6>
                </div>
              </div>
              <div class="col-3">
              </div>
              <div class="col-5 float-right">
              </div>
            </div>
            <div class="table-responsive p-0">
              <table class="table table-hover table-striped align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">No</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Periode</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Payment</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Created At</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Created By</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in tableLoanPayHistory.data" :key="i">
                    <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold">{{ moment(row.periode).locale('en').format('MMMM YYYY') }}</span>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold">Rp. {{ convertRp(row.payment) }}</span>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold">{{ moment(row.created_at).locale('us').format('LL') }}</span>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="text-secondary text-xs font-weight-bold">{{ row.created_by }}</span>
                    </td>
                    <td class="align-middle text-center">
                      <!-- <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i> -->
                      <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="removePayHistory(row.id, row.transaction_code)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="transactionData = {}">Clear</argon-button> -->
        </div>
        <div class="col-1">
          <template >
            <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
              <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
              <span v-else>
                  <span>Save</span>
              </span>
            </argon-button>
          </template>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL PAY ======= -->
  <div class="container">
    <vue-final-modal v-model="formPay.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formPay.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formPay.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Employee Name <span style="color: red;">*</span></label>
        <multiselect v-model="loanData.employee_name" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee" disabled>
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Besar Pinjaman <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Besar Pinjaman" v-model="loanData.besar_pinjaman" @change="changeInstallment()" disabled>
        <label for="example-text-input" class="form-control-label mt-3">Lama Potongan <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Lama Potongan" v-model="loanData.lama_potongan" @change="changeInstallment()" disabled>
        <label for="example-text-input" class="form-control-label mt-3">Installment <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Installment" v-model="loanData.installment">
        <label for="example-text-input" class="form-control-label mt-3">Mulai Potongan <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Mulai Potongan" v-model="loanData.mulai_potongan" disabled>
        <label for="example-text-input" class="form-control-label mt-3">Berakhir Potongan <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Berakhir Potongan" v-model="loanData.berakhir_potongan" disabled>
        <label for="example-text-input" class="form-control-label mt-3">Type Of Loan <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="loanData.type_loan" disabled>
          <option value="Company">Company</option>
          <option value="Education">Education</option>
          <option value="Medical">Medical</option>
          <option value="J Trust">J Trust</option>
          <option value="Other">Other</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Periode <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Periode" v-model="loanData.periode">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formPay.show = false">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="pay()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
              <span v-else>
                  <span>Pay</span>
              </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>

</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
var moment = require('moment');
import loan from '@/services/loan.service';
import vehicleList from '@/services/vehicleList.service';
import plant from '@/services/plant.service';
import employee from '@/services/employee.service';
import vehicleRequest from '@/services/vehicleRequest.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    Card,
    flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      tableLoanReminder: {
        data: []
      },
      tableLoanPayHistory: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formPay: {
        add: true,
        title: "Form Pay",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formDetailLoan: {
        add: true,
        title: "DETAIL LOAN",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Master Spool",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      loanData: {},
      loanDetailHistory: {},
      search: {
        employee_name: '',
        lama_potongan: '',
        mulai_potongan: '',
        berakhir_potongan: '',
        type_loan: '',
        status: '',
        periode_pay: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tablePlant: {
        data: []
      },
      tableEmployee: {
        data: []
      },
      tablePassenger: {
        data: []
      },
      tableVehicle: {
        data: []
      },
      totalPaidOff: '',
      totalNotYetPaidOff: '',

      tabComponent: '',

    };
  },
  mounted(){
    this.get();
    this.getLoanRemember();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, loan.index({employee_name: this.search.employee_name['name'], lama_potongan: this.search.lama_potongan, mulai_potongan: this.search.mulai_potongan, berakhir_potongan: this.search.berakhir_potongan, type_loan: this.search.type_loan, status: this.search.status, periode_pay: this.search.periode_pay, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.totalPaidOff       = response.data.data.totalPaidOff
          context.totalNotYetPaidOff = response.data.data.totalNotYetPaidOff

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getLoanRemember(param){
      let context = this;               
      Api(context, loan.getLoanRemember({employee_name: this.search.employee_name, lama_potongan: this.search.lama_potongan, mulai_potongan: this.search.mulai_potongan, berakhir_potongan: this.search.berakhir_potongan, type_loan: this.search.type_loan, page: context.pagination.page})).onSuccess(function(response) {    
          context.tableLoanReminder.data = response.data.data.data.data;
          context.pagination.page_count  = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableLoanReminder.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getEmployee(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tableEmployee.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableEmployee.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    changeInstallment(){
      this.loanData.installment = (this.loanData.besar_pinjaman / this.loanData.lama_potongan)
    },
    updateBerakhirPotongan(){
      var lamaPotongan = this.loanData.lama_potongan
      var date         = new Date(this.loanData.mulai_potongan);
      var day          = ("0" + date.getDate()).slice(-2);
      var month        = ("0" + (date.getMonth() + lamaPotongan)).slice(-2);
      var endPotongan  = date.getFullYear() + "-" + (month) + "-" + '08';

      this.loanData.berakhir_potongan = endPotongan;
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add           = true;
      this.form.show          = true;
      this.form.title         = "Add Data";
      this.loanData = {};
    },
    payLoan(id) {
      let context = this;               
      Api(context, loan.show(id)).onSuccess(function(response) {
        context.loanData               = response.data.data.data;
        context.loanData.employee_name = response.data.data.dataEmployee[0];
        context.formPay.show           = true;
        context.formPay.title          = 'Form Pay'; 
        context.defaultDate()
      })
      .call()    
    },
    edit(id) {
      let context = this;               
      Api(context, loan.show(id)).onSuccess(function(response) {
        context.loanData               = response.data.data.data;
        context.loanData.employee_name = response.data.data.dataEmployee[0];
        context.form.show              = true;
        context.form.title             = 'Edit Data'; 
      })
      .call()        
    },
    // modalImport(){
    //   this.formImport.show  = true;
    //   this.formImport.title = "Import Materil Stock";
    //   this.tabelError.data  = [];
    // },
    // filesChangeImport(e) {
    //     this.dataImport = e.target.files[0];
    // },
    // importData(){
    //   let api        = null;
    //   let context    = this;
    //   let formData   = new FormData();
    //   context.onLoading = true;

    //   if (context.dataImport != undefined) {
    //     formData.append('import_data', context.dataImport);
    //   }else{
    //     return alert('File Import Not Found')
    //   }

    //   api = Api(context, loan.import(formData));
    //   api.onSuccess(function(response) {
    //       context.get();
    //       context.onLoading       = false;
    //       context.formImport.show = false;
    //       context.notify('Import Master Spool Success', 'success')
    //   }).onError(function(error) {      
    //       context.tabelError.data = error.response.data.data; 
    //       context.onLoading       = false;             
    //       context.notify('Import Master Spool Failed', 'error')
    //   }).onFinish(function() {  
    //       context.onLoading = false;
    //   })
    //   .call();
    // },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.loanData.employee_name != undefined && context.loanData.besar_pinjaman != undefined && context.loanData.lama_potongan != undefined && context.loanData.installment != undefined && context.loanData.mulai_potongan != undefined && context.loanData.berakhir_potongan != undefined && context.loanData.type_loan != undefined) {
        formData.append('employee_name', JSON.stringify(context.loanData.employee_name));
        formData.append('besar_pinjaman', context.loanData.besar_pinjaman);
        formData.append('lama_potongan', context.loanData.lama_potongan);
        formData.append('installment', context.loanData.installment);
        formData.append('mulai_potongan', context.loanData.mulai_potongan);
        formData.append('berakhir_potongan', context.loanData.berakhir_potongan);
        formData.append('type_loan', context.loanData.type_loan);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, loan.create(formData));
      } else {
          api = Api(context, loan.update(this.loanData.id, formData));
      }
      api.onSuccess(function(response) {
        context.get();
        context.getLoanRemember();
        context.loanData = {}
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.form.show = false;
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, loan.delete(id)).onSuccess(function(response) {
            context.get();
            context.getLoanRemember();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    getDetailLoan(transaction_code){
      let context = this;               
      Api(context, loan.detailLoan({transaction_code: transaction_code})).onSuccess(function(response) { 
        context.loanDetailHistory        = response.data.data.loanHistory;
        context.tableLoanPayHistory.data = response.data.data.loanPayHistory;
        // context.tableLoanPayHistory.data = response.data.loanPayHistory;
        console.log(response.data.data.loanPayHistory)

        context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
        context.formDetailLoan.show  = true;
        context.formDetailLoan.title = "==== DETAIL LOAN ====";
      })
      .call()
    },
    pay(){
      let api           = null;
      let context       = this;
      let formData      = new FormData();
      context.onLoading = true;

      if (context.loanData.employee_name != undefined && context.loanData.besar_pinjaman != undefined && context.loanData.lama_potongan != undefined && context.loanData.installment != undefined && context.loanData.mulai_potongan != undefined && context.loanData.berakhir_potongan != undefined && context.loanData.type_loan != undefined) {
        formData.append('transaction_code', context.loanData.transaction_code);
        formData.append('periode', context.loanData.periode);
        formData.append('balance', context.loanData.balance);
        formData.append('payment', context.loanData.installment);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.formPay.title == 'Form Pay') {
          api = Api(context, loan.createPayHistory(formData));
      } else {
          api = Api(context, loan.updatePayHistory(this.loanData.id, formData));
      }

      api.onSuccess(function(response) {
        context.get()
        context.getLoanRemember();
        context.notify((context.formPay === 'Form Pay') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.formPay != 'Form Pay') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading    = false;
        context.formPay.show = false;
      })
      .call();
    },
    removePayHistory(id, transaction_code) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, loan.deletePayHistory(id)).onSuccess(function(response) {
            context.get();
            context.getLoanRemember();
            context.getDetailLoan(transaction_code)
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    defaultDate(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var today = date.getFullYear() + "-" + (month) + "-" + '08';

      this.loanData.periode = today
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}

/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 1090px;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
