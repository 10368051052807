<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <!-- <div class="container-fluid"> -->
            <div class="top-0 my-auto text-center col-12 d-lg-flex h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div class="position-relative bg-gradient-primary h-100 m-3  border-radius-lg d-flex flex-column justify-content-center overflow-hidden bg">
                <span class="mask bg-gradient-dark opacity-7"></span>
                <div class="container-fluid scroll">
                  <div class="row justify-content-center mt-3">
                    <div class="col-lg-8 card mb-6">
                      <div class="card-body text-start">
                        <div class="row">
                          <div class="col-lg-7 d-none d-lg-block d-sm-none" style="margin-left: -120px; margin-top: 10px; margin-top: -40px;">
                            <img :src="logo" style="width: 190px; margin-left: 130px; margin-top: 30px;"/>
                            <h5 style="margin-left: 130px; font-weight: bold;">PT. TOKKI ENGINEERING AND FABRICATION</h5>
                            <h6 style="margin-left: 130px; font-weight: bold;">Cilegon Factory & Office :</h6>
                            <p style="margin-left: 130px;">Jl. Australia 1 Kav. C1/2 <br>
          Kawasan Krakatau Industri Estate Cilegon (KIEC)
          Cilegon - Banten - Indonesia <br>
          Phone : +62 - 254 831 7244 (Hunting) <br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+62 - 254 831 7243, +62 - 254 831 7245</p>
                          </div>
                          <div class="col-lg-3 justify-content-center mb-2">
                            <table border="2">
                              <thead>
                                <tr>
                                  <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Requisition&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                </tr>
                              </thead>
                            </table>
                            <p style="margin-top: 8px;">Section : <span style="color: red;">*</span>
                              <select class="form-select mb-3" aria-label="Default select example" v-model="purchaseData.section" >
                                <option :value="row.name" v-for="(row, i) in tableSection.data" :key="i">{{ row.name }}</option>
                              </select>
                            </p>
                            <p style="margin-top: -14px;">Request By : <span style="color: red;">*</span>
                              <multiselect v-model="purchaseData.request_by" :options="tableRequestBy.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Name" label="name" track-by="name" :preselect-first="true" @keyup="getUser">
                                <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
                              </multiselect>
                            </p>
                            <textarea class="form-control mt-3" v-model="purchaseData.comment" placeholder="Comments :"></textarea>
                          </div>
                          <div class="col-lg-3">
                            <argon-button
                              style="white-space: nowrap; margin-right: 8px;"
                              class="mb-3"
                              variant="gradient"
                              color="primary"
                              size="sm"
                              @click="newPr()"
                            ><i class="fa fa-file-text-o" aria-hidden="true"></i> New PR</argon-button>
                            <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/purchasing-requisition?pr_code='+prCode+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print / PDF</a>
                            <table class="table-bordered" border="2">
                              <thead>
                                <tr>
                                  <th>&nbsp;&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                  <th>&nbsp;Requisition Number&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="text-center">&nbsp;{{ moment(purchaseData.date).locale('id').format('L') }}&nbsp;</td>
                                  <td class="text-center">{{ purchaseData.pr_code }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="card-header pb-0">
                            </div>
                          </div>
                          <div class="col-lg-6">
                          </div>
                          <div class="col-lg-2 float-right mt-2">
                            <argon-button
                              style="white-space: nowrap;"
                              class="mb-2"
                              variant="gradient"
                              color="warning"
                              size="sm"
                              @click="create()"
                            ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> New Item</argon-button>
                          </div>
                        </div>
                        <div class="table-responsive p-0">
                          <table class="table table-striped align-items-center mb-0" style="border-collapse: collapse;">
                            <thead>
                              <tr style="background-color: #F0F8FF;">
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Quantity</th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">UOM</th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Description</th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Expected Arrival</th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Image</th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(row, i) in table.data" :key="i" style="border-bottom: 1pt solid #DCDCDC; padding: 5px;">
                                <td class="align-middle text-center text-sm" style="border-bottom: 1px;">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.uom }}</span>
                                </td>
                                <td class="align-middle text-left text-sm">
                                  <span class="text-secondary text-xs font-weight-bold">{{ row.description }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="text-secondary text-xs font-weight-bold" v-if="row.expected_arrival != '0000-00-00'">{{ moment(row.expected_arrival).locale('id').format('L') }}</span>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <img
                                    :src="storageUrl+'/image_pr/'+row.image"
                                    class="avatar avatar-lg me-1"
                                    alt="user1"
                                    v-if="row.image"
                                  />
                                </td>
                                <td class="align-middle text-center">
                                  <span style="white-space: nowrap;">
                                    <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                                    <i class="fas fa-trash fa-sm" aria-hidden="true" title="Delete" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="card-footer bg-dark mb-4" style="height: 60px;">
                        <button type="success" style="margin-top: -10px;" class="btn btn-sm btn-success btn-fill" @click="sendRequest()" :disabled="onLoading" v-if="table.data.length != 0">
                          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
                          <span v-else>
                              <span>Send Request <i class="fa fa-paper-plane mr-5" aria-hidden="true"></i></span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <!-- </div> -->
        <!-- </div> -->
      </div>
    </section>
  </main>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container container mt-7" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row container mt-3">
        <div class="col-10 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Description <span style="color: red;">*</span></label>
        <textarea class="form-control" v-model="purchaseData.description" placeholder="Description"></textarea>
        <label for="example-text-input" class="form-control-label mt-3">Qty <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Qty" v-model="purchaseData.qty">
        <label for="example-text-input" class="form-control-label mt-3">UOM <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="UOM" v-model="purchaseData.uom">
        <label for="example-text-input" class="form-control-label mt-3">Expected Arrival </label>
        <input type="date" class="form-control" placeholder="Expected Arrival" v-model="purchaseData.expected_arrival">
        <label for="example-text-input" class="form-control-label mt-3">Image </label>
        <input type="file" class="form-control" placeholder="Image" @change="filesChange">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3 mb-5">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3">
          <argon-button variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save </span>
            </span>
          </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
  </div>
</template>

<script>
import logo from "@/assets/img/logo-tef.png";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import { VueFinalModal } from 'vue-final-modal'
import config from '@/configs/config';
import axios from 'axios';
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
var moment = require('moment');
import 'vue-multiselect/dist/vue-multiselect.css';
// import flatPickr from 'vue-flatpickr-component';
// import 'flatpickr/dist/flatpickr.css';
import purchaseRequisition from '@/services/purchaseRequisition.service';
import department from '@/services/department.service';
import plant from '@/services/plant.service';
import employee from '@/services/employee.service';

export default {
  name: "signin",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    // flatPickr,
  },
  data() {
    return {
      logo,
      moment:moment,
      onLoading: false,
      purchaseData: {},
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      table: {
        data: []
      },
      tableSection: {
        data: []
      },
      tableRequestBy: {
        data: []
      },
      apiUrl :config.apiUrl,
      storageUrl : config.storageUrl,
      prCode: '',
    };
  },
  mounted(){
    this.getSection();
  },
  methods: {
    getDetailPr(){
      let context = this;

      Api(context, purchaseRequisition.getDetailPr({pr_code: context.prCode})).onSuccess(function(response) {    
          context.table.data              = response.data.data.data;
          context.purchaseData.section    = response.data.data.data[0].section;
          context.purchaseData.request_by = response.data.data.dataRequesteBy[0];
          context.purchaseData.date       = response.data.data.data[0].date;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    generatePrCode(){
      let context = this;              
      Api(context, purchaseRequisition.generatePrCode()).onSuccess(function(response) {   
          context.purchaseData.pr_code = response.data.data.PrCode;
          context.prCode = response.data.data.PrCode;
      })
      .call()
    },
    getSection(){
      let context = this;        
      Api(context, department.getDepartment({search: ''})).onSuccess(function(response) {    
          context.tableSection.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableSection.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getUser(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tableRequestBy.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableRequestBy.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    newPr(){
      this.generatePrCode()
      this.defaultDate();
      this.table.data = [];
      // this.getDetailPr();
    },
    create() {
      this.form.add     = true;
      this.form.show    = true;
      this.form.title   = "Add Data";
      // this.purchaseData = {};
    },
    filesChange(e) {
      this.purchaseData.image = e.target.files[0];
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.purchaseData.pr_code != undefined && context.purchaseData.date != undefined && context.purchaseData.section != undefined && context.purchaseData.request_by != undefined && context.purchaseData.description != undefined && context.purchaseData.qty != undefined && context.purchaseData.uom != undefined) {
        formData.append('pr_code', context.purchaseData.pr_code);
        formData.append('date', context.purchaseData.date);
        formData.append('section', context.purchaseData.section);
        formData.append('request_by', JSON.stringify(context.purchaseData.request_by));
        formData.append('description', context.purchaseData.description);
        formData.append('qty', context.purchaseData.qty);
        formData.append('uom', context.purchaseData.uom);
        formData.append('expected_arrival', context.purchaseData.expected_arrival);
        formData.append('image', context.purchaseData.image);
        formData.append('comment', (context.purchaseData.comment == undefined) ? '' : context.purchaseData.comment);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, purchaseRequisition.create(formData));
      api.onSuccess(function(response) {
        context.purchaseData.description      = '';
        context.purchaseData.qty              = '';
        context.purchaseData.uom              = '';
        context.purchaseData.expected_arrival = '';
        context.purchaseData.image            = '';
        context.getDetailPr()
        context.notify('Request Successful', 'success')
      }).onError(function(error) { 
        context.notify('Request Failed', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    sendRequest(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.purchaseData.pr_code != undefined) {
        formData.append('pr_code', context.purchaseData.pr_code);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, purchaseRequisition.sendRequest(formData));
      api.onSuccess(function(response) {
        context.purchaseData = {}
        context.notify('Request Successful', 'success')
      }).onError(function(error) { 
        context.notify('Request Failed', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    defaultDate(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var today = date.getFullYear() + "-" + (month) + "-" + (day);

      this.purchaseData.date = today
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped lang="scss">
.bg {
  background-image: url('@/assets/img/tef.jpeg');
  background-size: 100%;
}
.scroll { 
  overflow: auto; 
  height: 100; 
}
</style>
