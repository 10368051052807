<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/karyawan?id_karyawan='+search.id_karyawan+'&nama='+search.nama+'&nik='+search.nik+'&jabatan='+search.jabatan+'&unit='+search.unit+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Meeting Room Schedule</h6><br>
                  <h6 style="margin-top: -18px; margin-left: 20px;">Date : 05 September 2023</h6>
                </div>
              </div>
              <div class="col-6">
              </div>
              <div class="col-2 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class=" align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xs font-weight-bolder" rowspan="2" style="border: 1pt solid #DCDCDC; width:50px;">No</th>
                      <th class="text-center text-uppercase text-white text-xs font-weight-bolder" rowspan="2" style="border: 1pt solid #DCDCDC; width:200px;">Meeting Room</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">08 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">09 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">10 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">11 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">12 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">13 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">14 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">15 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">16 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">17 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">18 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">19 : 00</th>
                      <th colspan="4" class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:110px;">20 : 00</th>
                    </tr>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC;" v-for="(d, k) in timeArrayMaster" :key="k"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" style="border: 1pt solid #DCDCDC; padding: 5px;">
                      <td class="align-middle text-center text-sm" style="border: 1pt solid #DCDCDC;">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-left text-sm" style="border: 1pt solid #DCDCDC; padding: 10px;">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.name }}</span>
                      </td>
                      <template v-for="(d, k) in timeArrayMaster" :key="k">
                        <template v-for="(tds, j) in row.meet_rom_boking" :key="j">
                          <td class="text-center" style="border: 1pt solid #DCDCDC; background-color: red;"  v-if="tds.start_hour === d" :colspan="tds.meeting_time_detail.split(',').length">
                            <span>{{tds.start_hour}} - {{tds.finish_hour}} </span>
                            <!-- <button type="button" class="btn btn-xs btn-outline-dark mt-3" data-toggle="tooltip" data-placement="bottom" title="Time : 12:00 - 13:00
PIC : Muhazir
Subject : Day 2 Final Witness Inspection Pupuk Petrokimia Gresik - D-0963
"><i class="fa fa-info-circle" aria-hidden="true"></i></button> -->
                          </td>
                        </template>
                        <td style="border: 1pt solid #DCDCDC;"></td>
                      </template>
                      
                      <!-- <td class="align-middle text-center" style="border: 1pt solid #DCDCDC; background-color: #FFB6C1;"></td> -->
                      <!-- <td class="align-middle text-center" style="border: 1pt solid #DCDCDC; background-color: #FFB6C1;"></td> -->
                     <!--  <td style="border: 1pt solid #DCDCDC;" v-for="(tds, j) in row.meet_rom_boking" :key="j" colspan="4">
                        <span>{{tds.start_hour}}</span>
                        <button type="button" class="btn btn-xs btn-outline-dark mt-3" data-toggle="tooltip" data-placement="bottom" title="Time : 12:00 - 13:00
PIC : Muhazir
Subject : Day 2 Final Witness Inspection Pupuk Petrokimia Gresik - D-0963
"><i class="fa fa-info-circle" aria-hidden="true"></i></button>
                      </td> -->
                      
                    </tr>
                  </tbody>
                </table> 


                <!-- <table class=" align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xs font-weight-bolder" rowspan="2" style="border: 1pt solid #DCDCDC; width:50px;">No</th>
                      <th class="text-center text-uppercase text-white text-xs font-weight-bolder" rowspan="2" style="border: 1pt solid #DCDCDC; width:200px;">Time</th>
                      <th class="text-center text-uppercase text-white text-xs font-weight-bolder" style="border: 1pt solid #DCDCDC; width:200px;" v-for="(row, k) in table.data" :key="k">{{ row.name }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, i) in timeArrayMaster" :key="i" style="border: 1pt solid #DCDCDC; padding: 5px;">
                      <td class="align-middle text-center text-sm" style="border: 1pt solid #DCDCDC;">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-left text-sm" style="border: 1pt solid #DCDCDC; padding: 10px;">
                        <span class="text-secondary text-xs font-weight-bold">{{ d }}</span>
                      </td>
                      <template v-for="(d, k) in timeArrayMaster" :key="k">
                        <template v-for="(tds, j) in row.meet_rom_boking" :key="j">
                          <td class="text-center" style="border: 1pt solid #DCDCDC; background-color: red;"  v-if="tds.start_hour === d" :colspan="tds.meeting_time_detail.split(',').length">
                            <span>{{tds.start_hour}} - {{tds.finish_hour}} </span> -->
                            <!-- <button type="button" class="btn btn-xs btn-outline-dark mt-3" data-toggle="tooltip" data-placement="bottom" title="Time : 12:00 - 13:00
PIC : Muhazir
Subject : Day 2 Final Witness Inspection Pupuk Petrokimia Gresik - D-0963
"><i class="fa fa-info-circle" aria-hidden="true"></i></button> -->
                          <!-- </td>
                        </template>
                          <td style="border: 1pt solid #DCDCDC;"></td>
                      </template> -->
                      
                      <!-- <td class="align-middle text-center" style="border: 1pt solid #DCDCDC; background-color: #FFB6C1;"></td> -->
                      <!-- <td class="align-middle text-center" style="border: 1pt solid #DCDCDC; background-color: #FFB6C1;"></td> -->
                     <!--  <td style="border: 1pt solid #DCDCDC;" v-for="(tds, j) in row.meet_rom_boking" :key="j" colspan="4">
                        <span>{{tds.start_hour}}</span>
                        <button type="button" class="btn btn-xs btn-outline-dark mt-3" data-toggle="tooltip" data-placement="bottom" title="Time : 12:00 - 13:00
PIC : Muhazir
Subject : Day 2 Final Witness Inspection Pupuk Petrokimia Gresik - D-0963
"><i class="fa fa-info-circle" aria-hidden="true"></i></button>
                      </td> -->
                      
                    <!-- </tr>
                  </tbody>
                </table> -->
              </div>
            </div>
            <div class="card-footer">
            </div>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import bookingRoom from '@/services/bookingRoom.service';
import plant from '@/services/plant.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
  },
  data() {
    return {
      table: {
        data: []
      },
      tablePlant: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      meetingRoomData: {},
      timeArrayMaster: [],
      search: {
        code: '',
        name: '',
        facility: '',
        description: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      dataEmptyHour: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16],

    };
  },
  mounted(){
    this.getScheduleMeeting();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    getScheduleMeeting(param){
      let context = this;               
      Api(context, bookingRoom.getScheduleMeeting({code: this.search.code, name: this.search.name, facility: this.search.facility, description: this.search.description})).onSuccess(function(response) {    
          context.table.data = response.data.data.data;
          context.timeArrayMaster = response.data.data.timeArrayMas;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getPlant(param){
      let context = this;        
      Api(context, plant.getPlant({search: param.target.value})).onSuccess(function(response) {    
          context.tablePlant.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePlant.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading = false;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.meetingRoomData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, bookingRoom.show(id)).onSuccess(function(response) {
          context.meetingRoomData       = response.data.data.data;
          context.meetingRoomData.plant = response.data.data.dataPlant
          context.form.show             = true;
          context.form.title            = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.meetingRoomData.name, context.meetingRoomData.plant, context.meetingRoomData.location != undefined && context.meetingRoomData.capacity != undefined && context.meetingRoomData.facility != undefined && context.meetingRoomData.description != undefined) {
        formData.append('name', context.meetingRoomData.name);
        formData.append('plant', JSON.stringify(context.meetingRoomData.plant));
        formData.append('location', context.meetingRoomData.location);
        formData.append('capacity', context.meetingRoomData.capacity);
        formData.append('facility', context.meetingRoomData.facility);
        formData.append('description', context.meetingRoomData.description);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, bookingRoom.create(formData));
      } else {
          api = Api(context, bookingRoom.update(this.meetingRoomData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, bookingRoom.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    filesChange(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, bookingRoom.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #32CD32;
  padding-top: 10px;
  padding-bottom: 10px;
}

tr:hover {background-color: #D3D3D3;}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>
