<template>
  <main>
    <div class="container-fluid mb-4">
      <div
        class="page-header min-height-300"
        style="
          background-image: url('https://png.pngtree.com/background/20230525/original/pngtree-person-welding-metal-while-wearing-welding-mask-picture-image_2735874.jpg');
          margin-right: -24px;
          margin-left: -34%;
        "
      >
        <span class="mask bg-gradient-dark opacity-6"></span>
      </div>
      <div class="card shadow-lg mt-n7">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative" v-if="false">
                <img
                  :src="storageUrl+'/foto_karyawan/'"
                  alt="profile_image"
                  class="shadow-sm w-100 border-radius-lg"
                />
              </div>
              <div class="avatar avatar-xl position-relative" v-else>
                <img
                    src="@/assets/img/avatar_karyawan.png"
                    alt="profile_image"
                    class="shadow-sm w-100 border-radius-lg"
                  />
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{ roleFullName }}</h5>
                <p class="mb-0 font-weight-bold text-sm">{{ position }}</p>
              </div>
            </div>
            <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
              <span class="font-weight-bold">Total Working Hour : <span class="text-danger">{{totalWorkingHour}} Hour {{totalWorkingMinutes}} Minutes</span></span><br>
              <span class="font-weight-bold" v-if="search.unit">Total Achievement  &nbsp;&nbsp;: <span class="text-danger">{{totalAchievement}} {{search.unit}}</span></span><br>
            </div>
          </div>
        </div>
        <div class="mx-auto mt-2 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mb-3">
          <argon-button color="success" size="xs" style="margin-right: 10px; margin-top: 5px;" class="mb-3" @click="create()"><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add Data</argon-button>
          <argon-button variant="gradient" color="secondary" size="xs" style="margin-right: 10px; margin-top: 5px;" @click="filter()" class="mb-3"><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <span class="mt-5 font-weight-bold">Working Hour List</span><hr>
    </div>

    <div class="container-fluid mb-7 mt-7" v-for="(row, i) in table.data" :key="i">
      <div class="card shadow-lg mt-n6">
        <div class="card-header" style="background-color: green;">
          <div class="row">
            <div class="col-11 float-left">
              <span class="mb-4 font-weight-bold text-white pb-5" style="font-size: 13px;">{{ row.project_number }} - {{ row.project_name }}</span>
            </div>
            <div class="col-1 float-right">
              <i style="cursor: pointer;" class="fa fa-times text-white" aria-hidden="true" @click="remove(row.id)" title="Delete"></i>
            </div>
          </div>
        </div>
        <div class="card-body p-3">
          <div class="row gx-4">
            <p class="mb-2 font-weight-bold" style="font-size: 12px;">Activity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp; {{ row.activity_name }}</p>
            <p class="mb-2 font-weight-bold" style="font-size: 12px;">Group &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp; {{ row.group_name }}</p>
            <p class="mb-2 font-weight-bold" style="font-size: 12px;">Start Date &nbsp;: {{ moment(row.start_working).locale('id').format('lll') }} WIB</p>
            <p class="mb-2 font-weight-bold" style="font-size: 12px;">End Date &nbsp;&nbsp; : {{ moment(row.end_working).locale('id').format('lll') }} WIB</p>
            <p class="mb-2 font-weight-bold" style="font-size: 12px;">Working Hour : <span class="text-danger">{{ row.working_hour }} Hour {{ row.working_minute }} Minute <br><span v-if="row.break_minutes != '0'">Break : {{ row.break_minutes }} Minute</span></span></p>
            <p class="mb-2 font-weight-bold" style="font-size: 12px;">Achievement : <span class="text-warning">{{ row.achievement }} {{ row.unit }} </span></p>
            <hr>
            <p class="mb-1 font-weight-bold" style="font-size: 12px;">Remarks : {{ row.remarks }}</p>
            <span class="text-secondary text-xs font-weight-bold">
              <template v-if="row.attachment">
                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">ATTACHMENT &nbsp;:&nbsp; </span> <a :href="storageUrl+'/wh_attachment/'+row.attachment" target="_BLANK" style="color: blue;">See Attachment</a></span><br>
              </template>
              <template v-else>
                <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold" style="color: blue;">NO ATTACHMENT </span></span>
              </template>
            </span>
          </div>
        </div>
      </div>
    </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <!-- <label for="example-text-input" class="form-control-label mt-3">Group <span style="color: red;">*</span></label>
        <multiselect v-model="dailyActivityData.group" :options="tableGroup.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Group" label="group_name" track-by="group_name" :preselect-first="true" @keyup="getGroup">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect> -->
        <label for="example-text-input" class="form-control-label mt-3">Project <span style="color: red;">*</span></label>
        <multiselect v-model="dailyActivityData.project" :options="tableProject.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Project" :custom-label="nameWithLang" label="project_name" track-by="project_name" :preselect-first="true" @keyup="getProject">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Activity <span style="color: red;">*</span></label>
        <multiselect v-model="dailyActivityData.activity" :options="tableActivity.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Activity" label="activity_name" track-by="activity_name" :preselect-first="true" @keyup="getActivity">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Start Working <span style="color: red;">*</span></label>
        <div class="row">
          <div class="col-6">
            <input type="date" class="form-control" placeholder="Start Working" v-model="dailyActivityData.start_working_date">
          </div>
          <div class="col-6">
            <flat-pickr :config="{ enableTime: 'true', noCalendar: 'true', dateFormat: 'H:i', time_24hr: 'true',}" class="form-control" v-model="dailyActivityData.start_working_time"/>
          </div>
        </div>
        <label for="example-text-input" class="form-control-label mt-3">End Working <span style="color: red;">*</span></label>
        <div class="row">
          <div class="col-6">
            <input type="date" class="form-control" placeholder="End Working" v-model="dailyActivityData.end_working_date">
          </div>
          <div class="col-6">
            <flat-pickr :config="{ enableTime: 'true', noCalendar: 'true', dateFormat: 'H:i', time_24hr: 'true',}" class="form-control" v-model="dailyActivityData.end_working_time"/>
          </div>
        </div>
        <label for="example-text-input" class="form-control-label mt-3">Today's Achievements <span style="color: red;">*</span></label>
        <div class="row">
          <div class="col-6">
            <select class="form-select" aria-label="Default select example" v-model="dailyActivityData.unit">
              <option value="DB">DB</option>
              <option value="CM">CM</option>
              <option value="Joint">Joint</option>
              <option value="Meter">Meter</option>
              <option value="Pcs">Pcs</option>
            </select>
          </div>
          <div class="col-6">
            <input type="number" class="form-control" placeholder="Total" v-model="dailyActivityData.achievement">
          </div>
        </div>
        <label for="example-text-input" class="form-control-label mt-3">Attachment (Optional) </label>
        <input type="file" class="form-control" placeholder="Attachment" @change="filesChange" >
        <label for="example-text-input" class="form-control-label mt-3">Remarks (Optional) </label>
        <textarea class="form-control" v-model="dailyActivityData.remarks" placeholder="Remarks"></textarea>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project</label>
        <multiselect v-model="search.project" :options="tableProject.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Project" :custom-label="nameWithLang" label="project_name" track-by="project_name" :preselect-first="true" @keyup="getProject"  v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Activity</label>
        <multiselect v-model="search.activity" :options="tableActivity.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Activity" label="activity_name" track-by="activity_name" :preselect-first="true" @keyup="getActivity"  v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <!-- <label for="example-text-input" class="form-control-label mt-3">Employee</label>
        <multiselect v-model="search.employee" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee" v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect> -->
        <label for="example-text-input" class="form-control-label mt-3">Date Range</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.date"/>
        <label for="example-text-input" class="form-control-label mt-3">Remarks</label>
        <input type="text" class="form-control" placeholder="Remarks" v-model="search.remarks" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Achievement</label>
        <select class="form-select" aria-label="Default select example" v-model="search.unit">
          <option value="DB">DB</option>
          <option value="CM">CM</option>
          <option value="Joint">Joint</option>
          <option value="Meter">Meter</option>
          <option value="Pcs">Pcs</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {project: '', activity: '', employee: '', leader: '', date: '', remarks: ''}">Clear</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL INFORMASI ======= -->
  <div class="container">
    <vue-final-modal v-model="formInfo.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formInfo.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formInfo.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <P>Sekilas info bapak - bapak yang terhormat untuk mendukung perusahaan dalam meng optimalkan data yang akurat dan bermanfaat, di system man hour di harapkan mengisi data Achievement / pencapaian dengan benar, terimakasih :)</P>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-5"> 
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="formInfo.show = false">Oke</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonButton from "@/components/ArgonButton.vue";
import config from '@/configs/config';
import Api from '@/helpers/api';
import { VueFinalModal } from 'vue-final-modal';
var moment = require('moment');
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import akun from '@/services/akun.service';
import dailyActivity from '@/services/dailyActivity.service';
import projectList from '@/services/projectList.service';
import activityList from '@/services/activityList.service';
import workingGroup from '@/services/workingGroup.service';
import workingGroupMember from '@/services/workingGroupMember.service';

const body = document.getElementsByTagName("body")[0];

export default {
  name: "profile",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      onLoading: false,
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formInfo: {
        add: true,
        title: "Info",
        show: false
      },
      dailyActivityData: {},
      table: {
        data: []
      },
      tableProject: {
        data: []
      },
      tableActivity: {
        data: []
      },
      tableGroup: {
        data: []
      },
      tableEmployee: {
        data: []
      },
      role: '',
      position: '',
      roleFullName: '',
      storageUrl : config.storageUrl,
      apiUrl :config.apiUrl,
      search: {
        project: '',
        activity: '',
        employee: '',
        leader: '',
        date: '',
        remarks: '',
        unit: '',
      },
      totalWorkingHour: '',
      totalWorkingMinutes: '',
      totalAchievement: '',
    };
  },
  mounted() {
    this.get();
    this.getRole();
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
    this.formInfo.show = true;
  },
  methods: {
    nameWithLang ({ project_number, project_name }) {
      return `${project_number} | ${project_name}`
    },
    get(param){
      let context = this;               
      Api(context, dailyActivity.getActivityByUser({project: this.search.project['project_number'], activity: this.search.activity['activity_name'], employee: this.search.employee['nik'], leader: this.search.leader, date: this.search.date, remarks: this.search.remarks, unit: this.search.unit, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page
          context.totalWorkingHour      = response.data.data.totalHour;
          context.totalWorkingMinutes   = response.data.data.totalMinutes;
          context.totalAchievement      = response.data.data.totalAchievement;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.totalAchievement = error.response.data.data.totalAchievement;
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.form.show  = false;
      })
      .call()
    },
    getRole(){
      let context = this;             
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.role         = response.data.data[0].role;
          context.position     = response.data.data[0].position_name;
          context.roleFullName = response.data.data[0].name;
          console.log(response.data.data)
      }).onError(function(error) {  
      })
      .call() 
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.dailyActivityData   = {};
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    getProject(param){
      let context = this; 
      Api(context, projectList.getProject({search: param.target.value})).onSuccess(function(response) {      
          context.tableProject.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableProject.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getActivity(param){
      let context = this; 
      Api(context, activityList.getActivity({search: param.target.value})).onSuccess(function(response) {       
          context.tableActivity.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableActivity.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getGroup(param){
      let context = this;        
      Api(context, workingGroup.getGroup({search: param.target.value})).onSuccess(function(response) {    
          context.tableGroup.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableGroup.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getGroupMember(param){
      let context = this;        
      Api(context, workingGroupMember.getGroupMember({search: context.dailyActivityData.group['code']})).onSuccess(function(response) {    
          context.tableEmployee.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableEmployee.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;  

      if (context.dailyActivityData.project != undefined && context.dailyActivityData.activity != undefined && context.dailyActivityData.start_working_date != undefined && context.dailyActivityData.start_working_time != undefined && context.dailyActivityData.end_working_date != undefined && context.dailyActivityData.end_working_time != undefined && context.dailyActivityData.unit != undefined && context.dailyActivityData.achievement != undefined) {
        formData.append('project', JSON.stringify(context.dailyActivityData.project));
        formData.append('activity', JSON.stringify(context.dailyActivityData.activity));
        formData.append('group', JSON.stringify());
        formData.append('employee', JSON.stringify());
        formData.append('start_working_date', context.dailyActivityData.start_working_date);
        formData.append('start_working_time', context.dailyActivityData.start_working_time);
        formData.append('end_working_date', context.dailyActivityData.end_working_date);
        formData.append('end_working_time', context.dailyActivityData.end_working_time);
        formData.append('attachment', context.dailyActivityData.attachment);
        formData.append('unit', context.dailyActivityData.unit);
        formData.append('achievement', context.dailyActivityData.achievement);
        formData.append('remarks', (context.dailyActivityData.remarks == undefined) ? '-' : context.dailyActivityData.remarks);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, dailyActivity.create(formData));
      } else {
          api = Api(context, dailyActivity.update(this.dailyActivityData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        // context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {
        context.onLoading = false; 
        context.get();  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;
        Api(context, dailyActivity.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    filesChange(e) {
      this.dailyActivityData.attachment = e.target.files[0];
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    
    
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}

/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  /*width: 1090px;*/
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
