<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/wh-summary-project-activity?project='+search.project['project_number']+'&employee='+search.employee['nik']+'&activity='+search.activity['activity_name']+'&plant='+search.plant['name']+'&department='+search.department['name']+'&date='+search.date+'&all_time='+search.all_time+'&group_by_employee='+search.group_by_employee+'&group_by_activity='+search.group_by_activity+'&group_by_plant='+search.group_by_plant+'&group_by_department='+search.group_by_department+''" target="_BLANK"><i class="fa fa-file-excel-o"></i> Export to Excel</a>
    <a class="btn btn-sm btn-warning" style="margin-right: 10px;" :href="apiUrl+'print-pdf/wh-summary-project-activity?project='+search.project['project_number']+'&employee='+search.employee['nik']+'&activity='+search.activity['activity_name']+'&plant='+search.plant['name']+'&department='+search.department['name']+'&date='+search.date+'&all_time='+search.all_time+'&group_by_employee='+search.group_by_employee+'&group_by_activity='+search.group_by_activity+'&group_by_plant='+search.group_by_plant+'&group_by_department='+search.group_by_department+''" target="_BLANK"><i class="fa fa-file-pdf-o"></i> Export to PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Summary Project & Activity</h6>
                </div>
              </div>
              <div class="col-6">
              </div>
              <div class="col-2 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Date</th>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Project</th>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Employee</th>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Activity</th>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Plant</th>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Department</th>
                      <th class="text-left text-uppercase text-white text-xxs font-weight-bolder">Total Working Hour</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <!-- ===== DATE ===== -->
                      <td class="align-middle text-left text-sm">
                        <!-- jika i sama dengan 0 -->
                        <div v-if="i == 0">
                          <span class="text-dark text-xs font-weight-bold" v-if="search.all_time == 'Yes'">All Time </span>
                          <span class="text-dark text-xs font-weight-bold" v-else>{{ moment(row.start_working).locale('id').format('ll') }} </span>
                        </div>
                        <!-- jika i bukan sama dengan 0 -->
                        <div v-else>
                          <!-- jika coil no sekarang dan sebelumnya sama -->
                          <div v-if="(row.project_number == table.data[i - 1].project_number && moment(row.start_working).locale('id').format('ll') == moment(table.data[i - 1].start_working).locale('id').format('ll'))">
                            <!-- jika i sama dengan 0 -->
                            <div v-if="i == 0">
                              <span class="text-dark text-xs font-weight-bold" v-if="search.all_time == 'Yes'">All Time </span>
                              <span class="text-dark text-xs font-weight-bold" v-else>{{ moment(row.start_working).locale('id').format('ll') }} </span>
                            </div>
                            <!-- jika i bukan sama dengan 0 -->
                            <div v-else>
                              
                            </div>
                          </div>
                          <!-- jika coil no sekarang dan sebelumnya tidak sama -->
                          <div v-else>
                            <span class="text-dark text-xs font-weight-bold" v-if="search.all_time == 'Yes'">All Time </span>
                            <span class="text-dark text-xs font-weight-bold" v-else>{{ moment(row.start_working).locale('id').format('ll') }} </span>
                          </div>
                        </div>
                      </td>
                      <!-- ===== PROJECT ===== -->
                      <td class="align-middle text-left text-sm">
                        <!-- jika i sama dengan 0 -->
                        <div v-if="i == 0">
                          <span class="text-dark text-xs font-weight-bold">{{ row.project_number }} - {{ row.short_name }} - {{ row.project_name }} </span>
                        </div>
                        <!-- jika i bukan sama dengan 0 -->
                        <div v-else>
                          <!-- jika coil no sekarang dan sebelumnya sama -->
                          <div v-if="(row.project_number == table.data[i - 1].project_number && moment(row.start_working).locale('id').format('ll') == moment(table.data[i - 1].start_working).locale('id').format('ll'))">
                            <!-- jika i sama dengan 0 -->
                            <div v-if="i == 0">
                              <span class="text-dark text-xs font-weight-bold">{{ row.project_number }} - {{ row.short_name }} - {{ row.project_name }} </span>
                            </div>
                            <!-- jika i bukan sama dengan 0 -->
                            <div v-else>
                              
                            </div>
                          </div>
                          <!-- jika coil no sekarang dan sebelumnya tidak sama -->
                          <div v-else>
                            <span class="text-dark text-xs font-weight-bold">{{ row.project_number }} - {{ row.short_name }} - {{ row.project_name }} </span>
                          </div>
                        </div>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold" v-if="((search.group_by_employee === 'Yes' || search.group_by_employee != '') || search.employee)">{{ row.employee_name }} </span>
                        <span class="text-dark text-xs font-weight-bold" v-else>All Employee </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold" v-if="(search.activity || search.group_by_activity != '')">{{ row.activity_name }} </span>
                        <span class="text-dark text-xs font-weight-bold" v-else>All Activity</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold" v-if="(search.plant || search.group_by_plant != '')">{{ row.plant_name }} </span>
                        <span class="text-dark text-xs font-weight-bold" v-else>All Plant </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold" v-if="search.department == ''">All Department </span>
                        <span class="text-dark text-xs font-weight-bold" v-if="search.department != ''">{{ row.department_name }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ timeConvert(row.working_minute) }} </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <span class="font-weight-bold text-danger ">TOTAL WORKING HOUR : {{ totalWorkingHour }} Hour {{ totalWorkingMinutes }} Minutes</span>
              </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project</label>
        <multiselect v-model="search.project" :options="tableProject.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Project" :custom-label="nameWithLang" label="project_name" track-by="project_name" :preselect-first="true" @keyup="getProject"  v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Employee</label>
        <multiselect v-model="search.employee" :options="tableEmployee.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Employee" label="name" track-by="name" :preselect-first="true" @keyup="getEmployee" v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Activity</label>
        <multiselect v-model="search.activity" :options="tableActivity.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Activity" label="activity_name" track-by="activity_name" :preselect-first="true" @keyup="getActivity"  v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Plant</label>
        <multiselect v-model="search.plant" :options="tablePlant.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Plant" label="name" track-by="name" :preselect-first="true" @keyup="getPlant"  v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Department</label>
        <multiselect v-model="search.department" :options="tableDepartment.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Department" label="name" track-by="name" :preselect-first="true" @keyup="getPlant"  v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Date Range</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.date"/>
        <label for="example-text-input" class="form-control-label mt-3">All Time </label>
        <select class="form-select" aria-label="Default select example" v-model="search.all_time">
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Group By Employe </label>
        <select class="form-select" aria-label="Default select example" v-model="search.group_by_employee">
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Group By Activity </label>
        <select class="form-select" aria-label="Default select example" v-model="search.group_by_activity">
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Group By Plant </label>
        <select class="form-select" aria-label="Default select example" v-model="search.group_by_plant">
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
        <!-- <label for="example-text-input" class="form-control-label mt-3">Group By Department </label>
        <select class="form-select" aria-label="Default select example" v-model="search.group_by_department">
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select> -->
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>

import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
// import ArgonPagination from "@/components/ArgonPagination.vue";
// import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import Multiselect from 'vue-multiselect'
import 'vue3-toastify/dist/index.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import BasePagination from "@/components/BasePagination";
var moment = require('moment');
import reportWorkingHour from '@/services/reportWorkingHour.service';
import projectList from '@/services/projectList.service';
import employee from '@/services/employee.service';
import activityList from '@/services/activityList.service';
import plant from '@/services/plant.service';
import department from '@/services/department.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    flatPickr,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      tableProject: {
        data: []
      },
      tableActivity: {
        data: []
      },
      tableEmployee: {
        data: []
      },
      tablePlant: {
        data: []
      },
      tableDepartment: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      reportWorkingHourData: {},
      search: {
        project: '',
        employee: '',
        activity: '',
        plant: '',
        department: '',
        date: '',
        all_time: '',
        group_by_employee: '',
        group_by_activity: '',
        group_by_plant: '',
        group_by_department: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      totalWorkingHour: '',
      totalWorkingMinutes: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    nameWithLang ({ project_number, project_name }) {
      return `${project_number} | ${project_name}`
    },
    get(param){
      let context = this;               
      Api(context, reportWorkingHour.getSummaryProjectActivity({project: this.search.project['project_number'], employee: this.search.employee['nik'], activity: this.search.activity['activity_name'], plant: this.search.plant['plant_name'], department: this.search.department['department_name'], date: this.search.date, all_time: this.search.all_time, group_by_employee: this.search.group_by_employee, group_by_activity: this.search.group_by_activity, group_by_plant: this.search.group_by_plant, group_by_department: this.search.group_by_department, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page
          context.totalWorkingHour      = response.data.data.totalHour;
          context.totalWorkingMinutes   = response.data.data.totalMinutes;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getProject(param){
      let context = this; 
      Api(context, projectList.getProject({search: param.target.value})).onSuccess(function(response) {      
          context.tableProject.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableProject.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getActivity(param){
      let context = this; 
      Api(context, activityList.getActivity({search: param.target.value})).onSuccess(function(response) {       
          context.tableActivity.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableActivity.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getEmployee(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tableEmployee.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableEmployee.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPlant(param){
      let context = this;        
      Api(context, plant.getPlant({search: param.target.value})).onSuccess(function(response) {    
          context.tablePlant.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePlant.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getDepartment(param){
      let context = this;        
      Api(context, department.getDepartment({search: param.target.value})).onSuccess(function(response) {    
          context.tableDepartment.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableDepartment.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.reportWorkingHourData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, reportWorkingHour.show(id)).onSuccess(function(response) {
          context.reportWorkingHourData          = response.data.data.data;
          context.reportWorkingHourData.project  = response.data.data.dataProject
          context.reportWorkingHourData.activity = response.data.data.dataActivity
          context.reportWorkingHourData.group    = response.data.data.dataGroup
          context.reportWorkingHourData.employee = response.data.data.dataEmployee
          context.form.show                  = true;
          context.form.title                 = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.reportWorkingHourData.project != undefined && context.reportWorkingHourData.activity != undefined && context.reportWorkingHourData.group != undefined && context.reportWorkingHourData.employee != undefined && context.reportWorkingHourData.start_working_date != undefined && context.reportWorkingHourData.start_working_time != undefined && context.reportWorkingHourData.end_working_date != undefined && context.reportWorkingHourData.end_working_time != undefined) {
        formData.append('project', JSON.stringify(context.reportWorkingHourData.project));
        formData.append('activity', JSON.stringify(context.reportWorkingHourData.activity));
        formData.append('group', JSON.stringify(context.reportWorkingHourData.group));
        formData.append('employee', JSON.stringify(context.reportWorkingHourData.employee));
        formData.append('start_working_date', context.reportWorkingHourData.start_working_date);
        formData.append('start_working_time', context.reportWorkingHourData.start_working_time);
        formData.append('end_working_date', context.reportWorkingHourData.end_working_date);
        formData.append('end_working_time', context.reportWorkingHourData.end_working_time);
        formData.append('attachment', context.reportWorkingHourData.attachment);
        formData.append('remarks', (context.reportWorkingHourData.remarks == undefined) ? '-' : context.reportWorkingHourData.remarks);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, reportWorkingHour.create(formData));
      } else {
          api = Api(context, reportWorkingHour.update(this.reportWorkingHourData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, reportWorkingHour.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    filesChange(e) {
        this.reportWorkingHourData.attachment = e.target.files[0];
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + " Hour " + rminutes + " Minute";
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
