<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/inventory-transaction?transaction_number='+search.transaction_number+'&transaction_type='+search.transaction_type+'&po_number='+search.po_number+'&delivery_order_number='+search.delivery_order_number+'&supplier='+search.supplier['name']+'&project='+search.project['project_number']+'&user='+search.user['name']+'&item_name='+search.item_name['item_name']+'&reducted_select='+search.reducted_select+'&category_reduction='+search.category_reduction+'&pic_warehouse='+search.pic_warehouse['name']+'&warehouse_location='+search.warehouse_location['name']+'&date='+search.date+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Inventory Transactions</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="createWithSubForm()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Transaction Number</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Transaction Type</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Additional Info</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Warehouse location</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">PIC Warehouse</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Total Item</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Status</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Date</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="getDetailTransaction(row.transaction_number)" style="cursor: pointer;">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <router-link :to="{ name: 'TransactionInventoryDetail', params: { type: row.transaction_type, code: row.transaction_number }}" v-if="row.transaction_type != 'Reduction'">
                          <span class="badge badge-sm bg-gradient-primary">{{ row.transaction_number }}</span>
                        </router-link>
                        <span class="badge badge-sm bg-gradient-primary" v-else>{{ row.transaction_number }}</span>
                      </td>
                      <template v-if="(formSub.show === false && formFilter.show === false)">
                        <td class="align-middle text-center text-sm">
                          <span class="text-secondary text-xs font-weight-bold">{{ row.transaction_type }}</span>
                        </td>
                        <td class="align-middle text-left text-sm">
                          <template v-if="row.transaction_type == 'Reciev New'">
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">PO NUMBER : </span> {{ row.po_number }}</span><br>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">DELIVERY ORDER NUMBER : </span> {{ row.delivery_order_number }}</span><br>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">SUPPLIER : </span> {{ row.supplier }}</span><br>
                          </template>
                          <template v-if="row.transaction_type == 'Usage / Loan' || row.transaction_type == 'Return'">
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">PROJECT : </span> {{ row.project }} - {{ row.project_name }}</span><br>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">USER : </span> {{ row.user }}</span><br>
                          </template>
                          <template v-if="row.transaction_type == 'Reduction'">
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">ITEM NAME : </span> {{ row.item_code }} - {{ row.item_name }}</span><br>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">QUANTITY : </span> {{ row.reduction_qty }}</span><br>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">REDUCED SELECT : </span> {{ row.reducted_select }}</span><br>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">REDUCTION CATEGORY : </span> {{ row.reduction_category }}</span><br>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">ATTACHMENT : </span> <a :href="storageUrl+'/attachment_inv_transaction/'+row.attachment" target="_BLANK" style="color: blue;">See Attachment</a></span><br>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">USER : </span> {{ row.user }}</span><br>
                          </template>
                          <template v-if="row.transaction_type == 'Adjust'">
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">ITEM NAME : </span> {{ row.item_code }} - {{ row.item_name }}</span><br>
                            <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">QUANTITY : </span> {{ row.reduction_qty }}</span><br>
                          </template>
                        </td>
                        <td class="align-middle text-center text-sm">
                          <span class="text-secondary text-xs font-weight-bold">{{ row.warehouse_location }}</span>
                        </td>
                        <td class="align-middle text-center text-sm">
                          <span class="text-secondary text-xs font-weight-bold">{{ row.pic_warehouse }}</span>
                        </td>
                        <td class="align-middle text-center text-sm">
                          <span class="text-secondary text-xs font-weight-bold">{{ (+row.total_item_count + +row.total_item_material_count) }} </span>
                        </td>
                        <td class="align-middle text-center text-sm">
                          <span class="badge badge-sm bg-gradient-info" v-if="row.status == 'Draf'">{{ row.status }}</span>
                          <span class="badge badge-sm bg-gradient-success" v-if="row.status == 'Completed'">{{ row.status }}</span>
                        </td>
                        <td class="align-middle text-center text-sm">
                          <span class="text-secondary text-xs font-weight-bold">{{ moment(row.date).locale('us').format('LL') }}</span>
                        </td>
                      </template>
                      <td class="align-middle text-center">
                        <i class="fa fa-check-circle-o" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="fixData(row.transaction_number)" v-if="row.status == 'Draf' && row.transaction_type == 'Reduction'"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id, row.total_item_count, row.transaction_type)" v-if="row.status == 'Draf'"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- ===========================================  MODAL ADD DATA WITH SUB FORM =============================================== -->
  <div class="container">
    <vue-final-modal v-model="formSub.show" classes="modal-container" content-class="modal-content-width" :z-index="10000" :focus-retain="false">
      <!-- header -->
      <div class="row">
        <div class="col-11 text-center">
          <span class="modal__title">{{formSub.title}} <template v-if="transactionData.transaction_type">{{ transactionData.transaction_type.toUpperCase() }} ====</template></span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formSub.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="d-flex justify-content-end">
          <a class="btn btn-sm btn-warning" style="margin-left: 0px;" :href="apiUrl+'print-pdf/delivery-order?transaction_number='+transactionData.transaction_number+''" target="_BLANK"><i class="fa fa-print fa-sm"></i> Print SJ</a> 
        </div>
        <div class="row">
          <div class="col-6">
            <label for="example-text-input" class="form-control-label mt-3">Transaction Type <span style="color: red;">*</span></label>
            <select class="form-select" aria-label="Default select example" v-model="transactionData.transaction_type" :disabled="transactionData.status == 'Completed'">
              <option selected>Transaction Type</option>
              <option value="Reciev New">Reciev New</option>
              <option value="Usage / Loan">Usage / Loan</option>
              <option value="Return">Return</option>
              <option value="Reduction">Reduction</option>
              <!-- <option value="Reduction" v-if="position == 'Manager'">Reduction</option> -->
            </select>

            <!-- TRANSACTION RECIEV NEW -->
            <template v-if="transactionData.transaction_type == 'Reciev New'">
              <label for="example-text-input" class="form-control-label mt-3">PO Number <span style="color: red;">*</span></label>
              <input type="text" class="form-control" placeholder="PO Number" v-model="transactionData.po_number" :disabled="transactionData.status == 'Completed'">
              <label for="example-text-input" class="form-control-label mt-3">Delivery Order Number <span style="color: red;">*</span></label>
              <input type="text" class="form-control" placeholder="Delivery Order Number" v-model="transactionData.delivery_order_number" :disabled="transactionData.status == 'Completed'">
              <label for="example-text-input" class="form-control-label mt-3">Supplier <span style="color: red;">*</span></label>
              <multiselect v-model="transactionData.supplier" :options="tableSupplier.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Supplier" label="name" track-by="name" :preselect-first="true" @keyup="getSupplier" :disabled="transactionData.status == 'Completed'">
                <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
              </multiselect>
            </template>
            <!-- TRANSACTION RECIEV NEW END -->

            <!-- TRANSACTION TRANSACTION USAGE / LOAN or RETURN or REDUCTION -->
            <template v-if="transactionData.transaction_type == 'Usage / Loan' || transactionData.transaction_type == 'Return' || transactionData.transaction_type == 'Reduction'">
              <label for="example-text-input" class="form-control-label mt-3">User <span style="color: red;">*</span></label>
              <multiselect v-model="transactionData.user" :options="tableUser.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Users" label="name" track-by="name" :preselect-first="true" @keyup="getUser" :disabled="transactionData.status == 'Completed'">
                <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
              </multiselect>
            </template>
            <!-- TRANSACTION TRANSACTION USAGE / LOAN or RETURN or REDUCTION END -->

            <label for="example-text-input" class="form-control-label mt-3">Date <span style="color: red;">*</span></label>
            <input type="date" class="form-control" placeholder="Date" v-model="transactionData.date" :disabled="(!transactionData.transaction_type || transactionData.status == 'Completed')">
          </div>
          <div class="col-6">
            <!-- TRANSACTION USAGE / LOAN or RETURN -->
            <template v-if="transactionData.transaction_type == 'Usage / Loan' || transactionData.transaction_type == 'Return'">
              <label for="example-text-input" class="form-control-label mt-3">Project <span style="color: red;">*</span></label>
              <multiselect v-model="transactionData.project" :options="tableProject.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Project" :custom-label="nameWithLang" label="project_name" track-by="project_name" :preselect-first="true" @keyup="getProject" :disabled="transactionData.status == 'Completed'">
                <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
              </multiselect>
            </template>
            <!-- TRANSACTION USAGE / LOAN or RETURN END -->

            <label for="example-text-input" class="form-control-label mt-3">Warehouse Location <span style="color: red;">*</span></label>
            <multiselect v-model="transactionData.warehouse_location" :options="tableWarehouseLocation.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Warehouse Location" label="name" track-by="name" :preselect-first="true" @keyup="getWarehouseLocation" :disabled="(!transactionData.transaction_type || transactionData.status == 'Completed')">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>

            <!-- HILANGKAN PIC WAREHOUSE JIKA TRANSACTION REDUCTION -->
            <template v-if="transactionData.transaction_type != 'Reduction'">
              <label for="example-text-input" class="form-control-label mt-3">PIC Warehouse <span style="color: red;">*</span></label>
              <multiselect v-model="transactionData.pic_warehouse" :options="tablePicWarehouse.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select PIC Warehouse" label="name" track-by="name" :preselect-first="true" @keyup="getPicWarehouse" :disabled="(!transactionData.transaction_type || transactionData.status == 'Completed')">
                <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
              </multiselect>
            </template>

            <!-- TRANSACTION REDUCTION -->
            <template v-if="transactionData.transaction_type == 'Reduction'">
              <label for="example-text-input" class="form-control-label mt-3">Item Name <span style="color: red;">*</span></label>
              <multiselect v-model="transactionData.item_name" :options="tableItem.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Item" label="name" track-by="name" :preselect-first="true" @keyup="getItemReduction" :custom-label="nameWithLangItem" :disabled="(!transactionData.warehouse_location || transactionData.status == 'Completed')">
                <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
              </multiselect>
              <label for="example-text-input" class="form-control-label mt-3">Select Reduction <span style="color: red;">*</span></label>
              <select class="form-select" aria-label="Default select example" v-model="transactionData.reducted_select" @click="getItemCategory, transactionData.qty = ''" :disabled="(!transactionData.item_name || itemCategory == 'Consumable' || transactionData.status == 'Completed')">
                <option selected>Select Reduction</option>
                <option value="Quantity">Quantity</option>
                <option value="Quantity On Loan">Quantity On Loan</option>
              </select>
              <label for="example-text-input" class="form-control-label mt-3" >Qty <span style="color: red;">*</span></label>
              <input type="number" class="form-control" placeholder="Qty" v-model="transactionData.qty" @click="setMaxQty" :max="qtyMax" min="0" :disabled="(!transactionData.item_name || transactionData.status == 'Completed')">
              <small style="color: red; font-size: 12px;" v-if="qtyMax">Qty Maximum {{ qtyMax }}</small><br>
              <label for="example-text-input" class="form-control-label mt-3">Reduction Category <span style="color: red;">*</span></label>
              <select class="form-select" aria-label="Default select example" v-model="transactionData.category_reduction" :disabled="transactionData.status == 'Completed'">
                <option selected>Select Reduction Category</option>
                <option value="Lost">Lost</option>
                <option value="Disposal">Disposal</option>
              </select>
              <label for="example-text-input" class="form-control-label mt-3" >Attachment <span style="color: red;">*</span></label>
              <input type="file" ref="fileupload" class="form-control" placeholder="Image" @change="filesChange" :disabled="transactionData.status == 'Completed'">
            </template>
            <!-- TRANSACTION REDUCTION END -->

          </div>
        </div>
          <!-- ===== FOR ITEM LIST ===== -->
          <div v-if="transactionData.transaction_type != 'Reduction'">
            <div class="">
              <hr style="border: 2px solid #008000;">
              <div class="row mb-2">
                <div class="col-4">
                  <div class="card-header pb-0">
                    <h6><i class="fas fa-list-ol" style="margin-right: 10px;"></i> Item List</h6>
                    <!-- <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader> -->
                  </div>
                </div>
                <div class="col-3">
                  <input type="text" class="form-control mt-3" placeholder="Input Item Code Here" v-model="qrItemCode" v-on:keyup.enter="getItemSave(), qrItemCode = ''" :disabled="transactionData.status == 'Completed'" ref="focusQr">
                </div>
                <div class="col-5 float-right">
                  <argon-button
                    style="margin-right: 10px; margin-left: 60px;"
                    class="mt-4"
                    variant="gradient"
                    color="warning"
                    size="sm"
                    :disabled="transactionData.status == 'Completed'"
                    @click="fixData(transactionData.transaction_number)"
                  ><i class="fa fa-check-circle-o" aria-hidden="true"></i> Fixed Data</argon-button>
                  <argon-button
                    class="mt-4"
                    variant="gradient"
                    color="success"
                    size="sm"
                    :disabled="transactionData.status == 'Completed'"
                    @click="createItem()"
                  ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
                </div>
              </div>
              <div class="table-responsive p-0">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <!-- <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">No</th> -->
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Image</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Code</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Description</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Category</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">UOM</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">QTY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;" v-if="transactionData.transaction_type == 'Reciev New'">Unit Price</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background: #228B22;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in dataListItem.data" :key="i">
                      <!-- <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td> -->
                      <td class="align-middle text-center text-sm">
                        <img
                          :src="storageUrl+'/image_warehouse/'+row.image"
                          class="avatar avatar-lg me-1"
                          alt="user1"
                        />
                      </td>
                      <td class="align-middle text-center text-sm">
                        <router-link :to="/items-detail/+row.code">
                          <span class="badge bg-gradient-primary">{{ row.code }}</span>
                        </router-link>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.description }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.category }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.uom_code }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-info">{{ row.qty }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" v-if="transactionData.transaction_type == 'Reciev New'">
                        <span class="text-secondary text-xs font-weight-bold"> Rp. {{ convertRp(row.unit_price) }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <!-- <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i> -->
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="removeItem(row.id)" v-if="transactionData.status === 'Draf'"></i>
                      </td>
                    </tr>
                    <template v-if="transactionData.transaction_type == 'Usage / Loan'">
                      <tr v-for="(row, j) in itemsMaterial.data" :key="j">
                        <!-- <td class="align-middle text-center text-sm" style="font-size: 13px; text-align: center;">
                        </td> -->
                        <td class="align-middle text-center text-sm" style="font-size: 13px; text-align: center;">
                        </td>
                        <td class="align-middle text-center text-sm" style="font-size: 13px; text-align: center;">
                          <input type="text" class="form-control" placeholder="Code" @change="updateItemSJ" :value="row.code" :id="row.id" :name="row.transaction_number">
                        </td>
                        <td class="align-middle text-center text-sm" style="font-size: 13px; text-align: center;">
                          <input type="text" class="form-control" placeholder="Name" @change="updateItemSJ" :value="row.name" :id="row.id" :name="row.transaction_number">
                        </td>
                        <td class="align-middle text-center text-sm" style="font-size: 13px; text-align: center;">
                          <input type="text" class="form-control" placeholder="Description" @change="updateItemSJ" :value="row.desciption" :id="row.id" :name="row.transaction_number">
                        </td>
                        <td class="align-middle text-center text-sm" style="font-size: 13px; text-align: center;">
                          <input type="text" class="form-control" placeholder="Category" @change="updateItemSJ" :value="row.category" :id="row.id" :name="row.transaction_number">
                        </td>
                        <td class="align-middle text-center text-sm" style="font-size: 13px; text-align: center;">
                          <input type="text" class="form-control" placeholder="UOM" @change="updateItemSJ" :value="row.uom" :id="row.id" :name="row.transaction_number">
                        </td>
                        <td class="align-middle text-center text-sm" style="font-size: 13px; text-align: center;">
                          <input type="text" class="form-control" placeholder="QTY" @change="updateItemSJ" :value="row.qty" :id="row.id" :name="row.transaction_number">
                        </td>
                        <td class="align-middle text-center">
                          <i class="fa fa-plus-square fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="addRow(param = 'plus', seq = j, idMtrl = row.id)" v-if="j == 0"></i>
                          <i class="fa fa-minus-square fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="addRow(param = 'minus', seq = j, idMtrl =row.id)" v-if="j != 0"></i>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="transactionData = {}">Clear</argon-button> -->
        </div>
        <div class="col-1">
          <template v-if="(transactionData.transaction_type == 'Reduction' && transactionData.status != 'Completed')">
            <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
              <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
              <span v-else>
                  <span>Save</span>
              </span>
            </argon-button>
          </template>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL ADD DATA ITEM ======= -->
  <div class="container">
    <vue-final-modal v-model="formAddItem.show" classes="modal-container" content-class="modal-content" :z-index="100000" :focus-retain="false">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formAddItem.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formAddItem.show = false, $refs.focusQr.focus()"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3" v-if="transactionData.transaction_type == 'Return'">From Warehouse Location </label>
        <multiselect v-model="itemData.from_warehouse_location" :options="tableFromWarehouseLocation.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Item" label="name" track-by="name" :preselect-first="true" @keyup="getFromWarehouseLocation" v-if="transactionData.transaction_type == 'Return'">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <small style="color: red; font-size: 12px;" v-if="transactionData.transaction_type == 'Return'">Note : Select Warehouse Location if warehouse withdrawal is different</small><br>

        <label for="example-text-input" class="form-control-label mt-3">Item Name <span style="color: red;">*</span></label>
        <multiselect v-model="itemData.item_name" :options="tableItem.data" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="Select Item" label="code" track-by="code" :preselect-first="true" @keyup="getItemName" :custom-label="nameWithLangItem" @click="itemData.qty = '' ">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>

        <label for="example-text-input" class="form-control-label mt-3">UOM </label>
        <input type="text" class="form-control" placeholder="UOM" v-model="itemData.uom_code" disabled>

        <label for="example-text-input" class="form-control-label mt-3">Qty <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Qty" v-model="itemData.qty" @click="setMaxQtyItem" :max="qtyMax" min="0" v-on:keyup.enter="save()" ref="focusQty">
        <small style="color: red; font-size: 12px;" v-if="qtyMax">Note : Qty Maximum {{ qtyMax }}</small><br>
        <label for="example-text-input" class="form-control-label " v-if="transactionData.transaction_type == 'Reciev New'">Unit Price <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Unit Price" v-model="itemData.unit_price" v-if="transactionData.transaction_type == 'Reciev New'" v-on:keyup.enter="save()">
        <small style="color: red; font-size: 12px;" v-if="transactionData.transaction_type == 'Reciev New'">Note : for currency except IDR, please convert currency first.</small><br>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="itemData = {}, qtyMax = '', tableItem.data = []">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>


   <!-- ================================================  MODAL FILTER ============================================== -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Transaction Number</label>
        <input type="text" class="form-control" placeholder="Transaction Number" v-model="search.transaction_number" v-on:keyup.enter="get()">

        <label for="example-text-input" class="form-control-label mt-3">Transaction Type</label>
        <select class="form-select" aria-label="Default select example" v-model="search.transaction_type">
          <option selected>Transaction Type</option>
          <option value="Reciev New">Reciev New</option>
          <option value="Usage / Loan">Usage / Loan</option>
          <option value="Return">Return</option>
          <option value="Reduction">Reduction</option>
          <option value="Adjust">Adjust</option>
        </select>

        <!-- TRANSACTION RECIEV NEW -->
          <template v-if="search.transaction_type == 'Reciev New'">
            <label for="example-text-input" class="form-control-label mt-3">PO Number </label>
            <input type="text" class="form-control" placeholder="PO Number" v-model="search.po_number">
            <label for="example-text-input" class="form-control-label mt-3">Delivery Order Number </label>
            <input type="text" class="form-control" placeholder="Delivery Order Number" v-model="search.delivery_order_number">
            <label for="example-text-input" class="form-control-label mt-3">Supplier </label>
            <multiselect v-model="search.supplier" :options="tableSupplier.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Supplier" label="name" track-by="name" :preselect-first="true" @keyup="getSupplier">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>
          <!-- TRANSACTION RECIEV NEW END -->

          <!-- TRANSACTION USAGE / LOAN or RETURN -->
          <template v-if="search.transaction_type == 'Usage / Loan' || search.transaction_type == 'Return'">
            <label for="example-text-input" class="form-control-label mt-3">Project </label>
            <multiselect v-model="search.project" :options="tableProject.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Project" :custom-label="nameWithLang" label="project_name" track-by="project_name" :preselect-first="true" @keyup="getProject">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>
          <!-- TRANSACTION USAGE / LOAN or RETURN END -->

          <label for="example-text-input" class="form-control-label mt-3">Warehouse Location </label>
          <multiselect v-model="search.warehouse_location" :options="tableWarehouseLocation.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Warehouse Location" label="name" track-by="name" :preselect-first="true" @keyup="getWarehouseLocation">
            <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
          </multiselect>
          
          <!-- TRANSACTION REDUCTION -->
          <template v-if="search.transaction_type == 'Reduction'">
            <label for="example-text-input" class="form-control-label mt-3">Item Name </label>
            <multiselect v-model="search.item_name" :options="tableItem.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Item" label="name" track-by="name" :preselect-first="true" @keyup="getItem" :custom-label="nameWithLangItem">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
            <label for="example-text-input" class="form-control-label mt-3">Select Reduction </label>
            <select class="form-select" aria-label="Default select example" v-model="search.reducted_select">
              <option selected>Select Reduction</option>
              <option value="Quantity">Quantity</option>
              <option value="Quantity On Loan">Quantity On Loan</option>
            </select>
            <label for="example-text-input" class="form-control-label mt-3">Reduction Category </label>
            <select class="form-select" aria-label="Default select example" v-model="search.category_reduction">
              <option selected>Select Reduction Category</option>
              <option value="Lost">Lost</option>
              <option value="Disposal">Disposal</option>
            </select>
          </template>
          <!-- TRANSACTION REDUCTION END -->

          <!-- TRANSACTION TRANSACTION USAGE / LOAN or RETURN or REDUCTION -->
          <template v-if="search.transaction_type == 'Usage / Loan' || search.transaction_type == 'Return' || search.transaction_type == 'Reduction'">
            <label for="example-text-input" class="form-control-label mt-3">User </label>
            <multiselect v-model="search.user" :options="tableUser.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Users" label="name" track-by="name" :preselect-first="true" @keyup="getUser">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>
          <!-- TRANSACTION TRANSACTION USAGE / LOAN or RETURN or REDUCTION END -->

          <!-- HILANGKAN PIC WAREHOUSE JIKA TRANSACTION REDUCTION -->
          <template v-if="search.transaction_type != 'Reduction'">
            <label for="example-text-input" class="form-control-label mt-3">PIC Warehouse </label>
            <multiselect v-model="search.pic_warehouse" :options="tablePicWarehouse.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select PIC Warehouse" label="name" track-by="name" :preselect-first="true" @keyup="getPicWarehouse">
              <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
            </multiselect>
          </template>

          <label for="example-text-input" class="form-control-label mt-3">Date </label>
          <input type="date" class="form-control" placeholder="PO Number" v-model="search.date">

      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Filter</span>
            </span>
          </argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL IMPORT ======= -->
  <div class="container">
    <vue-final-modal v-model="formImport.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImport.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImport.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChange" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import Data Users.xlsx'" style="color: blue;"> Import Data Setting Approval</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center;">Column</th>
                  <th style="font-size: 13px; text-align: center;">Error</th>
                  <th style="font-size: 13px; text-align: center;">Row</th>
                  <th style="font-size: 13px; text-align: center;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-6"> 
      </div>
      <div class="col-2" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImport.show = false">Close</argon-button>
      </div>
      <div class="col-1">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importData()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import transactionInventory from '@/services/transactionInventory.service';
var moment = require('moment');
import supplier from '@/services/supplier.service';
import warehouseLocation from '@/services/warehouseLocation.service';
import employee from '@/services/employee.service';
import projectList from '@/services/projectList.service';
import inventoryList from '@/services/inventoryList.service';
import akun from '@/services/akun.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      formSub: {
        add: true,
        title: "==== TRANSACTION DETAIL ",
        show: false
      },
      formAddItem: {
        add: true,
        title: "==== TRANSACTION DETAIL ",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      transactionData: {},
      search: {
        transaction_number: '',
        transaction_type: '',
        po_number: '',
        delivery_order_number: '',
        supplier: '',
        project: '',
        user: '',
        item_name: '',
        reducted_select: '',
        category_reduction: '',
        pic_warehouse: '',
        warehouse_location: '',
        date: '',
        status: '',
      },
      apiUrl :config.apiUrl,
      tableSupplier: {
        data: []
      },
      tableProject: {
        data: []
      },
      tableUser: {
        data: []
      },
      tableWarehouseLocation: {
        data: []
      },
      tablePicWarehouse: {
        data: []
      },
      tableItem: {
        data: []
      },
      tableFromWarehouseLocation: {
        data: []
      },
      qtyMax: '',
      itemCategory: '',
      position: '',

      // ----- Item Transaction -----
      dataTransaction: {},
      dataListItem: {
        data: []
      },
      itemData: {},
      qrItemCode: '',
      itemsMaterial: {
        data: []
      },
    };
  },
  mounted(){
    this.get();
    this.getPositionUser();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
    this.itemsMaterial.data = [1]
  },
  methods: {
    nameWithLang ({ project_number, project_name }) {
      return `${project_number} - ${project_name}`
    },
    nameWithLangItem ({ code, name }) {
      return `${code} - ${name}`
    },
    get(param){
      let context = this; 
      context.onLoading = true;

      Api(context, transactionInventory.index({transaction_number: this.search.transaction_number, transaction_type: this.search.transaction_type, po_number: this.search.po_number, delivery_order_number: this.search.delivery_order_number, supplier: this.search.supplier['name'], project: this.search.project['project_number'] , user: this.search.user['name'], item_name: this.search.item_name['item_name'], reducted_select: this.search.reducted_select, category_reduction: this.search.category_reduction, pic_warehouse: this.search.pic_warehouse['name'], warehouse_location: this.search.warehouse_location['name'], date: this.search.date, status: this.search.status, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show = false;
         context.onLoading       = false;
      })
      .call()
    },
    getDetailTransaction(trxnumber){
      let context = this;               
      Api(context, transactionInventory.transactionDetail({transaction_number: trxnumber})).onSuccess(function(response) {    
          context.transactionData.id                    = response.data.data.data.id;
          context.transactionData.transaction_number    = trxnumber;
          context.transactionData.transaction_type      = response.data.data.data.transaction_type;
          context.transactionData.status                = response.data.data.data.status;
          context.transactionData.user                  = response.data.data.data.user;
          context.transactionData.date                  = response.data.data.data.date;
          context.transactionData.po_number             = response.data.data.data.po_number;
          context.transactionData.delivery_order_number = response.data.data.data.delivery_order_number;
          context.transactionData.reducted_select       = response.data.data.data.reducted_select;
          context.transactionData.category_reduction    = response.data.data.data.reduction_category;
          context.transactionData.qty                   = response.data.data.data.reduction_qty;
          context.transactionData.date                  = response.data.data.data.date;

          context.transactionData.supplier           = response.data.data.dataSuplier;
          context.transactionData.warehouse_location = response.data.data.dataWL;
          context.transactionData.pic_warehouse      = response.data.data.dataPicWL;
          context.transactionData.project            = response.data.data.dataProject;
          context.transactionData.user               = response.data.data.dataUser;
          context.transactionData.item_name          = response.data.data.dataItem;

          context.dataListItem.data = response.data.data.dataListItem;
          context.itemsMaterial.data = response.data.data.dataListItemMaterial;

          context.formSub.add   = true;
          context.formSub.show  = true;
          context.formSub.title = "==== TRANSACTION DETAIL ";

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.transactionData = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    getSupplier(param){
      let context = this;        
      Api(context, supplier.getSupplier({search: param.target.value})).onSuccess(function(response) {    
          context.tableSupplier.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableSupplier.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getProject(param){
      let context = this; 
      Api(context, projectList.getProject({search: param.target.value})).onSuccess(function(response) {    
      // console.log(response.data.data)       
          context.tableProject.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableProject.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getUser(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tableUser.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableUser.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getWarehouseLocation(param){
      let context = this;        
      Api(context, warehouseLocation.getWarehouseLocation({search: param.target.value})).onSuccess(function(response) {    
          context.tableWarehouseLocation.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableWarehouseLocation.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPicWarehouse(param){
      let context = this;        
      Api(context, employee.getPicWarehouse({search: param.target.value})).onSuccess(function(response) {    
          context.tablePicWarehouse.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePicWarehouse.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getItemReduction(param){
      let context = this;        
      let WhLoc   = (context.itemData.from_warehouse_location == undefined) ? context.transactionData.warehouse_location['code'] : context.itemData.from_warehouse_location['code']
      let user     = (context.transactionData.user == undefined) ? '' : context.transactionData.user['nik']

      Api(context, inventoryList.getItem({item_name: param.target.value, type_transaction: context.transactionData.transaction_type, warehouse_location: WhLoc, user: user, project: ''})).onSuccess(function(response) {    
          context.tableItem.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableItem.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getItemName(param){
      let context = this;   
      let WhLoc   = (context.itemData.from_warehouse_location == undefined) ? context.transactionData.warehouse_location['code'] : context.itemData.from_warehouse_location['code']
      let user     = (context.transactionData.user == undefined) ? '' : context.transactionData.user['nik']
      let project  = (context.transactionData.project == undefined) ? '' : context.transactionData.project['project_number']

      Api(context, inventoryList.getItem({item_name: param.target.value, type_transaction: context.transactionData.transaction_type, warehouse_location: WhLoc, user: user, project: project})).onSuccess(function(response) {    
          context.tableItem.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableItem.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getFromWarehouseLocation(param){
      let context = this;        
      Api(context, warehouseLocation.getFromWarehouseLocation({search: param.target.value, item_name: '', type_transaction: context.transactionData.transaction_type, user: context.dataTransaction.user, project: context.dataTransaction.project})).onSuccess(function(response) {    
          context.tableFromWarehouseLocation.data = response.data.data;
          context.itemData.item_name = '';
          context.itemData.qty = '';
          context.tableItem.data = [];
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableFromWarehouseLocation.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPositionUser(){
      let context = this;     
      context.onLoading = true;
      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.position = response.data.data[0].position;
      }).onError(function(error) {  
      })
      .call() 
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    createWithSubForm() {
      this.formSub.add                     = true;
      this.formSub.show                    = true;
      this.formSub.title                   = "==== TRANSACTION ADD DATA ";
      this.transactionData                 = {};
      this.transactionData.reducted_select = 'Quantity',
      this.dataListItem.data               = {}
      this.defaultDate()
    },
    createItem() {
      this.formAddItem.add   = true;
      this.formAddItem.show  = true;
      this.formAddItem.title = "Add Item";
      this.itemData          = []
    },
    edit(id) {
      let context = this;               
      Api(context, transactionInventory.show(id)).onSuccess(function(response) {
          context.transactionData                    = response.data.data.data;
          context.transactionData.uom                = response.data.data.dataUom
          context.transactionData.warehouse_location = response.data.data.dataWL
          context.form.show                          = true;
          context.form.title                         = 'Edit Data';   
      })
      .call()        
    },
    filesChange(e) {
      this.transactionData.attachment = e.target.files[0];
    },
    save(){
      let api           = null;
      let context       = this;
      let formData      = new FormData();
      context.onLoading = true;

      if (context.transactionData.transaction_type != undefined && context.transactionData.warehouse_location != undefined && context.transactionData.date != undefined) {
        formData.append('transaction_type', context.transactionData.transaction_type);
        formData.append('warehouse_location', JSON.stringify(context.transactionData.warehouse_location));
        formData.append('date', context.transactionData.date);

        // Reciev New
        if (context.transactionData.transaction_type == 'Reciev New') {
          if (context.transactionData.po_number != undefined && context.transactionData.delivery_order_number != undefined && context.transactionData.supplier != undefined) {
            formData.append('po_number', context.transactionData.po_number);
            formData.append('delivery_order_number', context.transactionData.delivery_order_number);
            formData.append('supplier', JSON.stringify(context.transactionData.supplier));
          }else{
            return alert('The Red Star Field is Mandatory')
          }
        }
        // Usage / Loan Or Return
        if (context.transactionData.transaction_type == 'Usage / Loan' || context.transactionData.transaction_type == 'Return') {
          if (context.transactionData.project != undefined) {
            formData.append('project', JSON.stringify(context.transactionData.project));
          }else{
            return alert('The Red Star Field is Mandatory')
          }
        }
        // Reduction
        if (context.transactionData.transaction_type == 'Reduction') {
          if (context.transactionData.item_name != undefined && context.transactionData.qty != undefined && context.transactionData.category_reduction != undefined && context.transactionData.attachment != undefined) {
            formData.append('item_name', JSON.stringify(context.transactionData.item_name));
            formData.append('reducted_select', context.transactionData.reducted_select);
            formData.append('qty', context.transactionData.qty);
            formData.append('category_reduction', context.transactionData.category_reduction);
            formData.append('attachment', context.transactionData.attachment);
          }else{
            return alert('The Red Star Field is Mandatory')
          }
        }
        // Usage / Loan Or Return
        if (context.transactionData.transaction_type == 'Usage / Loan' || context.transactionData.transaction_type == 'Return' || context.transactionData.transaction_type == 'Reduction') {
          if (context.transactionData.user != undefined) {
            formData.append('user', JSON.stringify(context.transactionData.user));
          }else{
            return alert('The Red Star Field is Mandatory')
          }
        }
        // HILANGKAN PIC WAREHOUSE JIKA TRANSACTION REDUCTION
        if (context.transactionData.transaction_type != 'Reduction') {
          if (context.transactionData.pic_warehouse != undefined) {
            formData.append('pic_warehouse', JSON.stringify(context.transactionData.pic_warehouse));
          }
        }

      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.formSub.title == '==== TRANSACTION ADD DATA ') {
        if (!context.transactionData.transaction_number) {
          api = Api(context, transactionInventory.create(formData));
        }else{
          api = Api(context, transactionInventory.update(this.transactionData.id, formData));
        }
      } else {
          api = Api(context, transactionInventory.update(this.transactionData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.transactionData.transaction_number = response.data.data.transaction_number
        if (context.transactionData.transaction_type != 'Reduction') {
          context.saveItem();
          context.formAddItem.show = false;
        }else{
          context.formSub.show = false;
        }
        context.notify((context.formSub.title === '==== TRANSACTION ADD DATA ') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.formSub.title != '==== TRANSACTION ADD DATA ') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id, total_item, transaction_type) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        if ((transaction_type == 'Reduction') || (total_item == 0)) {
          let context = this;
          Api(context, transactionInventory.delete(id)).onSuccess(function(response) {
              context.get();
              context.notify('Data Successfully Deleted', 'success')
          }).call();
        }else{
          this.notify('Failed to delete. Please clear items first', 'error')
        }
      }
    },
    fixData(param){
      let api      = null;
      let context  = this;

      api = Api(context, transactionInventory.fixData({transaction_number: param}));
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.getDetailTransaction(context.transactionData.transaction_number)
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    setMaxQty(){
      if (this.transactionData.item_name['category'] != 'Consumable' && this.transactionData.reducted_select == 'Quantity On Loan') {
        this.qtyMax = JSON.stringify(Number(this.transactionData.item_name['total_qty_item_on_loan_count']))
      }else{
        this.qtyMax = JSON.stringify(Number(this.transactionData.item_name['total_qty_item_count']))
      }
    },
    getItemCategory(){
      alert('ok')
      // this.itemCategory = this.transactionData.item_name['category']
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    filesChangeImport(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, transactionInventory.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },

    // ========================= Transaction Item ======================
    // for qr code
    getItemSave(param){
      let context  = this;   
      let itemCode = (context.qrItemCode.slice(0, 38) == 'https://system.tef.co.id/items-detail/') ? context.qrItemCode.slice(38) : context.qrItemCode;
      let WhLoc    = (context.itemData.from_warehouse_location == undefined) ? context.transactionData.warehouse_location['code'] : context.itemData.from_warehouse_location['code']
      let user     = (context.transactionData.user == undefined) ? '' : context.transactionData.user['nik']
      let project  = (context.transactionData.project == undefined) ? '' : context.transactionData.project['project_number']

      Api(context, inventoryList.getItemSave({item_code: itemCode, type_transaction: context.transactionData.transaction_type, warehouse_location: WhLoc, user: user, project: project})).onSuccess(function(response) {    
          context.createItem()
          context.itemData.item_name = response.data.data[0];
          context.itemData.uom_code  = response.data.data[0];
          context.itemData.uom_code  = response.data.data[0];
          context.tableItem.data     = response.data.data;
          context.setMaxQtyItem()
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableItem.data = [];
          }
      }).onFinish(function() { 
          context.$refs.focusQty.focus()
      })
      .call()
    },
    setMaxQtyItem(){
      if (this.itemData.item_name) {
        this.itemData.uom_code = this.itemData.item_name['uom_code']
        
        if (this.transactionData.transaction_type == 'Usage / Loan') {
          this.qtyMax = 9999
          // this.qtyMax = (JSON.stringify(Number(this.itemData.item_name['total_qty_item_count'])) - JSON.stringify(Number(this.itemData.item_name['total_qty_item_on_loan_count'])))
        }else if(this.transactionData.transaction_type == 'Return'){
          if (this.itemData.item_name['category'] === 'Consumable') {
            this.qtyMax = 9999
          }else{
            this.qtyMax = JSON.stringify(Number(this.itemData.item_name['total_qty_item_on_loan_count']))
          }
        }else if (this.transactionData.transaction_type == 'Reduction') {
          this.qtyMax = JSON.stringify(Number(this.itemData.item_name['total_qty_item_count']))
        }
      }
    },
    saveItem(){
      let api           = null;
      let context       = this;
      let formData      = new FormData();
      context.onLoading = true;

      if (context.transactionData.transaction_type != 'Reciev New' && context.itemData.qty > context.qtyMax) {
        return context.notify('The quantity you entered exceeds the maximum quantity !!!', 'error')
      }

      if (context.itemData.item_name != undefined && context.itemData.qty != undefined) {
        formData.append('transaction_number', context.transactionData.transaction_number);
        formData.append('item_name', JSON.stringify(context.itemData.item_name));
        formData.append('qty', context.itemData.qty);
        formData.append('unit_price', context.itemData.unit_price);

        // jika return change location
        formData.append('transaction_type', context.transactionData.transaction_type);
        formData.append('project', (context.transactionData.project == undefined) ? '' : context.transactionData.project['project_number']);
        formData.append('user', (context.transactionData.user == undefined) ? '' : context.transactionData.user['nik']);
        formData.append('warehouse_location', (context.transactionData.warehouse_location == undefined) ? '' : context.transactionData.warehouse_location['code']);
        formData.append('from_warehouse_location', (JSON.stringify(context.itemData.from_warehouse_location) == undefined) ? '' : JSON.stringify(context.itemData.from_warehouse_location));
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      // if (context.formSub.title == '==== TRANSACTION ADD DATA ') {
          api = Api(context, transactionInventory.createItem(formData));
      // } else {
      //     api = Api(context, transactionInventory.update(this.itemData.id, formData));
      // }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.getDetailTransaction(context.transactionData.transaction_number)
        context.formAddItem.show = false;
        context.$refs.focusQr.focus()
        context.notify((context.formSub.title === '==== TRANSACTION ADD DATA ') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.formSub.title != '==== TRANSACTION ADD DATA ') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() { 
        context.onLoading = false;
      })
      .call();
    },
    removeItem(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context  = this;
        let formData = new FormData();

        formData.append('transaction_type', context.transactionData.transaction_type);
        formData.append('warehouse_location', context.transactionData.warehouse_location['code']);

        Api(context, transactionInventory.deleteItem(id, formData)).onSuccess(function(response) {
            context.get();
            context.getDetailTransaction(context.transactionData.transaction_number)
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },


    // ========================= End Transaction Item ======================
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    defaultDate(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var today = date.getFullYear() + "-" + (month) + "-" + (day);

      this.transactionData.date = today
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    addRow(param, seq, idMtrl) {
      if (param == 'plus') {
        this.counter++;
        this.itemsMaterial.data.push({category: "Material"});
      }else{
        this.itemsMaterial.data.splice(seq, 1)
        if (idMtrl) {
            let context = this;
            Api(context, transactionInventory.deleteMaterial(idMtrl)).onSuccess(function(response) {
                context.get();
            }).call();
        }
      }

    },
    updateItemSJ(param){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.transactionData.transaction_number != undefined) {
        formData.append('transaction_number', context.transactionData.transaction_number);
      }else{
        formData.append('transaction_number', param.target.name);
      }
      formData.append('transaction_type', context.transactionData.transaction_type);
      formData.append('project', JSON.stringify(context.transactionData.project));
      formData.append('user', JSON.stringify(context.transactionData.user));
      formData.append('warehouse_location', JSON.stringify(context.transactionData.warehouse_location));
      formData.append('pic_warehouse', JSON.stringify(context.transactionData.pic_warehouse));
      formData.append('date', context.transactionData.date);
      formData.append('sj_item_id', param.target.id);

      if (param.target.placeholder == 'Code') {
        formData.append('code', param.target.value);
      }
      if (param.target.placeholder == 'Name') {
        formData.append('name', param.target.value);
      }
      if (param.target.placeholder == 'Description') {
        formData.append('description', param.target.value);
      }
      if (param.target.placeholder == 'Category') {
        formData.append('category', param.target.value);
      }
      if (param.target.placeholder == 'UOM') {
        formData.append('uom', param.target.value);
      }
      if (param.target.placeholder == 'QTY') {
        formData.append('qty', param.target.value);
      }

      api = Api(context, transactionInventory.updateItemSJ(formData));
  
      api.onSuccess(function(response) {
        context.get();
        if (context.transactionData.transaction_number == undefined) {
          console.log(response.data)
          context.getDetailTransaction(response.data.data.transaction_number);
        }else{
          context.getDetailTransaction(context.transactionData.transaction_number);
        }
        console.log(response.data.data.transaction_number)
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() { 
      })
      .call();
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 1090px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
