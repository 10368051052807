<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'export-excel/material-status-piping?project_number='+paramProject+'&material_type='+search.material_type+'&spool_no='+search.spool_no+'&isometric_no='+search.isometric_no+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export to Excel</a>
    <argon-button color="info" size="sm" class="mb-3"  style="margin-right: 10px;" variant="gradient" @click="modalImport()"><i class="fa fa-spoon fa-sm"></i> Import Spool</argon-button>
    <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImportComponents()"><i class="fa fa-puzzle-piece fa-sm"></i> Import Component</argon-button>
    <div class="row mb-4 ">
      <div class="col-lg-8">
      </div>
      <div class="col-lg-4">
        <select class="form-select" aria-label="Default select example" @change="changeProject()" v-model="paramProject">
          <option value="C0411" >C0411</option>
          <option value="C0393" >C0393</option>
          <option value="C0392" >C0392</option>
          <option value="E0027" >E0027</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Ready For Allocation"
          :value="convertRp(totalReadyForAllocation)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-info"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Complete Spool"
          :value="convertRp(totalComplete)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Spool Is Not Complete"
          :value="convertRp(totalNotComplete)"
          iconClass="fa fa-times-circle"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Already Installed"
          :value="convertRp(totalInstalled)"
          iconClass="fa fa-tasks"
          iconBackground="bg-gradient-primary"
          directionReverse
        ></card>
      </div>
    </div>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Material Status</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
                <argon-button
                  class="mt-4"
                  style="margin-left: 10px;"
                  variant="gradient"
                  color="warning"
                  size="sm"
                  @click="checkReadyAllocationMaterial()"
                >
                  <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
                  <span v-else>
                      <span><i class="fa fa-search fa-sm" aria-hidden="true"></i> Check Material</span>
                  </span>
                </argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Project No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Material Type</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Spool No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Area</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Priority</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">STATUS</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Created By</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Created At</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" :style="(row.status == 'Complete') ? 'background-color: #00FF7F;' : (row.status == 'Installed') ? 'background-color: #FFD700;' : (row.status == 'Ready For Allocation') ? 'background-color: #00FFFF;' : '' " >
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.project_number }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.material_type }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                          <span class="badge badge-sm bg-gradient-primary" @click="getDetailComponent(row.project_number, row.material_type, row.spool_no), idSpool =row.id " style="cursor: pointer">{{ row.spool_no }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.isometric_no }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.priority }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-info" v-if="row.status == 'Ready For Allocation'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-success" v-if="row.status == 'Complete'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-danger" v-if="row.status == 'Not Complete Yet'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-primary" v-if="row.status == 'Installed'">{{ row.status }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ row.created_by }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-dark text-xs font-weight-bold">{{ moment(row.created_at).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit" v-if="row.status != 'Installed'"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)" v-if="row.status != 'Installed'"></i>
                        <i class="fa fa-check-square fa-sm" aria-hidden="true" title="Done" style="cursor: pointer; margin-right: 20px;" @click="approval(row.id, 'Installed')" v-if="row.status == 'Complete'"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Material Type</label>
        <input type="text" class="form-control" placeholder="Material Type" v-model="search.material_type" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Spool No</label>
        <input type="text" class="form-control" placeholder="Spool No" v-model="search.spool_no" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Area</label>
        <input type="text" class="form-control" placeholder="Area" v-model="search.isometric_no" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select" aria-label="Default select example" v-model="search.status" v-on:keyup.enter="get()">
          <option value="Ready For Allocation">Ready For Allocation</option>
          <option value="Complete">Complete</option>
          <option value="Not Complete Yet">Not Complete Yet</option>
          <option value="Installed">Installed</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- ===========================================  MODAL DETAIL COMPONENT =============================================== -->
  <div class="container">
    <vue-final-modal v-model="formDetailComponent.show" classes="modal-container" content-class="modal-content-width" :z-index="10000" :focus-retain="false">
      <!-- header -->
      <div class="row">
        <div class="col-11 text-center">
          <span class="modal__title">{{formDetailComponent.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formDetailComponent.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <div class="d-flex justify-content-end">
          <argon-button
            class="mt-4"
            variant="gradient"
            color="success"
            size="sm"
            @click="createComponent()"
          ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add Component</argon-button>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="modal__title" v-if="tableComponent.data[0]">PROJECT NO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; {{ tableComponent.data[0].project_number }}</span><br>
            <span class="modal__title" v-if="tableComponent.data[0]">MATERIAL TYPE &nbsp;:&nbsp; {{ tableComponent.data[0].material_type }}</span><br>
            <span class="modal__title" v-if="tableComponent.data[0]">SPOOL NO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; {{ tableComponent.data[0].spool_no }}</span>
            <nav class="mt-4">
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" :data-bs-target="'#C'+row.component_name.split(' ').join('_').split('.').join('_')" type="button" role="tab" aria-controls="nav-home" aria-selected="true" v-for="(row, i) in tableComponent.data" :key="i">{{row.component_name}}</button>
              </div>
            </nav>
            <div class="tab-content mt-4" id="nav-tabContent">
              <div class="tab-pane fade show" :id="'C'+row.component_name.split(' ').join('_').split('.').join('_')" role="tabpanel" aria-labelledby="nav-home-tab" v-for="(row, i) in tableComponent.data" :key="i">
                <div class="table-responsive p-0 scroll">
                  <table class="table table-bordered table-hover align-items-center mb-0">
                    <thead>
                      <tr style="background-color: #FA8072;">
                        <th rowspan="3" class="text-center text-uppercase text-white text-xs font-weight-bold">Stock Allocation</th>
                      </tr>
                        <tr style="background-color: #008000;">
                          <th :colspan="RelSch.qty" class="text-center text-uppercase text-white text-xs font-weight-bold" v-for="(RelSch, j) in row.rel_sch" :key="j">{{ RelSch.sch }}</th>
                        </tr>
                        <tr style="background-color: #DB7093;">
                          <th class="text-center text-uppercase text-white text-xs font-weight-bold" v-for="(RelSize, k) in row.rel_size" :key="k">{{ RelSize.size }}</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="align-middle text-left text-sm">
                          <span class="text-dark text-xs font-weight-bold">Required</span>
                        </td>
                        <td class="align-middle text-center text-sm" v-for="(RelSize, k) in row.rel_size" :key="k">
                          <span class="text-dark text-xs font-weight-bold" >{{ RelSize.required }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle text-left text-sm">
                          <span class="text-dark text-xs font-weight-bold">Allocation</span>
                        </td>
                        <td class="align-middle text-center text-sm" v-for="(RelSize, k) in row.rel_size" :key="k">
                          <span class="text-dark text-xs font-weight-bold">
                            <input type="number" :max="RelSize.required" :min="RelSize.required" style="border: 1px solid white; text-align: center;" :value="RelSize.alocation" @change="updateQtyAlocation(RelSize.id,  RelSize.qty_ok, RelSize.required, tableComponent.data[0].project_number, tableComponent.data[0].material_type, tableComponent.data[0].spool_no)">
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle text-left text-sm">
                          <span class="text-dark text-xs font-weight-bold">Usable Stock</span>
                        </td>
                        <td class="align-middle text-center text-sm" v-for="(RelSize, k) in row.rel_size" :key="k">
                          <span class="text-dark text-xs font-weight-bold">{{ RelSize.qty_ok }}</span>
                        </td>
                      </tr>
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <!-- <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="transactionData = {}">Clear</argon-button> -->
        </div>
        <div class="col-1">
          <template >
            <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()" :disabled="onLoading">
              <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
              <span v-else>
                  <span>Save</span>
              </span>
            </argon-button>
          </template>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL ADD SPOOL ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project Number <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Project Number" v-model="spoolData.project_number">
        <label for="example-text-input" class="form-control-label mt-3">Material Type <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Material Type" v-model="spoolData.material_type">
        <label for="example-text-input" class="form-control-label mt-3">Spool No <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Spool No" v-model="spoolData.spool_no">
        <label for="example-text-input" class="form-control-label mt-3">Area <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Area" v-model="spoolData.isometric_no">
        <label for="example-text-input" class="form-control-label mt-3">Priority <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Priority" v-model="spoolData.priority">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = false">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="saveSpool()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save</span>
            </span>
          </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL ADD COMPONENT ======= -->
  <div class="container">
    <vue-final-modal v-model="formAddComponent.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formAddComponent.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formAddComponent.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Component <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Component Name" v-model="componentData.component_name">
        <label for="example-text-input" class="form-control-label mt-3">Schedule <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Schedule" v-model="componentData.sch">
        <label for="example-text-input" class="form-control-label mt-3">Size <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Size" v-model="componentData.size">
        <label for="example-text-input" class="form-control-label mt-3">Required <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Required" v-model="componentData.required">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formAddComponent.show = false">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="saveComponent()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Save</span>
          </span>
        </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL IMPORT SPOOL ======= -->
  <div class="container">
    <vue-final-modal v-model="formImport.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImport.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImport.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChangeImport" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import Spool Master.xlsx'" style="color: blue;"> Import Master Spool</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">No</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Column</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Error</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Row</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ i + 1 }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-lg-6 col-4"> 
      </div>
      <div class="col-lg-2 col-3" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImport.show = false">Close</argon-button>
      </div>
      <div class="col-lg-1 col-2">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importData()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL IMPORT COMPONENT ======= -->
  <div class="container">
    <vue-final-modal v-model="formImportComponent.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImportComponent.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImportComponent.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChangeImportComponent" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import Component Spool.xlsx'" style="color: blue;"> Import Component</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">No</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Column</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Error</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Row</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ i + 1 }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-lg-6 col-4"> 
      </div>
      <div class="col-lg-2 col-3" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImportComponent.show = false">Close</argon-button>
      </div>
      <div class="col-lg-1 col-2">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importDataComponent()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>

</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
// import Multiselect from 'vue-multiselect'
// import 'vue-multiselect/dist/vue-multiselect.css';
// import flatPickr from 'vue-flatpickr-component';
// import 'flatpickr/dist/flatpickr.css';
var moment = require('moment');
import materialStatusPiping from '@/services/materialStatusPiping.service';
import vehicleList from '@/services/vehicleList.service';
import plant from '@/services/plant.service';
import employee from '@/services/employee.service';
import vehicleRequest from '@/services/vehicleRequest.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    // Multiselect,
    BasePagination,
    Card,
    // flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      idSpool: '',
      tableComponent: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formDetailComponent: {
        add: true,
        title: "==== DETAIL COMPONENT ====",
        show: false
      },
      formAddComponent: {
        add: true,
        title: "Add Component",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Master Spool",
        show: false
      },
      formImportComponent: {
        add: true,
        title: "Import Master Component",
        show: false
      },
      dataImport: '',
      dataImportComponent: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      spoolData: {},
      componentData: {},
      search: {
        material_type: '',
        spool_no: '',
        isometric_no: '',
        status: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tablePlant: {
        data: []
      },
      tablePic: {
        data: []
      },
      tablePassenger: {
        data: []
      },
      tableVehicle: {
        data: []
      },
      totalComplete: '',
      totalNotComplete: '',
      totalInstalled: '',

      tabComponent: '',
      paramProject: '',

    };
  },
  mounted(){
    this.paramProject = localStorage.getItem('paramProject');
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    changeProject(param){
      localStorage.setItem('paramProject', this.paramProject)
      location.reload();
    },
    get(param){
      let context = this;               
      Api(context, materialStatusPiping.index({project_number: this.paramProject, material_type: this.search.material_type, spool_no: this.search.spool_no, isometric_no: this.search.isometric_no, status: this.search.status, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data              = response.data.data.data.data;
          context.pagination.page_count   = response.data.data.data.last_page
          context.totalReadyForAllocation = response.data.data.totalReadyForAllocation
          context.totalComplete           = response.data.data.totalComplete
          context.totalNotComplete        = response.data.data.totalNotComplete
          context.totalInstalled          = response.data.data.totalInstalled

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getPlant(param){
      let context = this;        
      Api(context, plant.getPlant({search: param.target.value})).onSuccess(function(response) {    
          context.tablePlant.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePlant.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPic(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tablePic.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePic.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPassenger(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tablePassenger.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePassenger.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getVehicle(param){
      let context = this;        
      Api(context, vehicleList.getVehicleByPlant({plant: context.vehicleRequestData.plant, vehicle_type: context.vehicleRequestData.vehicle_type, departure_date: context.vehicleRequestData.departure_date, return_date: context.vehicleRequestData.return_date, clock_gone: context.vehicleRequestData.clock_gone, clock_back: context.vehicleRequestData.clock_back })).onSuccess(function(response) {    
          context.tableVehicle.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableVehicle.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add      = true;
      this.form.show     = true;
      this.form.title    = "Add Data";
      this.componentData = {};
    },
    createComponent() {
      this.formAddComponent.add   = true;
      this.formAddComponent.show  = true;
      this.formAddComponent.title = "Add Data";
      this.componentData          = {};
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Master Spool";
      this.tabelError.data  = [];
    },
    modalImportComponents(){
      this.formImportComponent.show  = true;
      this.formImportComponent.title = "Import Master Component";
      this.tabelError.data           = [];
    },
    filesChangeImport(e) {
        this.dataImport = e.target.files[0];
    },
    filesChangeImportComponent(e) {
        this.dataImportComponent = e.target.files[0];
    },
    importData(){
      let api        = null;
      let context    = this;
      let formData   = new FormData();
      context.onLoading = true;

      if (context.dataImport != undefined) {
        formData.append('import_data', context.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, materialStatusPiping.import(formData));
      api.onSuccess(function(response) {
          context.get();
          context.onLoading       = false;
          context.formImport.show = false;
          context.notify('Import Master Spool Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data; 
          context.onLoading       = false;             
          context.notify('Import Master Spool Failed', 'error')
      }).onFinish(function() {  
          context.onLoading = false;
      })
      .call();
    },
    importDataComponent(){
      let api        = null;
      let context    = this;
      let formData   = new FormData();
      context.onLoading = true;

      if (context.dataImportComponent != undefined) {
        formData.append('import_data', context.dataImportComponent);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, materialStatusPiping.importComponent(formData));
      api.onSuccess(function(response) {
          context.get();
          context.onLoading                = false;
          context.formImportComponent.show = false;
          context.notify('Import Master Component Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data; 
          context.onLoading       = false;             
          context.notify('Import Master Component Failed', 'error')
      }).onFinish(function() {  
          context.onLoading = false;
      })
      .call();
    },

    edit(id) {
      let context = this;               
      Api(context, materialStatusPiping.show(id)).onSuccess(function(response) {
        context.spoolData  = response.data.data.data;
        context.form.show  = true;
        context.form.title = 'Edit Data'; 
      })
      .call()        
    },
    saveSpool(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.spoolData.project_number != undefined && context.spoolData.material_type != undefined && context.spoolData.spool_no != undefined && context.spoolData.isometric_no != undefined && context.spoolData.priority != undefined) {
        formData.append('project_number', context.spoolData.project_number);
        formData.append('material_type', context.spoolData.material_type);
        formData.append('spool_no', context.spoolData.spool_no);
        formData.append('isometric_no', context.spoolData.isometric_no);
        formData.append('priority', context.spoolData.priority);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, materialStatusPiping.create(formData));
      } else {
          api = Api(context, materialStatusPiping.update(this.spoolData.id, formData));
      }
      api.onSuccess(function(response) {
        context.get();
        context.spoolData = {}
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    saveComponent(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.componentData.component_name != undefined && context.componentData.sch != undefined && context.componentData.size != undefined && context.componentData.required != undefined) {
        formData.append('id_spool', context.idSpool);
        formData.append('component_name', context.componentData.component_name);
        formData.append('sch', context.componentData.sch);
        formData.append('size', context.componentData.size);
        formData.append('required', context.componentData.required);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, materialStatusPiping.createComponent(formData));
      } else {
          api = Api(context, materialStatusPiping.update(this.componentData.id, formData));
      }
      api.onSuccess(function(response) {
        if (context.tableComponent.data[0].project_number) {
          context.getDetailComponent(context.tableComponent.data[0].project_number, context.tableComponent.data[0].material_type, context.tableComponent.data[0].spool_no)
        }else{
          location.reload();
        }
        context.get();
        context.componentData = {}
        context.formAddComponent.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, materialStatusPiping.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    getDetailComponent(project_number, material_type, spool_no){
      let context = this;               
      Api(context, materialStatusPiping.detailComponent({project_number: project_number, material_type: material_type, spool_no: spool_no})).onSuccess(function(response) { 
        context.tableComponent.data = response.data.data.dataComponent;
        context.formDetailComponent.add   = true;
        context.formDetailComponent.show  = true;
        context.formDetailComponent.title = "==== DETAIL COMPONENT ====";

        // context.componentData.spool_no = spool_no;
        context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.transactionData = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
      })
      .call()
    },
    updateQtyAlocation(id, qty_ok, required, project_number, material_type, spool_no){
      let api         = null;
      let context     = this;
      let formData    = new FormData();
      let qty         = parseFloat(event.target.value).toFixed(1)
      let requiredQty = parseFloat(required).toFixed(1)
      let qtyOk       = parseFloat(qty_ok).toFixed(1)
      console.log(qty)
      console.log(requiredQty)
      console.log(qtyOk)

      // id dan qty harus ada 
      if (id != undefined && qty != undefined) {
        // qty stock harus lebih atau sama dari required
        // if (requiredQty <= qtyOk) {
          // qty harus sama dengan required
          if (qty === requiredQty) {
            formData.append('qty', qty);
          }else if(qty == 0){
            formData.append('qty', qty);
          }else{
            context.getDetailComponent(project_number, material_type, spool_no)
            return alert('Allocation must be as required '+requiredQty)
          }
        // }else{
        //   if (qty == 0) {
        //     formData.append('qty', qty);
        //   }else{
        //     context.getDetailComponent(project_number, material_type, spool_no)
        //     return alert('Usable stock is less than required')
        //   }
        // }
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, materialStatusPiping.updateAlocation(id, formData));

      api.onSuccess(function(response) {
        context.getDetailComponent(project_number, material_type, spool_no)
        context.get()
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    approval(id, typeApprov){
      var r = confirm("Is it true that this item has been installed ?");

      if (r == true) {
        let api      = null;
        let context  = this;
        let formData = new FormData();

        formData.append('type_approval', typeApprov);

        api = Api(context, materialStatusPiping.approval(id, formData));
        api.onSuccess(function(response) {
          context.get()
          context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
        }).onError(function(error) { 
          context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
        }).onFinish(function() { 
        })
        .call();
      }
    },
    checkReadyAllocationMaterial(){
      let context = this;  
      context.onLoading = true;

      Api(context, materialStatusPiping.checkReadyAllocationMaterial({project_number: this.paramProject})).onSuccess(function(response) { 
        context.get();
        context.notify('Process Is Complete', 'success')
      }).onError(function(error) {                    
          context.notify('Process Is Complete', 'error')
      }).onFinish(function() { 
        context.onLoading = false;
      })
      .call()
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  /*background-color: #6495ED;*/
}

/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 1090px;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
