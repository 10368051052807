<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <h4 class="text-center font-weight-bold mb-3">COMMERCIAL INVOICE</h4>
              <div class="row">
                <div class="col-9">
                </div>
                <div class="col-3">
                  <a class="btn btn-sm btn-warning mb-5" style="margin-left: 130px; margin-top: -40px;" :href="apiUrl+'print-pdf/invoice-detail?invoice_number='+$route.params.invoice_number+''" target="_BLANK"><i class="fa fa-print fa-sm"></i> Print / PDF</a><br>

                  Invoice No &nbsp;:&nbsp;&nbsp; {{ invoiceData.invoice_number }} <br>
                  Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp; {{ moment(invoiceData.invoice_date).locale('en').format('ll') }}
                </div>
              </div>

<!-- =============================================== FORM INVOICE DOMESTIC ======================================================================== -->
            <template v-if="invoiceData.country_plant == 'Indonesia'">
              <div class="table-responsive p-0 scroll justify-content-center" style="margin-left: 100px; margin-right: 79px;">
                <table class="table table-hover table-bordered ">
                  <thead>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;">
                        Seller <br>
                        PT. TOKKI ENGINEERING AND FABRICATION <br>
                        JL. Jababeka XIIB, Blok W-26, Kawasan industri Jababeka I,<br>
                        Harja Mekar Cikarang Utara, Kab. Bekasi, Jawa Barat 17530 <br>
                        Attn. : Mr. Ryohichi Yunomae <br>
                        Telp. (021) 8936733 Fax : (021) 8936737 
                      </th>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;" rowspan="2">
                        Buyer <br>
                        {{ invoiceData.customer_name }} <br>
                        {{ invoiceData.address}} <br>
                        Attn : 
                        <input type="text" style="border: 1px solid white; text-align: left; size: 30px; font-size: 13px;" size="60" placeholder="Attn" v-model="invoiceData.attn" @change="updateInvoice(invoiceData.id)">
                        <select class="form-select" aria-label="Default select example" v-model="invoiceData.attn" @change="updateInvoice(invoiceData.id)">
                          <option v-for="(row, i) in tableAttn.data" :key="i" :value="row.attn" >{{row.attn}}</option>
                        </select>
                        <!-- {{ invoiceData.attention }}  -->
                        <br>
                        Telp : {{ invoiceData.phone_number }} <br> 
                        Fax : {{ invoiceData.fax }}
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;">
                        NPWP : <br> 92.010.689.5-414.000
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;">
                        From : <br> PT. Tokki Engineering and Fabriction
                      </th>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;">
                        Your PO No. :  {{ invoiceData.po_number }} <br>
                        <template v-if="invoiceData.customer_name == 'PT. Sulfindo Adi Usaha'">
                          Ditarik Atas Dasar SKBDN No : <input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="100" placeholder="SKBDN No" :value="invoiceData.transportation" @change="updateInvoice(invoiceData.id)">
                        </template>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;">
                        Transportation : <br> {{ invoiceData.delivery }}
                      </th>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;">
                        NPWP : <br> {{ invoiceData.npwp }}
                      </th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;">
                        Currency : <br> {{ invoiceData.currency }}
                      </th>
                      <th class="text-black text-xxs font-weight-bolder" style="width: 700px;">
                        Packing : <br> <input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="100" placeholder="Packing" :value="invoiceData.packing" @change="updateInvoice(invoiceData.id)">
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="table-responsive p-0 scroll justify-content-center" style="margin-left: 99px; margin-right: 79px; margin-top: -230px; margin-bottom: -290px;">
                <!-- <a class="btn btn-sm btn-warning mb-2 mt-2" style="margin-left: 0px; margin-top: 0px;" :href="apiUrl+'print-pdf/invoice-detail?invoice_number='+$route.params.invoice_number+''" target="_BLANK"><i class="fa fa-print fa-sm"></i> Add Item</a> -->
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center">Mark & Number</th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="padding-right: 99px;">Description Of Goods</th>
                      <th class="text-black text-xxs font-weight-bolder text-center">Qty</th>
                      <th class="text-black text-xxs font-weight-bolder text-center">Unit Price</th>
                      <th class="text-black text-xxs font-weight-bolder text-center">Amount</th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;"></th>
                      <th class="text-black text-xxs font-weight-bolder text-left" style="padding-right: 99px; border-bottom: 1px solid white;">
                        {{ invoiceData.product_name}}
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;">
                        {{ invoiceData.qty }} {{ invoiceData.uom }}
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;">{{ convertRp(invoiceData.currency) }}&nbsp;&nbsp; {{ convertRp(invoiceData.unit_price) }}</th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;">{{ convertRp(invoiceData.currency) }}&nbsp;&nbsp; {{ convertRp(invoiceData.total_price) }}</th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;"></th>
                      <th class="text-black text-xxs font-weight-bolder text-left" style="padding-right: 99px; border-bottom: 1px solid white;">
                        <span style="font-weight: bold font-size: 10px; border-bottom: 1px solid white;">Sub Total</span> <br>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;"></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;"></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;">{{ convertRp(invoiceData.currency) }}&nbsp;&nbsp; {{ convertRp(invoiceData.total_price) }}</th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;"></th>
                      <th class="text-black text-xxs font-weight-bolder text-left" style="padding-right: 99px; border-bottom: 1px solid white;">
                        <span style="font-weight: bold font-size: 10px;">{{ invoiceData.term_payment }} {{ invoiceData.percentage }}% x {{ convertRp(invoiceData.currency) }}&nbsp;&nbsp; {{ convertRp(invoiceData.total_price) }}</span> <br>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;"></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;"></th>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="border-bottom: 1px solid white;">{{ convertRp(invoiceData.currency) }}&nbsp;&nbsp; {{ convertRp( ((invoiceData.percentage/100).toFixed(2) * invoiceData.total_price) ) }}</th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center"></th>
                      <th class="text-black text-xxs font-weight-bolder text-left" style="padding-right: 99px;">
                        <span style="font-weight: bold font-size: 10px;">V.A.T (PPN) 11%</span> <br>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center"></th>
                      <th class="text-black text-xxs font-weight-bolder text-center"></th>
                      <th class="text-black text-xxs font-weight-bolder text-center">{{ convertRp(invoiceData.currency) }}&nbsp;&nbsp; {{ convertRp( (((invoiceData.percentage/100).toFixed(2) * invoiceData.total_price) * 0.11) ) }}</th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center"></th>
                      <th class="text-black text-xxs font-weight-bolder text-left" style="padding-right: 99px;">
                        <span style="font-weight: bold font-size: 10px;">Total</span> <br>
                      </th>
                      <th class="text-black text-xxs font-weight-bolder text-center">{{ invoiceData.qty }} {{ invoiceData.uom }}</th>
                      <th class="text-black text-xxs font-weight-bolder text-center"></th>
                      <th class="text-black text-xxs font-weight-bolder text-center">{{ convertRp(invoiceData.currency) }}&nbsp;&nbsp; {{ convertRp( ((invoiceData.percentage/100).toFixed(2) * invoiceData.total_price) + (((invoiceData.percentage/100).toFixed(2) * invoiceData.total_price) * 0.11) ) }}</th>
                    </tr>
                  </thead>
                </table>
              </div>

              <div class="table-responsive p-0 scroll justify-content-center" style="margin-left: 99px; margin-right: 79px; margin-top: -0px;">
                <table class="table table-hover ">
                  <thead>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-lft">
                        Payment {{ invoiceData.percentage }} %  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; Payment (Full Amount) shall be made as follow to <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ invoiceData.short_name }} term of payment refer to PO No :<br> 
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ invoiceData.po_number }} <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; by transfer to our Bank Account <br><br>

                        Due Date of Payment &nbsp;&nbsp;:&nbsp; {{ moment(moment(invoiceData.invoice_date).add(invoiceData.periode, 'days')).locale('en').format('ll') }}  <br><br>

                        Name of Bank &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; MUFG Bank, Ltd <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Jakarta Branch, Trinity Tower 6F-9F, <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Jl. H.R. Rasuna Said Kav.C22 <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Blok IIB, Karet Kuningan Jakarta 12940 Indonesia<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Telp (021) 2553-8300<br><br>

                        Account No. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; 5101000078 (IDR Currency) <br><br>
                      </th>
                      <th class="text-black text-center text-xxs font-weight-bolder text-lft" style="padding-right: 99px;">
                        <span style="padding-top: 100px;">PT TOKKI ENGINEERING AND FABRICATION</span><br>
                        <p style="margin-top: 180px;"></p>
                        <span><u style="size: 20px;">Ryohichi Yunomae</u></span><br>
                        President Director
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </template>
<!-- ====================================================== END FORM INVOICE DOMESTIC ============================================================================= -->

<!-- ====================================================== FORM INVOICE OVERSEAS ================================================================================ -->
            <template v-else>
              <div class="row mt-4">
                <div class="col-6" style="padding-left: 110px;">
                  To : {{ invoiceData.customer_name }} <br>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ invoiceData.address}}</p>

                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Attn &nbsp;:&nbsp; {{ invoiceData.attention }} <br><br>
                  Telp &nbsp;:&nbsp; {{ invoiceData.phone_number }} <br>
                  Fax &nbsp;&nbsp; :&nbsp; {{ invoiceData.fax }}
                </div>
                <div class="col-6">
                </div>
              </div>
              <div class="table-responsive p-0 scroll justify-content-center" style="margin-left: 99px; margin-right: 79px; margin-top: 10px;">
                <table class="table table-hover table-bordered ">
                  <thead>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="padding-right: 99px; size: 20px;">Description</th>
                      <th class="text-black text-xxs font-weight-bolder text-center">Qty</th>
                      <th class="text-black text-xxs font-weight-bolder text-center">Unit Price</th>
                      <th class="text-black text-xxs font-weight-bolder text-center">Amount</th>
                    </tr>
                    <tr>
                      <th class="text-black text-xxs font-weight-bolder text-center" style="padding-right: 99px;">{{ invoiceData.product_name}}</th>
                      <th class="text-black text-xxs font-weight-bolder text-center">{{ invoiceData.qty }} {{ invoiceData.uom }}</th>
                      <th class="text-black text-xxs font-weight-bolder text-center">{{ convertRp(invoiceData.unit_price) }}</th>
                      <th class="text-black text-xxs font-weight-bolder text-center">{{ convertRp( ((invoiceData.percentage/100).toFixed(2) * invoiceData.total_price) ) }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <p style="padding-left: 110px; margin-top: -490px;">
                Price &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; 
                  <span v-if="invoiceData.currency === 'JPY'"> <b>Japanese Yen</b> ({{ invoiceData.delivery }})</span>
                  <span v-else> {{ invoiceData.currency }} ({{ invoiceData.delivery }})</span> <br>
                Payment &nbsp;&nbsp;:&nbsp; T.T Remittance to our bank account <br>
                Note  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp; For {{ invoiceData.term_payment }} ({{ invoiceData.percentage }} %) of Purchase Order No.: <b> {{ invoiceData.po_number }} </b>
              </p>
              <div class="mb-3" style="padding-left: 110px;">
                <table border="1" >
                  <tbody>
                    <tr>
                      <td style="padding: 10px; size: 10px;">
                        <small>
                          <u> Our Bank Details : </u> <br>
                          MUFG Bank, Ltd <br>
                          Jakarta Branch, Trinity Tower 6F-9F <br>
                          Jl. H. R. Rasuna Said Kav. C22, <br>
                          Blok II B, Karet Kuningan Jakarta 12940 Indonesia <br>
                          Beneficiary Account : 5201000037 <br>
                          Swift Code : BOTKIDJX <br>
                          Beneficiary's name : PT. Tokki Engineering And Fabrication
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
<!-- ====================================================== END FORM INVOICE OVERSEAS ================================================================================ -->

            <div class="row" style="margin-top: -0px;">
              <div class="col-lg-8">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add Document</argon-button>
                <a class="btn btn-sm btn-warning " style="margin-left: 20px; margin-top: 38px;" :href="apiUrl+'print-pdf/invoice-doc-merge?invoice_number='+$route.params.invoice_number+''" target="_BLANK"><i class="fa fa-print fa-sm"></i> Print / PDF Doc</a>
              </div>
            </div>
            <div class="container card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Document Name</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Sequence</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Created At</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Created By</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in tableDoc.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          <a :href="storageUrl+'document_invoice/'+row.document_name" target="_BLANK" style="color: blue; size: 5px;">{{ row.document_name }}</a>
                        </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.sequence }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.created_at).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.created_by }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" title="Delete" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Document Name <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="invoiceDoc.filename" :disabled="form.title != 'Add Data'">
          <option value="PO">PO</option>
          <option value="Faktur Pajak">Faktur Pajak</option>
          <option value="WCN">WCN</option>
          <option value="Delivery Order">Delivery Order</option>
          <option value="Drawing">Drawing</option>
          <option value="Other">Other</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Document <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Document" @change="filesChange" accept="application/pdf" :disabled="form.title != 'Add Data'">
        <label for="example-text-input" class="form-control-label mt-3">Sequence <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Sequence" v-model="invoiceDoc.sequence">
        
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="save()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Save</span>
          </span>
        </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
// import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import salesInvoice from '@/services/salesInvoice.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    // Card,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      tableDoc: {
        data: []
      },
      tableAttn: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      invoiceData: {},
      invoiceDoc: {},
      search: {
        project_number: '',
        po_number: '',
        customer_name: '',
        end_user_name: '',
        product_name: '',
        main_material: '',
        material_category: '',
        po_received_date: '',
        delivery_date: '',
        currency: '',
        term_of_payment: '',
        vat: '',
        quotation_number: '',
        code_regulation: '',
        type_equipment: '',
        work_location: '',
        plant: '',
        country_plant: '',
        sales_pic: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tablePlant: {
        data: []
      },
      tablePic: {
        data: []
      },
      tablePoList: {
        data: []
      },
      totalPending: '',
      totalApproved: '',
      totalNotApproved: '',
      totalOnDuty: '',
      totalDone: '',
      totalRequest: '',

    };
  },
  mounted(){
    this.getDetailInvoice();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    getDetailInvoice(param){
      let context = this;               
      Api(context, salesInvoice.detailInvoice({invoice_number: context.$route.params.invoice_number})).onSuccess(function(response) {    
          context.invoiceData   = response.data.data.data;
          context.tableDoc.data = response.data.data.document;
          context.tableAttn.data = response.data.data.dataAttn;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableDoc.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    updateInvoice(id){
      let api         = null;
      let context     = this;
      let formData    = new FormData();

      if (event.target.placeholder == 'SKBDN No') {
        formData.append('transportation', event.target.value);
      }
      else if (event.target.placeholder == 'Packing') {
        formData.append('packing', event.target.value);
      }
      else {
        formData.append('attn', event.target.value);
      }

      api = Api(context, salesInvoice.updateInvoice(id, formData));

      api.onSuccess(function(response) {
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        // context.getMaterial();
        // context.get();
      })
      .call();
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add           = true;
      this.form.show          = true;
      this.form.title         = "Add Data";
      this.invoiceDoc = {};
    },
    edit(id) {
      let context = this;               
      Api(context, salesInvoice.showDoc(id)).onSuccess(function(response) {
        context.invoiceDoc = response.data.data.data;
        context.form.show  = true;
        context.form.title = 'Edit Document'; 
      })
      .call()        
    },
    filesChange(e) {
      this.invoiceDoc.document_name = e.target.files[0];
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.invoiceDoc.filename != undefined && context.invoiceDoc.document_name != undefined && context.invoiceDoc.sequence != undefined) {
        formData.append('invoice_number', context.$route.params.invoice_number);
        formData.append('filename', context.invoiceDoc.filename);
        formData.append('document_name', context.invoiceDoc.document_name);
        formData.append('sequence', context.invoiceDoc.sequence);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
        api = Api(context, salesInvoice.createDoc(formData));
      }else {
        api = Api(context, salesInvoice.updateDoc(this.invoiceDoc.id, formData));
      }
      api.onSuccess(function(response) {
        context.getDetailInvoice();
        context.invoiceDoc = {}
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, salesInvoice.deleteDoc(id)).onSuccess(function(response) {
            context.getDetailInvoice();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: ;
}

table.table-bordered{
    border:1px solid black;
    /*margin-top:20px;*/
  }
table.table-bordered > thead > tr > th{
    border:1px solid black;
}
table.table-bordered > tbody > tr > td{
    border:1px solid black;
}

</style>
