<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/karyawan?id_karyawan='+search.id_karyawan+'&nama='+search.nama+'&nik='+search.nik+'&jabatan='+search.jabatan+'&unit='+search.unit+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <!-- <argon-button color="info" size="sm" class="mb-3" variant="gradient" style="margin-right: 10px;"><i class="fa fa-download fa-sm"></i> Export</argon-button> -->
    <!-- <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button> -->
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Total Asset"
          :value="convertRp(totalAsset)"
          iconClass="fa fa-database"
          iconBackground="bg-gradient-primary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Total Maintenance"
          :value="convertRp(totalMaintenance)"
          iconClass="fa fa-object-group"
          iconBackground="bg-gradient-secondary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Total RFSPEP"
          :value="convertRp(totalPsppe)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-info"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <card
          title="Total Spare Part"
          :value="convertRp(totalSparepart)"
          iconClass="fa fa-cubes"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-lg-7">
        <!-- CHART BAR DATA -->
        <div class="card mb-3"> 
          <div class="card-header pb-0 text-center align-content-center justify-content-center">
            <div class="row">
              <div class="col-10">
                <h5 class="text-center">Summary of Maintenance Requests <span style="size:90px; color: red;">{{ yearBarChart }} </span></h5>
              </div>
              <div class="col-2">
                <select class="form-select" aria-label="Default select example" @change="changeBarChart()" v-model="yearBarChart">
                  <option value="2026" >2026</option>
                  <option value="2025" >2025</option>
                  <option value="2024" >2024</option>
                  <option value="2023" >2023</option>
                  <option value="2022" >2022</option>
                  <option value="2021" >2021</option>
                  <option value="2019" >2019</option>
                  <option value="2018" >2018</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body mt-4">
            <div class="pt-3 chart">
              <canvas id="chart-cons-week" class="chart-canvas" height="250"></canvas>
            </div>
          </div>
        </div>

        <!-- FINISHED MAINTENANCE -->
        <div class="card"> 
          <div class="row">
            <div class="col-7">
              <div class="card-header pb-0">
                <h6>Finished Maintenance</h6>
                <p class="mt-n2">{{ moment(searchMonitoring.periode).format('MMMM YYYY') }}</p>
              </div>
            </div>
            <div class="col-4 float-right">
              <argon-button
                class="mt-3"
                variant="gradient"
                color="secondary"
                size="sm"
                @click="filter()"
              ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table table-sm table-bordered table-hover align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C">Category</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #32CD32;">Today</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #32CD32;">Week <br> 7 Days</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #32CD32;">Month <br> 30 Days</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="align-middle text-center" style="background-color: #FAF0E6;">
                      <span class="text-secondary text-xs font-weight-bold">Preventive</span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ dayPrev }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ weekPrev }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ monthPrev }} </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center" style="background-color: #FAF0E6;">
                      <span class="text-secondary text-xs font-weight-bold">Breakdown</span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ dayBreak }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ weekBreak }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ monthBreak }} </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center" style="background-color: #FAF0E6;">
                      <span class="text-secondary text-xs font-weight-bold">Corrective</span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ dayCorek }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ weekCorek }} </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"> {{ monthCorek }} </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>
      <!-- CRART PIE -->
      <div class="col-lg-5">
        <div class="card-header pb-0 text-center align-content-center justify-content-center">
          <p class="text-right font-weight-bold">Percentage Maintenance Completed <br><span style="size:90px; color: red;"> 
          {{ moment(searchMonitoring.periode).format('MMMM YYYY') }} </span></p>
          <Pie
            id="my-chart-id"
            :options="chartOptions"
            :data="chartData"
            :height="50" 
          />
        </div>
        <div class="card-footer">
        </div>
      </div>
    </div>

    <!-- MONITORING MACHINE -->
    <div class="row">
      <div class="col-12">
        <div class="card mt-0"> 
          <div class="row">
            <div class="col-4">
              <div class="card-header pb-0">
                <h6>MACHINE MONITORING</h6>
                <p class="mt-n2">Periode : {{ moment(searchMonitoring.periode).format('MMMM YYYY') }}</p>
              </div>
            </div>
            <div class="col-2">
            </div>
            <div class="col-6 float-right">
              <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-3"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-3"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="createPeriode()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Create Periode</argon-button>
              <a class="btn btn-sm btn-warning" style="margin-left: 10px; margin-top: 30px;" :href="apiUrl+'print-pdf/machine-monitoring?periode='+searchMonitoring.periode+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a>
            </div>
          </div>
          
          <div class="card-body px-0 pt-0 pb-2 mt-4">
            <div class="table-responsive p-0 scroll">
              <table class="table table-sm table-bordered table-hover align-items-center mb-0">
                <thead>
                  <tr>
                    <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #CD5C5C">DATE</th>
                    <th colspan="4" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">LINE 114</th>
                    <th colspan="4" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #8FBC8F;">LINE 60</th>
                    <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #DAA520">HYDROTEST</th>
                    <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #DAA520">SLITTING</th>
                    <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #DAA520">SHEARING</th>
                    <th colspan="7" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #20B2AA;">HOIST CRANE</th>
                    <th colspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #9370DB;">COMPRESSOR</th>
                    <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #FFA500">GARDU LISTRIK</th>
                    <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">DOWN TIME</th>
                    <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: red;">TROUBLE SHORT</th>
                    <th rowspan="2" class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #1E90FF;">REMARK</th>
                  </tr>
                  <tr>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">TUBE MILL</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">H.E</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">H.F</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #6495ED;">End Fcr</th>

                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #8FBC8F;">TUBE MILL</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #8FBC8F;">H.E</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #8FBC8F;">H.F</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #8FBC8F;">End Fcr</th>

                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #20B2AA;">1</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #20B2AA;">2</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #20B2AA;">3</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #20B2AA;">4</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #20B2AA;">5</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #20B2AA;">6</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #20B2AA;">7</th>

                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #9370DB;">SCR30M</th>
                    <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" style="background-color: #9370DB;">XK06-010</th>
                  </tr>

                </thead>
                <tbody>
                  <tr v-for="(row, i) in tableMonitoring.data" :key="i" >
                    <td class="align-middle text-center" style="background-color: #FAF0E6;">
                      <span class="text-secondary text-xs font-weight-bold">{{ moment(row.date).format('D') }}</span>
                    </td>
                    <td class="align-middle text-center text-xs" style="background-color: yellow;">
                        <input type="text" style="border: 1px solid yellow; text-align: center; background-color:yellow; font-weight: bold;" size="9" :value="row.tm_114" placeholder="TM 114" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.he_114 == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.he_114 == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.he_114" placeholder="HE 114" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.hf_114 == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.hf_114 == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.hf_114" placeholder="HF 114" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.end_fcr_114 == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.end_fcr_114 == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.end_fcr_114" placeholder="End Fcr 114" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" style="background-color: yellow;">
                        <input type="text" style="border: 1px solid yellow; text-align: center; background-color:yellow; font-weight: bold;" size="9" :value="row.tm_60" placeholder="TM 60" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.he_60 == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.he_60 == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.he_60" placeholder="HE 60" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.hf_60 == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.hf_60 == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.hf_60" placeholder="HF 60" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.end_fcr_60 == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.end_fcr_60 == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.end_fcr_60" placeholder="End Fcr 60" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.hydrotest == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.hydrotest == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.hydrotest" placeholder="Hydrotest" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.slitting == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.slitting == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.slitting" placeholder="Slitting" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.shearing == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.shearing == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.shearing" placeholder="Shearing" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.crane_satu == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.crane_satu == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.crane_satu" placeholder="Crane 1" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.crane_dua == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.crane_dua == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.crane_dua" placeholder="Crane 2" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.crane_tiga == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.crane_tiga == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.crane_tiga" placeholder="Crane 3" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.crane_empat == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.crane_empat == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.crane_empat" placeholder="Crane 4" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.crane_lima == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.crane_lima == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.crane_lima" placeholder="Crane 5" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.crane_enam == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.crane_enam == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.crane_enam" placeholder="Crane 6" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.crane_tujuh == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.crane_tujuh == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.crane_tujuh" placeholder="Crane 7" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.compres_scr == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.compres_scr == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.compres_scr" placeholder="Compressor SCR" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.compres_xk == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.compres_xk == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="5" :value="row.compres_xk" placeholder="Compressor XK" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" :style="(row.gardu_listrik == 'OFF') ? 'background-color: red;' : 'background-color: green;' ">
                        <input type="text" :style="(row.gardu_listrik == 'OFF') ? 'border: 1px solid red; text-align: center; background-color:red; font-weight: bold; color: white;' : 'border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;' " size="7" :value="row.gardu_listrik" placeholder="Gardu Listrik" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" style="background-color: green;">
                        <input type="text" style="border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;" size="5" :value="row.down_time" placeholder="Down Time" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" style="background-color: green;">
                        <input type="text" style="border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;" size="50" :value="row.trouble_short" placeholder="Trouble Short" @change="updateMonitoring(row.id)">
                    </td>
                    <td class="align-middle text-center text-xs" style="background-color: green;">
                        <input type="text" style="border: 1px solid green; text-align: center; background-color:green; font-weight: bold; color: white;" size="50" :value="row.remark" placeholder="Remark" @change="updateMonitoring(row.id)">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <!-- CHART Line DATA -->
        <!-- <div class="card mb-3"> 
          <div class="card-header pb-0 text-center align-content-center justify-content-center">
            <div class="row">
              <div class="col-12">
                <h5 class="text-center">MAINTENANCE REPORT GRAPH </h5>
              </div>
            </div>
          </div>
          <div class="card-body mt-4 float-right">
            <div class="pt-3 chart">
              <canvas id="chart-line" class="chart-canvas" height="300"></canvas>
            </div>
          </div>
        </div> -->

          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Assets That Need Maintenance</h6> <h4>{{ tableMaintenance.data.length }} Maintenance Order</h4>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Maintenance No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Problem</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Image Asset</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Machine / Asset</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Asset Location</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Target</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Requested By</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Requested Date</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Attachment</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Notes</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Status</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in tableMaintenance.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-danger" v-if="row.maintenance_type == 'User Request' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-info" v-if="row.maintenance_type == 'Preventive' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-warning" v-if="row.maintenance_type == 'Scheduled' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-primary" v-if="row.maintenance_type == 'Predictive' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-success" v-if="row.maintenance_type == 'Corrective' ">{{ row.request_no }}</span>
                        <span class="badge bg-gradient-secondary" v-if="row.maintenance_type == 'Breakdown' ">{{ row.request_no }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.problem }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <img
                          :src="storageUrl+'/image_maintenance_aset/'+row.image_machine"
                          class="avatar avatar-lg me-1"
                        />
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.machine_code }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.asset_location }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.due_date).locale('id').format('LLL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.requested_by }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.requested_date).locale('id').format('LLL') }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">
                          <a :href="storageUrl+'image_maintenance_list/'+row.image" target="_BLANK" style="color: blue; size: 5px;">{{ row.image }}</a>
                        </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.notes }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-danger" v-if="row.status == 'Open' ">Open</span>
                        <span class="badge bg-gradient-info" v-if="row.status == 'In Progress' ">In Progress</span>
                        <span class="badge bg-gradient-warning" v-if="row.status == 'Pending' ">Pending</span>
                        <span class="badge bg-gradient-success" v-if="row.status == 'Closed' ">Closed</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fa fa-check" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
            </div>
          </div>

      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Cause Analysis <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Cause Analysis" v-model="maintenanceListData.cause_analysis">
        <label for="example-text-input" class="form-control-label mt-3">Maintenance Plan <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Maintenance Plan" v-model="maintenanceListData.maintenance_plan">
        <label for="example-text-input" class="form-control-label mt-3">Target <span style="color: red;">*</span></label>
        <input type="datetime-local" class="form-control" placeholder="Target" v-model="maintenanceListData.due_date">
        <label for="example-text-input" class="form-control-label mt-3">Closed Date <span style="color: red;">*</span></label>
        <input type="datetime-local" class="form-control" placeholder="Closed Date" v-model="maintenanceListData.closed_date">
        <label for="example-text-input" class="form-control-label mt-3">Improvement Results <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Improvement Results" v-model="maintenanceListData.improvement_results">
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Done </span>
            </span>
          </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL CREATE PERIODE ======= -->
  <div class="container">
    <vue-final-modal v-model="formPeriode.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formPeriode.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formPeriode.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Create Periode <span style="color: red;">*</span></label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="monitoringData.create_periode"/>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formPeriode.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="saveMonitoring()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Done </span>
            </span>
          </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Periode</label>
        <flat-pickr class="form-control" v-model="searchMonitoring.periode" v-on:keyup.enter="getMonitoring(), getCard()"/>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="searchMonitoring = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="getMonitoring(), getCard()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
var moment = require('moment');
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import Chart from "chart.js/auto";
ChartJS.register(ArcElement, Tooltip, Legend)
import { Pie } from 'vue-chartjs'
import dashboardMaintenance from '@/services/dashboardMaintenance.service';
import maintenanceList from '@/services/maintenanceList.service';
import monitoringMachine from '@/services/monitoringMachine.service';

export default {
  name: "tables",
  components: {
    Card,
    ArgonButton,
    VueFinalModal,
    flatPickr,
    Pie,
  },
  data() {
    return {
      moment:moment,
      tableMaintenance: {
        data: []
      },
      tableMonitoring: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formPeriode: {
        add: true,
        title: "Create Periode",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      backgroundRed: null,
      onLoading: false,
      storageUrl : config.storageUrl,
      maintenanceListData: {},
      monitoringData: {},
      requestedDate:'',
      search: {
        code: '',
        name: '',
        address: '',
        industrial_type: '',
      },
      searchMonitoring: {
        periode: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      totalAsset: '',
      totalMaintenance: '',
      totalPsppe: '',
      totalSparepart: '',

      dayPrev: '',
      dayBreak: '',
      dayCorek: '',
      weekPrev: '',
      weekBreak: '',
      weekCorek: '',
      monthPrev: '',
      monthBreak: '',
      monthCorek: '',

      percentageBreakdown: '',
      percentagePreventive: '',
      percentageCorecive: '',

      yearBarChart: '',
      dateMtc: '',
      mtcBrk: [],
      mtcPrev: [],
      mtcCor: [],

      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            labelTwo: '',
            backgroundColor: '',
            data: []
          }, 
        ],
      },

    };
  },
  mounted(){
    this.yearBarChart = localStorage.getItem('yearBarChart');
    this.defaultDate();
    this.getCard();
    this.getChart();
    this.getAssetMaintenance();
    this.getMonitoring();
    this.tokenApi     = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    getCard(param){
      let context = this;               
      Api(context, dashboardMaintenance.getCard({periode: this.searchMonitoring.periode})).onSuccess(function(response) {    
          context.totalAsset       = response.data.data.totalAsset;
          context.totalMaintenance = response.data.data.totalMaintenance;
          context.totalPsppe       = response.data.data.totalPsppe;
          context.totalSparepart   = response.data.data.totalSparepart;

          context.dayPrev  = response.data.data.dayPrev;
          context.dayBreak = response.data.data.dayBreak;
          context.dayCorek = response.data.data.dayCorek;

          context.weekPrev  = response.data.data.weekPrev;
          context.weekBreak = response.data.data.weekBreak;
          context.weekCorek = response.data.data.weekCorek;

          context.monthPrev  = response.data.data.monthPrev;
          context.monthBreak = response.data.data.monthBreak;
          context.monthCorek = response.data.data.monthCorek;

          context.percentageBreakdown  = response.data.data.percentageBreakdown;
          context.percentagePreventive = response.data.data.percentagePreventive;
          context.percentageCorecive   = response.data.data.percentageCorecive;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.totalAsset = '';
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
          context.pieChart();
         context.formFilter.show  = false;
      })
      .call()
    },
    changeBarChart(param){
      localStorage.setItem('yearBarChart', this.yearBarChart)
      location.reload();
    },
    getChart(param){
      let context = this;               
      Api(context, dashboardMaintenance.getChart({year: context.yearBarChart})).onSuccess(function(response) {    
          context.dateMtc = response.data.data.dateMtc
          context.mtcBrk  = response.data.data.mtcBrk
          context.mtcPrev = response.data.data.mtcPrev
          context.mtcCor  = response.data.data.mtcCor
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              // context.totalAsset = '';
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.getChartBar();
         // context.getChartLine();
      })
      .call()
    },
    getAssetMaintenance(param){
      let context = this;               
      Api(context, dashboardMaintenance.getAssetMaintenance()).onSuccess(function(response) {    
          context.tableMaintenance.data       = response.data.data.data.data;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.totalAsset = '';
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getMonitoring(param){
      let context = this;               
      Api(context, monitoringMachine.index({periode: this.searchMonitoring.periode})).onSuccess(function(response) {    
          context.tableMonitoring.data = response.data.data.data;

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableMonitoring.data = '';
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading = false;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.customerData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, maintenanceList.show(id)).onSuccess(function(response) {
        context.maintenanceListData              = response.data.data.data;
        context.maintenanceListData.machine_code = response.data.data.dataMachine[0];
        context.form.show                        = true;
        context.form.title                       = 'Edit Data';   

        context.requestedDate = response.data.data.data.requested_date
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.maintenanceListData.cause_analysis != undefined && context.maintenanceListData.maintenance_plan != undefined && context.maintenanceListData.due_date != undefined && context.maintenanceListData.closed_date != undefined && context.maintenanceListData.improvement_results != undefined) {
        formData.append('machine_code', JSON.stringify(context.maintenanceListData.machine_code));
        formData.append('problem', context.maintenanceListData.problem);
        formData.append('cause_analysis', context.maintenanceListData.cause_analysis);
        formData.append('maintenance_plan', context.maintenanceListData.maintenance_plan);
        formData.append('due_date', context.maintenanceListData.due_date);
        formData.append('closed_date', context.maintenanceListData.closed_date);
        formData.append('improvement_results', context.maintenanceListData.improvement_results);
        formData.append('status', 'Closed');
        formData.append('notes', (context.maintenanceListData.notes == undefined) ? '' : context.maintenanceListData.notes);
        formData.append('lead_time', context.leadTime(context.requestedDate, context.maintenanceListData.closed_date));
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, maintenanceList.create(formData));
      } else {
          api = Api(context, maintenanceList.update(this.maintenanceListData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getAssetMaintenance();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    leadTime(startDate, endDate){
      var start = moment(startDate, "YYYY-MM-DD H:i:s");
      var end   = moment(endDate, "YYYY-MM-DD H:i:s");
      return moment.duration(end.diff(start)).asHours();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, dashboardMaintenance.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    // ============================== FOR MONITORING MACHINE ============================================
    createPeriode() {
      this.formPeriode.add   = true;
      this.formPeriode.show  = true;
      this.formPeriode.title = "Create Periode";
      this.monitoringData    = {};
    },
    saveMonitoring(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.monitoringData.create_periode != undefined) {
        formData.append('create_periode', context.monitoringData.create_periode);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, monitoringMachine.create(formData));

      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.getMonitoring();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    updateMonitoring(id){
      let api         = null;
      let context     = this;
      let formData    = new FormData();

      if (event.target.placeholder == 'TM 114') {
        formData.append('tm_114', event.target.value);
      }
      if (event.target.placeholder == 'HE 114') {
        formData.append('he_114', event.target.value);
      }
      if (event.target.placeholder == 'HF 114') {
        formData.append('hf_114', event.target.value);
      }
      if (event.target.placeholder == 'End Fcr 114') {
        formData.append('end_fcr_114', event.target.value);
      }
      if (event.target.placeholder == 'TM 60') {
        formData.append('tm_60', event.target.value);
      }
      if (event.target.placeholder == 'HE 60') {
        formData.append('he_60', event.target.value);
      }
      if (event.target.placeholder == 'HF 60') {
        formData.append('hf_60', event.target.value);
      }
      if (event.target.placeholder == 'End Fcr 60') {
        formData.append('end_fcr_60', event.target.value);
      }
      if (event.target.placeholder == 'Hydrotest') {
        formData.append('hydrotest', event.target.value);
      }
      if (event.target.placeholder == 'Slitting') {
        formData.append('slitting', event.target.value);
      }
      if (event.target.placeholder == 'Shearing') {
        formData.append('shearing', event.target.value);
      }
      if (event.target.placeholder == 'Crane 1') {
        formData.append('crane_satu', event.target.value);
      }
      if (event.target.placeholder == 'Crane 2') {
        formData.append('crane_dua', event.target.value);
      }
      if (event.target.placeholder == 'Crane 3') {
        formData.append('crane_tiga', event.target.value);
      }
      if (event.target.placeholder == 'Crane 4') {
        formData.append('crane_empat', event.target.value);
      }
      if (event.target.placeholder == 'Crane 5') {
        formData.append('crane_lima', event.target.value);
      }
      if (event.target.placeholder == 'Crane 6') {
        formData.append('crane_enam', event.target.value);
      }
      if (event.target.placeholder == 'Crane 7') {
        formData.append('crane_tujuh', event.target.value);
      }
      if (event.target.placeholder == 'Compressor SCR') {
        formData.append('compres_scr', event.target.value);
      }
      if (event.target.placeholder == 'Compressor XK') {
        formData.append('compres_xk', event.target.value);
      }
      if (event.target.placeholder == 'Gardu Listrik') {
        formData.append('gardu_listrik', event.target.value);
      }
      if (event.target.placeholder == 'Down Time') {
        formData.append('down_time', event.target.value);
      }
      if (event.target.placeholder == 'Trouble Short') {
        formData.append('trouble_short', event.target.value);
      }
      if (event.target.placeholder == 'Remark') {
        formData.append('remark', event.target.value);
      }


      api = Api(context, monitoringMachine.update(id, formData));

      api.onSuccess(function(response) {
        context.getMonitoring();
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        // context.getMaterial();
        // context.get();
      })
      .call();
    },

    // ============================== END FOR MONITORING MACHINE ============================================
    defaultDate(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var today = date.getFullYear() + "-" + (month) + "-" + (day);

      this.searchMonitoring.periode = today
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    setBg(id) {
      this.backgroundRed = id
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    // CHART LINE
    getChartLine(){
      var ctx1 = document.getElementById("chart-line").getContext("2d");

      var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, "rgba(94, 114, 228, 0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(94, 114, 228, 0.0)");
      gradientStroke1.addColorStop(0, "rgba(94, 114, 228, 0)");

      new Chart(ctx1, {
        type: "line",
        data: {
          labels: [32,43,54,32],
          datasets: [
            {
              label: "Joint",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              borderColor: "#3A416F ",
              backgroundColor: gradientStroke1,
              // eslint-disable-next-line no-dupe-keys
              borderWidth: 3,
              fill: true,
              data: [32,43,54,32],
              maxBarThickness: 6,
            },
            {
              label: "DB",
              tension: 0.4,
              borderWidth: 0,
              pointRadius: 0,
              borderColor: "#4BB543 ",
              backgroundColor: gradientStroke1,
              // eslint-disable-next-line no-dupe-keys
              borderWidth: 3,
              fill: true,
              data: [32,85,54,32],
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#fbfbfb",
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#ccc",
                padding: 20,
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    },
    // CHART BAR
    getChartBar() {
    var ctx = document.getElementById("chart-cons-week").getContext("2d");

    new Chart(ctx, {
      type: "bar",
      data: {
        labels: Object.values(this.dateMtc),
        datasets: [
          {
            label: "Breakdown",
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "blue",
            data: Object.values(this.mtcBrk),
            maxBarThickness: 6,
          },
          {
            label: "Preventive",
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "red",
            data: Object.values(this.mtcPrev),
            maxBarThickness: 6,
          },
          {
            label: "Corrective",
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "#4BB543",
            data: Object.values(this.mtcCor),
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              beginAtZero: true,
              font: {
                size: 12,
                family: "Open Sans",
                style: "normal",
              },
              color: "#9ca2b7",
            },
          },
          // eslint-disable-next-line no-dupe-keys
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
          // eslint-disable-next-line no-dupe-keys
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
        },
      },
    });
  },
  // PIE BAR
  pieChart () {
    this.chartData = {
      labels: ['Breakdown ' +this.percentageBreakdown+ ' %', 'Preventive ' +this.percentagePreventive+ ' %', 'Corrective ' +this.percentageCorecive+ ' %'],
      datasets: [
        {
          backgroundColor: ['#3A416F','#FFD700','#FF4500'],
          data: [this.percentageBreakdown, this.percentagePreventive, this.percentageCorecive]
        }, 
      ],
    }
  },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}

/*for freze ke samping*/
.scroll table td:nth-child(1) {
  position: sticky;
  left: 0;
  background-color: #F0E68C;
}
</style>
