<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/sales-experience-list?project_number='+search.project_number+'&po_number='+search.po_number+'&customer_name='+search.customer_name['name']+'&end_user_name='+search.end_user_name['name']+'&product_name='+search.product_name+'&main_material='+search.main_material+'&material_category='+search.material_category+'&po_received_date='+search.po_received_date+'&delivery_date='+search.delivery_date+'&currency='+search.currency+'&term_of_payment='+search.term_of_payment+'&vat='+search.vat+'&quotation_number='+search.quotation_number+'&code_regulation='+search.code_regulation+'&type_equipment='+search.type_equipment+'&work_location='+search.work_location+'&plant='+search.plant+'&country_plant='+search.country_plant+'&sales_pic='+search.sales_pic+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/sales-project-cost-report?periode_date='+search.periode_date+'&project_number='+search.project_number+'&customer_name='+search.customer_name['name']+'&material_category='+search.material_category+'&type_equipment='+search.type_equipment+'&work_location='+search.work_location+'&plant='+search.plant+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Project Cost Report / PL</h6>
                </div>
              </div>
              <div class="col-lg-6">
              </div>
              <div class="col-lg-2 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-sm table-hover table-bordered">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PERIODE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" colspan="4">PROJECT DESCRIPTION</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MATERIAL TOTAL</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">SUB VENDOR</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PACKING & TRANS</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">VARIABLE COST</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MHR</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PRICE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MARGIN</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">FOH</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">SGA</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">FIXED COST</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">COST FACTOR</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">P/L</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">P/L (%)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MATERIAL CATEGORY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">EQUIPMENT TYPE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">WORK LOCATION</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PLANT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm text-uppercase">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <!-- PERIODE DATE -->
                      <td class="align-middle text-center text-sm text-uppercase">
                        <span class="text-secondary text-xs font-weight-bold"> {{ moment(row.periode_date).locale('en').format('ll') }} </span>
                      </td>
                      <!-- PROJECT DESKRIPTION -->
                      <td class="align-middle text-center text-sm text-uppercase">
                        <input type="text" style="border: 1px solid white; text-align: center; size: 30px;" size="6" :value="row.project_number" disabled>
                      </td>
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table style="border: 1px solid black;">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="6" value="Cust" disabled></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td>Prod</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td>Qty</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="60" :value="row.customer_name" disabled></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td>{{ row.product_name.substring(0, 60)+ ' ...' }}</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td>{{ row.qty }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="10" value="Estimation" disabled></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td>Actual</td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td>Different</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- MATERIAL TOTAL -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Material" :value="convertRp(row.est_material_total)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act Material" :value="convertRp(row.act_material_total)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" :value="convertRp(row.est_material_total - row.act_material_total)" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- SUB VENDOR -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Sub Vendor" :value="convertRp(row.est_sub_vendor)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act Sub Vendor" :value="convertRp(row.act_sub_vendor)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" :value="(row.est_sub_vendor - row.act_sub_vendor)" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- PACKING & TRANS -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Packing" :value="convertRp(row.est_packing)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act Packing" :value="convertRp(row.act_packing)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" :value="convertRp(row.est_packing - row.act_packing)" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- VARIABLE COST -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Variable Cost" :value="convertRp(row.est_material_total + row.est_sub_vendor + row.est_packing)" disabled></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Act Variable Cost" :value="convertRp(row.act_material_total + row.act_sub_vendor + row.act_packing)" disabled></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" :value="convertRp((row.est_material_total + row.est_sub_vendor + row.est_packing) - (row.act_material_total + row.act_sub_vendor + row.act_packing))" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- MHR -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Mhr" :value="convertRp(row.est_mhr)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act Mhr" :value="convertRp(row.act_mhr)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" :value="convertRp(row.est_mhr - row.act_mhr)" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- PRICE -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Price" :value="convertRp(row.est_price)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act Price" :value="convertRp(row.act_price)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" :value="convertRp(row.est_price - row.act_price)" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- MARGIN -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Margin" :value="convertRp(row.est_price - (row.est_material_total + row.est_sub_vendor + row.est_packing))" disabled></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act Margin" :value="convertRp(row.act_price - (row.act_material_total + row.act_sub_vendor + row.act_packing))" disabled></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" :value="convertRp((row.act_price - (row.act_material_total + row.act_sub_vendor + row.act_packing)) - (row.est_price - (row.est_material_total + row.est_sub_vendor + row.est_packing)))" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- FOH -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" disabled></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act FOH" :value="convertRp(row.act_foh)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- SGA -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" disabled></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act SGA" :value="convertRp(row.act_sga)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- FIXED COST -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Fixed Cost" :value="convertRp(row.est_fixed_cost)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Act Fixed Cost" :value="convertRp(row.act_foh + row.act_sga)" disabled></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" :value="convertRp((row.est_fixed_cost) - (row.act_foh + row.act_sga))" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- COST FACTOR -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Cost Factor" :value="convertRp(row.cost_factor)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act Cost Factor" :value="convertRp(row.act_cost_factor)" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Dif Cost Factor" :value="convertRp(row.cost_factor - (row.act_cost_factor))" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- P/L -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Pl" :value="convertRp((row.est_price - (row.est_material_total + row.est_sub_vendor + row.est_packing)) - (row.est_fixed_cost))"></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act Pl" :value="convertRp((row.act_price - (row.act_material_total + row.act_sub_vendor + row.act_packing)) - (row.act_foh + row.act_sga))"></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Dif Pl" :value="convertRp((row.act_price - (row.act_material_total + row.act_sub_vendor + row.act_packing)) - (row.act_foh + row.act_sga) - ((row.est_price - (row.est_material_total + row.est_sub_vendor + row.est_packing)) - (row.est_mhr * row.cost_factor)))" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- P/L PERCENTAGE (%) -->
                      <td class="align-middle text-left text-sm text-uppercase">
                        <table border="1">
                          <tbody>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" placeholder="Est Pl Percentage" :value="convertRp(row.est_pl_percentage) + ' % '" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black; background-color: yellow;">
                              <td><input type="text" style="border: 1px solid white; background-color: yellow; text-align: left; size: 30px;" size="20" placeholder="Act Pl Percentage" :value="convertRp(row.act_pl_percentage) + ' % '" @change="updateCostReport(row.id)"></td>
                            </tr>
                            <tr style="border: 1px solid black;">
                              <td><input type="text" style="border: 1px solid white; text-align: left; size: 30px;" size="20" disabled></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <!-- MATERIAL TYPE -->
                      <td class="align-middle text-center text-sm text-uppercase">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.material_category }} </span>
                      </td>
                      <!-- EQUIPMENT TYPE -->
                      <td class="align-middle text-center text-sm text-uppercase">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.type_equipment }} </span>
                      </td>
                      <!-- WORK LOCATION -->
                      <td class="align-middle text-center text-sm text-uppercase">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.work_location }} </span>
                      </td>
                      <!-- PLANT -->
                      <td class="align-middle text-center text-sm text-uppercase">
                        <span class="text-secondary text-xs font-weight-bold"> {{ row.plant }} </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Periode Date </label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.periode_date"/>
        <label for="example-text-input" class="form-control-label mt-3">Project No. </label>
        <input type="text" class="form-control" placeholder="Project No." v-on:keyup.enter="get()" v-model="search.project_number">
        <label for="example-text-input" class="form-control-label mt-3">Customer </label>
        <multiselect v-on:keyup.enter="get()" v-model="search.customer_name" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Customer" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Material Category </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.material_category">
          <option value="CS">CS</option>
          <option value="Cu-Ni">Cu-Ni</option>
          <option value="Duplex">Duplex</option>
          <option value="Ni Alloy">Ni Alloy</option>
          <option value="SUS">SUS</option>
          <option value="Ti">Ti</option>
          <option value="Other">Other</option>
        </select>
         <label for="example-text-input" class="form-control-label mt-3">Equipment Type </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.type_equipment">
          <option value="V">V - Vessel</option>
          <option value="H">H - Heat Exchanger</option>
          <option value="P">P - Piping</option>
          <option value="M">M - Mufle</option>
          <option value="R">R - Radiant Tube</option>
          <option value="S">S - Service</option>
          <option value="O">O - Other</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Work Location </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.work_location">
          <option value="Workshop">Workshop</option>
          <option value="Site">Site</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Plant </label>
        <select class="form-select mb-4" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.plant">
          <option value="Discovery">Discovery</option>
          <option value="Endeavor">Endeavor</option>
          <option value="Simba">Simba</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
// import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
var moment = require('moment');
import salesAdvisorPo from '@/services/salesAdvisorPo.service';
import customer from '@/services/customer.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    flatPickr,
    // Card,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      // tablePayTerm: {
      //   data: []
      // },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      // formTermPay: {
      //   add: true,
      //   title: "Term Of Payment",
      //   show: false
      // },
      // formAddTermPay: {
      //   add: true,
      //   title: "Add Term Of Payment",
      //   show: false
      // },
      // dataImport: '',
      onLoading: false,
      // tabelError: {
      //   data: []
      // },
      storageUrl : config.storageUrl,
      costReportData: {},
      // termPayData: {},
      search: {
        periode_date: '',
        project_number: '',
        customer_name: '',
        material_category: '',
        type_equipment: '',
        work_location: '',
        plant: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      // tablePlant: {
      //   data: []
      // },
      // tablePic: {
      //   data: []
      // },
      tableCustomer: {
        data: []
      },
      backgroundRed: null,

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, salesAdvisorPo.costReport({periode_date: this.search.periode_date, project_number: this.search.project_number, customer_name: this.search.customer_name['name'], material_category: this.search.material_category, type_equipment: this.search.type_equipment, work_location: this.search.work_location, plant: this.search.plant, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getCustomer(param){
      let context = this;        
      Api(context, customer.getCustomer({search: param.target.value})).onSuccess(function(response) {    
          context.tableCustomer.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableCustomer.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    // create() {
    //   this.form.add           = true;
    //   this.form.show          = true;
    //   this.form.title         = "Add Data";
    //   this.costReportData = {};
    // },
    edit(id) {
      let context = this;               
      Api(context, salesAdvisorPo.show(id)).onSuccess(function(response) {
        context.costReportData               = response.data.data.data;
        context.costReportData.customer_name = response.data.data.dataCustomerName[0];
        context.costReportData.end_user_name = response.data.data.dataEndUserName[0];
        context.form.show                      = true;
        context.form.title                     = 'Edit Data'; 
      })
      .call()        
    },
    updateCostReport(id){
      let api         = null;
      let context     = this;
      let formData    = new FormData();

      if (event.target.placeholder == 'Est Material') {
        formData.append('est_material_total', event.target.value);
      }
      if (event.target.placeholder == 'Est Sub Vendor') {
        formData.append('est_sub_vendor', event.target.value);
      }
      if (event.target.placeholder == 'Est Packing') {
        formData.append('est_packing', event.target.value);
      }
      if (event.target.placeholder == 'Est Mhr') {
        formData.append('est_mhr', event.target.value);
      }
      if (event.target.placeholder == 'Est Price') {
        formData.append('est_price', event.target.value);
      }
      if (event.target.placeholder == 'Est Fixed Cost') {
        formData.append('est_fixed_cost', event.target.value);
      }
      if (event.target.placeholder == 'Est Pl Percentage') {
        formData.append('est_pl_percentage', event.target.value);
      }
      if (event.target.placeholder == 'Est Cost Factor') {
        formData.append('cost_factor', event.target.value);
      }
      if (event.target.placeholder == 'Act Material') {
        formData.append('act_material_total', event.target.value);
      }
      if (event.target.placeholder == 'Act Sub Vendor') {
        formData.append('act_sub_vendor', event.target.value);
      }
      if (event.target.placeholder == 'Act Packing') {
        formData.append('act_packing', event.target.value);
      }
      if (event.target.placeholder == 'Act Mhr') {
        formData.append('act_mhr', event.target.value);
      }
      if (event.target.placeholder == 'Act Price') {
        formData.append('act_price', event.target.value);
      }
      if (event.target.placeholder == 'Act FOH') {
        formData.append('act_foh', event.target.value);
      }
      if (event.target.placeholder == 'Act SGA') {
        formData.append('act_sga', event.target.value);
      }
      if (event.target.placeholder == 'Act Cost Factor') {
        formData.append('act_cost_factor', event.target.value);
      }
      if (event.target.placeholder == 'Act Pl Percentage') {
        formData.append('act_pl_percentage', event.target.value);
      }

      api = Api(context, salesAdvisorPo.updateCostReport(id, formData));

      api.onSuccess(function(response) {
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        // context.getMaterial();
        context.get();
      })
      .call();
    },
    // save(){
    //   let api      = null;
    //   let context  = this;
    //   let formData = new FormData();
    //   context.onLoading = true;

    //   if (context.costReportData.material_cost != undefined && context.costReportData.sub_vendor_cost != undefined && context.costReportData.packing_trans_cost != undefined && context.costReportData.  variable_cost != undefined && context.costReportData.mhr_cost != undefined && context.costReportData.margin != undefined && context.costReportData.foh != undefined && context.costReportData.sga != undefined && context.costReportData.fixed_cost != undefined && context.costReportData.cost_factor != undefined) {
    //     formData.append('material_cost', context.costReportData.material_cost);
    //     formData.append('sub_vendor_cost', context.costReportData.sub_vendor_cost);
    //     formData.append('packing_trans_cost', context.costReportData.packing_trans_cost);
    //     formData.append('variable_cost', context.costReportData.variable_cost);
    //     formData.append('mhr_cost', context.costReportData.mhr_cost);
    //     formData.append('margin', context.costReportData.margin);
    //     formData.append('foh', context.costReportData.foh);
    //     formData.append('sga', context.costReportData.sga);
    //     formData.append('fixed_cost', context.costReportData.fixed_cost);
    //     formData.append('cost_factor', context.costReportData.cost_factor);
    //   }else{
    //     context.onLoading = false;
    //     return alert('The Red Star Field is Mandatory')
    //   }

    //   api = Api(context, salesAdvisorPo.updateCostReport(this.costReportData.id, formData));
    //   api.onSuccess(function(response) {
    //     context.get();
    //     context.costReportData = {}
    //     context.form.show = false;
    //     context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
    //   }).onError(function(error) { 
    //     context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
    //   }).onFinish(function() {  
    //     context.onLoading = false;
    //   })
    //   .call();
    // },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    setBg(id) {
      this.backgroundRed = id
    }

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}

/*for freze ke samping*/
.scroll table td:nth-child(3) {
  position: sticky;
  left: 0;
  background-color: white;
}
</style>
