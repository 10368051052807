<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/sales-advisor-po?project_number='+search.project_number+'&po_number='+search.po_number+'&customer_name='+search.customer_name['name']+'&end_user_name='+search.end_user_name['name']+'&product_name='+search.product_name+'&main_material='+search.main_material+'&material_category='+search.material_category+'&invoice_date='+search.invoice_date+'&paid_of_date='+search.paid_of_date+'&delivery_date='+search.delivery_date+'&currency='+search.currency+'&term_of_payment='+search.term_of_payment+'&vat='+search.vat+'&quotation_number='+search.quotation_number+'&code_regulation='+search.code_regulation+'&type_equipment='+search.type_equipment+'&work_location='+search.work_location+'&plant='+search.plant+'&country_plant='+search.country_plant+'&sales_pic='+search.sales_pic+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/sales-report?project_number='+search.project_number+'&po_number='+search.po_number+'&customer_name='+search.customer_name['name']+'&end_user_name='+search.end_user_name['name']+'&product_name='+search.product_name+'&main_material='+search.main_material+'&material_category='+search.material_category+'&invoice_date='+search.invoice_date+'&paid_of_date='+search.paid_of_date+'&delivery_date='+search.delivery_date+'&currency='+search.currency+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a>
   <!--  <div class="row">
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Total Pending"
          :value="convertRp(totalPending)"
          iconClass="fa fa-newspaper-o"
          iconBackground="bg-gradient-secondary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Approved"
          :value="convertRp(totalApproved)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Not Approved"
          :value="convertRp(totalNotApproved)"
          iconClass="fa fa-times-circle"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="On Duty"
          :value="convertRp(totalOnDuty)"
          iconClass="fa fa-car"
          iconBackground="bg-gradient-warning"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Done"
          :value="convertRp(totalDone)"
          iconClass="ni ni-satisfied"
          iconBackground="bg-gradient-info"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Total Request"
          :value="convertRp(totalRequest)"
          iconClass="fa fa-tasks"
          iconBackground="bg-gradient-primary"
          directionReverse
        ></card>
      </div>
    </div> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Sales Report</h6>
                </div>
              </div>
              <div class="col-lg-6">
              </div>
              <div class="col-lg-2 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: ##F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">SOURCE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">PROJECT No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">CUSTOMER NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">END USER</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">PRODUCT NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">MATERIAL</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" colspan="7">SALES (EXPORT & LOCAL)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" colspan="3">V.A.T (PPN 11%)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">PLANT </th>
                    </tr>
                    <tr style="background-color: ##F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DATE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">INVOICE NO.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">QTY (SET)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ORIGINAL <br> CURRENCY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">AMOUNT <br> ORIGINAL <br> CURRENCY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">EXCHANGE <br> RATE </th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">AMOUNT IN (IDR) </th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">AMOUNT <br> ORIGINAL <br> CURRENCY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">EXCHANGE <br> RATE </th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">AMOUNT IN (IDR) </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="row.country_plant === 'Indonesia'">DOMESTIC</span>
                        <span class="text-secondary text-xs font-weight-bold" v-else> OVERSEAS</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.project_number }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.customer_name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.end_user_name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.product_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.main_material }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.invoice_date).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.invoice_number }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.currency }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ convertRp( ((row.percentage/100).toFixed(2) * row.total_price) ) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.exchange_rate }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="row.currency == 'IDR'">{{ convertRp( ((row.percentage/100).toFixed(2) * row.total_price) ) }}</span>
                        <span class="text-secondary text-xs font-weight-bold" v-else>{{ convertRp(((row.percentage/100).toFixed(2) * row.total_price) * row.exchange_rate) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="row.currency == 'IDR'">{{ convertRp( (((row.percentage/100).toFixed(2) * row.total_price) * 0.11) ) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="row.currency == 'IDR'">{{ row.exchange_rate }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="row.currency == 'IDR'">{{ convertRp( (((row.percentage/100).toFixed(2) * row.total_price) * 0.11) ) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.plant }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Periode Date </label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.paid_of_date"/>
        <label for="example-text-input" class="form-control-label mt-3">Invoice Date </label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.invoice_date"/>
        <label for="example-text-input" class="form-control-label mt-3">Project No </label>
        <input type="text" class="form-control" placeholder="Project No" v-on:keyup.enter="get()" v-model="search.project_number">
        <label for="example-text-input" class="form-control-label mt-3">PO Number </label>
        <input type="text" class="form-control" placeholder="PO Number" v-on:keyup.enter="get()" v-model="search.po_number">
        <label for="example-text-input" class="form-control-label mt-3">Customer </label>
        <multiselect v-on:keyup.enter="get()" v-model="search.customer_name" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Customer" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">End User Name </label>
        <multiselect v-on:keyup.enter="get()" v-model="search.end_user_name" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Customer" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Product Name </label>
        <input type="text" class="form-control" placeholder="Product Name" v-on:keyup.enter="get()" v-model="search.product_name">
        <label for="example-text-input" class="form-control-label mt-3">Main Material </label>
        <input type="text" class="form-control" placeholder="Main Material" v-on:keyup.enter="get()" v-model="search.main_material">
        <label for="example-text-input" class="form-control-label mt-3">Plant </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.plant">
          <option value="Discovery">Discovery</option>
          <option value="Endeavor">Endeavor</option>
          <option value="Simba">Simba</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
// import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import 'vue-multiselect/dist/vue-multiselect.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import salesInvoice from '@/services/salesInvoice.service';
import customer from '@/services/customer.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    flatPickr,
    // Card,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      onLoading: false,
      storageUrl : config.storageUrl,
      salesAdvisorData: {},
      search: {
        project_number: '',
        po_number: '',
        customer_name: '',
        end_user_name: '',
        product_name: '',
        main_material: '',
        material_category: '',
        invoice_date: '',
        paid_of_date: '',
        delivery_date: '',
        currency: '',
        plant: '',
      },
      apiUrl :config.apiUrl,
      tableCustomer: {
        data: []
      },

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, salesInvoice.salesReport({project_number: this.search.project_number, po_number: this.search.po_number, customer_name: this.search.customer_name['name'], end_user_name: this.search.end_user_name['name'], product_name: this.search.product_name, main_material: this.search.main_material, material_category: this.search.material_category, invoice_date: this.search.invoice_date, paid_of_date: this.search.paid_of_date, delivery_date: this.search.delivery_date, currency: this.search.currency, plant: this.search.plant, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getCustomer(param){
      let context = this;        
      Api(context, customer.getCustomer({search: param.target.value})).onSuccess(function(response) {    
          context.tableCustomer.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableCustomer.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
