<template>
  <main>
    <div class="container-fluid mb-4">
      <div
        class="page-header min-height-300"
        style="
          background-image: url('https://www.tokkiltd.co.jp/wordpress/wp-content/uploads/2022/12/Fgrb_H6UAAAFsFJ.jpeg');
          margin-right: -24px;
          margin-left: -34%;
        "
      >
        <span class="mask bg-gradient-dark opacity-6"></span>
      </div>
      <div class="card shadow-lg mt-n7">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative" v-if="photo">
                <img
                  :src="storageUrl+'document_employee/'+photo"
                  alt="profile_image"
                  class="shadow-sm w-100 border-radius-lg"
                />
              </div>
              <div class="avatar avatar-xl position-relative" v-else>
                <img
                    src="@/assets/img/avatar_karyawan.png"
                    alt="profile_image"
                    class="shadow-sm w-100 border-radius-lg"
                  />
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{ roleFullName }}</h5>
                <p class="mb-0 font-weight-bold text-sm">{{ position }}</p>
              </div>
            </div>
            <div class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0">
              <!-- <span class="font-weight-bold">Total Working Hour : <span class="text-danger">{{totalWorkingHour}} Hour {{totalWorkingMinutes}} Minutes</span></span><br> -->
            </div>
          </div>
        </div>
        <div class="mx-auto mt-2 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mb-3">
          <!-- <argon-button color="success" size="xs" style="margin-right: 10px; margin-top: 5px;" class="mb-3" @click="create()"><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add Data</argon-button>
          <argon-button variant="gradient" color="secondary" size="xs" style="margin-right: 10px; margin-top: 5px;" @click="filter()" class="mb-3"><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button> -->
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <span class="mt-5 font-weight-bold">Detail Employee Data</span><hr>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <p class="text-uppercase text-sm font-weight-bold" style="color: #FF4500;">Company Information</p>
            <div class="col-lg-6">
              <label for="example-text-input" class="form-control-label mt-3">NIK <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="NIK" v-model="employeeData.nik" disabled="">
              <label for="example-text-input" class="form-control-label mt-3">Name <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="Name" v-model="employeeData.name">
              <label for="example-text-input" class="form-control-label mt-3">NPWP <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="NPWP" v-model="employeeData.npwp">
              <label for="example-text-input" class="form-control-label mt-3">Position <span style="color: red;">*</span></label>
              <multiselect v-model="employeeData.position" :options="tablePosition.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Position" label="name" track-by="name" :preselect-first="true" @keyup="getPosition" @change="updateProfile()" disabled>
                <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
              </multiselect>
              <label for="example-text-input" class="form-control-label mt-3">Golongan <span style="color: red;">*</span></label>
              <select class="form-select" aria-label="Default select example" v-model="employeeData.group" @change="updateProfile()" disabled>
                <option value="III">III</option>
                <option value="III A">III A</option>
                <option value="IV">IV</option>
                <option value="V">V</option>
                <option value="VI">VI</option>
                <option value="VII">VII</option>
                <option value="VIII">VIII</option>
                <option value="IX">IX</option>
                <option value="S">S</option>
                <option value="-">-</option>
              </select>
              <label for="example-text-input" class="form-control-label mt-3">Department <span style="color: red;">*</span></label>
              <multiselect v-model="employeeData.department" :options="tableDepartment.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Department" label="name" track-by="name" :preselect-first="true" @keyup="getDepartment" @change="updateProfile()" disabled>
                <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
              </multiselect>
              <label for="example-text-input" class="form-control-label mt-3">Job Title <span style="color: red;">*</span></label>
              <multiselect v-model="employeeData.job_title" :options="tableJobTitle.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Job Title" label="name" track-by="name" :preselect-first="true" @keyup="getJobTitle" @change="updateProfile()" disabled>
                <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
              </multiselect>
            </div>
            <div class="col-lg-6">
              <label for="example-text-input" class="form-control-label mt-3">SPSI Member <span style="color: red;">*</span></label>
              <select class="form-select" aria-label="Default select example" v-model="employeeData.member_spsi" @change="updateProfile()">
                <option value="SPSI">SPSI</option>
                <option value="Non SPSI">Non SPSI</option>
                <option value="-">-</option>
              </select>
              <label for="example-text-input" class="form-control-label mt-3">Join Date <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="date" class="form-control" placeholder="Join Date" v-model="employeeData.join_date" disabled>
              <label for="example-text-input" class="form-control-label mt-3">Join Date MMF</label>
              <input @change="updateProfile()" type="date" class="form-control" placeholder="Join Date MMF" v-model="employeeData.join_date_mmf" disabled>
              <label for="example-text-input" class="form-control-label mt-3">Employee Status <span style="color: red;">*</span></label>
              <select class="form-select" aria-label="Default select example" v-model="employeeData.employee_status" @change="updateProfile()" disabled>
                <option value="Expat">Expat</option>
                <option value="Kontrak">Kontrak</option>
                <option value="Kontrak I">Kontrak I</option>
                <option value="Kontrak II">Kontrak II</option>
                <option value="Kontrak III">Kontrak III</option>
                <option value="Kontrak IV">Kontrak IV</option>
                <option value="Kontrak V">Kontrak V</option>
                <option value="Kontrak VI">Kontrak VI</option>
                <option value="Kontrak VII">Kontrak VII</option>
                <option value="Kontrak Project">Kontrak Project</option>
                <option value="Pegawai Tetap">Pegawai Tetap</option>
                <option value="Magang">Magang</option>
              </select>
              <label for="example-text-input" class="form-control-label mt-3">Employee Activity <span style="color: red;">*</span></label>
              <select class="form-select" aria-label="Default select example" v-model="employeeData.employee_activity" @change="updateProfile()" disabled>
                <option value="Active">Active</option>
                <option value="Contract Ends">Contract Ends</option>
                <option value="Resign">Resign</option>
                <option value="Fired">Fired</option>
              </select>
              <label for="example-text-input" class="form-control-label mt-3">Permanent Date </label>
              <input @change="updateProfile()" type="date" class="form-control" placeholder="Permanent Date" v-model="employeeData.permanent_date" disabled>
              <label for="example-text-input" class="form-control-label mt-3">Employement End Date </label>
              <input @change="updateProfile()" type="date" class="form-control" placeholder="Employement End Date" v-model="employeeData.empoyee_end_date" disabled>
              <label for="example-text-input" class="form-control-label mt-3">Plant <span style="color: red;">*</span></label>
              <multiselect v-model="employeeData.plant" :options="tablePlant.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Plant" label="name" track-by="name" :preselect-first="true" @keyup="getPlant" @change="updateProfile()" disabled>
                <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
              </multiselect>
            </div>

            <hr class="horizontal dark mt-4" />
            <p class="text-uppercase text-sm font-weight-bold" style="color: #DAA520;">Personal Information</p>
            <div class="col-lg-6">
              <label for="example-text-input" class="form-control-label mt-3">NO KTP <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="NO KTP" v-model="employeeData.ktp">
              <label for="example-text-input" class="form-control-label mt-3">Place Of Birth <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="Place Of Birth" v-model="employeeData.place_birth">
              <label for="example-text-input" class="form-control-label mt-3">Date Of Birth <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="date" class="form-control" placeholder="Date Of Birth" v-model="employeeData.date_birth">
              <label for="example-text-input" class="form-control-label mt-3">Gender <span style="color: red;">*</span></label>
              <select class="form-select" aria-label="Default select example" v-model="employeeData.gender" @change="updateProfile()">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <label for="example-text-input" class="form-control-label mt-3">Blood Group <span style="color: red;">*</span></label>
              <select class="form-select" aria-label="Default select example" v-model="employeeData.blood_group" @change="updateProfile()">
                <option value="A">A</option>
                <option value="A+">A+</option>
                <option value="B">B</option>
                <option value="B+">B+</option>
                <option value="AB">AB</option>
                <option value="AB+">AB+</option>
                <option value="O">O</option>
                <option value="O+">O+</option>
                <option value="-">-</option>
              </select>
              <label for="example-text-input" class="form-control-label mt-3">Mother Name <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="Mother Name" v-model="employeeData.mother_name">
              <label for="example-text-input" class="form-control-label mt-3">Religion <span style="color: red;">*</span></label>
              <select class="form-select" aria-label="Default select example" v-model="employeeData.religion" @change="updateProfile()">
                <option value="Islam">Islam</option>
                <option value="Protestan">Protestan</option>
                <option value="Katolik">Katolik</option>
                <option value="Hindu">Hindu</option>
                <option value="Buddha">Buddha</option>
                <option value="Konghucu">Konghucu</option>
                <option value="-">-</option>
              </select>
              <label for="example-text-input" class="form-control-label mt-3">Home Address <span style="color: red;">*</span></label>
              <textarea class="form-control" v-model="employeeData.home_address" placeholder="Home Address" @change="updateProfile()"></textarea>
              <label for="example-text-input" class="form-control-label mt-3">KTP Address <span style="color: red;">*</span></label>
              <textarea class="form-control" v-model="employeeData.ktp_address" placeholder="KTP Address" @change="updateProfile()"></textarea>
            </div>
            <div class="col-lg-6">
              <label for="example-text-input" class="form-control-label mt-3">NO KK. <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="NO KK." v-model="employeeData.no_kk">
              <label for="example-text-input" class="form-control-label mt-3">NO HP 1 <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="NO HP 1" v-model="employeeData.no_hp_first">
              <label for="example-text-input" class="form-control-label mt-3">NO HP 2 <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="NO HP 2" v-model="employeeData.no_hp_second">
              <label for="example-text-input" class="form-control-label mt-3">No Tlp Emergency <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="No Tlp Emergency" v-model="employeeData.no_emergency">
              <label for="example-text-input" class="form-control-label mt-3">Email <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="Email" v-model="employeeData.email">
              <label for="example-text-input" class="form-control-label mt-3">Married Status <span style="color: red;">*</span></label>
              <select class="form-select" aria-label="Default select example" v-model="employeeData.maried_status" @change="updateProfile()">
                <option value="Married">Married</option>
                <option value="Not Married Yet">Not Married Yet</option>
              </select>
              <label for="example-text-input" class="form-control-label mt-3">Total Amenability <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="number" class="form-control" placeholder="Total Amenability" v-model="employeeData.total_amenability">
              <label for="example-text-input" class="form-control-label mt-3">Last Education <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="Last Education" v-model="employeeData.last_education">
            </div>

            <hr class="horizontal dark mt-4" />
            <p class="text-uppercase text-sm font-weight-bold" style="color: #6495ED;">Bank Information</p>
            <div class="col-lg-6">
              <label for="example-text-input" class="form-control-label mt-3">A/N Bank <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="A/N Bank" v-model="employeeData.an_bank">
              <label for="example-text-input" class="form-control-label mt-3">Type Bank <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="Type Bank" v-model="employeeData.type_bank">
            </div>
            <div class="col-lg-6">
              <label for="example-text-input" class="form-control-label mt-3">No Rek. <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="No Rek." v-model="employeeData.no_rek">
              <label for="example-text-input" class="form-control-label mt-3">Brance <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="Brance" v-model="employeeData.brance">
            </div>

            <hr class="horizontal dark mt-4" />
            <p class="text-uppercase text-sm font-weight-bold" style="color: #32CD32;">BPJS Information</p>
            <div class="col-lg-6">
              <label for="example-text-input" class="form-control-label mt-3">No BPJS Ketenagakerjaan MMF <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="No BPJS Ketenagakerjaan MMF" v-model="employeeData.bpjs_ket_mmf">
              <label for="example-text-input" class="form-control-label mt-3">No BPJS Ketenagakerjaan Tokki <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="No BPJS Ketenagakerjaan Tokki" v-model="employeeData.bpjs_ket_tokki">
            </div>
            <div class="col-lg-6">
              <label for="example-text-input" class="form-control-label mt-3">No BPJS Kesehatan <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="No BPJS Kesehatan" v-model="employeeData.bpjs_kesehatan">
              <label for="example-text-input" class="form-control-label mt-3">No Asuransi Mandiri In Health <span style="color: red;">*</span></label>
              <input @change="updateProfile()" type="text" class="form-control" placeholder="No Asuransi Mandiri In Health" v-model="employeeData.asuransi_mandiri_inhelath">
            </div>

            <hr class="horizontal dark mt-4" />
            <p class="text-uppercase text-sm font-weight-bold" style="color: #F4A460;">File Information</p>
            <div class="col-lg-6">
              <label for="example-text-input" class="form-control-label mt-3">Photo </label>
              <input type="file" class="form-control" placeholder="Photo" @change="filesChangePhoto">
              <label for="example-text-input" class="form-control-label mt-3">File KTP </label>
              <input type="file" class="form-control" placeholder="File KTP" @change="filesChangeKtp">
              <label for="example-text-input" class="form-control-label mt-3">File KK </label>
              <input type="file" class="form-control" placeholder="File KK" @change="filesChangeKk">
              <label for="example-text-input" class="form-control-label mt-3">File NPWP </label>
              <input type="file" class="form-control" placeholder="File NPWP" @change="filesChangeNpwp">
              <label for="example-text-input" class="form-control-label mt-3">File Buku Rekening </label>
              <input type="file" class="form-control" placeholder="File Buku Rekening" @change="filesChangeBukuRekening">
            </div>
            <div class="col-lg-6">
              <label for="example-text-input" class="form-control-label mt-3">File BPJS Kesehatan </label>
              <input type="file" class="form-control" placeholder="File BPJS Kesehatan" @change="filesChangeBpjsKesehatan">
              <label for="example-text-input" class="form-control-label mt-3">File BPJS Ketenagakerjaan </label>
              <input type="file" class="form-control" placeholder="File BPJS Ketenagakerjaan" @change="filesChangeBpjsKetenagakerjaan">
              <label for="example-text-input" class="form-control-label mt-3">File CV </label>
              <input type="file" class="form-control" placeholder="File CV" @change="filesChangeCv">
              <label for="example-text-input" class="form-control-label mt-3">File Vaksin </label>
              <input type="file" class="form-control" placeholder="File Vaksin" @change="filesChangeVaksin">
              <label for="example-text-input" class="form-control-label mt-3">File Sertefikat Keahlian </label>
              <input type="file" class="form-control" placeholder="File Sertefikat Keahlian" @change="filesChangeSertefikatKeahlian">
              <label for="example-text-input" class="form-control-label mt-3">File Ijazah Terakhir </label>
              <input type="file" class="form-control" placeholder="File Ijazah Terakhir" @change="filesChangeIjazahTerakhir">
            </div>
          </div> 
        </div>
      </div>
    </div>

   <!-- =======  MODAL INFORMASI ======= -->
  <div class="container">
    <vue-final-modal v-model="formInfo.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formInfo.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formInfo.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <P>Dear user yang terhormat, di mohon untuk melengkapi data berikut dengan benar untuk kolom input bertanda bintang merah wajib di isi, dan jika memang data nya tidak ada bisa masukkan symbol - atau 0 di dalam kolom inputannya. terimakasih :)</P>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-5"> 
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="formInfo.show = false">Oke</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  </main>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ArgonButton from "@/components/ArgonButton.vue";
import config from '@/configs/config';
import Api from '@/helpers/api';
import { VueFinalModal } from 'vue-final-modal';
var moment = require('moment');
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect';
import akun from '@/services/akun.service';
import employee from '@/services/employee.service';
import plant from '@/services/plant.service';
import department from '@/services/department.service';
import position from '@/services/position.service';
import jobTitle from '@/services/jobTitle.service';

const body = document.getElementsByTagName("body")[0];

export default {
  name: "profile",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
  },
  data() {
    return {
      moment:moment,
      onLoading: false,
      formInfo: {
        add: true,
        title: "Info",
        show: false
      },
      employeeData: {},
      tablePlant: {
        data: []
      },
      tableDepartment: {
        data: []
      },
      tablePosition: {
        data: []
      },
      tableJobTitle: {
        data: []
      },
      photo: '',
      position: '',
      roleFullName: '',
      storageUrl : config.storageUrl,
      apiUrl :config.apiUrl,
    };
  },
  mounted() {
    this.getEmployee();
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
    this.formInfo.show = true;
  },
  methods: {
    getEmployee() {
      let context = this;               
      Api(context, employee.showDetailEmployee(context.$route.params.safety_code)).onSuccess(function(response) {
          context.employeeData            = response.data.data.data;
          context.employeeData.plant      = response.data.data.dataPlant
          context.employeeData.department = response.data.data.dataDept
          context.employeeData.position   = response.data.data.dataPos
          context.employeeData.job_title  = response.data.data.dataJT

          context.employeeData.id = response.data.data.data.id
          context.roleFullName    = response.data.data.data.name
          context.position        = response.data.data.data.position_name
          context.photo           = response.data.data.data.photo
      })
      .call()        
    },
    getPlant(param){
      let context = this;        
      Api(context, plant.getPlant({search: param.target.value})).onSuccess(function(response) {    
          context.tablePlant.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePlant.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getDepartment(param){
      let context = this;        
      Api(context, department.getDepartment({search: param.target.value})).onSuccess(function(response) {    
          context.tableDepartment.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableDepartment.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPosition(param){
      let context = this;        
      Api(context, position.getPosition({search: param.target.value})).onSuccess(function(response) {    
          context.tablePosition.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePosition.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getJobTitle(param){
      let context = this;        
      Api(context, jobTitle.getJobTitle({search: param.target.value})).onSuccess(function(response) {    
          context.tableJobTitle.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableJobTitle.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    updateProfile(){
      let api     = null;
      let context = this;

      api = Api(context, employee.updateDetailEmployee(context.employeeData.id, {
          // Company Information
          nik: context.employeeData.nik,
          name: context.employeeData.name,
          npwp: context.employeeData.npwp,
          position: JSON.stringify(context.employeeData.position),
          group: context.employeeData.group,
          department: JSON.stringify(context.employeeData.department),
          job_title: JSON.stringify(context.employeeData.job_title),
          member_spsi: context.employeeData.member_spsi,
          join_date: context.employeeData.join_date,
          join_date_mmf: context.employeeData.join_date_mmf,
          employee_status: context.employeeData.employee_status,
          employee_activity: context.employeeData.employee_activity,
          permanent_date: context.employeeData.permanent_date,
          empoyee_end_date: context.employeeData.empoyee_end_date,
          plant: JSON.stringify(context.employeeData.plant),
          // Personal Information
          ktp: context.employeeData.ktp,
          place_birth: context.employeeData.place_birth,
          date_birth: context.employeeData.date_birth,
          gender: context.employeeData.gender,
          blood_group: context.employeeData.blood_group,
          mother_name: context.employeeData.mother_name,
          religion: context.employeeData.religion,
          home_address: context.employeeData.home_address,
          ktp_address: context.employeeData.ktp_address,
          no_kk: context.employeeData.no_kk,
          no_hp_first: context.employeeData.no_hp_first,
          no_hp_second: context.employeeData.no_hp_second,
          no_emergency: context.employeeData.no_emergency,
          email: context.employeeData.email,
          maried_status: context.employeeData.maried_status,
          total_amenability: context.employeeData.total_amenability,
          last_education: context.employeeData.last_education,
          // Bank Information
          an_bank: context.employeeData.an_bank,
          type_bank: context.employeeData.type_bank,
          no_rek: context.employeeData.no_rek,
          brance: context.employeeData.brance,
          // BPJS Information
          bpjs_ket_mmf: context.employeeData.bpjs_ket_mmf,
          bpjs_ket_tokki: context.employeeData.bpjs_ket_tokki,
          bpjs_kesehatan: context.employeeData.bpjs_kesehatan,
          asuransi_mandiri_inhelath: context.employeeData.asuransi_mandiri_inhelath,
          // File Information
          photo: context.employeeData.photo,
          file_ktp: context.employeeData.file_ktp,
          file_kk: context.employeeData.file_kk,
          file_npwp: context.employeeData.file_npwp,
          file_buku_rekening: context.employeeData.file_buku_rekening,
          file_bpjs_kesehatan: context.employeeData.file_bpjs_kesehatan,
          file_bpjs_ketenagakerjaan: context.employeeData.file_bpjs_ketenagakerjaan,
          file_cv: context.employeeData.file_cv,
          file_vaksin: context.employeeData.file_vaksin,
          file_sertefikat_keahlian: context.employeeData.file_sertefikat_keahlian,
          file_ijazah_terakhir: context.employeeData.file_ijazah_terakhir,
        }));
      api.onSuccess(function(response) {
          context.notify('Update Success', 'success')
      }).onError(function(error) { 
          context.notify('Update Failed', 'error')
      }).onFinish(function() {  
      })
      .call();
    },
    filesChangePhoto(e) {
        this.employeeData.photo = e.target.files[0];
        this.updateProfile()
    },
    filesChangeKtp(e) {
        this.employeeData.file_ktp = e.target.files[0];
        this.updateProfile()
    },
    filesChangeKk(e) {
        this.employeeData.file_kk = e.target.files[0];
        this.updateProfile()
    },
    filesChangeNpwp(e) {
        this.employeeData.file_npwp = e.target.files[0];
        this.updateProfile()
    },
    filesChangeBukuRekening(e) {
        this.employeeData.file_buku_rekening = e.target.files[0];
        this.updateProfile()
    },
    filesChangeBpjsKesehatan(e) {
        this.employeeData.file_bpjs_kesehatan = e.target.files[0];
        this.updateProfile()
    },
    filesChangeBpjsKetenagakerjaan(e) {
        this.employeeData.file_bpjs_ketenagakerjaan = e.target.files[0];
        this.updateProfile()
    },
    filesChangeCv(e) {
        this.employeeData.file_cv = e.target.files[0];
        this.updateProfile()
    },
    filesChangeVaksin(e) {
        this.employeeData.file_vaksin = e.target.files[0];
        this.updateProfile()
    },
    filesChangeSertefikatKeahlian(e) {
        this.employeeData.file_sertefikat_keahlian = e.target.files[0];
        this.updateProfile()
    },
    filesChangeIjazahTerakhir(e) {
        this.employeeData.file_ijazah_terakhir = e.target.files[0];
        this.updateProfile()
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}

/*MODAL WIDTH KUSUS LEBAR BESAR*/
::v-deep .modal-content-width {
  /*display: flex;*/
  /*flex-direction: column;*/
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  /*width: 1090px;*/
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
