import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('inventory-list/index', {params});
    },
    getItem(params) {
        return Service().get('inventory-list/get-item', {params});
    },
    show(id) {
        return Service().get('inventory-list/show/'+id);
    },
    create(params) {
        return Service().post('inventory-list/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('inventory-list/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('inventory-list/delete/'+id);
    },
    itemsDetail(params) {
        return Service().get('inventory-list/items-detail', {params});
    },
    getItemSave(params) {
        return Service().get('inventory-list/get-item-save', {params});
    },
    import(params) {
        return Service().post('import/import-item_list', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    getAdjust(id) {
        return Service().get('inventory-list/get-adjust/'+id);
    },
    saveAdjust(params) {
        return Service().post('inventory-list/save-adjust', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}