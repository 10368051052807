<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/invoice-monitoring-list?invoice_no='+search.invoice_no+'&vendor_name='+search.vendor_name+'&submited_by='+search.submited_by+'&submited_purchase='+search.submited_purchase+'&approved_acc_edv='+search.approved_acc_edv+'&payment_schedule='+search.payment_schedule+'&approved_selamet='+search.approved_selamet+'&approved_iis='+search.approved_iis+'&paid_by_ardi='+search.paid_by_ardi+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Print PDF</a>
    <argon-button color="warning" size="sm" class="mb-3" variant="gradient" @click="modalImport()"><i class="fa fa-upload fa-sm"></i> Import</argon-button>
    <div class="row mb-5">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>My Task</h6>
                  <h5>TOTAL TASK : {{tableTask.data.length}}</h5>
                </div>
              </div>
              <div class="col-4">
                <input type="text" class="form-control mt-5" placeholder="Search by Vendor Name" v-model="search.vendor_name" @keyup="getMyTask()">
              </div>
              <div class="col-4 float-right">
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll-task">
                <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Invoice No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Vendor Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Invoice Date</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Currency</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Total Amount</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Submited Purchase</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Submited By</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Approved ACC EDV</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Age Of Invoice</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Payment Schedule</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Approved Pak Selamet (AP)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Approved Bu Iis</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Invoice Status</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in tableTask.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.invoice_no }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.vendor_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.invoice_date).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.currency }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.total_price) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #FFF8DC;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.submited_purchase).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #FFF8DC;">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.submited_by }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #E6E6FA;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.approved_acc_edv).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #E6E6FA;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment().diff(row.approved_acc_edv, 'days') }} Days</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #E6E6FA;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.payment_schedule).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #AFEEEE;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.approved_selamet).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #7FFF00;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.approved_iis).locale('id').format('LL') }}</span>
                      </td>
                       <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-secondary" v-if="row.status == 'Submited Purchase'">Submited Purchase</span>
                        <span class="badge bg-gradient-primary" v-if="row.status == 'Approved Acc Edv'">Approved Acc Edv</span>
                        <span class="badge bg-gradient-warning" v-if="row.status == 'Approved AP'">Approved AP</span>
                        <span class="badge bg-gradient-info" v-if="row.status == 'Approved Finally'">Approved Finally</span>
                        <span class="badge bg-gradient-success" v-if="row.status == 'Paid'">Paid</span>
                        <span class="badge bg-gradient-danger" style="cursor:pointer;" v-if="row.status == 'Rejected Acc Edv'" @click="showReason(row.reason_rejected)">Rejected Acc Edv</span>
                        <span class="badge bg-gradient-danger" style="cursor:pointer;" v-if="row.status == 'Rejected AP'" @click="showReason(row.reason_rejected)">Rejected AP</span>
                        <span class="badge bg-gradient-danger" style="cursor:pointer;" v-if="row.status == 'Rejected AF'" @click="showReason(row.reason_rejected)">Rejected AF</span>
                        <span class="badge bg-gradient-danger" style="cursor:pointer;" v-if="row.status == 'Rejected Paid'" @click="showReason(row.reason_rejected)">Rejected Paid</span>
                      </td>
                      <td class="align-middle text-center">
                        <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
                        <span v-else>
                          <i class="fa fa-check-square fa-sm text-success" style="cursor: pointer; margin-right: 20px;" @click="approval('Approved', row.approval_to, row.id)"></i>
                          <i class="fa fa-times fa-sm text-danger" style="cursor: pointer; margin-right: 20px;" @click="approval('Rejected', row.approval_to, row.id)"></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <!-- <div class="float-left">TOTAL TASK : {{tableTask.data.length}} </div> -->
              <!-- <div class="float-right"> -->
                <!-- <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination> -->
              <!-- </div> -->
            </div>
          </div>
      </div>
    </div>

    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Invoice Vendor List</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                  v-if="userName == 'Fitri Alfiana' || userName == 'Sidrin Makhdhud' || userName == 'Dimas Prajodhia Hadiansyah' || userName == 'Shinta Marlita' || userName == 'Muhazir'"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Invoice No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Vendor Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Invoice Date</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Currency</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Total Amount</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Submited Purchase</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Submited By</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Approved ACC EDV</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Lead Time ACC EDV</th>
                      <!-- <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Age Of Invoice</th> -->
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Payment Schedule</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Approved Pak Selamet (AP)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Lead Time AP</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Approved Bu Iis</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Lead Time Bu Iis</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Paid by Ardi</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Lead Time Paid</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Invoice Status</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Total Leadtime</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.invoice_no }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.vendor_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.invoice_date).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.currency }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.total_price }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #FFF8DC; z-index: -200px;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.submited_purchase).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #FFF8DC;">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.submited_by }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #E6E6FA;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.approved_acc_edv).format('LL') }}</span>
                      </td>
                      <!-- lead time acc edv -->
                      <td class="align-middle text-center text-sm" style="background-color: #E6E6FA;">
                        <span class="text-secondary text-xs font-weight-bold">{{ leadTime(row.submited_purchase, row.approved_acc_edv) }} Days</span>
                      </td>
                      <!-- age of invoice -->
                      <!-- <td class="align-middle text-center text-sm" style="background-color: #E6E6FA;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment().diff(row.approved_acc_edv, 'days') }} Days</span>
                      </td> -->
                      <td class="align-middle text-center text-sm" style="background-color: #E6E6FA;">
                        <span class="text-secondary text-xs font-weight-bold">{{  moment(row.payment_schedule).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #AFEEEE;">
                        <span class="text-secondary text-xs font-weight-bold">{{  moment(row.approved_selamet).locale('id').format('LL') }}</span>
                      </td>
                      <!-- lead time ap -->
                      <td class="align-middle text-center text-sm" style="background-color: #AFEEEE;">
                        <span class="text-secondary text-xs font-weight-bold">{{ leadTime(row.approved_acc_edv, row.approved_selamet) }} Days</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #7FFF00;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.approved_iis).locale('id').format('LL') }}</span>
                      </td>
                      <!-- lead time bu iis -->
                      <td class="align-middle text-center text-sm" style="background-color: #7FFF00;">
                        <span class="text-secondary text-xs font-weight-bold">{{ leadTime(row.approved_selamet, row.approved_iis) }} Days</span>
                      </td>
                      <td class="align-middle text-center text-sm" style="background-color: #FFD700;">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.paid_by_ardi).locale('id').format('LL') }}</span>
                      </td>
                      <!-- lead time ardi / paid -->
                      <td class="align-middle text-center text-sm" style="background-color: #FFD700;">
                        <span class="text-secondary text-xs font-weight-bold">{{ leadTime(row.approved_iis, row.paid_by_ardi) }} Days</span>
                      </td>
                       <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-secondary" v-if="row.status == 'Submited Purchase'">Submited Purchase</span>
                        <span class="badge bg-gradient-primary" v-if="row.status == 'Approved Acc Edv'">Approved Acc Edv</span>
                        <span class="badge bg-gradient-warning" v-if="row.status == 'Approved AP'">Approved AP</span>
                        <span class="badge bg-gradient-info" v-if="row.status == 'Approved Finally'">Approved Finally</span>
                        <span class="badge bg-gradient-success" v-if="row.status == 'Paid'">Paid</span>
                        <span class="badge bg-gradient-danger" style="cursor:pointer;" v-if="row.status == 'Rejected Acc Edv'" @click="showReason(row.reason_rejected)">Rejected Acc Edv</span>
                        <span class="badge bg-gradient-danger" style="cursor:pointer;" v-if="row.status == 'Rejected AP'" @click="showReason(row.reason_rejected)">Rejected AP</span>
                        <span class="badge bg-gradient-danger" style="cursor:pointer;" v-if="row.status == 'Rejected AF'" @click="showReason(row.reason_rejected)">Rejected AF</span>
                        <span class="badge bg-gradient-danger" style="cursor:pointer;" v-if="row.status == 'Rejected Paid'" @click="showReason(row.reason_rejected)">Rejected Paid</span>
                      </td>
                      <!-- total lead time acc edv to paid by ardi -->
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ leadTime(row.approved_acc_edv, row.paid_by_ardi) }} Days</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" v-if="row.submited_by == userName && row.approved_acc_edv == '0000-00-00'"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)" v-if="row.submited_by == userName && row.approved_acc_edv == '0000-00-00'"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Invoice No. <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Invoice No." v-model="invoiceData.invoice_no">
        <label for="example-text-input" class="form-control-label mt-3">Vendor Name <span style="color: red;">*</span></label>
        <multiselect v-model="invoiceData.vendor_name" :options="tableSupplier.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Supplier" label="name" track-by="name" :preselect-first="true" @keyup="getSupplier">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Invoice Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Invoice Date" v-model="invoiceData.invoice_date">
        <label for="example-text-input" class="form-control-label mt-3">Currency <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="invoiceData.currency">
          <option value="IDR">IDR - Indonesian Rupiah</option>
          <option value="USD">USD - US Dollar</option>
          <option value="KRW">KRW - South Korean Won</option>
          <option value="SGD">SGD - Singapore Dollar</option>
          <option value="JPY">JPY - Japanese Yen</option>
          <option value="EUR">EUR - Euro</option>
          <option value="GBP">GBP - British Pound</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Total Amount <span style="color: red;">*</span></label>
        <input type="number" class="form-control mb-5" placeholder="Total Amount" v-model="invoiceData.total_price">
      </div>
      <!-- footer -->
      <div class="row float-right mt-5">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save </span>
            </span>
          </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Invoice No.</label>
        <input type="text" class="form-control" placeholder="Invoice No." v-model="search.invoice_no" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Vendor Name</label>
        <input type="text" class="form-control" placeholder="Vendor Name" v-model="search.vendor_name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Submited By</label>
        <select class="form-select" aria-label="Default select example" v-model="search.submited_by" v-on:keyup.enter="get()">
          <option value="Fitri Alfiana">Fitri Alfiana</option>
          <option value="Sidrin Makhdhud">Sidrin Makhdhud</option>
          <option value="Dimas Prajodhia Hadiansyah">Dimas Prajodhia Hadiansyah</option>
          <option value="Shinta Marlita">Shinta Marlita</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Submited Date Purchase</label>
        <input type="date" class="form-control" placeholder="Submited Date Purchase" v-model="search.submited_purchase" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Approved ACC EDV</label>
        <input type="date" class="form-control" placeholder="Approved ACC EDV" v-model="search.approved_acc_edv" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Payment Schedule</label>
        <input type="date" class="form-control" placeholder="Payment Schedule" v-model="search.payment_schedule" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Approved Pak Selamet (AP)</label>
        <input type="date" class="form-control" placeholder="Approved Pak Selamet (AP)" v-model="search.approved_selamet" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Approved Bu Iis</label>
        <input type="date" class="form-control" placeholder="Approved Bu Iis" v-model="search.approved_iis" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Paid by Ardi</label>
        <input type="date" class="form-control" placeholder="Paid by Ardi" v-model="search.paid_by_ardi" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select" aria-label="Default select example" v-model="search.status" v-on:keyup.enter="get()">
          <option value="Submited Purchase">Submited Purchase</option>
          <option value="Approved Acc Edv">Approved Acc Edv</option>
          <option value="Approved AP">Approved AP</option>
          <option value="Approved Finally">Approved Finally</option>
          <option value="Paid">Paid</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL CREATE SCHEDULE PAY ======= -->
  <div class="container">
    <vue-final-modal v-model="formSchedulePay.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formSchedulePay.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formSchedulePay.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Create Payment Schedule <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Create Payment Schedule" style="margin-bottom: 20px;" v-model="schedulePayement">
        <small class="text-danger">Make sure the schedule date is correct <span style="color: red;">*</span></small>
      </div>
    </div>
    <!-- footer -->
    <div class="row text-center mt-5">
      <div class="col-12">
        <button type="success" class="btn btn-sm btn-success btn-fill" @click="saveApproveSchedulePay()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Approved</span>
          </span>
        </button>
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL NOTE REJECT ======= -->
  <div class="container">
    <vue-final-modal v-model="formNoteReject.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formNoteReject.title}} </span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formNoteReject.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Reason <span style="color: red;">*</span></label>
        <textarea class="form-control" v-model="noteReject" placeholder="Enter your reason for rejecting this invoice" rows="8" :disabled="formNoteReject.title == 'Show Reason Rejected'"></textarea>
        <template v-if="formNoteReject.title != 'Show Reason Rejected'">
          <label for="example-text-input" class="form-control-label mt-3">Reject To <span style="color: red;">*</span></label>
          <select class="form-select" aria-label="Default select example" v-model="rejectTo">
            <option value="To Purchaser">To Purchaser</option>
            <option value="To Acc Edv">To Acc Edv / Uci</option>
            <option value="To AP">To AP / Slamet</option>
            <option value="To Approved Finally">To Approved Finally / Iis</option>
          </select>
          <small class="text-danger">Make sure you are sure to reject this invoice <span style="color: red;">*</span></small>
        </template>
      </div>
    </div>
    <!-- footer -->
    <div class="row text-center mt-5">
      <div class="col-12">
        <button type="danger" class="btn btn-sm btn-danger btn-fill" @click="saveRejected()" :disabled="onLoading" v-if="formNoteReject.title != 'Show Reason Rejected'">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Rejected</span>
          </span>
        </button>
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>

  <!-- =======  MODAL IMPORT ======= -->
  <div class="container">
    <vue-final-modal v-model="formImport.show" classes="modal-container" content-class="modal-content" :z-index="10000">
    <!-- header -->
    <div class="row">
      <div class="col-11 float-left">
        <span class="modal__title">{{formImport.title}}</span>
      </div>
      <div class="col-1 float-right">
        <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formImport.show = false"></i>
      </div>
    </div><hr>
    <!-- end header -->
    <div class="modal__content container">
      <div>
        <label for="example-text-input" class="form-control-label mt-3">Upload File <span style="color: red;">*</span></label>
        <input type="file" class="form-control" placeholder="Upload File" @change="filesChangeImport" style="margin-bottom: 20px;">
        <small>Download Template<a :href="storageUrl+'/template_import/Template Import Invoice List.xlsx'" style="color: blue;"> Import Invoice List</a></small>
        <div class="mt-2" v-if="tabelError.data.length !== 0 ">
          <table>
            <thead>
              <slot name="columns">
                <tr style="background-color: #F0F8FF;">
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">No</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Column</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Error</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 50px;">Row</th>
                  <th style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC; width: 150px;">Values</th>
                </tr>
              </slot>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tabelError.data" :key="i">
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ i + 1 }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.attribute }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.errors }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.row }}</td>
                <td style="font-size: 13px; text-align: center; border: 1pt solid #DCDCDC;">{{ row.values }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- footer -->
    <div class="row float-right mt-5">
      <div class="col-lg-6 col-4"> 
      </div>
      <div class="col-lg-2 col-3" style="margin-right: 20px;">
        <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="formImport.show = false">Close</argon-button>
      </div>
      <div class="col-lg-1 col-2">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="importData()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Import</span>
          </span>
        </button>
        <!-- <argon-button variant="gradient" color="success" size="sm" width="1" @click="get(), formImport.show = false">Import</argon-button> -->
      </div>
    </div>
    <!-- end footer -->
    </vue-final-modal>
  </div>

</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import invoiceMonitoring from '@/services/invoiceMonitoring.service';
import akun from '@/services/akun.service';
import supplier from '@/services/supplier.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    BasePagination,
    Multiselect,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      tableTask: {
        data: []
      },
      tableSupplier: {
        data: []
      },
      form: {
        add: true,
        title: "Add Invoice Vendor",
        show: false
      },
      formSchedulePay: {
        add: true,
        title: "Create Payment Schedule",
        show: false
      },
      formNoteReject: {
        add: true,
        title: "Reason Rejected",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      invoiceData: {},
      search: {
        invoice_no: '',
        vendor_name: '',
        submited_by: '',
        submited_purchase: '',
        approved_acc_edv: '',
        payment_schedule: '',
        approved_selamet: '',
        approved_iis: '',
        paid_by_ardi: '',
        status: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      userName: '',
      backgroundRed: null,

      idData: '',
      schedulePayement: '',
      noteReject: '',
      rejectTo: '',

    };
  },
  mounted(){
    this.get();
    this.getMyTask();
    this.getRole();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, invoiceMonitoring.index({invoice_no: this.search.invoice_no, vendor_name: this.search.vendor_name, submited_by: this.search.submited_by, submited_purchase: this.search.submited_purchase, approved_acc_edv: this.search.approved_acc_edv, payment_schedule: this.search.payment_schedule, approved_selamet: this.search.approved_selamet, approved_iis: this.search.approved_iis, paid_by_ardi: this.search.paid_by_ardi, status: this.search.status})).onSuccess(function(response) {    
          context.table.data = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page 
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getMyTask(param){
      let context = this;               
      Api(context, invoiceMonitoring.getMyTask({invoice_no: this.search.invoice_no, vendor_name: this.search.vendor_name, submited_by: this.search.submited_by, status: this.search.status})).onSuccess(function(response) {    
          context.tableTask.data = response.data.data.data;
          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableTask.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getRole(){
      let context = this;        

      Api(context, akun.indexProfile()).onSuccess(function(response) {
          context.userName = response.data.data[0].name;
      }).onError(function(error) {  
      })
      .call() 
    },
    getSupplier(param){
      let context = this;        
      Api(context, supplier.getSupplier({search: param.target.value})).onSuccess(function(response) {    
          context.tableSupplier.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableSupplier.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Invoice Vendor";
      this.invoiceData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, invoiceMonitoring.show(id)).onSuccess(function(response) {
          context.invoiceData             = response.data.data.data;
          context.invoiceData.vendor_name = response.data.data.dataVendor
          context.form.show               = true;
          context.form.title              = 'Edit Invoice Vendor';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.invoiceData.invoice_no != undefined && context.invoiceData.vendor_name != undefined && context.invoiceData.invoice_date != undefined && context.invoiceData.currency != undefined && context.invoiceData.total_price != undefined) {
        formData.append('invoice_no', context.invoiceData.invoice_no);
        formData.append('vendor_name', JSON.stringify(context.invoiceData.vendor_name));
        formData.append('invoice_date', context.invoiceData.invoice_date);
        formData.append('currency', context.invoiceData.currency);
        formData.append('total_price', context.invoiceData.total_price);
      }else{
        context.onLoading = true;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Invoice Vendor') {
          api = Api(context, invoiceMonitoring.create(formData));
      } else {
          api = Api(context, invoiceMonitoring.update(this.invoiceData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Invoice Vendor') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Invoice Vendor') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, invoiceMonitoring.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    approval(param, approval_to, idData) {
      this.idData = idData;

      if (param == 'Approved') {
        if (approval_to == 'Kursiyawati') {
          this.formSchedulePay.add   = true;
          this.formSchedulePay.show  = true;
          this.formSchedulePay.title = "Create Payment Schedule";
          this.defaultSchedule();
        }else{
          var r = confirm("Are you sure you will approve this invoice ?");
          if (r == true) {
            let context = this;
            context.onLoading = true; 

            Api(context, invoiceMonitoring.approvedTask(idData)).onSuccess(function(response) {
                context.get();
                context.getMyTask();
                context.notify('Successfully approved', 'success')
            }).onFinish(function() {  
              context.onLoading = false;
            }).call();
          }
        }
      }else if(param == 'Rejected'){
        this.formNoteReject.add   = true;
        this.formNoteReject.show  = true;
        this.formNoteReject.title = "Reason Rejected";
        this.noteReject           = '';
      }
    },
    saveApproveSchedulePay(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.schedulePayement != '') {
        formData.append('schedule_payement', context.schedulePayement);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, invoiceMonitoring.createSchedulePayement(context.idData, formData));
      
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.getMyTask();
        context.formSchedulePay.show = false;
        context.notify('Schedule created successfully', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify('Schedule failed to create', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    saveRejected(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.noteReject != '') {
        formData.append('reason_rejected', context.noteReject);
        formData.append('rejected_to', context.rejectTo);
      }else{
        context.onLoading = false;
        return alert('Field is Mandatory')
      }

      api = Api(context, invoiceMonitoring.rejectedTask(context.idData, formData));
      
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.getMyTask();
        context.formNoteReject.show = false;
        context.notify('Schedule created successfully', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify('Schedule failed to create', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    leadTime(startDate, endDate){

      var start = moment(startDate, "YYYY-MM-DD");
      var end = moment(endDate, "YYYY-MM-DD");

      return moment.duration(end.diff(start)).asDays();
    },
    showReason(data){
      this.formNoteReject.add   = true;
      this.formNoteReject.show  = true;
      this.formNoteReject.title = "Show Reason Rejected";

      this.noteReject = data;
    },
    defaultSchedule(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 2)).slice(-2);
      var year  = date.getFullYear();
      var today = date.getFullYear() + "-" + (month) + "-" + (day);

      // jika hari ini kurang atau sama dengan tanggal 15
      if (day <= 15) {
        // bayar tgl 15 bulan berikutnya
        this.schedulePayement = year+'-'+month+'-15'
      }
      // jika hari ini lebih atau sama dengan tanggal 15
      if (day > 15 ) {
        // bayar tgl 30 bulan berikutnya
        this.schedulePayement = year+'-'+month+'-30'
      }
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data";
      this.tabelError.data  = [];
    },
    filesChangeImport(e) {
        this.dataImport = e.target.files[0];
    },
    importData(){
      let api        = null;
      let context    = this;
      let formData   = new FormData();
      context.onLoading = true;

      if (context.dataImport != undefined) {
        formData.append('import_data', context.dataImport);
      }else{
        context.onLoading = false;
        return alert('File Import Not Found')
      }

      api = Api(context, invoiceMonitoring.import(formData));
      api.onSuccess(function(response) {
          context.get();
          context.onLoading       = false;
          context.formImport.show = false;
          context.notify('Import Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data; 
          context.onLoading       = false;             
          context.notify('Import Import Data Failed', 'error')
      }).onFinish(function() {  
          context.onLoading = false;
      })
      .call();
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
    setBg(id) {
      this.backgroundRed = id
    }

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}

.scroll-task { 
  overflow: auto; 
}
.scroll-task thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: red;
}

/*for freze ke samping*/
.scroll table td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: white;
}

/*for freze ke samping*/
.scroll-task table td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: white;
}
</style>
