import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('sales-invoice/index', {params});
    },
    show(id) {
        return Service().get('sales-invoice/show/'+id);
    },
    create(params) {
        return Service().post('sales-invoice/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('sales-invoice/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('sales-invoice/delete/'+id);
    },
    detailInvoice(params) {
        return Service().get('sales-invoice/detail-invoice', {params});
    },
    updateInvoice(id, params) {
        return Service().post('sales-invoice/update-detail-invoice/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    showDoc(id) {
        return Service().get('sales-invoice/show-document/'+id);
    },
    createDoc(params) {
        return Service().post('sales-invoice/create-document', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateDoc(id, params) {
        return Service().post('sales-invoice/update-document/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteDoc(id) {
        return Service().post('sales-invoice/delete-document/'+id);
    },

    salesReport(params) {
        return Service().get('sales-invoice/sales-report', {params});
    },
}