<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/sales-advisor-po?project_number='+search.project_number+'&po_number='+search.po_number+'&customer_name='+search.customer_name['name']+'&end_user_name='+search.end_user_name['name']+'&product_name='+search.product_name+'&main_material='+search.main_material+'&material_category='+search.material_category+'&po_received_date='+search.po_received_date+'&delivery_date='+search.delivery_date+'&currency='+search.currency+'&term_of_payment='+search.term_of_payment+'&vat='+search.vat+'&quotation_number='+search.quotation_number+'&code_regulation='+search.code_regulation+'&type_equipment='+search.type_equipment+'&work_location='+search.work_location+'&plant='+search.plant+'&country_plant='+search.country_plant+'&sales_pic='+search.sales_pic+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/sales-received-order-report?project_number='+search.project_number+'&po_number='+search.po_number+'&customer_name='+search.customer_name['name']+'&end_user_name='+search.end_user_name['name']+'&product_name='+search.product_name+'&main_material='+search.main_material+'&material_category='+search.material_category+'&po_received_date='+search.po_received_date+'&delivery_date='+search.delivery_date+'&currency='+search.currency+'&plant='+search.plant+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a>
   <!--  <div class="row">
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Total Pending"
          :value="convertRp(totalPending)"
          iconClass="fa fa-newspaper-o"
          iconBackground="bg-gradient-secondary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Approved"
          :value="convertRp(totalApproved)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Not Approved"
          :value="convertRp(totalNotApproved)"
          iconClass="fa fa-times-circle"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="On Duty"
          :value="convertRp(totalOnDuty)"
          iconClass="fa fa-car"
          iconBackground="bg-gradient-warning"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Done"
          :value="convertRp(totalDone)"
          iconClass="ni ni-satisfied"
          iconBackground="bg-gradient-info"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Total Request"
          :value="convertRp(totalRequest)"
          iconClass="fa fa-tasks"
          iconBackground="bg-gradient-primary"
          directionReverse
        ></card>
      </div>
    </div> -->
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Received Order Report</h6>
                </div>
              </div>
              <div class="col-lg-6">
              </div>
              <div class="col-lg-2 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: ##F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">YEAR</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">MONTH</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">DATE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">MPO No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">P/O</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">PLAN DELIVERY TEF</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">CUSTOMER NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">END USER</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">PRODUCT NAME</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">MATERIAL</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">CURRENCY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" colspan="2">Received P/O</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder" rowspan="2">Plant</th>
                    </tr>
                    <tr style="background-color: ##F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">(REV)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">QTY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">UNIT PRICE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">TOTAL PRICE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.po_received_date, 'YYYY/MM/DD').locale('en').format('YYYY') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.po_received_date, 'YYYY/MM/DD').locale('en').format('MMM') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.po_received_date, 'YYYY/MM/DD').format('D') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.project_number }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.po_number }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.delivery_date).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.customer_name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.end_user_name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.product_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.main_material }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.currency }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.unit_price) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ convertRp(row.unit_price * row.qty) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.plant }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">PO Received Date </label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.po_received_date"/>
        <label for="example-text-input" class="form-control-label mt-3">MPO NO </label>
        <input type="text" class="form-control" placeholder="MPO NO" v-on:keyup.enter="get()" v-model="search.project_number">
        <label for="example-text-input" class="form-control-label mt-3">PO Number </label>
        <input type="text" class="form-control" placeholder="PO Number" v-on:keyup.enter="get()" v-model="search.po_number">
        <label for="example-text-input" class="form-control-label mt-3">Customer </label>
        <multiselect v-on:keyup.enter="get()" v-model="search.customer_name" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Customer" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">End User Name </label>
        <multiselect v-on:keyup.enter="get()" v-model="search.end_user_name" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Customer" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Product Name </label>
        <input type="text" class="form-control" placeholder="Product Name" v-on:keyup.enter="get()" v-model="search.product_name">
        <label for="example-text-input" class="form-control-label mt-3">Main Material </label>
        <input type="text" class="form-control" placeholder="Main Material" v-on:keyup.enter="get()" v-model="search.main_material">
        <label for="example-text-input" class="form-control-label mt-3">Currency </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.currency">
          <option value="IDR">IDR - Indonesian Rupiah</option>
          <option value="USD">USD - US Dollar</option>
          <option value="KRW">KRW - South Korean Won</option>
          <option value="SGD">SGD - Singapore Dollar</option>
          <option value="JPY">JPY - Japanese Yen</option>
          <option value="EUR">EUR - Euro</option>
          <option value="GBP">GBP - British Pound</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Plant </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.plant">
          <option value="Discovery">Discovery</option>
          <option value="Endeavor">Endeavor</option>
          <option value="Simba">Simba</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
// import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import 'vue-multiselect/dist/vue-multiselect.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import salesAdvisorPo from '@/services/salesAdvisorPo.service';
import customer from '@/services/customer.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    flatPickr,
    // Card,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      onLoading: false,
      storageUrl : config.storageUrl,
      salesAdvisorData: {},
      search: {
        project_number: '',
        po_number: '',
        customer_name: '',
        end_user_name: '',
        product_name: '',
        main_material: '',
        material_category: '',
        po_received_date: '',
        delivery_date: '',
        currency: '',
        plant: '',
      },
      apiUrl :config.apiUrl,
      tableCustomer: {
        data: []
      },

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, salesAdvisorPo.receivedOrderReport({project_number: this.search.project_number, po_number: this.search.po_number, customer_name: this.search.customer_name['name'], end_user_name: this.search.end_user_name['name'], product_name: this.search.product_name, main_material: this.search.main_material, material_category: this.search.material_category, po_received_date: this.search.po_received_date, delivery_date: this.search.delivery_date, currency: this.search.currency, plant: this.search.plant, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getCustomer(param){
      let context = this;        
      Api(context, customer.getCustomer({search: param.target.value})).onSuccess(function(response) {    
          context.tableCustomer.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableCustomer.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    // create() {
    //   this.form.add           = true;
    //   this.form.show          = true;
    //   this.form.title         = "Add Data";
    //   this.salesAdvisorData = {};
    // },
    // edit(id) {
    //   let context = this;               
    //   Api(context, salesAdvisorPo.show(id)).onSuccess(function(response) {
    //     context.salesAdvisorData               = response.data.data.data;
    //     context.salesAdvisorData.customer_name = response.data.data.dataCustomerName[0];
    //     context.salesAdvisorData.end_user_name = response.data.data.dataEndUserName[0];
    //     context.form.show                      = true;
    //     context.form.title                     = 'Edit Data'; 
    //   })
    //   .call()        
    // },
    // copy(id) {
    //   let context = this;               
    //   Api(context, salesAdvisorPo.show(id)).onSuccess(function(response) {
    //     context.salesAdvisorData               = response.data.data.data;
    //     context.salesAdvisorData.customer_name = response.data.data.data.dataCustomerName;
    //     context.salesAdvisorData.end_user_name = response.data.data.data.dataEndUserName;
    //     context.form.show                      = true;
    //     context.form.title                     = 'Copy Value'; 
    //   })
    //   .call()        
    // },
    // save(){
    //   let api      = null;
    //   let context  = this;
    //   let formData = new FormData();
    //   context.onLoading = true;

    //   if (context.salesAdvisorData.project_number, context.salesAdvisorData.po_number != undefined && context.salesAdvisorData.customer_name != undefined && context.salesAdvisorData.end_user_name != undefined && context.salesAdvisorData.product_name != undefined && context.salesAdvisorData.  main_material != undefined && context.salesAdvisorData.material_category != undefined && context.salesAdvisorData.po_received_date != undefined && context.salesAdvisorData.delivery_date != undefined && context.salesAdvisorData.qty != undefined && context.salesAdvisorData.currency != undefined && context.salesAdvisorData.unit_price != undefined && context.salesAdvisorData.exchange_rate != undefined && context.salesAdvisorData.term_of_payment != undefined && context.salesAdvisorData.vat != undefined && context.salesAdvisorData.delay_penalty_clause != undefined && context.salesAdvisorData.cost_estimation_price != undefined && context.salesAdvisorData.quotation_number != undefined && context.salesAdvisorData.code_regulation != undefined && context.salesAdvisorData.type_equipment != undefined && context.salesAdvisorData.work_location != undefined && context.salesAdvisorData.plant != undefined && context.salesAdvisorData.country_plant != undefined && context.salesAdvisorData.sales_pic != undefined) {
    //     formData.append('project_number', context.salesAdvisorData.project_number);
    //     formData.append('po_number', context.salesAdvisorData.po_number);
    //     formData.append('customer_name', JSON.stringify(context.salesAdvisorData.customer_name));
    //     formData.append('end_user_name', JSON.stringify(context.salesAdvisorData.end_user_name));
    //     formData.append('product_name', context.salesAdvisorData.product_name);
    //     formData.append('main_material', context.salesAdvisorData.main_material);
    //     formData.append('material_category', context.salesAdvisorData.material_category);
    //     formData.append('po_received_date', context.salesAdvisorData.po_received_date);
    //     formData.append('delivery_date', context.salesAdvisorData.delivery_date);
    //     formData.append('qty', context.salesAdvisorData.qty);
    //     formData.append('currency', context.salesAdvisorData.currency);
    //     formData.append('unit_price', context.salesAdvisorData.unit_price);
    //     formData.append('exchange_rate', context.salesAdvisorData.exchange_rate);
    //     formData.append('term_of_payment', context.salesAdvisorData.term_of_payment);
    //     formData.append('vat', context.salesAdvisorData.vat);
    //     formData.append('delay_penalty_clause', context.salesAdvisorData.delay_penalty_clause);
    //     formData.append('cost_estimation_price', context.salesAdvisorData.cost_estimation_price);
    //     formData.append('quotation_number', context.salesAdvisorData.quotation_number);
    //     formData.append('code_regulation', context.salesAdvisorData.code_regulation);
    //     formData.append('type_equipment', context.salesAdvisorData.type_equipment);
    //     formData.append('work_location', context.salesAdvisorData.work_location);
    //     formData.append('plant', context.salesAdvisorData.plant);
    //     formData.append('country_plant', context.salesAdvisorData.country_plant);
    //     formData.append('sales_pic', context.salesAdvisorData.sales_pic);
    //   }else{
    //     context.onLoading = false;
    //     return alert('The Red Star Field is Mandatory')
    //   }

    //   if (context.form.title == 'Add Data') {
    //     api = Api(context, salesAdvisorPo.create(formData));
    //   } else if(context.form.title == 'Copy Value'){
    //     api = Api(context, salesAdvisorPo.create(formData));
    //   }else {
    //     api = Api(context, salesAdvisorPo.update(this.salesAdvisorData.id, formData));
    //   }
    //   api.onSuccess(function(response) {
    //     context.get();
    //     context.salesAdvisorData = {}
    //     context.form.show = false;
    //     context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
    //   }).onError(function(error) { 
    //     context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
    //   }).onFinish(function() {  
    //     context.onLoading = false;
    //   })
    //   .call();
    // },
    // remove(id) {
    //   var r = confirm("Are you sure you want to delete this data ?");
    //   if (r == true) {
    //     let context = this;

    //     Api(context, salesAdvisorPo.delete(id)).onSuccess(function(response) {
    //         context.get();
    //         context.notify('Data Successfully Deleted', 'success')
    //     }).call();
    //   }
    // },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
