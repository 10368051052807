import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('loan/index', {params});
    },
    getLoanRemember(params) {
        return Service().get('loan/get-loan-remember', {params});
    },
    getVisual(params) {
        return Service().get('loan/get-visual', {params});
    },
    getLineChart(params) {
        return Service().get('loan/get-line-chart', {params});
    },
    show(id) {
        return Service().get('loan/show/'+id);
    },
    create(params) {
        return Service().post('loan/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('loan/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('loan/delete/'+id);
    },
    detailLoan(params) {
        return Service().get('loan/get-detail-loan', {params});
    },
    createPayHistory(params) {
        return Service().post('loan/create-pay-history', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    updatePayHistory(id, params) {
        return Service().post('loan/update-pay-history/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    deletePayHistory(id) {
        return Service().post('loan/delete-pay-history/'+id);
    },
}