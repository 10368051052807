import Service from '@/helpers/service'

export default {

    index(params) {
        return Service().get('sales-advisor-po/index', {params});
    },
    getPoList(params) {
        return Service().get('sales-advisor-po/get-sales-advisor-po', {params});
    },
    show(id) {
        return Service().get('sales-advisor-po/show/'+id);
    },
    create(params) {
        return Service().post('sales-advisor-po/create', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    update(id, params) {
        return Service().post('sales-advisor-po/update/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete(id) {
        return Service().post('sales-advisor-po/delete/'+id);
    },
    receivedOrderReport(params) {
        return Service().get('sales-advisor-po/received-order-report', {params});
    },
    salesPlanning(params) {
        return Service().get('sales-advisor-po/sales-planning', {params});
    },
    experienceList(params) {
        return Service().get('sales-advisor-po/experience-list', {params});
    },

    // --------------- Payment Term ---------------
    showPaymentTerm(project_number) {
        return Service().get('sales-advisor-po/show-payment-term/'+project_number);
    },
    showEditTermPay(id) {
        return Service().get('sales-advisor-po/show-edit-term-pay/'+id);
    },
    createTermPay(params) {
        return Service().post('sales-advisor-po/create-term-pay', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateTermPay(id, params) {
        return Service().post('sales-advisor-po/update-term-pay/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteTermPay(id) {
        return Service().post('sales-advisor-po/delete-term-pay/'+id);
    },
    
    // --------------- Attachment Email  ---------------
    showAttachment(project_number) {
        return Service().get('sales-advisor-po/show-attach-for-email/'+project_number);
    },
    createAttachEmail(params) {
        return Service().post('sales-advisor-po/create-attach-for-email', params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteAttachEmail(id) {
        return Service().post('sales-advisor-po/delete-attach-for-email/'+id);
    },

    // --------------- Export Control ---------------
    exportControlled(params) {
        return Service().get('sales-advisor-po/export-controlled', {params});
    },
    updateExport(id, params) {
        return Service().post('sales-advisor-po/update-export-control/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    detailExportControl(params) {
        return Service().get('sales-advisor-po/detail-export-control', {params});
    },
    updateExportControl(project_number, params) {
        return Service().post('sales-advisor-po/update-export-control-detail/'+project_number, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },

    // --------------- Project Cost Report  ---------------
    costReport(params) {
        return Service().get('sales-advisor-po/cost-report', {params});
    },

    updateCostReport(id, params) {
        return Service().post('sales-advisor-po/update-cost-report/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
}