<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/machine-list?machine_code='+search.machine_code+'&serial_number='+search.serial_number+'&machine_name='+search.machine_name+'&brand='+search.brand+'&production_year='+search.production_year+'&purchase_date='+search.purchase_date+'&asset_location='+search.asset_location+'&remark='+search.remark+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export to Excel</a>

    <a class="btn btn-sm btn-danger" style="margin-left: 13px;" :href="apiUrl+'print-pdf/machine-list?machine_code='+search.machine_code+'&serial_number='+search.serial_number+'&machine_name='+search.machine_name+'&brand='+search.brand+'&production_year='+search.production_year+'&purchase_date='+search.purchase_date+'&asset_location='+search.asset_location+'&remark='+search.remark+'&status='+search.status+''" target="_BLANK"><i class="fa fa-file fa-sm"></i> Print PDF</a>

    <a class="btn btn-sm btn-warning" style="margin-left: 13px;" :href="apiUrl+'print-pdf/machine-asset-qr-code?machine_code='+search.machine_code+'&serial_number='+search.serial_number+'&machine_name='+search.machine_name+'&brand='+search.brand+'&production_year='+search.production_year+'&purchase_date='+search.purchase_date+'&asset_location='+search.asset_location+'&remark='+search.remark+'&status='+search.status+''" target="_BLANK"><i class="fa fa-qrcode fa-sm"></i> Print QR Code</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-4">
                <div class="card-header pb-0">
                  <h6>Machine / Asset List</h6>
                </div>
              </div>
              <div class="col-4">
              </div>
              <div class="col-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-bordered table-hover align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Image</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Machine Code</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Machine Name</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Serial Number</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Brand</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Production Year</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Purchase Date</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Qty</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Location</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MAINTENANCE SCHEDULE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Remark</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Status</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Machine Group</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Asset Status</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <img
                          :src="storageUrl+'/image_maintenance_aset/'+row.image"
                          class="avatar avatar-lg me-1"
                        />
                      </td>
                      <td class="align-middle text-center text-sm">
                        <router-link :to="/machine-detail/+row.machine_code" target="_BLANK">
                          <span class="badge bg-gradient-warning">{{ row.machine_code }}</span>
                        </router-link>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.machine_name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.serial_number }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.brand }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.production_year }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.purchase_date).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.asset_location }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-info">{{ moment(row.maintenance_schedule).locale('id').format('LL') }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.remark }}</span>
                      </td>
                       <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-success" v-if="row.status == 'Ready'">Ready</span>
                        <span class="badge bg-gradient-danger" v-if="row.status == 'Maintenance'">Maintenance</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.machine_group }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge bg-gradient-info" v-if="row.asset_status == 'Yes'">Yes </span>
                        <span class="badge bg-gradient-danger" v-if="row.asset_status == 'No'">No</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Image <span style="color: red;">*</span></label>
        <input type="file" ref="fileupload" class="form-control" placeholder="Image" @change="filesChange">
        <label for="example-text-input" class="form-control-label mt-3">Serial Number <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Serial Number" v-model="machineListData.serial_number">
        <label for="example-text-input" class="form-control-label mt-3">Machine Name <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Machine Name" v-model="machineListData.machine_name">
        <label for="example-text-input" class="form-control-label mt-3">Brand <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Brand" v-model="machineListData.brand">
        <label for="example-text-input" class="form-control-label mt-3">Production Year <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Production Year" v-model="machineListData.production_year">
        <label for="example-text-input" class="form-control-label mt-3">Purchase Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Purchase Date" v-model="machineListData.purchase_date">
        <label for="example-text-input" class="form-control-label mt-3">Qty <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Qty" v-model="machineListData.qty">
        <label for="example-text-input" class="form-control-label mt-3">Maintenance Schedule <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Maintenance Schedule" v-model="machineListData.maintenance_schedule">
        <label for="example-text-input" class="form-control-label mt-3">Asset Location <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Asset Location" v-model="machineListData.asset_location">
        <label for="example-text-input" class="form-control-label mt-3">Remark <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Remark" v-model="machineListData.remark">
        <label for="example-text-input" class="form-control-label mt-3">Status <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="machineListData.status">
          <option value="Ready">Ready</option>
          <option value="Maintenance">Maintenance</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Machine Group <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Machine Group" v-model="machineListData.machine_group">
        <label for="example-text-input" class="form-control-label mt-3">Asset Status <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="machineListData.asset_status">
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-1">
          <button type="success" class="btn btn-sm btn-success btn-fill" @click="save()" :disabled="onLoading">
            <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
            <span v-else>
                <span>Save </span>
            </span>
          </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Serial Number</label>
        <input type="text" class="form-control" placeholder="Serial Number" v-model="search.serial_number" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Machine Name</label>
        <input type="text" class="form-control" placeholder="Machine Name" v-model="search.machine_name" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Brand</label>
        <input type="text" class="form-control" placeholder="Brand" v-model="search.brand" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Production Year</label>
        <input type="text" class="form-control" placeholder="Production Year" v-model="search.production_year" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Purchase Date</label>
        <input type="text" class="form-control" placeholder="Purchase Date" v-model="search.purchase_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Asset Location</label>
        <input type="text" class="form-control" placeholder="Asset Location" v-model="search.asset_location" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Remark</label>
        <input type="text" class="form-control" placeholder="Remark" v-model="search.remark" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Status </label>
        <select class="form-select" aria-label="Default select example" v-model="search.status">
          <option value="Ready">Ready</option>
          <option value="Maintenance">Maintenance</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Machine Group</label>
        <input type="text" class="form-control" placeholder="Machine Group" v-model="search.machine_group" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Status </label>
        <select class="form-select" aria-label="Default select example" v-model="search.asset_status">
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-6"> 
        </div>
        <div class="col-2" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-1">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
var moment = require('moment');
import machineList from '@/services/machineList.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    BasePagination,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      machineListData: {},
      search: {
        machine_code: '',
        serial_number: '',
        machine_name: '',
        brand: '',
        production_year: '',
        purchase_date: '',
        asset_location: '',
        remark: '',
        status: '',
        machine_group: '',
        asset_status: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      backgroundRed: null,

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, machineList.index({machine_code: this.search.machine_code, serial_number: this.search.serial_number, machine_name: this.search.machine_name, brand: this.search.brand, production_year: this.search.production_year, purchase_date: this.search.purchase_date, asset_location: this.search.asset_location, remark: this.search.remark, status: this.search.status, machine_group: this.search.machine_group, asset_status: this.search.asset_status})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page 

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.machineListData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, machineList.show(id)).onSuccess(function(response) {
          context.machineListData = response.data.data;
          context.form.show      = true;
          context.form.title     = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.machineListData.serial_number != undefined && context.machineListData.machine_name != undefined && context.machineListData.brand != undefined && context.machineListData.production_year != undefined && context.machineListData.purchase_date != undefined && context.machineListData.qty != undefined && context.machineListData.asset_location != undefined && context.machineListData.remark != undefined && context.machineListData.image != undefined && context.machineListData.maintenance_schedule != undefined && context.machineListData.status != undefined && context.machineListData.machine_group != undefined && context.machineListData.asset_status != undefined) {
        formData.append('serial_number', context.machineListData.serial_number);
        formData.append('machine_name', context.machineListData.machine_name);
        formData.append('brand', context.machineListData.brand);
        formData.append('production_year', context.machineListData.production_year);
        formData.append('purchase_date', context.machineListData.purchase_date);
        formData.append('qty', context.machineListData.qty);
        formData.append('maintenance_schedule', context.machineListData.maintenance_schedule);
        formData.append('asset_location', context.machineListData.asset_location);
        formData.append('remark', context.machineListData.remark);
        formData.append('status', context.machineListData.status);
        formData.append('machine_group', context.machineListData.machine_group);
        formData.append('asset_status', context.machineListData.asset_status);
        formData.append('image', context.machineListData.image);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, machineList.create(formData));
      } else {
          api = Api(context, machineList.update(this.machineListData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, machineList.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    filesChange(e) {
        this.machineListData.image = e.target.files[0];
    },
    modalImport(){
      this.formImport.show  = true;
      this.formImport.title = "Import Data Setting Approval";
      this.tabelError.data  = [];
    },
    importData(){
      let api = null;
      let context = this;
      let formData = new FormData();
      this.onLoading = true;

      if (this.dataImport != undefined) {
        formData.append('import_data', this.dataImport);
      }else{
        return alert('File Import Not Found')
      }

      api = Api(context, machineList.import(formData));
      api.onSuccess(function(response) {
          context.onLoading = false;
          context.get();
          context.formImport.show = false;
          context.notify('Import Data Success', 'success')
      }).onError(function(error) {      
          context.tabelError.data = error.response.data.data;              
          context.notify('Import Data Failed', 'error')
          context.onLoading = false;
      }).onFinish(function() {  
      })
      .call();
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    setBg(id) {
      this.backgroundRed = id
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
/*for freze ke samping*/
.scroll table td:nth-child(4) {
  position: sticky;
  left: 0;
  background-color: #F0E68C;
}
</style>
