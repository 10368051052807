<template>
  <div class="py-4 container-fluid">
    <!-- <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/sales-planning?project_number='+search.project_number+'&po_number='+search.po_number+'&customer_name='+search.customer_name['name']+'&end_user_name='+search.end_user_name['name']+'&product_name='+search.product_name+'&main_material='+search.main_material+'&material_category='+search.material_category+'&po_received_date='+search.po_received_date+'&delivery_date='+search.delivery_date+'&currency='+search.currency+'&term_of_payment='+search.term_of_payment+'&vat='+search.vat+'&quotation_number='+search.quotation_number+'&code_regulation='+search.code_regulation+'&type_equipment='+search.type_equipment+'&work_location='+search.work_location+'&plant='+search.plant+'&country_plant='+search.country_plant+'&sales_pic='+search.sales_pic+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a> -->
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/sales-planning?project_number='+search.project_number+'&po_number='+search.po_number+'&customer_name='+search.customer_name['name']+'&end_user_name='+search.end_user_name['name']+'&product_name='+search.product_name+'&main_material='+search.main_material+'&material_category='+search.material_category+'&po_received_date='+search.po_received_date+'&delivery_date='+search.delivery_date+'&currency='+search.currency+'&term_of_payment='+search.term_of_payment+'&vat='+search.vat+'&quotation_number='+search.quotation_number+'&code_regulation='+search.code_regulation+'&type_equipment='+search.type_equipment+'&work_location='+search.work_location+'&plant='+search.plant+'&country_plant='+search.country_plant+'&sales_pic='+search.sales_pic+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Sales Planning</h6>
                </div>
              </div>
              <div class="col-lg-6">
              </div>
              <div class="col-lg-2 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-striped table-sm table-hover table-bordered align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">Fiscal Year</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">YY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MM</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DD</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PROJECT NO.</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CUSTOMER</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">COUNTRY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PRODUCT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MAIN MATERIAL</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">MATERIAL CATEGORY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CODE / REGULATION</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">QTY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PRICE (JPY)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PRICE (K.IDR)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">COUNTRY - PLANT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PLANT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="moment(row.delivery_date, 'YYYY/MM/DD').format('M') == 1">FY{{ moment(row.delivery_date).subtract(1, 'year').format('YYYY') }}</span>
                        <span class="text-secondary text-xs font-weight-bold" v-else-if="moment(row.delivery_date, 'YYYY/MM/DD').format('M') == 2">FY{{ moment(row.delivery_date).subtract(1, 'year').format('YYYY') }}</span>
                        <span class="text-secondary text-xs font-weight-bold" v-else-if="moment(row.delivery_date, 'YYYY/MM/DD').format('M') == 3">FY{{ moment(row.delivery_date).subtract(1, 'year').format('YYYY') }}</span>
                        <span class="text-secondary text-xs font-weight-bold" v-else>FY{{ moment(row.delivery_date, 'YYYY/MM/DD').format('YYYY') }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.delivery_date, 'YYYY/MM/DD').format('YYYY') }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.delivery_date, 'YYYY/MM/DD').format('M') }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.delivery_date, 'YYYY/MM/DD').format('D') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.project_number }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.customer_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.country_plant }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.product_name }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.main_material }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.material_category }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.code_regulation }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.qty }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="row.currency === 'JPY'">{{ convertRp(row.unit_price) }}</span>
                        <span class="text-secondary text-xs font-weight-bold" v-if="row.currency === 'IDR'">{{ convertRp( (row.unit_price*1000/104.45) ) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold" v-if="row.currency === 'JPY'">{{ convertRp( (row.unit_price*104.45)/1000 ) }}</span>
                        <span class="text-secondary text-xs font-weight-bold" v-if="row.currency === 'IDR'">{{ convertRp(row.unit_price/1000) }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.country_plant }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.plant }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project No. </label>
        <input type="text" class="form-control" placeholder="Project No." v-on:keyup.enter="get()" v-model="search.project_number">
        <label for="example-text-input" class="form-control-label mt-3">End User Name </label>
        <multiselect v-on:keyup.enter="get()" v-model="search.end_user_name" :options="tableCustomer.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Customer" label="name" track-by="name" :preselect-first="true" @keyup="getCustomer">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Product Name </label>
        <input type="text" class="form-control" placeholder="Product Name" v-on:keyup.enter="get()" v-model="search.product_name">
        <label for="example-text-input" class="form-control-label mt-3">Type </label>
        <select class="form-select" aria-label="Default select example" v-model="search.type_equipment">
          <option value="V">V - Vessel</option>
          <option value="H">H - Heat Exchanger</option>
          <option value="P">P - Piping</option>
          <option value="M">M - Mufle</option>
          <option value="R">R - Radiant Tube</option>
          <option value="S">S - Service</option>
          <option value="O">O - Other</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Main Material </label>
        <input type="text" class="form-control" placeholder="Main Material" v-on:keyup.enter="get()" v-model="search.main_material">
        <label for="example-text-input" class="form-control-label mt-3">Code/Regulation </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.code_regulation">
          <option value="ASME">ASME</option>
          <option value="ASME NO STAMP">ASME NO STAMP</option>
          <option value="HPGSL/TOKUTEI">HPGSL/TOKUTEI</option>
          <option value="ICHIATSU">ICHIATSU</option>
          <option value="NIATSU">NIATSU</option>
          <option value="SHOBO">SHOBO</option>
          <option value="KEMENAKER">KEMENAKER</option>
          <option value="NON-CODE">NON-CODE</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Delivery </label>
        <input type="date" class="form-control" placeholder="Delivery" v-on:keyup.enter="get()" v-model="search.delivery_date">
        <label for="example-text-input" class="form-control-label mt-3">Country, Shipped </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.country_plant">
          <option value="Indonesia">Indonesia</option>
          <option value="Japan">Japan</option>
          <option value="Singapore">Singapore</option>
          <option value="Malaysia">Malaysia</option>
          <option value="Thailand">Thailand</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Plant </label>
        <select class="form-select" aria-label="Default select example" v-on:keyup.enter="get()" v-model="search.plant">
          <option value="Discovery">Discovery</option>
          <option value="Endeavor">Endeavor</option>
          <option value="Simba">Simba</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
// import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
var moment = require('moment');
import salesAdvisorPo from '@/services/salesAdvisorPo.service';
import customer from '@/services/customer.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    // Card,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      // tablePayTerm: {
      //   data: []
      // },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      // formTermPay: {
      //   add: true,
      //   title: "Term Of Payment",
      //   show: false
      // },
      // formAddTermPay: {
      //   add: true,
      //   title: "Add Term Of Payment",
      //   show: false
      // },
      // dataImport: '',
      onLoading: false,
      // tabelError: {
      //   data: []
      // },
      storageUrl : config.storageUrl,
      dataExportCon: {
        export_control_status_yes: [],
        export_control_status_no: [],
      },
      // termPayData: {},
      search: {
        project_number: '',
        po_number: '',
        customer_name: '',
        end_user_name: '',
        product_name: '',
        main_material: '',
        material_category: '',
        po_received_date: '',
        delivery_date: '',
        currency: '',
        term_of_payment: '',
        vat: '',
        quotation_number: '',
        code_regulation: '',
        type_equipment: '',
        work_location: '',
        plant: '',
        country_plant: '',
        sales_pic: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      // tablePlant: {
      //   data: []
      // },
      // tablePic: {
      //   data: []
      // },
      tableCustomer: {
        data: []
      },

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, salesAdvisorPo.salesPlanning({project_number: this.search.project_number, po_number: this.search.po_number, customer_name: this.search.customer_name['name'], end_user_name: this.search.end_user_name['name'], product_name: this.search.product_name, main_material: this.search.main_material, material_category: this.search.material_category, po_received_date: this.search.po_received_date, delivery_date: this.search.delivery_date, currency: this.search.currency, term_of_payment: this.search.term_of_payment, vat: this.search.vat, quotation_number: this.search.quotation_number, code_regulation: this.search.code_regulation, type_equipment: this.search.type_equipment, work_location: this.search.work_location, plant: this.search.plant, country_plant: this.search.country_plant, sales_pic: this.search.sales_pic, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.table.data.forEach((staffDetail) => {
            context.dataExportCon.export_control_status_yes[staffDetail.id] = staffDetail.export_control_status_yes
            context.dataExportCon.export_control_status_no[staffDetail.id] = staffDetail.export_control_status_no
          });
          context.pagination.page_count = response.data.data.data.last_page
          context.totalPending          = response.data.data.totalPending
          context.totalApproved         = response.data.data.totalApproved
          context.totalNotApproved      = response.data.data.totalNotApproved
          context.totalOnDuty           = response.data.data.totalOnDuty
          context.totalDone             = response.data.data.totalDone
          context.totalRequest          = response.data.data.totalRequest

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getCustomer(param){
      let context = this;        
      Api(context, customer.getCustomer({search: param.target.value})).onSuccess(function(response) {    
          context.tableCustomer.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableCustomer.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    // create() {
    //   this.form.add           = true;
    //   this.form.show          = true;
    //   this.form.title         = "Add Data";
    //   this.salesAdvisorData = {};
    // },
    // edit(id) {
    //   let context = this;               
    //   Api(context, salesAdvisorPo.show(id)).onSuccess(function(response) {
    //     context.salesAdvisorData               = response.data.data.data;
    //     context.salesAdvisorData.customer_name = response.data.data.dataCustomerName[0];
    //     context.salesAdvisorData.end_user_name = response.data.data.dataEndUserName[0];
    //     context.form.show                      = true;
    //     context.form.title                     = 'Edit Data'; 
    //   })
    //   .call()        
    // },
    // copy(id) {
    //   let context = this;               
    //   Api(context, salesAdvisorPo.show(id)).onSuccess(function(response) {
    //     context.salesAdvisorData               = response.data.data.data;
    //     context.salesAdvisorData.customer_name = response.data.data.data.dataCustomerName;
    //     context.salesAdvisorData.end_user_name = response.data.data.data.dataEndUserName;
    //     context.form.show                      = true;
    //     context.form.title                     = 'Copy Value'; 
    //   })
    //   .call()        
    // },
    updateExport(id){
      let api         = null;
      let context     = this;
      let formData    = new FormData();

      console.log(id, context.dataExportCon.export_control_status_no[id])

      formData.append('export_control_status_yes', context.dataExportCon.export_control_status_yes[id]);
      formData.append('export_control_status_no', context.dataExportCon.export_control_status_no[id]);
      formData.append('remarks_export', event.target.value);

      api = Api(context, salesAdvisorPo.updateExport(id, formData));

      api.onSuccess(function(response) {
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    // remove(id) {
    //   var r = confirm("Are you sure you want to delete this data ?");
    //   if (r == true) {
    //     let context = this;

    //     Api(context, salesAdvisorPo.delete(id)).onSuccess(function(response) {
    //         context.get();
    //         context.notify('Data Successfully Deleted', 'success')
    //     }).call();
    //   }
    // },
    // paymentTerm(project_number) {
    //   let context = this;               
    //   context.termPayData.project_number = project_number;
      
    //   Api(context, salesAdvisorPo.showPaymentTerm(project_number)).onSuccess(function(response) {
    //     context.tablePayTerm.data  = response.data.data;
    //     context.formTermPay.show   = true;
    //     context.formTermPay.title  = 'Term Of Payment'; 
    //   })
    //   .call()        
    // },
    // createTermPay() {
    //   this.formAddTermPay.add   = true;
    //   this.formAddTermPay.show  = true;
    //   this.formAddTermPay.title = "Add Term Of Payment";
    //   // this.termPayData          = {};
    // },
    // editTermPay(id) {
    //   let context = this;               
    //   Api(context, salesAdvisorPo.showEditTermPay(id)).onSuccess(function(response) {
    //     context.termPayData          = response.data.data;
    //     context.formAddTermPay.show  = true;
    //     context.formAddTermPay.title = 'Edit Term Of Payment'; 
    //   })
    //   .call()        
    // },
    // saveTermPay(){
    //   let api      = null;
    //   let context  = this;
    //   let formData = new FormData();
    //   context.onLoading = true;

    //   if (context.termPayData.term_payment != undefined && context.termPayData.percentage != undefined && context.termPayData.periode != undefined) {
    //     formData.append('project_number', context.termPayData.project_number);
    //     formData.append('term_payment', context.termPayData.term_payment);
    //     formData.append('percentage', context.termPayData.percentage);
    //     formData.append('periode', context.termPayData.periode);
    //     formData.append('note', (context.termPayData.note == undefined) ? '' : context.termPayData.note);
    //   }else{
    //     context.onLoading = false;
    //     return alert('The Red Star Field is Mandatory')
    //   }

    //   if (context.formAddTermPay.title == 'Add Term Of Payment') {
    //     api = Api(context, salesAdvisorPo.createTermPay(formData));
    //   } else {
    //     api = Api(context, salesAdvisorPo.updateTermPay(this.termPayData.id, formData));
    //   }
    //   api.onSuccess(function(response) {
    //     context.paymentTerm(context.termPayData.project_number);
    //     context.termPayData = {}
    //     context.formAddTermPay.show   = false;
    //     context.notify((context.form === 'Add Term Of Payment') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
    //   }).onError(function(error) { 
    //     context.notify((context.form != 'Add Term Of Payment') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
    //   }).onFinish(function() {  
    //     context.onLoading = false;
    //   })
    //   .call();
    // },
    // removeTermPay(id) {
    //   var r = confirm("Are you sure you want to delete this data ?");
    //   if (r == true) {
    //     let context = this;

    //     Api(context, salesAdvisorPo.deleteTermPay(id)).onSuccess(function(response) {
    //         context.paymentTerm(context.tablePayTerm.data[0].project_number);
    //         context.notify('Data Successfully Deleted', 'success')
    //     }).call();
    //   }
    // },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}
</style>
