<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <!-- <div class="container-fluid"> -->
            <div class="top-0 my-auto text-center col-12 d-lg-flex h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div class="position-relative bg-gradient-primary h-100 m-3  border-radius-lg d-flex flex-column justify-content-center overflow-hidden bg">
                <span class="mask bg-gradient-dark opacity-7"></span>
                <h5 class="mt-0 text-white font-weight-bolder position-relative"> <img src="@/assets/img/logo-tef.png" width="290"></h5>
                <h5 class="mt-0 text-white font-weight-bolder position-relative" style="text-shadow: 2px 2px 4px black;">PT. TOKKI ENGINEERING AND FABRICATION</h5>
                <h4 class="mt-0 text-white font-weight-bolder position-relative" style="text-shadow: 2px 2px 4px black;">UPDATE PROGRESS PIPING PROJECT</h4>
                <div class="container-fluid scroll">
                  <div class="row justify-content-center mt-3">
                    <div class="col-lg-3 card mb-6">
                      <div class="card-body text-start">
                        <label for="example-text-input" class="form-control-label mt-3">Project No. <span style="color: red;">*</span></label>
                        <select class="form-select" aria-label="Default select example" @change="changeProject()" v-model="monitoringData.project_number">
                          <option value="C0411" >C0411</option>
                          <option value="C0393" >C0393</option>
                          <option value="C0392" >C0392</option>
                          <option value="E0027" >E0027</option>
                        </select>
                        <label for="example-text-input" class="form-control-label mt-3">Division <span style="color: red;">*</span></label>
                        <select class="form-select" aria-label="Default select example" v-model="monitoringData.division">
                          <option value="Engineering">Engineering</option>
                          <option value="PC">PC</option>
                          <option value="QC">QC</option>
                        </select>
                        <label for="example-text-input" class="form-control-label mt-3">Input Type <span style="color: red;">*</span></label>
                        <select class="form-select" aria-label="Default select example" v-model="monitoringData.input_type">
                          <option value="Submit DWG" v-if="monitoringData.division == 'Engineering'">Submit DWG</option>
                          <option value="MTO" v-if="monitoringData.division == 'Engineering'">MTO</option>
                          <option value="Material Preparation" v-if="monitoringData.division == 'PC'">Material Preparation</option>
                          <option value="Fit Up" v-if="monitoringData.division == 'PC'">Fit Up</option>
                          <option value="Welding" v-if="monitoringData.division == 'PC'">Welding</option>
                          <option value="RFI" v-if="monitoringData.division == 'QC'">RFI</option>
                          <option value="Visual" v-if="monitoringData.division == 'QC'">Visual</option>
                          <option value="PMI" v-if="monitoringData.division == 'QC'">PMI</option>
                          <option value="Ferrite" v-if="monitoringData.division == 'QC'">Ferrite</option>
                          <option value="Radiograph" v-if="monitoringData.division == 'QC'">Radiograph</option>
                          <option value="Preasure Test" v-if="monitoringData.division == 'QC'">Preasure Test</option>
                          <option value="Report" v-if="monitoringData.division == 'QC'">Report</option>
                          <option value="Pickling" v-if="monitoringData.division == 'PC'">Pickling</option>
                          <option value="WCN" v-if="monitoringData.division == 'PC'">WCN</option>
                        </select>
                        <label for="example-text-input" class="form-control-label mt-3">Spool No <span style="color: red;">*</span></label>
                        <multiselect v-model="monitoringData.spool_no" :options="tableSpool.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Spool No" label="spool_no" track-by="spool_no" :preselect-first="true" @keyup="getMaterialPiping" @select="getMaterialJointId()" :loading="isLoading">
                          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
                        </multiselect>
                        <label for="example-text-input" class="form-control-label mt-3">Joint Id <span style="color: red;">*</span></label>
                        <multiselect v-model="monitoringData.joint_id" :options="tableJointId.data" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Joint Id" label="joint_id" track-by="joint_id" :preselect-first="true" :group-select="true" :group-label="paramProject">
                          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
                        </multiselect>
                        <!-- <select class="form-select" aria-label="Default select example" v-model="monitoringData.joint_id" >
                          <option :value="row.joint_id" v-for="(row, i) in tableJointId.data" :key="i">{{ row.joint_id }}</option>
                        </select> -->
                        <label for="example-text-input" class="form-control-label mt-3" v-if="monitoringData.division == 'QC'">W-Stamp </label>
                        <input type="text" class="form-control" placeholder="W-Stamp" v-model="monitoringData.w_stamp" v-if="monitoringData.division == 'QC'">
                        <label for="example-text-input" class="form-control-label mt-3" v-if="monitoringData.division == 'QC'">RFI No </label>
                        <input type="number" class="form-control mb-3" placeholder="RFI No" v-model="monitoringData.rfi_no" v-if="monitoringData.division == 'QC'">
                        <label for="example-text-input" class="form-control-label mt-3">Date <span style="color: red;">*</span></label>
                        <input type="date" class="form-control" placeholder="Date" v-model="monitoringData.input_date">
                      </div>
                      <div class="card-footer bg-dark mb-4" style="height: 60px;">
                        <button type="success" style="margin-top: -10px;" class="btn btn-sm btn-success btn-fill" @click="updateDone()" :disabled="onLoading">
                          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
                          <span v-else>
                              <span>Done <i class="fa fa-paper-plane mr-5" aria-hidden="true"></i></span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </section>
  </main>
</template>

<script>
// import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];
import config from '@/configs/config';
import axios from 'axios';
import Api from '@/helpers/api';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import materialPiping from '@/services/materialPiping.service';

export default {
  name: "signin",
  components: {
    // ArgonButton,
    Multiselect,
  },
  data() {
    return {
      username: '',
      password: '',
      onLoading: false,
      monitoringData: {
        project_number: '',
      },
      tableSpool: {
        data: []
      },
      tableJointId: {
        data: []
      },
      paramProject: '',
      isLoading: false,
    };
  },
  mounted(){
    this.defaultDate();
    // this.monitoringData.project_number = this.paramProject;
    // this.getPrivilage();
    // this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    // nameWithLang ({ spool_no, joint_id }) {
    //   return `${spool_no} - ${joint_id}`
    // },
    changeProject(param){
      localStorage.setItem('paramProject', this.monitoringData.project_number)
    },
    getMaterialPiping(param){
      if (param.target.value.length >= 2) {
        let context = this;        
        context.isLoading = true
        Api(context, materialPiping.getMaterialPiping({project_number: this.monitoringData.project_number, search: param.target.value})).onSuccess(function(response) {    
            context.tableSpool.data = response.data.data;
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.tableSpool.data = [];
            }
        }).onFinish(function() { 
          context.isLoading = false
        })
        .call()
      }
    },
    getMaterialJointId(){
      let context = this;        
      // alert(context.monitoringData.spool_no['spool_no'])

      Api(context, materialPiping.getMaterialJointId({project_number: this.monitoringData.project_number, spool_no: context.monitoringData.spool_no['spool_no'], input_type: context.monitoringData.input_type})).onSuccess(function(response) {    
          context.tableJointId.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableJointId.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    updateDone(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.monitoringData.project_number != undefined && context.monitoringData.division != undefined && context.monitoringData.input_type != undefined && context.monitoringData.spool_no != undefined && context.monitoringData.joint_id != undefined && context.monitoringData.input_date != undefined) {
        formData.append('project_number', context.monitoringData.project_number);
        formData.append('division', context.monitoringData.division);
        formData.append('input_type', context.monitoringData.input_type);
        formData.append('spool_no', context.monitoringData.spool_no['spool_no']);
        formData.append('joint_id', JSON.stringify(context.monitoringData.joint_id));
        formData.append('w_stamp', (context.monitoringData.w_stamp == undefined) ? '' : context.monitoringData.w_stamp);
        formData.append('rfi_no', (context.monitoringData.rfi_no == undefined) ? '' : context.monitoringData.rfi_no);
        formData.append('input_date', context.monitoringData.input_date);
      }else{
        context.onLoading = false;
        return alert('The Red Star Field is Mandatory')
      }

      api = Api(context, materialPiping.updateDone(formData));
      api.onSuccess(function(response) {
        context.monitoringData.input_type = ''
        context.monitoringData.spool_no   = ''
        context.monitoringData.joint_id   = ''
        context.monitoringData.w_stamp    = ''
        context.monitoringData.rfi_no     = ''
        context.notify('Successful', 'success')
      }).onError(function(error) { 
        context.notify('Failed', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    defaultDate(){
      var date  = new Date();
      var day   = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var today = date.getFullYear() + "-" + (month) + "-" + (day);

      this.monitoringData.input_date = today
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped lang="scss">
.bg {
  background-image: url('@/assets/img/tef.jpeg');
  background-size: 100%;
}
.scroll { 
  overflow: auto; 
  height: 100; 
}
</style>
