<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'export-excel/vehicle-request?request_no='+search.request_no+'&pic='+search.pic['nik']+'&plant='+search.plant['code']+'&destination='+search.destination+'&agenda='+search.agenda+'&departure_date='+search.departure_date+'&return_date='+search.return_date+'&vehicle_type='+search.vehicle_type+'&vehicle='+search.vehicle+'&approved_by='+search.approved_by['name']+'&status='+search.status+''" target="_BLANK"><i class="fa fa-download fa-sm"></i> Export</a>
    <a class="btn btn-sm btn-danger" style="margin-right: 10px;" :href="apiUrl+'print-pdf/vehicle-request?request_no='+search.request_no+'&pic='+search.pic['nik']+'&plant='+search.plant['code']+'&destination='+search.destination+'&agenda='+search.agenda+'&departure_date='+search.departure_date+'&return_date='+search.return_date+'&vehicle_type='+search.vehicle_type+'&vehicle='+search.vehicle+'&approved_by='+search.approved_by['name']+'&status='+search.status+''" target="_BLANK"><i class="fa fa-file-pdf-o fa-sm"></i> Print PDF</a>
    <div class="row">
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Total Pending"
          :value="convertRp(totalPending)"
          iconClass="fa fa-newspaper-o"
          iconBackground="bg-gradient-secondary"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Approved"
          :value="convertRp(totalApproved)"
          iconClass="fa fa-check-circle"
          iconBackground="bg-gradient-success"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Not Approved"
          :value="convertRp(totalNotApproved)"
          iconClass="fa fa-times-circle"
          iconBackground="bg-gradient-danger"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="On Duty"
          :value="convertRp(totalOnDuty)"
          iconClass="fa fa-car"
          iconBackground="bg-gradient-warning"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Done"
          :value="convertRp(totalDone)"
          iconClass="ni ni-satisfied"
          iconBackground="bg-gradient-info"
          directionReverse
        ></card>
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <card
          title="Total Request"
          :value="convertRp(totalRequest)"
          iconClass="fa fa-tasks"
          iconBackground="bg-gradient-primary"
          directionReverse
        ></card>
      </div>
    </div>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Vehicle Management</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover align-items-center mb-0">
                  <thead>
                    <tr style="background-color: #F0F8FF;">
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Request No.</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">PIC</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Plant</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Passenger</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Destination</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Agenda</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Departure Date</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Return Date</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Clock Gone</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Clock Back</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Vehicle Type</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Vehicle</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Status</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Approved By</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                          <span class="badge badge-sm bg-gradient-primary">{{ row.request_no }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.pic }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.plant_name }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <div v-for="(relPass, j) in row.rel_passenger" :key="j">
                          {{j + 1}}. <span class="badge badge-sm bg-gradient-success">{{ relPass.rel_passenger_nik.name_passenger }}</span>
                        </div>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.destination }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.agenda }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.departure_date).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ moment(row.return_date).locale('en').format('ll') }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.clock_gone }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.clock_back }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.vehicle_type }}</span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.vehicle }}</span>
                        <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">VEHICLE &nbsp;:&nbsp; {{ row.vehicle_name }} </span></span><br>
                        <span class="text-secondary text-xs font-weight-bold"><span class="font-weight-bold text-dark">DRIVER &nbsp;&nbsp; :&nbsp; {{ row.driver_name }}</span></span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="badge badge-sm bg-gradient-secondary" v-if="row.status == 'Pending'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-success" v-if="row.status == 'Approved'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-danger" v-if="row.status == 'Not Approved'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-warning" v-if="row.status == 'On Duty'">{{ row.status }}</span>
                        <span class="badge badge-sm bg-gradient-info" v-if="row.status == 'Done'">{{ row.status }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ row.approved_by }}</span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)" title="Edit"></i>
                        <i class="fas fa-trash fa-sm" aria-hidden="true" title="Hapus" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>

                        <i class="fa fa-check-square fa-sm" aria-hidden="true" title="Approve" style="cursor: pointer; margin-right: 20px;" @click="approval(row.id, 'Approved')"></i>
                        <i class="fa fa-times fa-sm" aria-hidden="true" title="Not Approve" style="cursor: pointer; margin-right: 20px;" @click="approval(row.id, 'Not Approved')"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-left">TOTAL ROWS : {{table.data.length}} </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Plant <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="vehicleRequestData.plant" @change="getVehicle(), vehicleRequestData.vehicle = ''">
          <option value="PLANT-001">Discovery</option>
          <option value="PLANT-002">Endeavor</option>
          <option value="PLANT-003">Simba</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">PIC <span style="color: red;">*</span></label>
        <multiselect v-model="vehicleRequestData.pic" :options="tablePic.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select PIC" label="name" track-by="name" :preselect-first="true" @keyup="getPic">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Passenger <span style="color: red;">*</span></label>
        <multiselect v-model="vehicleRequestData.passenger" :options="tablePassenger.data" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Passenger" label="name" track-by="name" :preselect-first="true" @keyup="getPassenger">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Destination <span style="color: red;">*</span></label>
        <textarea class="form-control" v-model="vehicleRequestData.destination" placeholder="Destination"></textarea>
        <label for="example-text-input" class="form-control-label mt-3">Agenda <span style="color: red;">*</span></label>
        <textarea class="form-control" v-model="vehicleRequestData.agenda" placeholder="Agenda"></textarea>
        <label for="example-text-input" class="form-control-label mt-3">Departure Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Departure Date" v-model="vehicleRequestData.departure_date" @change="getVehicle(), vehicleRequestData.vehicle = ''">
        <label for="example-text-input" class="form-control-label mt-3">Return Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Return Date" v-model="vehicleRequestData.return_date" :min="vehicleRequestData.departure_date" @change="getVehicle(), vehicleRequestData.vehicle = ''">
        <label for="example-text-input" class="form-control-label mt-3">Clock Gone <span style="color: red;">*</span></label>
        <flat-pickr :config="{ enableTime: 'true', noCalendar: 'true', dateFormat: 'H:i', time_24hr: 'true',}" class="form-control" v-model="vehicleRequestData.clock_gone"/>
        <label for="example-text-input" class="form-control-label mt-3">Clock Back <span style="color: red;">*</span></label>
        <flat-pickr :config="{ enableTime: 'true', noCalendar: 'true', dateFormat: 'H:i', time_24hr: 'true', minTime:'vehicleRequestData.clock_gone'}" class="form-control" v-model="vehicleRequestData.clock_back" />
        <label for="example-text-input" class="form-control-label mt-3">Vehicle Type <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="vehicleRequestData.vehicle_type" @change="getVehicle(), vehicleRequestData.vehicle = ''">
          <option value="Passenger Car">Passenger Car</option>
          <option value="Pick Up Car">Pick Up Car</option>
          <option value="Truck">Truck</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Vehicle Available <span style="color: red;">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="vehicleRequestData.vehicle" >
          <option :value="row.code" v-for="(row, i) in tableVehicle.data" :key="i">{{ row.vehicle_name }} - {{ row.driver_name }}</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3" v-if="form.title == 'Edit Data'">Status <span style="color: red;">*</span></label>
        <select class="form-select mb-3" aria-label="Default select example" v-model="vehicleRequestData.status" v-if="form.title == 'Edit Data'">
          <!-- <option value="Pending">Pending</option> -->
          <!-- <option value="Approved">Approved</option> -->
          <!-- <option value="Not Approved">Not Approved</option> -->
          <option value="On Duty">On Duty</option>
          <option value="Done">Done</option>
        </select>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-lg-1 col-2">
        <button type="primary" class="btn btn-sm btn-info btn-fill" @click="sendRequest()" :disabled="onLoading">
          <span v-if="onLoading"><i class="fa fa-spinner fa-spin"></i> Please Wait...</span>
          <span v-else>
              <span>Save</span>
          </span>
        </button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

   <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Request No.</label>
        <input type="text" class="form-control" placeholder="Request No." v-model="search.request_no" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">PIC</label>
        <multiselect v-model="search.pic" :options="tablePic.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select PIC" label="name" track-by="name" :preselect-first="true" @keyup="getPic" v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Destination</label>
        <input type="text" class="form-control" placeholder="Destination" v-model="search.destination" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Agenda</label>
        <input type="text" class="form-control" placeholder="Agenda" v-model="search.agenda" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Departure Date</label>
        <input type="date" class="form-control" placeholder="Departure Date" v-model="search.departure_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Return Date</label>
        <input type="date" class="form-control" placeholder="Return Date" v-model="search.return_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Vehicle Type</label>
        <select class="form-select" aria-label="Default select example" v-model="search.vehicle_type" v-on:keyup.enter="get()">
          <option value="Pending">Passenger Car</option>
          <option value="Pick Up Car">Pick Up Car</option>
          <option value="Truck">Truck</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Status</label>
        <select class="form-select" aria-label="Default select example" v-model="search.status" v-on:keyup.enter="get()">
          <option value="Pending">Pending</option>
          <option value="Approved">Approved</option>
          <option value="Not Approved">Not Approved</option>
          <option value="On Duty">On Duty</option>
          <option value="Done">Done</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">Plant <span style="color: red;">*</span></label>
        <multiselect v-model="search.plant" :options="tablePlant.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Plant" label="name" track-by="name" :preselect-first="true" @keyup="getPlant" v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
        <label for="example-text-input" class="form-control-label mt-3">Approved By <span style="color: red;">*</span></label>
        <multiselect class="mb-3" v-model="search.approved_by" :options="tablePic.data" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Approved By" label="name" track-by="name" :preselect-first="true" @keyup="getPic" v-on:keyup.enter="get()">
          <slot name="selection"><slot name="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></slot></slot>
        </multiselect>
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-lg-6 col-4"> 
        </div>
        <div class="col-lg-2 col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-lg-1 col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
import BasePagination from "@/components/BasePagination";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
var moment = require('moment');
import vehicleManagement from '@/services/vehicleManagement.service';
import vehicleList from '@/services/vehicleList.service';
import plant from '@/services/plant.service';
import employee from '@/services/employee.service';
import vehicleRequest from '@/services/vehicleRequest.service';

export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    Multiselect,
    BasePagination,
    Card,
    flatPickr,
  },
  data() {
    return {
      moment:moment,
      pagination: {
        page_count: '',
        page: 1,
      },
      table: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      vehicleRequestData: {},
      search: {
        request_no: '',
        pic: '',
        plant: '',
        destination: '',
        agenda: '',
        departure_date: '',
        return_date: '',
        vehicle_type: '',
        vehicle: '',
        status: '',
        approved_by: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      tablePlant: {
        data: []
      },
      tablePic: {
        data: []
      },
      tablePassenger: {
        data: []
      },
      tableVehicle: {
        data: []
      },
      totalPending: '',
      totalApproved: '',
      totalNotApproved: '',
      totalOnDuty: '',
      totalDone: '',
      totalRequest: '',

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    get(param){
      let context = this;               
      Api(context, vehicleManagement.index({request_no: this.search.request_no, pic: this.search.pic['nik'], plant: this.search.plant['code'], destination: this.search.destination, agenda: this.search.agenda, departure_date: this.search.departure_date, return_date: this.search.return_date, vehicle_type: this.search.vehicle_type, vehicle: this.search.vehicle, status: this.search.status, approved_by: this.search.approved_by['name'], page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page
          context.totalPending          = response.data.data.totalPending
          context.totalApproved         = response.data.data.totalApproved
          context.totalNotApproved      = response.data.data.totalNotApproved
          context.totalOnDuty           = response.data.data.totalOnDuty
          context.totalDone             = response.data.data.totalDone
          context.totalRequest          = response.data.data.totalRequest

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    getPlant(param){
      let context = this;        
      Api(context, plant.getPlant({search: param.target.value})).onSuccess(function(response) {    
          context.tablePlant.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePlant.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPic(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tablePic.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePic.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getPassenger(param){
      let context = this;        
      Api(context, employee.getEmployee({search: param.target.value})).onSuccess(function(response) {    
          context.tablePassenger.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tablePassenger.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    getVehicle(param){
      let context = this;        
      Api(context, vehicleList.getVehicleByPlant({plant: context.vehicleRequestData.plant, vehicle_type: context.vehicleRequestData.vehicle_type, departure_date: context.vehicleRequestData.departure_date, return_date: context.vehicleRequestData.return_date, clock_gone: context.vehicleRequestData.clock_gone, clock_back: context.vehicleRequestData.clock_back })).onSuccess(function(response) {    
          context.tableVehicle.data = response.data.data;
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.tableVehicle.data = [];
          }
      }).onFinish(function() { 
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add           = true;
      this.form.show          = true;
      this.form.title         = "Add Data";
      this.vehicleRequestData = {};
    },
    edit(id) {
      let context = this;               
      Api(context, vehicleManagement.show(id)).onSuccess(function(response) {
        context.vehicleRequestData           = response.data.data.data;
        context.vehicleRequestData.plant     = response.data.data.data.plant_code;
        context.vehicleRequestData.pic       = response.data.data.dataPic[0];
        context.vehicleRequestData.passenger = response.data.data.dataPassenger;
        context.tableVehicle.data            = response.data.data.dataVehicle;
        if (response.data.data.dataVehicle[0]) {
          context.vehicleRequestData.vehicle   = response.data.data.dataVehicle[0].code;
        }
        context.form.show                    = true;
        context.form.title                   = 'Edit Data'; 
      })
      .call()        
    },
    sendRequest(){
      let api      = null;
      let context  = this;
      let formData = new FormData();
      context.onLoading = true;

      if (context.vehicleRequestData.plant != undefined && context.vehicleRequestData.pic != undefined && context.vehicleRequestData.destination != undefined && context.vehicleRequestData.agenda != undefined && context.vehicleRequestData.departure_date != undefined && context.vehicleRequestData.return_date != undefined && context.vehicleRequestData.clock_gone != undefined && context.vehicleRequestData.clock_back != undefined && context.vehicleRequestData.vehicle_type != undefined && context.vehicleRequestData.vehicle != undefined) {
        formData.append('plant', context.vehicleRequestData.plant);
        formData.append('pic', JSON.stringify(context.vehicleRequestData.pic));
        formData.append('passenger', JSON.stringify(context.vehicleRequestData.passenger));
        formData.append('destination', context.vehicleRequestData.destination);
        formData.append('agenda', context.vehicleRequestData.agenda);
        formData.append('departure_date', context.vehicleRequestData.departure_date);
        formData.append('return_date', context.vehicleRequestData.return_date);
        formData.append('clock_gone', context.vehicleRequestData.clock_gone);
        formData.append('clock_back', context.vehicleRequestData.clock_back);
        formData.append('vehicle_type', context.vehicleRequestData.vehicle_type);
        formData.append('vehicle', context.vehicleRequestData.vehicle);
        if (context.form.title == 'Edit Data') {
          formData.append('status', context.vehicleRequestData.status);
        }
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, vehicleManagement.create(formData));
      } else {
          api = Api(context, vehicleManagement.update(this.vehicleRequestData.id, formData));
      }
      api.onSuccess(function(response) {
        context.get();
        // context.approval(response.data.data.id, 'Approved');
        context.vehicleRequestData = {}
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
        context.onLoading = false;
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;

        Api(context, vehicleManagement.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    approval(id, typeApprov){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      formData.append('type_approval', typeApprov);

      api = Api(context, vehicleManagement.approval(id, formData));
      api.onSuccess(function(response) {
        context.get()
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() { 
      })
      .call();
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 600px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #F0F8FF;
}
</style>
